import {
  publishedZeckCoverPath,
  sectionPublishPath,
  zeckHomePath,
} from '../../../../services/Paths.ts';
import { PublishedZeck } from '../../../../types.ts';
import ViewSidebar from '../ViewSidebar.tsx';

type PublishedSidebarProps = {
  scrollToSectionOrZeckCover: (sectionId?: string) => void;
  onRequestClose(): void;
  zeck: PublishedZeck;
  isOpen: boolean;
};

const PublishedSidebar = ({
  zeck,
  onRequestClose,
  scrollToSectionOrZeckCover,
  isOpen,
}: PublishedSidebarProps) => {
  return (
    <ViewSidebar
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      scrollToSectionOrZeckCover={scrollToSectionOrZeckCover}
      zeck={{
        ...zeck,
        path: publishedZeckCoverPath(zeck),
        homePath: zeckHomePath(zeck),
        sections: zeck.sections.map((section) => ({
          ...section,
          path: sectionPublishPath(section, zeck.companyId),
        })),
      }}
    />
  );
};

export default PublishedSidebar;
