import { Icon } from 'icons';
import { useLocation, useNavigate } from 'react-router-dom';
import TableOfContentsItem from '../../../../../design-system/atoms/TableOfContentsItem.js';
import styles from './TableOfContentsLayout.module.scss';

type TableOfContentsCoverItemProps = {
  to: string;
};

const TableOfContentsCoverItem: React.FC<TableOfContentsCoverItemProps> = ({
  to,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <TableOfContentsItem
      href={to}
      active={location.pathname === to}
      onClick={(e) => {
        e.preventDefault();
        navigate(to);
      }}
    >
      <div className={styles.coverItem}>
        <Icon name="picture" /> <span>Cover</span>
      </div>
    </TableOfContentsItem>
  );
};

export default TableOfContentsCoverItem;
