import { isPointInRect } from '../../../../domHelpers/rectHelpers.js';
import { logError } from '../../../../logging/logger.js';
import { MousePosition } from './useLinkTooltips.js';

export const getClosestRectToMouse = (
  el: HTMLElement,
  position: MousePosition,
) => {
  let closestRect = null;

  try {
    // shitty try/catch but selectNode can throw
    const range = document.createRange();
    range.selectNode(el);
    const rects = range.getClientRects();

    let closestDistance = Infinity;

    for (let i = 0; i < rects.length; i++) {
      const current = rects[i];
      if (current) {
        const rectCenterX = (current.left + current.right) / 2;

        const distance = Math.pow(rectCenterX - position.x, 2);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestRect = current;
        }
      }
    }
  } catch (e) {
    logError(e as Error);
  }

  return closestRect;
};

function padRect(
  rect: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  padPx: number,
) {
  return {
    x: rect.x - padPx,
    y: rect.y - padPx,
    width: rect.width + padPx * 2,
    height: rect.height + padPx * 2,
  };
}

export const isMouseHoveringLink = (
  linkRect: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  tooltipEl: HTMLElement | null,
  mousePos: MousePosition,
) => {
  const hoverZoneRect = tooltipEl?.getBoundingClientRect();

  return (
    isPointInRect(mousePos, padRect(linkRect, 4)) ||
    (hoverZoneRect && isPointInRect(mousePos, hoverZoneRect))
  );
};
