import { Block, FileBlock, ImageBlock } from 'editor-content/Block.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { TableBlock } from 'editor-content/TableBlock.js';
import { IntegrationListItemData } from '../../../api/endpoints/createIntegrationApi.ts';
import { isTextSelection } from '../../../editor/selection/TextSelection.ts';
import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.ts';
import { User } from '../../../types.ts';
import { AiContext } from './AiChartFlow/createAiContext.ts';
import ChartFormattingExperience from './ChartFormattingExperience/ChartFormattingExperience.tsx';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.ts';
import FileFormattingExperience from './FileFormattingExperience.tsx';
import ImageFormattingExperience from './ImageFormattingExperience.tsx';
import TableFormattingExperience from './TableFormattingExperience/TableFormattingExperience.tsx';
import VoteFormattingExperience from './VoteFormattingExperience.tsx';
import ZeckEditorSelection from './ZeckEditorSelection.ts';

export type BlockFormattingMenuProps = {
  blocksWithEl: ElementAndData<Block>[];
  selection: ZeckEditorSelection;
  onSetImageWidth: (width: ImageBlock['width']) => void;
  onSetImageAlign: (align: ImageBlock['align']) => void;
  onReplaceImage: (newData: Pick<ImageBlock, 'guid' | 'dimensions'>) => void;
  onDeleteImage: () => void;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  onReplaceFile: (file: Pick<FileBlock, 'guid' | 'filename'>) => void;
  onReplaceTable: (table: Pick<TableBlock, 'data'>) => void;
  onDeleteFile: () => void;
  user: User;
  selectionComments: SelectionCommentWithActions[];
  integrationData: IntegrationListItemData;
  zeckId: string;
  sectionId: string;
  aiContext: AiContext;
};

const BlockFormattingMenu: React.FC<BlockFormattingMenuProps> = ({
  blocksWithEl,
  selection,
  onSetImageWidth,
  onSetImageAlign,
  onReplaceImage,
  onDeleteImage,
  onAddSelectionComment,
  onReplaceFile,
  onDeleteFile,
  onReplaceTable,
  user,
  selectionComments,
  zeckId,
  sectionId,
  integrationData,
  aiContext,
}) => {
  if (
    !(selection && selection.target === 'body' && isTextSelection(selection))
  ) {
    return null;
  }

  const blockWithEl = blocksWithEl[selection.index];
  if (!blockWithEl) return null;

  const block = blockWithEl.data;

  switch (block.type) {
    case 'image':
      return (
        <ImageFormattingExperience
          key={block.id}
          {...{
            block,
            getEl: blockWithEl.getEl,
            onSetImageWidth,
            onSetImageAlign,
            onReplaceImage,
            onDeleteImage,
            onAddSelectionComment,
            user,
            selectionComments,
            zeckId,
            sectionId,
          }}
        />
      );
    case 'vote':
      return (
        <VoteFormattingExperience
          key={block.id}
          {...{
            block,
            getEl: blockWithEl.getEl,
            onAddSelectionComment,
            user,
            selectionComments,
            zeckId,
            sectionId,
          }}
        />
      );
    case 'file':
      return (
        <FileFormattingExperience
          key={block.id}
          {...{
            block,
            getEl: blockWithEl.getEl,
            onDeleteFile,
            onReplaceFile,
            onAddSelectionComment,
            user,
            selectionComments,
            zeckId,
            sectionId,
          }}
        />
      );
    case 'table':
      return (
        <TableFormattingExperience
          key={block.id}
          {...{
            block,
            getEl: blockWithEl.getEl,
            onReplaceTable,
            user,
            integrationData,
            zeckId,
            sectionId,
            aiContext,
          }}
        />
      );
    case 'paragraph':
      break;
    case 'label':
      break;
    case 'bulleted-list-item':
      break;
    case 'numbered-list-item':
      break;
    case 'heading':
      break;
    case 'chart':
      return (
        <ChartFormattingExperience
          key={block.id}
          {...{
            block,
            getEl: blockWithEl.getEl,
            aiContext,
          }}
        />
      );
  }

  return null;
};

export default BlockFormattingMenu;
