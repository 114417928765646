import { getTextNodesLength } from 'editor-content/TextNode.js';
import {
  contentSelection,
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { EditorContent } from '../../edit/useEditorState.js';
import BodyEditor from '../BodyEditor/BodyEditor.js';
import { getBodyState } from '../getBodyState.js';
import ZeckEditorSelection, {
  isBodySelection,
  isHeadlineSelection,
  isTitleSelection,
} from '../ZeckEditorSelection.js';
import { ZeckEditorState } from './ZeckEditorState.js';

export const pressArrowLeft = (
  content: EditorContent,
  selection: ZeckEditorSelection,
): ZeckEditorState | void => {
  if (isTitleSelection(selection)) {
    return;
  } else if (isHeadlineSelection(selection)) {
    if (!isCollapsed(selection)) return;
    if (selection.anchorOffset === 0)
      return {
        content,
        selection: {
          target: 'title',
          ...contentSelection(content.title.length),
        },
      };
  } else if (isBodySelection(selection)) {
    const result = BodyEditor.pressArrowLeft(getBodyState(content, selection));
    if (!result) return;
    switch (result.type) {
      case 'boundary':
        return {
          content,
          selection: {
            target: 'headline',
            ...contentSelection(getTextNodesLength(content.headline)),
          },
        };
      case 'selection':
        return {
          content,
          selection: {
            target: 'body',
            ...result.selection,
          },
        };
    }
  }
};
