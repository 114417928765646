import { TextFormat } from 'editor-content/TextNode.js';
import {
  ToolbarButton,
  ToolbarGroup,
} from '../../../design-system/organisms/Toolbar.tsx';
import {
  BoldButton,
  ItalicsButton,
  UnderlineButton,
} from '../../../pages/zeck/editor/editableBlocks/FormatTextButton.tsx';
import IndentFormattingActions from '../../../pages/zeck/editor/editableBlocks/IndentFormattingActions.tsx';
import { getSelectionFormat } from '../../selection/contentSelection/getSelectionFormat.ts';

import { BulletedListItemBlock } from 'editor-content/Block.js';
import { TurnIntoable } from '../../../design-system/molecules/TurnIntoMenu.tsx';
import TextFormattingMenu from '../../../design-system/organisms/TextFormattingMenu.tsx';
import { PublicEditorEvent } from '../../EditorEvents.ts';
import ContentSelection from '../../selection/contentSelection/ContentSelection.ts';
import { textBlockIsHighlightedStrategy } from '../textBlocksStrategies/textBlockToggleHighlightStrategy.ts';

type BulletedListItemFormattingMenuProps = {
  block: BulletedListItemBlock;
  selection: ContentSelection;
  dispatch: (e: PublicEditorEvent) => void;
  turnIntoables: TurnIntoable[];
};

export function BulletedListItemFormattingMenu({
  block,
  selection,
  dispatch,
  turnIntoables,
}: BulletedListItemFormattingMenuProps) {
  const format = getSelectionFormat(block, selection);

  const onToggleFormat = (formatName: keyof TextFormat) => {
    const event: PublicEditorEvent = {
      type: 'format',
      data: formatName,
    };
    dispatch(event);
  };

  const onIndent = (amount: -1 | 1) => {
    const event: PublicEditorEvent = {
      type: 'indent',
      data: amount,
    };
    dispatch(event);
  };

  const onHighlight = () => {
    dispatch({
      type: 'toggleHighlight',
    });
  };

  return (
    <TextFormattingMenu
      {...{
        turnIntoables,
        blockDisplayName: 'Bulleted List',
        linkables: [],
        otherActions: (
          <>
            <ToolbarGroup>
              <BoldButton {...{ format, onToggleFormat }} />
              <ItalicsButton {...{ format, onToggleFormat }} />
              <UnderlineButton {...{ format, onToggleFormat }} />
            </ToolbarGroup>
            <ToolbarGroup>
              <IndentFormattingActions value={block} onIndent={onIndent} />
            </ToolbarGroup>
            <ToolbarButton
              iconName={'highlight'}
              onClick={onHighlight}
              active={textBlockIsHighlightedStrategy(block, selection)}
            >
              Highlight
            </ToolbarButton>
          </>
        ),
      }}
    />
  );
}
