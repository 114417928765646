import { Icon } from 'icons';
import Loader from '../../../../../design-system/atoms/Loader.tsx';
import { ModalPanel } from '../../../../../design-system/organisms/Modal.tsx';
import { ReactComponent as ZeckLogo } from '../../../../../images/ZeckBlocksLogoBlack.svg';
import styles from './IntegrationLoader.module.scss';

const IntegrationLoader: React.FC = () => {
  return (
    <div className={styles.loader} data-testid="loading-spinner">
      <Loader size="extra-large" />
    </div>
  );
};

export const ZeckCommunicationLoader: React.FC = () => {
  return (
    <ModalPanel dark>
      <div
        className={styles.zeckCommunicationLoader}
        data-testid="loading-spinner"
      >
        <Icon className={styles.zeckCommunicationImage} name="cloud" />
        <Loader size="large" />
        <ZeckLogo className={styles.zeckCommunicationImage} />
      </div>
    </ModalPanel>
  );
};

export default IntegrationLoader;
