import { z } from 'zod';
import { AgendaBlockSchema } from './AgendaBlock.js';
import { CartaCapTableBlockSchema } from './CartaCapTableBlock.js';
import { DividerBlockSchema } from './DividerBlock.js';
import { FileBlockSchema } from './FileBlock.js';
import { ImageBlockSchema } from './ImageBlock.js';
import { TableBlockSchema } from './TableBlock.js';
import { TextBlockSchema } from './TextBlock.js';
import { VideoBlockSchema } from './VideoBlock.js';
import { VoteBlockSchema } from './VoteBlock.js';
import { ChartBlockSchema } from './ChartBlock.js';

export const BlockSchema = z.union([
  ImageBlockSchema,
  TextBlockSchema,
  AgendaBlockSchema,
  CartaCapTableBlockSchema,
  DividerBlockSchema,
  FileBlockSchema,
  VideoBlockSchema,
  VoteBlockSchema,
  ChartBlockSchema,
  TableBlockSchema,
]);
