import { HydratedBlock } from 'app/types/HydratedBlock.ts';
import { isTextBlock, updateTextBlock } from 'editor-content/Block.ts';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { EditorContent } from '../../edit/useEditorState.js';
import addHighlightToTextNodes from '../BodyEditor/addHighlightToTextNodes.ts';
import removeHighlightFromTextNodes from '../BodyEditor/removeHighlightFromTextNodes.js';
import {
  HeadlineContent,
  HeadlineSelection,
} from '../HeadlineEditor/HeadlineEditor.js';
import { TransientHighlightSelection } from './SelectionCommentsBehavior.js';

export const TRANSIENT_HIGHLIGHT_ID = 'transient-highlight-id';

export const removeTransientHighlightsFromBlock = (block: HydratedBlock) => {
  if (!isTextBlock(block)) return block;
  const content = removeHighlightFromTextNodes(
    block.content,
    TRANSIENT_HIGHLIGHT_ID,
  );
  return updateTextBlock(block, content);
};

type EditorWithContentEditable = {
  editBlock: (
    index: number,
    newBlock: HydratedBlock,
    newSelection: ContentSelection,
  ) => void;
  editHeadline: (newState: {
    content: HeadlineContent;
    selection: HeadlineSelection;
  }) => void;
};
export const withRemoveTransientHighlights = (
  editor: EditorWithContentEditable,
): EditorWithContentEditable => {
  return {
    editBlock: (index, newBlock, newSelection) => {
      editor.editBlock(
        index,
        removeTransientHighlightsFromBlock(newBlock),
        newSelection,
      );
    },
    editHeadline: (newState) => {
      editor.editHeadline({
        ...newState,
        content: removeHighlightFromTextNodes(
          newState.content,
          TRANSIENT_HIGHLIGHT_ID,
        ),
      });
    },
  };
};

export const addTransientHighlights = (
  editorContent: EditorContent,
  highlightSelection: TransientHighlightSelection | null,
): EditorContent => {
  if (!highlightSelection) return editorContent;

  switch (highlightSelection.target) {
    case 'headline':
      return {
        ...editorContent,
        headline: addHighlightToTextNodes(
          editorContent.headline,
          highlightSelection,
          TRANSIENT_HIGHLIGHT_ID,
        ),
      };
    case 'body':
      return {
        ...editorContent,
        body: editorContent.body.map((block, i) => {
          if (i !== highlightSelection.index) return block;
          if (!isTextBlock(block)) return block;

          return updateTextBlock(
            block,
            addHighlightToTextNodes(
              block.content,
              highlightSelection.offset,
              TRANSIENT_HIGHLIGHT_ID,
            ),
          );
        }),
      };
  }
};
