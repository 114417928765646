import { Block } from 'editor-content/Block.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { useState } from 'react';
import IconButton from '../../../../../design-system/atoms/IconButton.tsx';
import HoverNextToPoint from '../../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import DetectsOutsideClick from '../../../../../junkDrawer/DetectsOutsideClick.tsx';
import { User } from '../../../../../types.ts';
import SelectionCommentWithActions from '../SelectionCommentWithActions.ts';
import styles from './BlockCommentsExperience.module.scss';
import SelectionCommentsMenu from './SelectionCommentsMenu.tsx';

type BlockCommentsExperienceProps = {
  block: Block;
  getEl: () => HTMLElement | undefined;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  user: User;
};

const BlockCommentsExperience: React.VFC<BlockCommentsExperienceProps> = ({
  block,
  getEl,
  selectionComments,
  zeckId,
  sectionId,
  onAddSelectionComment,
  user,
}) => {
  const [showComments, setShowComments] = useState(false);

  const commentsForBlock = selectionComments.filter(
    ({ selectionId }) => block.id === selectionId,
  );

  if (commentsForBlock.length === 0) return null;

  return (
    <>
      <IconButton
        className={styles.commentButton}
        name="speechBubbleWithText"
        aria-label="show comments"
        onClick={(e) => {
          e.stopPropagation();
          setShowComments(!showComments);
        }}
      />
      {showComments && (
        <HoverNextToPoint
          usePortal
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height + 24,
            ];
          }}
        >
          <DetectsOutsideClick<HTMLDivElement>
            onClick={() => {
              setShowComments(false);
            }}
          >
            {(containerRef) => (
              <SelectionCommentsMenu
                autofocus
                user={user}
                ref={containerRef}
                comments={commentsForBlock}
                onPostComment={(content) =>
                  onAddSelectionComment(block.id, content)
                }
                zeckId={zeckId}
                sectionId={sectionId}
              />
            )}
          </DetectsOutsideClick>
        </HoverNextToPoint>
      )}
    </>
  );
};

export default BlockCommentsExperience;
