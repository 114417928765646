import { assertUnreachable } from 'editor-content/Block.js';
import { MeetingMinutesBlock } from 'editor-content/MeetingMinutes/MeetingMinutesBlock.ts';
import { createBulletedListItemBlockRenderable } from '../../../../editor/blocks/bulletedListItem/BulletedListItemBlockRenderable.js';
import { createParagraphBlockRenderable } from '../../../../editor/blocks/paragraph/ParagraphBlockRenderable.js';
import { BlockToBlockRenderable } from '../../../../editor/blockToBlockRenderable.js';
import { PublicEditorEvent } from '../../../../editor/EditorEvents.js';
import {
  EditorSelection,
  isBlockSelection,
  isTextSelection,
} from '../../../../editor/EditorSelection.js';
import { indexIsInSelection } from '../../../../editor/selection/BlockSelection.js';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';

export function createBlockRenderables(
  meetingMinutes: MeetingMinutesBlock[],
  selection: EditorSelection | null,
  select: (selection: EditorSelection) => void,
  dispatch: (event: PublicEditorEvent<MeetingMinutesBlock>) => void,
): BlockToBlockRenderable<MeetingMinutesBlock>[] {
  return meetingMinutes.map((block, index) => {
    const blockSpecificContentSelection =
      isTextSelection(selection) && selection.index === index
        ? selection.offset
        : null;

    const isInBlockSelection =
      isBlockSelection(selection) && indexIsInSelection(selection, index);

    const selectBlock = (contentSelection: ContentSelection) =>
      select({ index: index, offset: contentSelection });

    const updateBlock = (newBlock: MeetingMinutesBlock) => {
      const event: PublicEditorEvent<MeetingMinutesBlock> = {
        type: 'updateBlock',
        data: {
          index: index,
          block: newBlock,
        },
      };

      dispatch(event);
    };

    switch (block.type) {
      case 'paragraph': {
        return createParagraphBlockRenderable(
          block,
          updateBlock,
          blockSpecificContentSelection,
          selectBlock,
          isInBlockSelection,
        );
      }
      case 'bulleted-list-item': {
        return createBulletedListItemBlockRenderable(
          block,
          updateBlock,
          blockSpecificContentSelection,
          selectBlock,
          isInBlockSelection,
        );
      }
      default:
        return assertUnreachable(block);
    }
  });
}
