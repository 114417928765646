import { Result } from '../../../../services/useFetch/useFetch.ts';

import useApi from '../../../../api/useApi.ts';
import { logError } from '../../../../logging/logger.ts';
import useExecuteOnLoad from '../../../../result/useExecuteOnLoad.ts';
import useAnalyticsEvents from '../../../../services/useAnalyticsEvents.tsx';
import { PublishedZeck } from '../../../../types.ts';

const useRegisterViewEventsOnLoad = ({
  publishedZeckResult,
}: {
  publishedZeckResult: Result<PublishedZeck>;
}) => {
  const { createPublishedZeckView } = useApi();

  const { trackViewZeck } = useAnalyticsEvents();

  const registerEvents = (publishedZeck: PublishedZeck): void => {
    trackViewZeck(publishedZeck.zeckId);

    createPublishedZeckView({
      companyId: publishedZeck.companyId,
      zeckId: publishedZeck.zeckId,
    }).catch((e) => {
      logError(
        new Error(
          `exception occurred while registering published zeck view: ${e.message}`,
        ),
      );
    });
  };

  useExecuteOnLoad({
    result: publishedZeckResult,
    onLoad: registerEvents,
  });
};

export default useRegisterViewEventsOnLoad;
