import { sectionEditPath, zeckCoverEditPath } from '../../../services/Paths.js';
import { Zeck } from '../../../types.ts';

const redirectAfterSectionDelete = ({
  zeck,
  currentSectionId,
  navTo,
  sectionId,
}: {
  zeck: Zeck;
  currentSectionId: string;
  navTo: (path: string) => void;
  sectionId: string;
}) => {
  if (sectionId !== currentSectionId) return;

  const sections = zeck.sections;
  const sectionIndex = sections.findIndex(({ id }) => id === sectionId);
  const newSections = sections.filter(({ id }) => id !== sectionId);

  const lastSection = newSections[newSections.length - 1];
  const nextSection = newSections[sectionIndex];

  if (nextSection) {
    navTo(sectionEditPath(nextSection, zeck.companyId));
  } else if (lastSection) {
    navTo(sectionEditPath(lastSection, zeck.companyId));
  } else {
    navTo(zeckCoverEditPath(zeck));
  }
};
export default redirectAfterSectionDelete;
