import { Icon } from 'icons';
import { useCallback, useState } from 'react';
import OutlineButton from '../../../../design-system/atoms/OutlineButton.js';
import Modal from '../../../../design-system/organisms/Modal.js';
import styles from './DeletedSections.module.scss';
import DeletedSectionsModal from './DeletedSectionsModal.js';

type DeletedSectionsProps = {
  zeckId: string;
  onRestore: (sectionId: string) => Promise<void>;
};

const DeletedSections = ({ zeckId, onRestore }: DeletedSectionsProps) => {
  const [showModal, setShowModal] = useState(false);

  const onRestoreWithClose = useCallback(
    async (sectionId: string) => {
      await onRestore(sectionId);
      setShowModal(false);
    },
    [setShowModal, onRestore],
  );

  const closeModal = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <div className={styles.deletedSections__wrapper}>
      <div className={styles.deletedSections__divider} />
      <OutlineButton
        data-testid="trash-button"
        size="large"
        color="primary"
        onClick={() => {
          setShowModal(true);
        }}
        className={styles.trashButton}
      >
        <Icon name="trash" className={styles.trashButton__icon} />
        Trash
      </OutlineButton>

      <Modal isOpen={showModal} onRequestClose={closeModal}>
        <div className={styles.deletedSections__modal_wrapper}>
          <DeletedSectionsModal
            zeckId={zeckId}
            onRestore={onRestoreWithClose}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DeletedSections;
