import { CompanyRole, User } from '../types.ts';

export interface UserWithRole extends User {
  role: CompanyRole;
  pending: false;
  boardDirector: boolean;
}

export interface PendingUserWithRole {
  id: string;
  email: string;
  role: CompanyRole;
  pending: true;
  boardDirector: boolean;
}

export type CompanyUser = UserWithRole | PendingUserWithRole;

export type MaybePendingUser =
  | {
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      pending: false;
    }
  | {
      id: string;
      email: string;
      pending: true;
    };

export function isPendingUser<User extends { pending: boolean }>(
  obj: User,
): obj is User & {
  pending: true;
} {
  return obj.pending;
}

export function isNotPendingUser<User extends { pending: boolean }>(
  obj: User,
): obj is User & {
  pending: false;
} {
  return !obj.pending;
}

export type ViewerUserAndPermission = MaybePendingUser & {
  role: 'viewer' | 'admin' | 'member';
};

export type UserAndPermission =
  | ViewerUserAndPermission
  | (MaybePendingUser & {
      sectionId: string;
      role: 'contributor' | 'owner' | 'admin' | 'member';
    });

export type UserAndContributorPermission = MaybePendingUser & {
  role: 'admin' | 'contributor' | 'member';
};
