// Delegate? rendering the content to screen
// Delegate? some way to save updates to the content

import React, { Fragment, useRef, useSyncExternalStore } from 'react';
import { MeetingMinutesEditorCoordinator } from '../../MeetingMinutes.ts';
import { createBlockRenderables } from '../util/createBlockRenderables.ts';

import cx from 'classnames';
import { MeetingMinutesBlock } from 'editor-content/MeetingMinutes/MeetingMinutesBlock.ts';
import { TurnIntoable } from '../../../../design-system/molecules/TurnIntoMenu.tsx';
import EditorLayout from '../../../../design-system/zeck/EditorLayout.tsx';
import { PublicEditorEvent } from '../../../../editor/EditorEvents.ts';
import { isTextSelection } from '../../../../editor/EditorSelection.ts';
import { AddingBlockExperience } from '../../../../editor/addBlock/AddBlockExperience.js';
import BlockHoverControls from '../../../../editor/addBlock/BlockHoverControls.js';
import { useAddBlockMeetingMinutes } from '../../../../editor/addBlock/minutes/MeetingMinutesAddBlock.tsx';
import { BlockToBlockRenderable } from '../../../../editor/blockToBlockRenderable.ts';
import DetectsOutsideEditorClick from '../../../../editor/domFacing/components/DetectsOutsideEditorClick.tsx';
import InsertTagMenu from '../../../../editor/domFacing/components/InsertTagMenu.tsx';
import TextSelectionFormattingMenu from '../../../../editor/domFacing/components/TextSelectionFormattingMenu.tsx';
import {
  callHandlers,
  handleKey,
  preventDefaultIfHandled,
} from '../../../../editor/domFacing/events/isKeyMatch.ts';
import {
  BlockDndCaret,
  BlockDndOverlay,
} from '../../../../editor/dragBlock/DragBlockExperience.js';
import useDragBlock from '../../../../editor/dragBlock/useDragBlock.js';
import MultiblockFormattingExperience from '../../../../editor/multiblockFormatting/MultiblockFormattingExperience.js';
import { fancyNavDown } from '../../../../editor/selection/contentSelection/fancyNav/fancyNavDown.ts';
import { fancyNavUp } from '../../../../editor/selection/contentSelection/fancyNav/fancyNavUp.ts';
import { isOnFirstLineOfBlock } from '../../../../editor/selection/contentSelection/lineDetection/isOnFirstLineOfBlock.ts';
import { isOnLastLineOfBlock } from '../../../../editor/selection/contentSelection/lineDetection/isOnLastLineOfBlock.ts';
import getSelectionRect from '../../../../editor/selection/getSelectionRect.js';
import useMouseSelectionOnEditor from '../../../../editor/selection/useMouseSelectionOnEditor.ts';
import useSetBrowserSelectionWhenNull from '../../../../editor/useSetBrowserSelectionWhenNull.ts';
import assertDefined from '../../../../junkDrawer/assertDefined.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import useElementAndDataArray from '../../../../junkDrawer/useElementAndDataArray.ts';
import { AvailableTag } from '../../../../types/AvailableTag.ts';
import { renderBlocksWithGroupingAndSpacing } from '../../../zeck/editor/blockPreprocessing/groupListRenderables.tsx';
import useExclusiveBlockSelection from '../../../zeck/editor/selection/useExclusiveBlockSelection.js';
import useUndoRedoKeybindings from '../../../zeck/editor/useUndoRedoKeybindings.ts';
import useKeepSelectionInView from '../../../zeck/scrolling/useKeepSelectionInView.js';
import useInsertTag from '../util/useInsertTag.ts';
import styles from './MeetingMinutesEditable.module.scss';

export type MeetingMinutesEditableProps = {
  editor: MeetingMinutesEditorCoordinator;
  allAvailableTags: AvailableTag[];
  additionalContent?: React.ReactNode;
  getScrollContainer: () => HTMLElement | null;
};

function buildTurnIntoables(
  editor: MeetingMinutesEditorCoordinator,
): TurnIntoable[] {
  return [
    {
      displayName: 'Text',
      iconName: 'text',
      onTurnInto: () => {
        editor.dispatch({
          type: 'turnInto',
          data: {
            blockType: 'paragraph',
          },
        });
      },
    },
    {
      displayName: 'Bulleted List',
      iconName: 'bulletedList',
      onTurnInto: () => {
        editor.dispatch({
          type: 'turnInto',
          data: {
            blockType: 'bulleted-list-item',
          },
        });
      },
    },
  ];
}

export default function MeetingMinutesEditable({
  editor,
  allAvailableTags,
  additionalContent,
  getScrollContainer,
}: MeetingMinutesEditableProps) {
  const editorState = useSyncExternalStore(
    (callback) => editor.subscribeToContentChanges(callback),
    () => editor.getState(),
  );

  const documentRef = useRef<HTMLDivElement>(null);
  const leftGutterRef = useRef<HTMLDivElement>(null);
  const blocksWithEl = useElementAndDataArray(editorState.content);

  const {
    tagSelection,
    onPressDownTagHandler,
    onPressEnterTagHandler,
    onPressUpTagHandler,
    matchingTags,
    onInsertTag,
  } = useInsertTag(editor, allAvailableTags);

  const { onMouseDown, onMouseMove, onMouseUp, isSelecting } =
    useMouseSelectionOnEditor(documentRef, blocksWithEl, (selection) => {
      const selectionEvent: PublicEditorEvent = {
        type: 'selection',
        data: selection,
      };
      editor.dispatch(selectionEvent);
    });

  const blockRenderables: BlockToBlockRenderable<MeetingMinutesBlock>[] =
    createBlockRenderables(
      editorState.content,
      editorState.selection,
      (selection) => {
        if (isSelecting) {
          return;
        }
        editor.dispatch({
          type: 'selection',
          data: selection,
        });
      },
      editor.dispatch,
    );

  const blockRenderablesWithEl = blocksWithEl.map((item, index) => ({
    ...item,
    data: assertDefined(blockRenderables[index]),
  }));

  useUndoRedoKeybindings(
    () => {
      editor.dispatch({
        type: 'undo',
      });
    },
    () => {
      editor.dispatch({
        type: 'redo',
      });
    },
  );

  const turnIntoables = buildTurnIntoables(editor);

  const getCurrentSelectionRect = () =>
    getSelectionRect(editorState.selection, blocksWithEl);

  useKeepSelectionInView(
    blocksWithEl,
    editorState.selection,
    isSelecting,
    getScrollContainer,
  );
  useSetBrowserSelectionWhenNull(
    () => documentRef.current,
    editorState.selection,
  );
  const editorEventListenerRef = useExclusiveBlockSelection(
    editorState.selection,
  );

  const handleOutsideClick = () => {
    const selectionEvent: PublicEditorEvent = {
      type: 'selection',
      data: null,
    };
    editor.dispatch(selectionEvent);
  };

  const blockdndBehavior = useDragBlock(
    blocksWithEl,
    getScrollContainer,
    (startIndex, endIndex) => {
      editor.dispatch({
        type: 'dropDraggedBlock',
        data: {
          startIndex,
          endIndex,
        },
      });
    },
    () => {
      editor.dispatch({
        type: 'selection',
        data: null,
      });
    },
  );

  const addBlockBehavior = useAddBlockMeetingMinutes({
    blocksWithEl,
    leftGutterRef,
    dispatch: editor.dispatch,
    pressForwardSlash: () => editor.pressForwardSlash(),
  });
  return (
    <DetectsOutsideEditorClick<HTMLDivElement>
      onOutsideClick={handleOutsideClick}
    >
      {(documentContainerRef) => (
        <EditorLayout
          ref={mergeRefs([
            documentContainerRef,
            documentRef,
            editorEventListenerRef,
          ])}
          leftGutterRef={leftGutterRef}
          onClickGutter={handleOutsideClick}
          className={cx(isSelecting && styles.editorInBlockSelection)}
          onKeyDown={callHandlers([
            handleKey(
              { key: 'Enter' },
              preventDefaultIfHandled(() => {
                const tagHandled = onPressEnterTagHandler();
                if (tagHandled) {
                  return true;
                }

                const params: PublicEditorEvent = {
                  type: 'pressEnter',
                };
                return editor.dispatch(params);
              }),
            ),
            handleKey(
              { key: 'Enter', shiftKey: true },
              preventDefaultIfHandled(() => {
                const params: PublicEditorEvent = {
                  type: 'pressShiftEnter',
                };
                return editor.dispatch(params);
              }),
            ),
            handleKey(
              { key: 'Tab' },
              preventDefaultIfHandled(() => {
                const params: PublicEditorEvent = {
                  type: 'indent',
                  data: 1,
                };
                return editor.dispatch(params);
              }),
            ),
            handleKey(
              { key: 'Tab', shiftKey: true },
              preventDefaultIfHandled(() => {
                const params: PublicEditorEvent = {
                  type: 'indent',
                  data: -1,
                };
                return editor.dispatch(params);
              }),
            ),
            handleKey(
              { key: 'Backspace' },
              preventDefaultIfHandled(() => {
                const params: PublicEditorEvent = {
                  type: 'pressBackspace',
                };
                return editor.dispatch(params);
              }),
            ),
            handleKey(
              { key: ' ' },
              preventDefaultIfHandled(() => {
                const params: PublicEditorEvent = {
                  type: 'pressSpaceBar',
                };
                return editor.dispatch(params);
              }),
            ),
            handleKey(
              { key: 'Delete' },
              preventDefaultIfHandled(() => {
                return editor.dispatch({
                  type: 'delete',
                });
              }),
            ),
            handleKey(
              { key: 'ArrowLeft' },
              preventDefaultIfHandled(() => {
                return editor.dispatch({
                  type: 'pressArrowLeft',
                });
              }),
            ),
            handleKey(
              { key: 'ArrowRight' },
              preventDefaultIfHandled(() => {
                return editor.dispatch({
                  type: 'pressArrowRight',
                });
              }),
            ),
            handleKey(
              { key: 'ArrowUp' },
              preventDefaultIfHandled(() => {
                const tagHandled = onPressUpTagHandler();
                if (tagHandled) {
                  return true;
                }

                return editor.dispatch({
                  type: 'pressArrowUp',
                  data: {
                    isOnFirstLineOfBlock: isOnFirstLineOfBlock(
                      blockRenderablesWithEl,
                    ),
                    fancyNavUp: fancyNavUp(blockRenderablesWithEl),
                  },
                });
              }),
            ),
            handleKey(
              { key: 'ArrowUp', shiftKey: true },
              preventDefaultIfHandled(() => {
                return editor.dispatch({
                  type: 'pressShiftArrowUp',
                });
              }),
            ),
            handleKey(
              { key: 'ArrowDown', shiftKey: true },
              preventDefaultIfHandled(() => {
                return editor.dispatch({
                  type: 'pressShiftArrowDown',
                });
              }),
            ),
            handleKey(
              { key: 'ArrowDown' },
              preventDefaultIfHandled(() => {
                const tagHandled = onPressDownTagHandler();
                if (tagHandled) {
                  return true;
                }

                return editor.dispatch({
                  type: 'pressArrowDown',
                  data: {
                    isOnLastLineOfBlock: isOnLastLineOfBlock(
                      blockRenderablesWithEl,
                    ),
                    fancyNavDown: fancyNavDown(blockRenderablesWithEl),
                  },
                });
              }),
            ),
          ])}
          {...{
            onMouseDown,
            onMouseMove,
            onMouseUp,
          }}
        >
          {renderBlocksWithGroupingAndSpacing(
            blockRenderablesWithEl,
            ({ i, data: blockEditor, setEl, spacingClassName }) => (
              <Fragment key={blockEditor.block.id}>
                <BlockDndCaret blockdnd={blockdndBehavior} currentIndex={i} />
                <blockEditor.Editable
                  ref={setEl}
                  value={blockEditor.block as unknown as never}
                  onChange={blockEditor.updateContent as unknown as never}
                  selection={blockEditor.selection}
                  onSelect={blockEditor.select}
                  className={spacingClassName}
                  isInBlockSelection={blockEditor.isInBlockSelection}
                />
              </Fragment>
            ),
          )}
          <BlockDndCaret
            blockdnd={blockdndBehavior}
            currentIndex={blockRenderablesWithEl.length}
          />

          {isTextSelection(editorState.selection) && (
            <TextSelectionFormattingMenu<MeetingMinutesBlock>
              dispatch={editor.dispatch}
              focusedBlock={blockRenderables[editorState.selection.index]}
              getSelectionRect={getCurrentSelectionRect}
              turnIntoables={turnIntoables}
            />
          )}

          <MultiblockFormattingExperience
            turnIntoables={turnIntoables}
            editorState={editorState}
            blocksWithEl={blocksWithEl}
          />

          <InsertTagMenu
            matchingTags={matchingTags}
            onInsertTag={onInsertTag}
            getSelectionRect={getCurrentSelectionRect}
            tagSelection={tagSelection}
          />

          <BlockHoverControls
            blocksWithEl={blocksWithEl}
            leftGutterRef={leftGutterRef}
            blockdnd={blockdndBehavior}
            addBlockBehavior={addBlockBehavior}
            getEditorContainer={getScrollContainer}
          />
          <AddingBlockExperience addBlockBehavior={addBlockBehavior} />
          <BlockDndOverlay blockdnd={blockdndBehavior} />

          {additionalContent}
        </EditorLayout>
      )}
    </DetectsOutsideEditorClick>
  );
}
