import { useRef } from 'react';
import { ElementAndData } from '../../../../junkDrawer/useElementAndDataArray.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import { BodySelection } from '../BodyEditor/BodyEditor.js';
import { HeadlineSelection } from '../HeadlineEditor/HeadlineEditor.js';
import { TitleSelection } from '../TitleEditor/TitleEditor.js';
import { ZeckBodySelection } from '../ZeckEditorSelection.js';
import useFancyNavBody, {
  fancyNavDown,
  fancyNavUp,
} from './useFancyNavBody.js';

const useFancyNav = ({
  blocksWithEl,
  bodySelection,
  onSelectTitle,
  onSelectHeadline,
  onSelectBody,
  onNavLeft,
  onNavRight,
}: {
  blocksWithEl: ElementAndData<HydratedBlock>[];
  bodySelection: ZeckBodySelection | null;
  onSelectTitle: (newSelection: TitleSelection) => void;
  onSelectHeadline: (newSelection: HeadlineSelection) => void;
  onSelectBody: (newBodySelection: BodySelection | null) => void;
  onNavLeft: () => void;
  onNavRight: () => void;
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const headlineRef = useRef<HTMLParagraphElement>(null);

  const navDownTitle = () => {
    try {
      const headlineEl = headlineRef.current;

      if (headlineEl) {
        return onSelectHeadline(fancyNavDown(headlineEl));
      }
      onNavRight();
    } catch {
      onNavRight();
    }
  };

  const navUpHeadline = () => {
    try {
      const titleEl = titleRef.current;
      if (titleEl) {
        return onSelectTitle(fancyNavUp(titleEl));
      }
      onNavLeft();
    } catch {
      onNavLeft();
    }
  };

  const navDownHeadline = () => {
    const firstBlockWithEl = blocksWithEl[0];
    if (!firstBlockWithEl) return;
    try {
      const firstBlockEl = firstBlockWithEl.getEl();
      if (firstBlockEl) {
        return onSelectBody({
          index: 0,
          offset: fancyNavDown(firstBlockEl),
        });
      }
      onNavRight();
    } catch {
      onNavRight();
    }
  };

  const navUpBody = () => {
    try {
      const headlineEl = headlineRef.current;

      if (headlineEl) {
        return onSelectHeadline(fancyNavUp(headlineEl));
      }
      onNavLeft();
    } catch {
      onNavLeft();
    }
  };

  const bodyNav = useFancyNavBody(
    blocksWithEl,
    bodySelection,
    navUpBody,
    onSelectBody,
    onNavLeft,
    onNavRight,
  );

  return {
    title: {
      onNavUp: () => {},
      onNavDown: navDownTitle,
      ref: titleRef,
    },
    headline: {
      onNavUp: navUpHeadline,
      onNavDown: navDownHeadline,
      ref: headlineRef,
    },
    body: bodyNav,
  };
};

export default useFancyNav;
