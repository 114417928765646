import { CartaCapTable } from 'editor-content/CartaBlock.ts';
import { TableBlock } from 'editor-content/TableBlock.ts';
import { CartaIcon, GoogleSpreadsheetIcon, MicrosoftExcelIcon } from 'icons';
import React, { useState } from 'react';
import { ApiService } from '../../../../../api/createApi.ts';
import {
  IntegrationListItem,
  IntegrationTypes,
} from '../../../../../api/endpoints/createIntegrationApi.ts';
import {
  ModalSidebarItem,
  ModalWithSidebar,
} from '../../../../../design-system/organisms/Modal.tsx';
import styles from './IntegrationsModalFlow.module.scss';
import {
  CartaIntegrationModalFlow,
  CartaIntegrationUIState,
} from './carta/CartaIntegrationModalFlow.tsx';
import {
  GoogleIntegrationFlow,
  GoogleIntegrationUIState,
} from './google/GoogleIntegrationsModalFlow.tsx';
import {
  ExcelIntegrationUIState,
  MicrosoftIntegrationFlow,
} from './microsoft/MicrosoftIntegrationsModalFlow.tsx';

type IntegrationsModalFlowProps = {
  onClose: () => void;
  createIntegration: ApiService['createIntegration'];
  addIntegration: (integration: IntegrationListItem) => void;
  onCreateTable: (table: TableBlock) => void;
  onAddCapTable: (capTable: CartaCapTable) => void;
  integrationType: IntegrationTypes;
};

type UIState =
  | GoogleIntegrationUIState
  | ExcelIntegrationUIState
  | CartaIntegrationUIState;

const getStartingUiState = (integrationType: IntegrationTypes): UIState => {
  switch (integrationType) {
    case 'google':
      return { type: 'SELECT_CREDENTIAL', integrationType };
    case 'excelUpload':
      return { type: 'UPLOAD_DOCUMENT', integrationType };
    case 'excelOnedrive':
      return { type: 'SELECT_CREDENTIAL', integrationType: 'excelOnedrive' };
    case 'cartaCapTable':
      return { type: 'SELECT_CREDENTIAL', integrationType };
  }
};

// state has to live up here because we conditionally render the sidebar
// so this is an attempt to keep the gross bits where this component knows
// too much in one place. We only show the sidebar if you havent picked a
// credential or document yet
const shouldShowSidebar = (uiState: UIState) =>
  (uiState.type === 'SELECT_CREDENTIAL' && !uiState.errorCredential) ||
  uiState.type === 'UPLOAD_DOCUMENT';

const IntegrationModalContent: React.FC<
  Omit<IntegrationsModalFlowProps, 'integrationType'> & {
    uiState: UIState;
    setUIState: (uiState: UIState) => void;
  }
> = ({
  onClose,
  createIntegration,
  addIntegration,
  onCreateTable,
  uiState,
  setUIState,
  onAddCapTable,
}) => {
  if (uiState.integrationType === 'google') {
    return (
      <GoogleIntegrationFlow
        onClose={onClose}
        handleIntegrationData={createIntegration}
        addIntegration={addIntegration}
        onCreateTable={onCreateTable}
        uiState={uiState}
        setUIState={setUIState}
      />
    );
  }

  if (uiState.integrationType === 'cartaCapTable') {
    return (
      <CartaIntegrationModalFlow
        onClose={onClose}
        //handleIntegrationData={createIntegration}
        //addIntegration={addIntegration}
        onAddCapTable={onAddCapTable}
        uiState={uiState}
        setUIState={setUIState}
      />
    );
  }
  return (
    <MicrosoftIntegrationFlow
      onClose={onClose}
      handleIntegrationData={createIntegration}
      addIntegration={addIntegration}
      onCreateTable={onCreateTable}
      uiState={uiState}
      setUIState={setUIState}
    />
  );
};

export const IntegrationsModalFlow: React.FC<IntegrationsModalFlowProps> = ({
  onClose,
  createIntegration,
  addIntegration,
  onCreateTable,
  integrationType,
  onAddCapTable,
}) => {
  const [uiState, setUIState] = useState<UIState>(
    getStartingUiState(integrationType),
  );
  const showSidebar = shouldShowSidebar(uiState);

  if (!showSidebar) {
    return (
      <IntegrationModalContent
        onClose={onClose}
        uiState={uiState}
        setUIState={setUIState}
        createIntegration={createIntegration}
        addIntegration={addIntegration}
        onCreateTable={onCreateTable}
        onAddCapTable={onAddCapTable}
      />
    );
  }

  return (
    <ModalWithSidebar
      sidebar={
        !showSidebar ? null : (
          <div data-testid="integrations-sidebar">
            <div className={styles.sidebar__header}>Integrations</div>
            <ModalSidebarItem
              icon={<GoogleSpreadsheetIcon />}
              label="Google Sheets"
              active={uiState.integrationType === 'google'}
              onClick={() => {
                setUIState(getStartingUiState('google'));
              }}
            />
            <ModalSidebarItem
              icon={<MicrosoftExcelIcon />}
              label="Excel Upload"
              active={uiState.integrationType === 'excelUpload'}
              onClick={() => {
                setUIState(getStartingUiState('excelUpload'));
              }}
            />
            <ModalSidebarItem
              icon={<MicrosoftExcelIcon />}
              label="Excel via OneDrive"
              active={uiState.integrationType === 'excelOnedrive'}
              onClick={() => {
                setUIState(getStartingUiState('excelOnedrive'));
              }}
            />
            <ModalSidebarItem
              icon={<CartaIcon />}
              label="Carta Cap Table"
              active={uiState.integrationType === 'cartaCapTable'}
              onClick={() => {
                setUIState(getStartingUiState('cartaCapTable'));
              }}
            />
          </div>
        )
      }
      className={styles.pickIntegration__wrapper}
    >
      <IntegrationModalContent
        onClose={onClose}
        uiState={uiState}
        setUIState={setUIState}
        createIntegration={createIntegration}
        addIntegration={addIntegration}
        onCreateTable={onCreateTable}
        onAddCapTable={onAddCapTable}
      />
    </ModalWithSidebar>
  );
};

export default IntegrationsModalFlow;
