import useThrottle from '../../pages/zeck/edit/useThrottle.js';
import useElementAndDataArray from '../useElementAndDataArray.js';
import elAndDataToRect from './elAndDataToRect.js';
import useSortable from './useSortable.js';

export default function useSortableWithEls<T>(
  items: T[],
  onReorder: (newItems: T[]) => void,
) {
  const itemsAndEls = useElementAndDataArray(items);

  const itemsSorted = useSortable(
    itemsAndEls.map(elAndDataToRect),
    (newItems) => {
      onReorder(newItems.map(({ data }) => data));
    },
  );

  const throttledDragMove = useThrottle(itemsSorted.dragMove, 100);

  return {
    ...itemsSorted,
    dragMove: (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault(); // stop drag image from flying back to original spot when dropped on list
      throttledDragMove([e.clientX, e.clientY]);
    },
  };
}
