import { useState } from 'react';
import useApi from '../../api/useApi.ts';
import useBreakpoints from '../../services/useBreakpoints.ts';
import { Company, CompanyRole, CompanyUser, User } from '../../types.ts';
import { assignUrlWrapper } from '../../userAndCompany/assignUrlWrapper.ts';
import CannotRemoveAdminModal from './CannotRemoveAdminModal.tsx';
import ConfirmDeleteUser from './ConfirmDeleteUser.tsx';
import PeopleListDesktop from './PeopleListDesktop.tsx';
import PeopleListMobile from './PeopleListMobile.tsx';

export type PeopleListResponsiveViewProps = {
  onUpdateRole: (user: CompanyUser, newRole: CompanyRole) => Promise<void>;
  onUpdateBoardDirector: (
    user: CompanyUser,
    boardDirector: boolean,
  ) => Promise<void>;
  onDelete: (user: CompanyUser) => void;
  onResendInvitation: (user: CompanyUser) => Promise<void>;
  users: CompanyUser[];
  currentUser: User;
};

type PeopleListProps = {
  currentUser: User;
  company: Company;
  setCompany: (company: Company) => void;
  sendInvitation: (emails: Array<string>) => void;
};

const PeopleList = ({
  currentUser,
  sendInvitation,
  company,
  setCompany,
}: PeopleListProps) => {
  const {
    updateCompanyPermissionRole,
    deleteCompanyPermission,
    updateCompanyPermissionBoardDirector,
  } = useApi();
  const [showCannotRemoveAdmin, setShowCannotRemoveAdmin] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] =
    useState<CompanyUser | null>(null);

  const { isMobile } = useBreakpoints();

  const onUpdateRole: PeopleListResponsiveViewProps['onUpdateRole'] = async (
    user,
    newRole,
  ) => {
    if (user.role === newRole) {
      return;
    }

    const admins = company.users.filter((u) => u.role === 'admin');
    if (
      admins.length === 1 &&
      admins[0] &&
      user.id === admins[0].id &&
      newRole !== 'admin'
    ) {
      setShowCannotRemoveAdmin(true);
      return;
    }

    setCompany({
      ...company,
      users: company.users.map((u) => {
        if (u.id === user.id) {
          return {
            ...u,
            role: newRole,
          };
        } else {
          return u;
        }
      }),
    });
    await updateCompanyPermissionRole(company, user, newRole);

    if (user.id === currentUser.id) {
      assignUrlWrapper('/');
    }
  };

  const onUpdateBoardDirector: PeopleListResponsiveViewProps['onUpdateBoardDirector'] =
    async (user, boardDirector) => {
      if (user.boardDirector === boardDirector) {
        return;
      }

      setCompany({
        ...company,
        users: company.users.map((u) => {
          if (u.id === user.id) {
            return {
              ...u,
              boardDirector,
            };
          } else {
            return u;
          }
        }),
      });
      await updateCompanyPermissionBoardDirector(company, user, boardDirector);
    };

  const onDelete: PeopleListResponsiveViewProps['onDelete'] = (user) => {
    const admins = company.users.filter((u) => u.role === 'admin');
    if (admins.length === 1 && admins[0]?.id === user.id) {
      setShowCannotRemoveAdmin(true);
      return;
    }

    setShowConfirmDelete(user);
  };

  const onResendInvitation: PeopleListResponsiveViewProps['onResendInvitation'] =
    // eslint-disable-next-line @typescript-eslint/require-await
    async (user) => {
      sendInvitation([user.email]);
    };

  return (
    <>
      {isMobile ? (
        <PeopleListMobile
          users={company.users}
          currentUser={currentUser}
          onUpdateRole={onUpdateRole}
          onUpdateBoardDirector={onUpdateBoardDirector}
          onResendInvitation={onResendInvitation}
          onDelete={onDelete}
        />
      ) : (
        <PeopleListDesktop
          users={company.users}
          currentUser={currentUser}
          onUpdateRole={onUpdateRole}
          onUpdateBoardDirector={onUpdateBoardDirector}
          onResendInvitation={onResendInvitation}
          onDelete={onDelete}
        />
      )}
      <CannotRemoveAdminModal
        isOpen={showCannotRemoveAdmin}
        onClose={() => {
          setShowCannotRemoveAdmin(false);
        }}
      />
      {showConfirmDelete && (
        <ConfirmDeleteUser
          isOpen={Boolean(showConfirmDelete)}
          user={showConfirmDelete}
          onDelete={async () => {
            if (showConfirmDelete) {
              await deleteCompanyPermission(company, showConfirmDelete);
              setShowConfirmDelete(null);
              setCompany({
                ...company,
                users: company.users.filter(
                  (u) => u.id !== showConfirmDelete.id,
                ),
              });

              if (showConfirmDelete.id === currentUser.id) {
                assignUrlWrapper('/');
              }
            }
          }}
          onCancel={() => {
            setShowConfirmDelete(null);
          }}
        />
      )}
    </>
  );
};
export default PeopleList;
