import { AiWizardBadge } from '../atoms/AiWizardBadge.js';
import ThreeDotLoading from '../molecules/ThreeDotLoading.tsx';
import styles from './AiLoader.module.scss';

export const AiLoader: React.FC = () => {
  return (
    <div className={styles.container}>
      <AiWizardBadge />

      <ThreeDotLoading className={styles.icon} />
    </div>
  );
};
