import { useState } from 'react';
import useApi from '../../api/useApi.ts';
import {
  AccessPageContent,
  AccessPageLayout,
} from '../../design-system/organisms/AccessPage.js';
import SigninPage, { SigninPageProps } from '../auth/signin/SigninPage.tsx';
import RequestMagicLinkForm from './RequestMagicLinkForm.tsx';
import styles from './RequestMagicLinkPage.module.scss';
import TextButton from './TextButton.tsx';

export type RequestMagicLinkWorkflowProps = (
  | { zeckId: string }
  | { sectionId: string }
) & { path: string; error?: string } & SigninPageProps;

export default function RequestMagicLinkWorkflow({
  onSignIn,
  error,
  ...identifier
}: RequestMagicLinkWorkflowProps) {
  const { createMagicLink } = useApi();

  const [uiState, setUiState] = useState<
    'requesting' | 'requested' | 'useEmailPassword'
  >('requesting');
  const [requestedEmail, setRequestedEmail] = useState('');

  // Render the page directly instead of re-routing and losing the URL
  if (uiState === 'useEmailPassword') {
    return <SigninPage onSignIn={onSignIn} />;
  }

  return (
    <AccessPageLayout>
      {uiState === 'requesting' && (
        <AccessPageContent
          {...{
            title: 'Sign In',
            description:
              "Please enter your email address, and we'll send you a temporary, secure link to sign in to Zeck.",
            actions: (
              <>
                <RequestMagicLinkForm
                  error={error}
                  onSubmit={async (email) => {
                    await createMagicLink({ ...identifier, email });
                    setUiState('requested');
                    setRequestedEmail(email);
                  }}
                />
                <p>
                  Or,{' '}
                  <TextButton
                    onClick={() => {
                      setUiState('useEmailPassword');
                    }}
                  >
                    sign in with your password
                  </TextButton>
                  .
                </p>
              </>
            ),
          }}
        />
      )}

      {uiState === 'requested' && (
        <AccessPageContent
          {...{
            title: 'Check Your Inbox',
            description: (
              <>
                <p>
                  If you have access to this page, you’ll receive an email to{' '}
                  <span className={styles.requestedEmail}>
                    {requestedEmail}
                  </span>
                  . Please click the link in the email to sign in.
                </p>
                <br />
                <p>
                  If you don’t have access to this page, or have requested
                  access, you may want to reach out to the company admin for
                  more information.
                </p>
              </>
            ),
          }}
        />
      )}
    </AccessPageLayout>
  );
}
