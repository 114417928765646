import cx from 'classnames';
import styles from './SectionList.module.scss';

type SectionListProps = React.ComponentProps<'ol'>;

export const SectionList: React.VFC<SectionListProps> = ({
  children,
  className,
}) => {
  return <ol className={cx(className, styles.sectionList)}>{children}</ol>;
};

type SectionListItemProps = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  light?: boolean;
  active?: boolean;
  onClick: () => void;
  className?: string;
};

export const SectionListItem: React.VFC<SectionListItemProps> = ({
  children,
  onClick,
  icon,
  active,
  light,
  className,
}) => {
  return (
    <li>
      <button
        className={cx(
          styles.sectionList__button,
          active && styles.sectionList__button_active,
          light && styles.sectionList__button_light,
          className,
        )}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        {icon}
        <span className={styles.sectionList__buttonText}>{children}</span>
      </button>
    </li>
  );
};
