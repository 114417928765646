import slugifyLib from '@sindresorhus/slugify';
import memoize from 'lodash/memoize.js';
import {
  PublishedSection,
  PublishedZeck,
  Section,
  Zeck,
  ZeckLite,
} from '../types.ts';
import {
  getSlugFromPublishedSection,
  isPublishedSection,
} from '../types/PublishedSection.js';
import { getSlugFromSection } from '../types/Section.js';

export const getZeckBaseUrl = (
  zeck: Pick<Zeck | ZeckLite, 'id' | 'name' | 'companyId'>,
) => {
  const titleSlug = slugify(zeck.name.slice(0, 16));
  return `company/${zeck.companyId}/${titleSlug}-${zeck.id}`;
};

export const getPublishedZeckBaseUrl = (publishedZeck: PublishedZeck) => {
  const titleSlug = slugify(publishedZeck.name.slice(0, 16));
  return `/company/${publishedZeck.companyId}/${titleSlug}-${publishedZeck.zeckId}`;
};

export const sectionPublishPath = (
  section:
    | Pick<Section, 'id' | 'title'>
    | Pick<PublishedSection, 'id' | 'sectionId' | 'title'>,
  companyId: string,
): string => {
  const sectionSlug = isPublishedSection(section)
    ? getSlugFromPublishedSection(section)
    : getSlugFromSection(section);

  return `/company/${companyId}/s/${sectionSlug}`;
};

export const sectionPreviewPath = (
  section: Section | PublishedSection,
  companyId: string,
): string => {
  const slug = isPublishedSection(section)
    ? getSlugFromPublishedSection(section)
    : getSlugFromSection(section);

  return `/company/${companyId}/s/${slug}/preview`;
};

export const sectionEditPath = (
  section:
    | Pick<Section, 'id' | 'title'>
    | Pick<PublishedSection, 'id' | 'sectionId' | 'title'>,
  companyId: string,
): string => {
  if (isPublishedSection(section)) {
    return `/company/${companyId}/s/${getSlugFromPublishedSection(
      section,
    )}/edit`;
  }
  return `/company/${companyId}/s/${getSlugFromSection(section)}/edit`;
};

export const sectionPresentPath = (
  section: Section | PublishedSection,
  companyId: string,
): string => {
  if (isPublishedSection(section)) {
    return `/company/${companyId}/s/${getSlugFromPublishedSection(
      section,
    )}/present`;
  }
  return `/company/${companyId}/s/${getSlugFromSection(section)}/edit/present`;
};

export const zeckEditPath = (
  zeck: Pick<Zeck | ZeckLite, 'id' | 'name' | 'companyId'>,
): string => {
  return `/${getZeckBaseUrl(zeck)}/edit`;
};

export const zeckCoverEditPath = (zeck: Zeck | ZeckLite): string => {
  return `/${getZeckBaseUrl(zeck)}/cover/edit`;
};

export const zeckPreviewPath = (zeck: Zeck | ZeckLite): string => {
  return `/${getZeckBaseUrl(zeck)}/preview`;
};

export const zeckAnalyticsPath = (zeck: Zeck | ZeckLite): string => {
  return `/${getZeckBaseUrl(zeck)}/analytics`;
};

export const zeckPublishPath = (zeck: Zeck | ZeckLite): string => {
  return `/${getZeckBaseUrl(zeck)}`;
};

export const publishedZeckCoverPath = (publishedZeck: PublishedZeck) => {
  return getPublishedZeckBaseUrl(publishedZeck);
};

export const meetingMinutesPath = (meetingMinutes: {
  id: string;
  companyId: string;
}): string =>
  `/company/${meetingMinutes.companyId}/minutes/${meetingMinutes.id}`;

export const meetingMinutesPrintPath = (meetingMinutes: {
  id: string;
  companyId: string;
}): string => `${meetingMinutesPath(meetingMinutes)}/print`;

export const zeckFolderPath = (folder: {
  id: string;
  companyId: string;
}): string => `/company/${folder.companyId}/folder/${folder.id}`;

export const zeckHomePath = (zeck: {
  folderId: string | null;
  companyId: string;
}): string =>
  zeck.folderId
    ? zeckFolderPath({
        id: zeck.folderId,
        companyId: zeck.companyId,
      })
    : '/';

export const companyHomePath = (companyId: string): string =>
  `/company/${companyId}`;

// this function is heavy enough to be worth memoizing
export const slugify = memoize(
  (input: string): string => {
    return slugifyLib(input);
  },
  (str) => {
    return str;
  },
);
