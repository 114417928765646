import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { ImageBlockSchema } from 'blocks/src/ImageBlock.ts';
import { TextBlockSchema } from 'blocks/src/TextBlock.ts';
import { TextSchema } from 'blocks/src/TextNode.ts';

const c = initContract();

const CommentContentSchema = z.array(
  z.discriminatedUnion('type', [
    z.object({
      type: z.literal('text'),
      text: z.string(),
    }),
    z.object({
      type: z.literal('tag'),
      userId: z.string().uuid(),
      displayName: z.string(),
    }),
  ]),
);
const CommentReplyContentSchema = z.array(
  z.discriminatedUnion('type', [
    z.object({
      type: z.literal('text'),
      text: z.string(),
    }),
    z.object({
      type: z.literal('tag'),
      userId: z.string().uuid(),
      displayName: z.string(),
    }),
  ]),
);

export const PublishedCommentReplySchema = z.object({
  id: z.string().uuid(),
  createdAt: z.number(),
  updatedAt: z.number(),

  parentId: z.string().uuid(),
  userId: z.string().uuid(),

  userName: z.string(),
  content: CommentReplyContentSchema,
  type: z.enum(['CommentReply', 'DirectMessageReply']),
});

const ContentSelectionSchema = z.object({
  anchorOffset: z.number(),
  focusOffset: z.number(),
});

const PublishedTableCommentSelectionSchema = z.object({
  block: z.object({
    id: z.string(),
    type: z.literal('table-cell'),
    content: z.array(TextSchema),
  }),
  snippetSelection: z.object({
    rowIndex: z.number(),
    columnIndex: z.number(),
  }),
});

const PublishedImageCommentSelectionSchema = z.object({
  block: ImageBlockSchema,
  snippetSelection: z.null(),
});

const PublishedTextCommentSelectionSchema = z.object({
  block: TextBlockSchema,
  snippetSelection: ContentSelectionSchema,
});

const PublishedCommentSelectionSchema = z.union([
  PublishedImageCommentSelectionSchema,
  PublishedTextCommentSelectionSchema,
  PublishedTableCommentSelectionSchema,
]);

export type PublishedImageCommentSelection = z.infer<
  typeof PublishedImageCommentSelectionSchema
>;
export type PublishedTextCommentSelection = z.infer<
  typeof PublishedTextCommentSelectionSchema
>;
export type PublishedTableCommentSelection = z.infer<
  typeof PublishedTableCommentSelectionSchema
>;

export type PublishedCommentSelection = z.infer<
  typeof PublishedCommentSelectionSchema
>;

export const PublishedCommentSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.number(),
  updatedAt: z.number(),

  sectionId: z.string().uuid(),
  userId: z.string().uuid(),

  userName: z.string(),
  content: CommentContentSchema,
  replies: z.array(PublishedCommentReplySchema),
  viewers: z.array(z.string().uuid()),
  type: z.enum(['Comment', 'DirectMessage']),
  resolved: z.boolean(),
  starred: z.boolean(),
  selection: z.union([PublishedCommentSelectionSchema, z.null()]),
});

const AuthHeadersSchema = z.object({
  authorization: z.string().startsWith('Bearer '),
});

const PublishedCommentTagsSchema = z.object({
  resolved: z.boolean(),
  starred: z.boolean(),
});

export const publishedCommentContract = c.router({
  create: {
    summary: 'Create a published comment',
    method: 'POST',
    path: '/section/:sectionId/published-comment',
    pathParams: z.object({ sectionId: z.string().uuid() }),
    responses: {
      201: PublishedCommentSchema,
      401: c.type(),
      403: c.type(),
      404: c.type(),
      426: c.type(),
      500: c.type(),
    },
    body: z.object({
      content: CommentContentSchema,
      selection: z.optional(PublishedCommentSelectionSchema),
      directMessage: z.boolean(),
    }),
    headers: AuthHeadersSchema,
    strictStatusCodes: true,
  },
  update: {
    summary: 'Update a published comment',
    method: 'PATCH',
    path: '/published-comment/:publishedCommentId',
    responses: {
      200: PublishedCommentSchema,
    },
    pathParams: z.object({ publishedCommentId: z.string().uuid() }),
    headers: AuthHeadersSchema,
    body: z.object({
      content: CommentContentSchema,
    }),
  },
  tagsUpdate: {
    summary: "Update a published comment's tags",
    method: 'PATCH',
    path: '/published-comment/:publishedCommentId/tags',
    responses: {
      200: PublishedCommentTagsSchema,
      401: c.type(),
      403: c.type(),
      404: c.type(),
      500: c.type(),
    },
    pathParams: z.object({ publishedCommentId: z.string().uuid() }),
    headers: AuthHeadersSchema,
    body: PublishedCommentTagsSchema.partial(),
    strictStatusCodes: true,
  },
  getAll: {
    summary: 'Get all published comments for a zeck',
    method: 'GET',
    path: '/zeck/:zeckId/published-comment',
    pathParams: z.object({ zeckId: z.string().uuid() }),
    responses: {
      200: z.array(PublishedCommentSchema),
    },
    headers: AuthHeadersSchema,
  },
  delete: {
    summary: 'Delete a published comment',
    method: 'DELETE',
    path: '/published-comment/:publishedCommentId',
    pathParams: z.object({ publishedCommentId: z.string().uuid() }),
    responses: {
      200: z.null(),
    },
    headers: AuthHeadersSchema,
  },
  replyCreate: {
    summary: 'Create a published comment reply',
    method: 'POST',
    path: '/published-comment/:publishedCommentId/reply',
    pathParams: z.object({ publishedCommentId: z.string().uuid() }),
    responses: {
      201: PublishedCommentReplySchema,
    },
    body: z.object({
      content: CommentReplyContentSchema,
    }),
    headers: AuthHeadersSchema,
  },
  replyUpdate: {
    summary: 'Update a published comment reply',
    method: 'PATCH',
    path: '/published-comment-reply/:publishedCommentReplyId',
    responses: {
      200: PublishedCommentReplySchema,
    },
    pathParams: z.object({ publishedCommentReplyId: z.string().uuid() }),
    headers: AuthHeadersSchema,
    body: z.object({
      content: CommentContentSchema,
    }),
  },
  replyDelete: {
    summary: 'Delete a published comment reply',
    method: 'DELETE',
    path: '/published-comment-reply/:publishedCommentReplyId',
    pathParams: z.object({ publishedCommentReplyId: z.string().uuid() }),
    responses: {
      200: z.null(),
    },
    headers: AuthHeadersSchema,
  },
});
