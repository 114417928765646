import cx from 'classnames';
import { Icon, IconName } from 'icons';
import React from 'react';
import styles from './IconButton.module.scss';

export type IconButtonProps = {
  className?: string;
  name: IconName;
  type?: 'normal' | 'filled' | 'dark';
  'aria-label': string;
  active?: boolean;
  disabled?: boolean;
  width?: number;
  height?: number;
};

const getIconStyle = (type: IconButtonProps['type']) => {
  switch (type) {
    case 'normal':
      return null;
    case 'filled':
      return styles.iconButton_filled;
    case 'dark':
      return styles.iconButton_dark;
  }
};

const IconButton = React.forwardRef<
  HTMLButtonElement,
  IconButtonProps & React.HTMLAttributes<HTMLButtonElement>
>(function IconButton(
  {
    className,
    name,
    type = 'normal',
    active = false,
    disabled = false,
    width = 24,
    height = 24,
    ...otherProps
  },
  forwardedRef,
) {
  const maybeDisabledProps = disabled
    ? { disabled, 'aria-disabled': true }
    : {};
  return (
    <button
      {...otherProps}
      {...maybeDisabledProps}
      type="button"
      ref={forwardedRef}
      className={cx(
        className,
        styles.iconButton,
        getIconStyle(type),
        active && styles.iconButton_active,
      )}
    >
      <Icon name={name} width={width} height={height} />
    </button>
  );
});

export default IconButton;
