import { useState } from 'react';
import { KebabItem } from '../../../../../design-system/organisms/KebabMenu.js';
import KebabMenuButton from '../../../../../design-system/organisms/KebabMenuButton.tsx';
import { rightAlignedBelow } from '../../../../../domHelpers/hoverNextTo/positionStrategy/rightAlignedBelow.js';
import CommentDeleteConfirmation from './CommentDeleteConfirmation.tsx';

type CommentKebabProps = {
  onClickEdit?: () => void;
  onDelete?: () => void;
  onUnresolve?: () => void;
  commentTypeLabel: string;
  className?: string;
};

const CommentKebab: React.FC<CommentKebabProps> = ({
  onClickEdit,
  onDelete,
  onUnresolve,
  commentTypeLabel,
  className,
}) => {
  const [isManaging, setIsManaging] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const kebabItems: KebabItem[] = [];
  if (onClickEdit) {
    kebabItems.push({
      label: 'Edit',
      iconName: 'edit',
      onClick: () => {
        setIsManaging(false);
        onClickEdit();
      },
    });
  }

  if (onUnresolve) {
    kebabItems.push({
      label: 'Re-open',
      iconName: 'edit',
      onClick: () => {
        setIsManaging(false);
        onUnresolve();
      },
    });
  }

  if (onDelete) {
    kebabItems.push({
      label: 'Delete',
      iconName: 'trash',
      color: 'danger',
      // this can't use KebabModalItem. it uses the size of its parent
      onClick: () => {
        setIsManaging(false);
        setIsConfirmingDelete(true);
      },
    });
  }

  if (kebabItems.length < 1) {
    return null;
  }

  return (
    <>
      <KebabMenuButton
        className={className}
        items={kebabItems}
        isOpen={isManaging}
        onOpen={() => setIsManaging(true)}
        onClose={() => setIsManaging(false)}
        ariaLabel={'manage-comment'}
        drawerHeading="Comments"
        enableDrawer={true}
        iconName="kebab"
        positionStrategy={rightAlignedBelow(8)}
        viewportPolicy={'none'}
        containerStyles={{ zIndex: 'initial' }}
      />

      {onDelete && (
        <CommentDeleteConfirmation
          commentTypeLabel={commentTypeLabel}
          isOpen={isConfirmingDelete}
          onCancel={() => setIsConfirmingDelete(false)}
          onConfirm={() => {
            setIsConfirmingDelete(false);
            onDelete();
          }}
        />
      )}
    </>
  );
};

export default CommentKebab;
