import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../api/useApi.js';
import { foldResult, pipe } from '../../result/Result.ts';
import { zeckFolderPath } from '../../services/Paths.js';
import useFetchInvalidate from '../../services/useFetch/useFetchInvalidate.js';
import usePageTracking from '../../services/usePageTracking.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.js';
import useHomepageFolders from '../useHomepageFolders.js';
import MemberHomePage from './MemberHomePage.js';

const MemberHomepageContainer: React.FC<{
  userAndCompany: UserAndCompany;
  onClickLogout: () => void;
}> = (props) => {
  const navigate = useNavigate();
  const { folderId } = useParams();
  const activeCompanyId = props.userAndCompany.activeCompany.id;
  const pageName = folderId ? 'zeck_folder' : 'home';
  usePageTracking(pageName, props.userAndCompany);

  const { listZecks } = useApi();

  const { result: zecksResult } = useFetchInvalidate(
    () => listZecks(props.userAndCompany.activeCompany, folderId),
    [folderId],
  );

  const { foldersResult } = useHomepageFolders(activeCompanyId, (folder) =>
    navigate(zeckFolderPath(folder)),
  );

  const isHome = !folderId;
  const pageTitle = !isHome
    ? pipe(
        foldersResult,
        foldResult({
          success: (folders) =>
            folders.find((folder) => folder.id === folderId)?.name || '',
          loading: () => '',
          error: () => '',
        }),
      )
    : 'Home';

  return (
    <MemberHomePage
      {...{
        onClickLogout: props.onClickLogout,
        userAndCompany: props.userAndCompany,
        activePage: folderId || 'home',
        pageTitle,
        zecksResult,
        foldersResult,
        isHome,
      }}
    />
  );
};

export default MemberHomepageContainer;
