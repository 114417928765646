import { VoteBlock } from 'editor-content/VoteBlock.js';
import React from 'react';
import {
  isCompletedVoteBlock,
  isOutstandingVoteBlock,
} from '../../../VoteBlockHydrated.ts';
import CompletedVoteView from './CompletedVoteView.tsx';
import OutstandingVoteView from './OutstandingVoteView.tsx';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
  generateVoteCapabilitiesForBlock,
} from './VoteCapability.js';

type VoteViewProps = {
  className?: string;
  block: VoteBlock;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
};

const VoteView = React.forwardRef<HTMLDivElement, VoteViewProps>(
  function VoteView(
    { block, className, zeckFinalizeVoteCapability, zeckPrevoteCapability },
    forwardedRef,
  ) {
    if (isCompletedVoteBlock(block)) {
      return (
        <CompletedVoteView
          ref={forwardedRef}
          block={block}
          editVote={zeckFinalizeVoteCapability?.editVote ?? null}
          className={className}
        />
      );
    }

    const { prevoteCapability, finalizeVoteCapability } =
      generateVoteCapabilitiesForBlock({
        block,
        zeckFinalizeVoteCapability,
        zeckPrevoteCapability,
      });

    if (isOutstandingVoteBlock(block)) {
      // this kinda sucks tho
      return (
        <OutstandingVoteView
          ref={forwardedRef}
          block={block}
          className={className}
          prevoteCapability={prevoteCapability}
          finalizeVoteCapability={finalizeVoteCapability}
        />
      );
    }

    return null;
  },
);

export default VoteView;
