import debounce from 'lodash/debounce.js';
import { useEffect } from 'react';

export default function useProvidesViewportHeight(
  containerRef: React.RefObject<HTMLElement>,
) {
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const height = containerRef.current.clientHeight;
    containerRef.current.style.setProperty('--viewportHeight', `${height}px`);

    const onResize = debounce(() => {
      if (!containerRef.current) {
        return;
      }
      const height = containerRef.current.clientHeight;
      containerRef.current.style.setProperty('--viewportHeight', `${height}px`);
    }, 100);

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [containerRef]);
}
