import { Editor, Text, TextFormat } from 'editor-content/TextNode.js';
import {
  Cell,
  CellErrorValue,
  CellSharedFormulaValue,
  CellValue,
  ValueType,
} from 'exceljs';
import { isDate } from 'lodash';
import SSF from 'ssf';
import getCellType from './getCellType.js';

const isErrorCellResult = (
  cell: CellSharedFormulaValue['result'],
): cell is CellErrorValue => {
  return cell?.hasOwnProperty('error') ?? false;
};

const isErrorCellValue = (cell: CellValue): cell is CellErrorValue => {
  return cell?.hasOwnProperty('error') ?? false;
};

const formatNumber = (value: CellValue, cell: Cell) => {
  if (!cell.numFmt) return cell.text;
  try {
    return SSF.format(cell.numFmt, value);
  } catch {
    return cell.text;
  }
};

const formatDate = (value: CellValue, cell: Cell) => {
  if (!isDate(value)) return cell.text;

  try {
    return SSF.format(
      cell.numFmt || 'mm/dd/yyyy',
      new Date(
        new Date(value).getTime() +
          new Date(value).getTimezoneOffset() * 60 * 1000,
      ),
    );
  } catch {
    return SSF.format(
      'mm/dd/yyyy',
      new Date(
        new Date(value).getTime() +
          new Date(value).getTimezoneOffset() * 60 * 1000,
      ),
    );
  }
};

const getTextValue = (cell: Cell, valueTypes: typeof ValueType): string => {
  if (cell.type === valueTypes.Formula) {
    // the library types for this are wrong so need to do this ugly cast
    const cellResultWithError =
      cell.result as unknown as CellSharedFormulaValue['result'];
    if (isErrorCellResult(cellResultWithError)) {
      return cellResultWithError.error;
    }
  }

  if (cell.type === valueTypes.Merge) {
    return '';
  }

  // get result of formula, or value if no formula
  // the library types on this are technically wrong (cell.result can be undefined)
  const effectiveValue =
    (cell.result as Cell['result'] | undefined) ?? cell.value;

  // get type of formula result, or type if no formula
  const effectiveType = getCellType(cell);

  switch (effectiveType) {
    case valueTypes.Null:
      return '';
    case valueTypes.Merge:
      return ''; // don't duplicate contents of merged cells
    case valueTypes.Formula:
      // effective type should never be formula, but we have it here to make typescript see that the switch is exhaustive
      return '';
    case valueTypes.Number:
      return formatNumber(effectiveValue, cell);
    case valueTypes.Date:
      return formatDate(effectiveValue, cell);
    case valueTypes.Error: {
      if (cell.value && isErrorCellValue(cell.value)) {
        return cell.value.error;
      }
      return '';
    }
    case valueTypes.String:
    case valueTypes.Hyperlink:
    case valueTypes.SharedString:
    case valueTypes.RichText:
    case valueTypes.Boolean:
      return cell.text || '';
  }
};

const getTextNodesFromCell = (
  cell: Cell,
  valueTypes: typeof ValueType,
): Editor.Text[] => {
  const textValue = getTextValue(cell, valueTypes).trim();

  const textFormatting: TextFormat = {};
  if (cell.style.font?.bold) {
    textFormatting.bold = true;
  }
  if (cell.style.font?.italic) {
    textFormatting.italic = true;
  }
  if (cell.style.font?.underline && cell.style.font?.underline !== 'none') {
    textFormatting.underline = true;
  }

  return [Text(textValue, textFormatting)];
};

export default getTextNodesFromCell;
