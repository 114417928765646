import React from 'react';
import useApi from '../../api/useApi.js';
import ButtonWithIcon from '../../design-system/atoms/ButtonWithIcon.tsx';
import IconButton from '../../design-system/atoms/IconButton.js';
import Toast from '../../design-system/molecules/toast/Toast.tsx';
import useToast from '../../design-system/molecules/toast/useToast.ts';
import isRequestErrorNotPanic from '../../junkDrawer/isRequestErrorNotPanic.ts';
import { Tagged } from '../../junkDrawer/tagged.ts';
import { useDocumentTitle } from '../../junkDrawer/useDocumentTitleFromResult.ts';
import useBreakpoints from '../../services/useBreakpoints.js';
import usePageTracking from '../../services/usePageTracking.ts';
import { User } from '../../types.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import {
  ManagementPageContent,
  ManagementPageShell,
  ManagementPageTitle,
} from '../ManagementPage.tsx';
import PageLayoutFetchesFolders from '../PageLayoutFetchesFolders.tsx';
import ProfileLabel from './ProfileLabel.js';
import UserEmailChangeForm from './UserEmailChangeForm.js';
import UserEmailVerificationForm from './UserEmailVerificationForm.js';
import UserNameChangeForm from './UserNameChangeForm.tsx';
import styles from './UserProfilePage.module.scss';

type UserProfilePageProps = {
  userAndCompany: UserAndCompany;
  onLogout: () => void;
  onLogoutNoRedirect: () => void;
};

const Value: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div className={styles.userProfile__value}>{children}</div>
);

type UIState =
  | Tagged<'changing-email', void>
  | Tagged<'viewing', void>
  | Tagged<'confirming', { emailChangeRequestId: string; newEmail: string }>;

const UserEmailPanel: React.FC<{
  email: string;
  onLogout: () => void;
}> = ({ email, onLogout }) => {
  const [uiState, setUiState] = React.useState<UIState>(
    Tagged('viewing', undefined),
  );

  const {
    createUserEmailChangeRequest,
    confirmUserEmailChangeRequest,
    resendEmailChangeRequest,
  } = useApi();

  const { isMobile } = useBreakpoints();
  const { showToast, ...toast } = useToast();
  return (
    <div className={styles.userProfile__row}>
      {uiState.type === 'viewing' && (
        <>
          <div>
            <ProfileLabel>Email</ProfileLabel>
            <Value>{email}</Value>
          </div>

          {isMobile ? (
            <IconButton
              name="edit"
              aria-label="Change"
              data-testid="change-email-button"
              className={styles.userProfile__changeButton_mobile}
              onClick={() => setUiState(Tagged('changing-email', undefined))}
            />
          ) : (
            <ButtonWithIcon
              iconName="edit"
              onClick={() => setUiState(Tagged('changing-email', undefined))}
              size="medium"
              color="secondary"
              position="left"
              data-testid="change-email-button"
            >
              Change
            </ButtonWithIcon>
          )}
        </>
      )}

      {(uiState.type === 'changing-email' || uiState.type === 'confirming') && (
        <UserEmailChangeForm
          email={email}
          onSubmit={async (newEmail) => {
            try {
              const { emailChangeRequestId } =
                await createUserEmailChangeRequest(newEmail);

              setUiState(
                Tagged('confirming', {
                  emailChangeRequestId,
                  newEmail,
                }),
              );

              return { type: 'success' };
            } catch (e) {
              if (isRequestErrorNotPanic(e) && e.userMessage) {
                return {
                  type: 'error',
                  userMessage: e.userMessage,
                };
              }

              throw e;
            }
          }}
          onCancel={() => setUiState(Tagged('viewing', undefined))}
        />
      )}

      {uiState.type === 'confirming' && (
        <UserEmailVerificationForm
          {...uiState.data}
          onCancel={() => setUiState(Tagged('viewing', undefined))}
          onResend={async () => {
            try {
              await resendEmailChangeRequest(uiState.data.emailChangeRequestId);

              showToast('Email verification code resent');
            } catch (e) {
              if (isRequestErrorNotPanic(e)) return;

              throw e;
            }
          }}
          onSubmit={async (confirmationCode: string) => {
            if (uiState.type !== 'confirming')
              throw new Error('you should not be able to call me');

            try {
              await confirmUserEmailChangeRequest({
                emailChangeRequestId: uiState.data.emailChangeRequestId,
                confirmationCode,
              });

              onLogout();

              return { type: 'success' };
            } catch (e) {
              if (isRequestErrorNotPanic(e)) return { type: 'error' };

              throw e;
            }
          }}
        />
      )}
      <Toast {...toast} />
    </div>
  );
};
const UserNamePanel: React.FC<{
  userAndCompany: UserAndCompany;
}> = ({ userAndCompany }) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const { isMobile } = useBreakpoints();

  const { user } = userAndCompany;
  return (
    <div className={styles.userProfile__row}>
      {!isEditing && (
        <>
          <div>
            <ProfileLabel>Name</ProfileLabel>
            <Value>
              {user.firstName} {user.lastName}
            </Value>
          </div>

          {isMobile ? (
            <IconButton
              name="edit"
              aria-label="Change"
              data-testid="change-name-button"
              className={styles.userProfile__changeButton_mobile}
              onClick={() => setIsEditing(true)}
            />
          ) : (
            <ButtonWithIcon
              iconName="edit"
              size="medium"
              color="secondary"
              position="left"
              data-testid="change-name-button"
              onClick={() => setIsEditing(true)}
            >
              Change
            </ButtonWithIcon>
          )}
        </>
      )}

      {isEditing && (
        <UserNameChangeForm
          firstName={user.firstName}
          lastName={user.lastName}
          onClose={() => setIsEditing(false)}
          onChangeName={({
            firstName,
            lastName,
          }: Pick<User, 'firstName' | 'lastName'>) => {
            userAndCompany.updateActiveUser({ firstName, lastName });
          }}
        />
      )}
    </div>
  );
};

const UserProfilePage: React.FC<UserProfilePageProps> = ({
  userAndCompany,
  onLogout,
  onLogoutNoRedirect,
}) => {
  useDocumentTitle('Your Account');
  usePageTracking('user_profile', userAndCompany);

  const { user } = userAndCompany;

  return (
    <PageLayoutFetchesFolders
      title="Your Account"
      userAndCompany={userAndCompany}
      onClickLogout={onLogout}
      activePage="profile"
    >
      <ManagementPageShell>
        <ManagementPageTitle title="Your Account" />
        <ManagementPageContent className={styles.userProfile__panel}>
          <UserNamePanel userAndCompany={userAndCompany} />
          <UserEmailPanel email={user.email} onLogout={onLogoutNoRedirect} />
        </ManagementPageContent>
      </ManagementPageShell>
    </PageLayoutFetchesFolders>
  );
};

export default UserProfilePage;
