import { AiLoader } from '../../../../design-system/organisms/AiLoader.tsx';
import { AiChartFlowBuildingContainer } from './AiChartFlowBuildingContainer.tsx';

export type AiChartFlowLoadingState = {
  type: 'loading';
};

export const AiChartFlowLoading: React.FC = () => {
  return (
    <AiChartFlowBuildingContainer>
      <AiLoader />
    </AiChartFlowBuildingContainer>
  );
};
