import { Navigate } from 'react-router-dom';
import useApi from '../../../../api/useApi.ts';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.tsx';
import getIdFromSlug from '../../../../getIdFromSlug.ts';
import useDocumentTitleFromResult from '../../../../junkDrawer/useDocumentTitleFromResult.ts';
import { foldResult, pipe } from '../../../../result/Result.js';
import { useRequiredParams } from '../../../../routing/useRequiredParams.ts';
import useFetch from '../../../../services/useFetch/useFetch.js';
import usePageTracking from '../../../../services/usePageTracking.ts';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import useFetchZeck from '../../useFetchZeck.ts';
import PreviewSectionPageView from './PreviewSectionPageView.tsx';
import ZeckPreviewPageEmpty from './ZeckPreviewPageEmpty.tsx';

type PreviewPageProps = {
  brandKitResource: BrandKitResource;
  userAndCompany: UserAndCompany;
};

function PreviewZeckPage({
  brandKitResource,
  userAndCompany,
}: PreviewPageProps) {
  const { slug } = useRequiredParams('slug');
  const { fetchZeck, getZeckMeetingMinutes, getZeckPrevoteData } = useApi();
  const zeckId = getIdFromSlug(slug);
  const zeckResult = useFetchZeck(
    () => fetchZeck(zeckId),
    [slug, userAndCompany],
  );
  useDocumentTitleFromResult(zeckResult, '[Preview]');
  usePageTracking('zeck_preview', userAndCompany);

  const meetingMinutesResult = useFetch(
    () => getZeckMeetingMinutes(zeckId),
    [zeckId],
  );

  const prevoteDataResult = useFetch(
    () => getZeckPrevoteData(zeckId),
    [zeckId],
  );

  const meetingMinutes = pipe(
    meetingMinutesResult,
    foldResult({
      success: (meetingMinutes) => meetingMinutes,
      loading: () => [],
      error: () => [],
    }),
  );

  switch (zeckResult.type) {
    case 'loading':
      return null;
    case 'error':
      return <Navigate to="/404" />;
    case 'success':
  }
  const { data: zeck } = zeckResult;

  if (zeck.sections.length === 0) {
    return (
      <ZeckPreviewPageEmpty
        {...{
          brandKitResource,
          zeck,
          userAndCompany,
        }}
      />
    );
  }

  const prevoteData = pipe(
    prevoteDataResult,
    foldResult({
      success: (prevoteData) => prevoteData,
      loading: () => ({
        tallies: [],
        results: [],
        boardDirectorCount: 0,
        currentUserPrevotes: [],
        prevotedBlockIds: [],
      }),
      error: () => ({
        tallies: [],
        results: [],
        boardDirectorCount: 0,
        currentUserPrevotes: [],
        prevotedBlockIds: [],
      }),
    }),
  );

  return (
    <PreviewSectionPageView
      {...{
        brandKitResource,
        zeck,
        userAndCompany,
        meetingMinutes,
        prevoteData,
      }}
    />
  );
}

export default PreviewZeckPage;
