import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from '../../design-system/atoms/Button.tsx';
import SessionLayout from '../../design-system/layouts/SessionLayout.tsx';
import useBreakpoints from '../../services/useBreakpoints.ts';
import styles from './ResetPasswordPage.module.scss';

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();

  return (
    <SessionLayout>
      <h1 className={cx(isMobile ? styles.title_mobile : styles.title)}>
        You've Reset Your Password
      </h1>
      <p className={styles.resetPassword__description}>
        Your new password is saved. Go to secure sign in to login to your
        account.
      </p>
      <Button
        {...{
          className: styles.form__submit,
          size: 'large',
          color: 'primary',
          onClick: () => {
            navigate('/login');
          },
        }}
      >
        Go to Secure Sign In
      </Button>
    </SessionLayout>
  );
};

export default ResetPasswordSuccess;
