import Spinner from '../../../design-system/atoms/Spinner.tsx';
import Modal from '../../../design-system/organisms/Modal.tsx';
import styles from './FileUploadModal.module.scss';

export const FileUploadLoadingModal: React.FC<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen} dark className={styles.fileUploadModal}>
      <div>
        <Spinner className={styles.fileUploadModal__waitSpinner} />
        <p className={styles.fileUploadModal__waitInstructions}>
          Please wait until the file upload is complete before navigating to
          another page or closing the browser. Doing so will interrupt the
          upload.
        </p>
      </div>
    </Modal>
  );
};

export const FileUploadErrorModal: React.FC<{
  message: string;
  onRequestClose: () => void;
  isOpen: boolean;
}> = ({ message, onRequestClose, isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      dark
      className={styles.fileUploadModal}
      onRequestClose={onRequestClose}
    >
      <div>
        <p className={styles.fileUploadModal__errorMessage}>{message}</p>
      </div>
    </Modal>
  );
};
