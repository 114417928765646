import { Zeck } from '../../../app/types.js';
import hydrateSection from './hydrateSection.js';
import HydratedZeck from './types/HydratedZeck.js';

function hydrateZeck(zeck: Zeck): HydratedZeck {
  return {
    ...zeck,
    sections: zeck.sections.map(hydrateSection),
  };
}

export default hydrateZeck;
