import { PublishedCommentSelection } from 'api-client/publishedCommentContract.js';
import { Block, isImageBlock, isTextBlock } from 'editor-content/Block.js';
import {
  isTableBlock,
  tableCellUncompress,
} from 'editor-content/TableBlock.js';
import { getTextFromNodes } from 'editor-content/TextNode.js';
import { equals } from 'lodash/fp.js';
import { match, P } from 'ts-pattern';
import ContentSelection, {
  contentSelection,
} from '../../../../../../../editor/selection/contentSelection/ContentSelection.js';
import findContentSelectionInModifiedText from '../../../../findContentSelectionInModifiedText.js';
import { isBlockChangedForComments } from './isBlockChangedForComments.js';
import { PublishedCommentSelectionRegion } from './SelectionComments.js';

export type SelectionCommentContentPosition =
  | { type: 'orphaned' }
  | { type: 'disowned'; blockIndex: number }
  | {
      type: 'valid';
      selection: PublishedCommentSelectionRegion;
      blockIndex: number;
    }
  | { type: 'edited'; selection: ContentSelection; blockIndex: number };

export const findCommentSelectionInSection = (
  comment: { selection: PublishedCommentSelection },
  section: { body: Block[] },
): SelectionCommentContentPosition => {
  const blockCurrentVersion = section.body.find(
    (block) => comment.selection.block.id === block.id,
  );
  const blockIndex = section.body.findIndex(
    (block) => comment.selection.block.id === block.id,
  );

  if (!blockCurrentVersion) return { type: 'orphaned' };

  return match(comment.selection)
    .returnType<SelectionCommentContentPosition>()
    .with({ block: { type: 'table-cell' } }, (commentSelection) => {
      if (!isTableBlock(blockCurrentVersion)) {
        // This should never happen because you can't convert a table block into another block type
        // But the types don't know that
        return { type: 'disowned', blockIndex };
      }

      const currentVersionTableCell =
        blockCurrentVersion.data.rows[
          commentSelection.snippetSelection.rowIndex
        ]?.cells[commentSelection.snippetSelection.columnIndex];

      if (!currentVersionTableCell) {
        return { type: 'disowned', blockIndex };
      }

      if (
        !equals(
          tableCellUncompress(currentVersionTableCell).content,
          commentSelection.block.content,
        )
      ) {
        return { type: 'disowned', blockIndex };
      }

      return {
        type: 'valid',
        selection: commentSelection.snippetSelection,
        blockIndex,
      };
    })
    .with({ block: P.when(isImageBlock) }, ({ block }) => {
      if (!isImageBlock(blockCurrentVersion)) {
        return { type: 'disowned', blockIndex };
      }

      if (blockCurrentVersion.guid !== block.guid) {
        return { type: 'disowned', blockIndex };
      }

      return { type: 'valid', selection: contentSelection(0), blockIndex };
    })
    .with({ block: P.when(isTextBlock) }, (commentSelection) => {
      if (!isTextBlock(blockCurrentVersion)) {
        return { type: 'disowned', blockIndex };
      }

      const modifiedContentSelection = findContentSelectionInModifiedText(
        getTextFromNodes(commentSelection.block.content),
        getTextFromNodes(blockCurrentVersion.content),
        commentSelection.snippetSelection,
      );

      if (!modifiedContentSelection) {
        return { type: 'disowned', blockIndex };
      }

      const isEdited = isBlockChangedForComments(
        commentSelection.block,
        blockCurrentVersion,
      );

      return isEdited
        ? { type: 'edited', selection: modifiedContentSelection, blockIndex }
        : { type: 'valid', selection: modifiedContentSelection, blockIndex };
    })
    .exhaustive();
};
