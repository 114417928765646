import cx from 'classnames';
import { Block } from 'editor-content/Block.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import { isAiOptimizeSectionErrorResponse } from '../../../../../api/endpoints/createAiApi.ts';
import useApi from '../../../../../api/useApi.ts';
import { ModalContainer } from '../../../../../design-system/organisms/Modal.tsx';
import { foldResult, pipe } from '../../../../../result/Result.ts';
import useFetch from '../../../../../services/useFetch/useFetch.ts';
import { EditorContent } from '../../useEditorState.tsx';
import AiContentModal from './AiContentModal.tsx';
import AiErrorModal from './AiErrorModal.tsx';
import AiLoadingModal from './AiLoadingModal.tsx';
import styles from './AiModal.module.scss';

type AiModalProps = {
  sectionId: string;
  editorContent: EditorContent;
  onInsertAbove: (content: Block[]) => void;
  onClose: () => void;
  linkables: Linkable[];
};

const AiModal: React.FC<AiModalProps> = ({
  sectionId,
  editorContent,
  onClose,
  onInsertAbove,
  linkables,
}) => {
  const { aiOptimizeSection } = useApi();

  const optimizedSectionResult = useFetch(
    () => aiOptimizeSection(sectionId, editorContent),
    [],
  );

  return (
    <ModalContainer
      isOpen
      onRequestClose={() => {}}
      className={cx(
        styles.aiModal,
        styles[`aiModal--${optimizedSectionResult.type}`],
      )}
    >
      {pipe(
        optimizedSectionResult,
        foldResult({
          success: (response) => {
            if (isAiOptimizeSectionErrorResponse(response)) {
              return (
                <AiErrorModal onClose={onClose} errorCode={response.error} />
              );
            }

            if (response?.data?.body) {
              return (
                <AiContentModal
                  sectionId={sectionId}
                  content={response.data.body}
                  onInsertAbove={onInsertAbove}
                  onClose={onClose}
                  linkables={linkables}
                  jobId={response.data.metadata.jobId}
                />
              );
            }

            // TODO: dont log analytics here?
            return <AiErrorModal onClose={onClose} />;
          },
          loading: () => <AiLoadingModal />,
          error: () => <AiErrorModal onClose={onClose} />,
        }),
      )}
    </ModalContainer>
  );
};

export default AiModal;
