import { allTextNodes } from 'editor-content/TextNode.js';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { textSelection } from '../../../../editor/selection/TextSelection.js';
import { pipe } from '../../../../result/Result.js';
import { EditorContent } from '../../edit/useEditorState.js';
import BodyEditor from '../BodyEditor/BodyEditor.js';
import ZeckEditorSelection, {
  isBodySelection,
  isHeadlineSelection,
  isTitleSelection,
} from '../ZeckEditorSelection.js';
import { applyBodyState } from './applyBodyState.js';
import {
  applyBodySelection,
  applyHeadlineSelection,
  applyTitleSelection,
  ZeckEditorState,
} from './ZeckEditorState.js';

export const navLeft = (
  content: EditorContent,
  selection: ZeckEditorSelection,
): ZeckEditorState => {
  if (isHeadlineSelection(selection)) {
    return pipe(
      { content, selection },
      applyTitleSelection(contentSelection(content.title.length)),
    );
  }
  if (isBodySelection(selection)) {
    const actionResult = BodyEditor.navLeft({
      content: content.body,
      selection,
    });
    if (actionResult) {
      return applyBodyState({ content, selection }, actionResult);
    } else {
      const offset = Array.from(allTextNodes(content.headline)).reduce(
        (acc, { text }) => acc + text.length,
        0,
      );

      return pipe(
        { content, selection },
        applyHeadlineSelection(contentSelection(offset)),
      );
    }
  }

  return { content, selection };
};

export const navRight = (
  content: EditorContent,
  selection: ZeckEditorSelection,
): ZeckEditorState => {
  if (isTitleSelection(selection)) {
    return pipe(
      { content, selection },
      applyHeadlineSelection(contentSelection(0)),
    );
  }
  if (isHeadlineSelection(selection)) {
    return pipe(
      { content, selection },
      applyBodySelection(textSelection(0, contentSelection(0))),
    );
  }
  if (isBodySelection(selection)) {
    const actionResult = BodyEditor.navRight({
      content: content.body,
      selection,
    });
    if (actionResult) {
      return applyBodyState({ content, selection }, actionResult);
    }
    return { content, selection };
  }

  return { content, selection };
};
