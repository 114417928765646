import isFunction from 'lodash/isFunction.js';
import { useEffect, useState } from 'react';
import {
  ErrorResult,
  LoadingResult,
  Result as ResultM,
  SuccessResult,
  foldResult,
  pipe,
} from '../../result/Result.js';

export type Result<T> = SuccessResult<T> | LoadingResult | ErrorResult<unknown>;

export const fromResultM = <ResultType>(
  resultM: ResultM<unknown, ResultType>,
): Result<ResultType> => {
  return pipe(
    resultM,
    foldResult<unknown, ResultType, Result<ResultType>>({
      loading: () => LoadingResult(),
      success: (d) => SuccessResult(d),
      error: (e) => ErrorResult(e),
    }),
  );
};

export const getResult = <T, R>(
  ifSuccess: (data: T) => R,
  ifLoading: (() => R) | R,
  ifError: (() => R) | R,
  result: Result<T>,
): R => {
  switch (result.type) {
    case 'loading':
      return isFunction(ifLoading) ? ifLoading() : ifLoading;
    case 'error':
      return isFunction(ifError) ? ifError() : ifError;
    case 'success':
      return ifSuccess(result.data);
  }
};

const useFetch = <T>(fn: () => Promise<T>, deps: Array<unknown>): Result<T> => {
  const [result, setResult] = useState<Result<T>>(LoadingResult());

  useEffect(() => {
    (async () => {
      try {
        setResult(SuccessResult(await fn()));
      } catch (e) {
        setResult(ErrorResult(undefined));
      }
    })();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return result;
};

export default useFetch;
