import pressShiftArrowDown from '../../../../editor/actions/pressShiftArrowDown.js';
import pressShiftArrowUp from '../../../../editor/actions/pressShiftArrowUp.js';
import { replaceWithBulletBody } from '../../../../editor/actions/replaceWithBullet.js';
import { replaceWithNumberedBody } from '../../../../editor/actions/replaceWithNumbered.ts';
import { dropDraggedBlock } from '../../../../editor/dragBlock/dropDraggedBlock.js';
import { pressArrowLeft } from '../../../../editor/keyboardNavigation/pressArrowLeft.js';
import { pressArrowRight } from '../../../../editor/keyboardNavigation/pressArrowRight.js';
import { BlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { TextSelection } from '../../../../editor/selection/TextSelection.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import pressEnter from '../pressEnter/pressEnterBody.js';
import addBlockAtEnd from './addBlockAtEnd.js';
import { addHighlight } from './addHighlight.js';
import { addLink } from './addLink.js';
import addNewBlocksAtIndex from './addNewBlocksAtIndex.ts';
import { zeckEditorCopy } from './copyPaste/copy.js';
import { zeckEditorCut } from './copyPaste/cut.js';
import pasteBlocks from './copyPaste/pasteBlocks.js';
import pastePlaintext from './copyPaste/pastePlaintext.js';
import pasteText from './copyPaste/pasteText.js';
import { pasteImage } from './copyPaste/pastImage.js';
import editBlock from './editBlock.js';
import { editorConfigurationBody } from './EditorConfigurationBody.js';
import {
  replaceImage,
  setImageAlign,
  setImageWidth,
} from './ImageFormattingActions.js';
import indent from './indent.js';
import insertAiContentAbove from './insertAiContentAbove.ts';
import { navLeft, navRight } from './nav.js';
import pressBackspaceBody from './pressBackspaceBody.js';
import pressDeleteBody from './pressDeleteBody.js';
import pressForwardSlash from './pressForwardSlashBody.js';
import { replaceFile } from './replaceFile.js';
import { replaceTable } from './replaceTable.js';
import select from './select.js';
import toggleFormat from './toggleFormat.js';
import turnInto from './turnInto.js';

export type BodySelection = TextSelection | BlockSelection;

export type BodyContent = HydratedBlock[];

export type BodyStateSelected = {
  content: BodyContent;
  selection: BodySelection;
};

export type BodyState = {
  content: BodyContent;
  selection: BodySelection | null;
};

export type BodyActionResult = BodyStateSelected | void;

const BodyEditor = {
  pasteText,
  pasteBlocks,
  pastePlaintext,
  cut: zeckEditorCut,
  copy: zeckEditorCopy,

  pressBackspace: pressBackspaceBody,
  pressDelete: pressDeleteBody,
  pressEnter,
  pressForwardSlash,
  pressSpacebar(initialState: BodyStateSelected): void | BodyStateSelected {
    const ret = replaceWithBulletBody(initialState);
    if (!ret) {
      return replaceWithNumberedBody(initialState);
    }

    return ret;
  },

  navLeft,
  navRight,
  pressArrowLeft: pressArrowLeft(editorConfigurationBody),
  pressArrowRight: pressArrowRight(editorConfigurationBody),

  selectUp(initialState: BodyStateSelected): BodyStateSelected {
    const result = pressShiftArrowUp(editorConfigurationBody)(initialState);

    return result || initialState;
  },
  selectDown(initialState: BodyStateSelected): BodyStateSelected {
    const result = pressShiftArrowDown(editorConfigurationBody)(initialState);

    return result || initialState;
  },

  toggleFormat,

  addLink,

  addHighlight,

  setImageWidth,
  setImageAlign,
  replaceImage,

  pasteImage,

  replaceFile,

  replaceTable,

  turnInto,
  indent,

  editBlock,
  select,

  addBlockAtEnd,
  insertAiContentAbove,
  addNewBlocksAtIndex,

  dropDraggedBlock: dropDraggedBlock<HydratedBlock>,
};

export default BodyEditor;
