import { VoteType } from 'blocks/src/VoteBlock.js';
import useApi from '../../../../api/useApi.js';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.js';
import getIdFromSlug from '../../../../getIdFromSlug.js';
import useDocumentTitleFromResult from '../../../../junkDrawer/useDocumentTitleFromResult.js';
import { foldResult, pipe } from '../../../../result/Result.js';
import { useRequiredParams } from '../../../../routing/useRequiredParams.js';
import useFetchInvalidate from '../../../../services/useFetch/useFetchInvalidate.js';
import usePageTracking from '../../../../services/usePageTracking.ts';
import { PrevoteChoice } from '../../../../types/Prevote.ts';
import Vote from '../../../../types/Vote.ts';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.js';
import RequestAccessPage from '../../../error/unauthorized/RequestAccessPage.js';
import PublishedSectionPageView from './PublishedSectionPageView.js';
import useRegisterViewEventsOnLoad from './useRegisterViewEventsOnLoad.js';

type PublishedZeckPageContainerProps = {
  userAndCompany: UserAndCompany;
  brandKitResource: BrandKitResource;
  onClickLogout: () => void;
};

const PublishedZeckPage = ({
  userAndCompany,
  brandKitResource,
  onClickLogout,
}: PublishedZeckPageContainerProps) => {
  const { slug } = useRequiredParams('slug');
  const {
    fetchPublishedZeck,
    createVote,
    createPrevote,
    getZeckPrevoteData,
    deletePrevote,
  } = useApi();

  const zeckId = getIdFromSlug(slug);

  const { result: publishedZeckResult, invalidate: invalidatePublishedZeck } =
    useFetchInvalidate(async () => fetchPublishedZeck(zeckId), [zeckId]);

  const { result: prevoteDataResult, invalidate: invalidatePrevoteData } =
    useFetchInvalidate(() => getZeckPrevoteData(zeckId), [zeckId]);

  useRegisterViewEventsOnLoad({
    publishedZeckResult,
  });

  useDocumentTitleFromResult(publishedZeckResult);
  usePageTracking('published_zeck', userAndCompany);

  return pipe(
    publishedZeckResult,
    foldResult({
      loading: () => null,
      error: () => (
        <RequestAccessPage
          type="zeck"
          requestedId={zeckId}
          email={userAndCompany.user.email}
          onClickLogout={onClickLogout}
        />
      ),
      success: (publishedZeck) => {
        const prevoteData = pipe(
          prevoteDataResult,
          foldResult({
            success: (prevoteData) => prevoteData,
            loading: () => ({
              tallies: [],
              results: [],
              currentUserPrevotes: [],
              boardDirectorCount: 0,
              prevotedBlockIds: [],
            }),
            error: () => ({
              tallies: [],
              results: [],
              currentUserPrevotes: [],
              boardDirectorCount: 0,
              prevotedBlockIds: [],
            }),
          }),
        );

        const takeVote = async (
          blockVoteData: Pick<
            Vote,
            | 'title'
            | 'details'
            | 'approved'
            | 'voteType'
            | 'blockId'
            | 'sectionId'
          >,
        ) => {
          await invalidatePublishedZeck(async () => {
            await createVote({
              ...blockVoteData,
              zeckId: publishedZeck.zeckId,
              voteType: blockVoteData.voteType || VoteType.None,
            });
          });
        };

        const takePrevote = async ({
          blockId,
          choice,
          sectionId,
        }: {
          blockId: string;
          choice: PrevoteChoice;
          sectionId: string;
        }) => {
          await invalidatePrevoteData(async () => {
            await createPrevote({
              sectionId,
              blockId,
              choice,
            });
          });
        };

        const removePrevote = async (id: string) => {
          await invalidatePrevoteData(async () => {
            await deletePrevote(id);
          });
        };

        return (
          <PublishedSectionPageView
            brandKitResource={brandKitResource}
            zeck={publishedZeck}
            userAndCompany={userAndCompany}
            prevoteData={prevoteData}
            takeVote={takeVote}
            takePrevote={takePrevote}
            removePrevote={removePrevote}
          />
        );
      },
    }),
  );
};

export default PublishedZeckPage;
