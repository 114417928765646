import React, { useState } from 'react';
import { Prevote } from '../../../types/Prevote.ts';
import Button from '../../atoms/Button.tsx';
import OutlineButton from '../../atoms/OutlineButton.tsx';
import { VoteDetails } from '../vote/Vote.js';
import VoteControlsRow from '../vote/VoteControlsRow.js';
import VoteSectionLayout from '../vote/VoteSectionLayout.tsx';
import ClearPrevoteButton from './ClearPrevoteButton.tsx';
import PrevoteChoiceLabel, { PrevoteChoiceBox } from './PrevoteChoiceLabel.js';
import styles from './PrevoteControls.module.scss';
import VoteButton from './VoteButton.js';

type UIState = 'not_taking_vote' | 'taking_vote';

const PREVOTE_ENABLED_TOOLTIP_TEXT =
  'Pre-voting allows Board Directors to indicate how they plan on voting in advance of the meeting.';

const PREVOTE_DISABLED_TOOLTIP_TEXT =
  'The Pre-Vote button is enabled for Board Directors in a published Zeck and allows them to Pre-Vote in advance of the meeting.';

const PrevoteControls: React.FC<{
  isPrimaryAction: boolean;
  onPrevote:
    | ((result: 'approved' | 'not_approved' | 'abstained') => Promise<void>)
    | null;
  onRemovePrevote: ((id: string) => Promise<void>) | null;
  currentUserPrevote: Pick<Prevote, 'id' | 'choice'> | null;
  className?: string;
}> = ({
  isPrimaryAction,
  onPrevote,
  onRemovePrevote,
  currentUserPrevote,
  className,
}) => {
  const VoteButtonComponent = isPrimaryAction ? Button : OutlineButton;

  const [uiState, setUIState] = useState<UIState>('not_taking_vote');
  const [isProcessingVote, setIsProcessingVote] = useState(false);

  if (currentUserPrevote) {
    if (isPrimaryAction) {
      return (
        <VoteSectionLayout
          tooltip={PREVOTE_ENABLED_TOOLTIP_TEXT}
          heading="Pre-Vote"
          className={className}
        >
          <PrevoteChoiceBox className={styles.prevoteResultWrap}>
            <VoteDetails className={styles.userPrevoteLabel}>
              You pre-voted{' '}
              <PrevoteChoiceLabel choice={currentUserPrevote.choice} />
            </VoteDetails>
            <ClearPrevoteButton
              currentUserPrevote={currentUserPrevote}
              onRemovePrevote={onRemovePrevote}
            />
          </PrevoteChoiceBox>
        </VoteSectionLayout>
      );
    }
    return (
      <VoteSectionLayout
        tooltip={PREVOTE_ENABLED_TOOLTIP_TEXT}
        heading="Pre-Vote"
        className={className}
      >
        <div className={styles.prevoteResultWrap}>
          <VoteDetails className={styles.userPrevoteLabel}>
            You pre-voted.
          </VoteDetails>
          <ClearPrevoteButton
            currentUserPrevote={currentUserPrevote}
            onRemovePrevote={onRemovePrevote}
          />
        </div>
      </VoteSectionLayout>
    );
  }

  if (!onPrevote) {
    return (
      <VoteSectionLayout
        tooltip={PREVOTE_DISABLED_TOOLTIP_TEXT}
        heading="Pre-Vote"
        className={className}
      >
        <VoteControlsRow>
          <VoteButtonComponent size="large" color="primary" disabled>
            Pre-Vote
          </VoteButtonComponent>
        </VoteControlsRow>
      </VoteSectionLayout>
    );
  }

  const handleClickPrevote =
    (result: 'approved' | 'not_approved' | 'abstained') => async () => {
      setIsProcessingVote(true);
      try {
        await onPrevote(result);
      } catch (err) {
        //
      } finally {
        setIsProcessingVote(false);
        setUIState('not_taking_vote');
      }
    };

  return (
    <VoteSectionLayout
      tooltip={PREVOTE_ENABLED_TOOLTIP_TEXT}
      heading="Pre-Vote"
      className={className}
    >
      {uiState === 'not_taking_vote' && (
        <VoteControlsRow>
          <VoteButtonComponent
            size="large"
            color="primary"
            onClick={() => setUIState('taking_vote')}
            key="prevote" // added to stop transition
          >
            Pre-Vote
          </VoteButtonComponent>
        </VoteControlsRow>
      )}
      {uiState === 'taking_vote' && (
        <>
          <VoteControlsRow>
            <VoteButton
              size="large"
              color="green"
              onClick={handleClickPrevote('approved')}
              disabled={isProcessingVote}
            >
              Approve
            </VoteButton>
            <VoteButton
              size="large"
              color="red"
              onClick={handleClickPrevote('not_approved')}
              disabled={isProcessingVote}
            >
              Not Approve
            </VoteButton>
            <VoteButton
              size="large"
              color="grey"
              onClick={handleClickPrevote('abstained')}
              disabled={isProcessingVote}
            >
              Abstain
            </VoteButton>
          </VoteControlsRow>
          <VoteControlsRow>
            <OutlineButton
              size="large"
              color="secondary"
              onClick={() => setUIState('not_taking_vote')}
              disabled={isProcessingVote}
            >
              Cancel
            </OutlineButton>
          </VoteControlsRow>
        </>
      )}
    </VoteSectionLayout>
  );
};

export default PrevoteControls;
