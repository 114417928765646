import { useState } from 'react';
import {
  ErrorResult,
  LoadingResult,
  SuccessResult,
} from '../../result/Result.js';
import useExecuteOnLoad from '../../result/useExecuteOnLoad.js';
import { Result } from './useFetch.js';

const useDependentFetch = <DependentType, ResultType>(
  dependentResult: Result<DependentType>,
  fn: (data: DependentType) => Promise<ResultType>,
): Result<ResultType> => {
  const [result, setResult] = useState<Result<ResultType>>(LoadingResult());

  useExecuteOnLoad({
    result: dependentResult,
    onLoad: async (data) => {
      try {
        setResult(SuccessResult(await fn(data)));
      } catch (e) {
        setResult(ErrorResult(undefined));
      }
    },
  });

  return result;
};

export default useDependentFetch;
