import { ChartBlock } from 'editor-content/ChartBlock.ts';
import React, { useState } from 'react';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { AiChartFlow } from '../AiChartFlow/AiChartFlow.js';
import { AiContext } from '../AiChartFlow/createAiContext.js';
import { useGenerateAiChartFlowProps } from '../AiChartFlow/useGenerateAiChartFlowProps.js';

type ChartFlowEditingProps = {
  setEditingChart: (editingChart: boolean) => void;
  aiContext: AiContext;
  selection: ContentSelection | null;
  onChange: (editedChart: ChartBlock, selection: ContentSelection) => void;
  block: ChartBlock;
};

export const ChartFlowEditing: React.FC<ChartFlowEditingProps> = ({
  block,
  selection,
  onChange,
  aiContext,
  setEditingChart,
}) => {
  const [chartSelection] = useState<ContentSelection | null>(selection);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { forceTableSelection, isSourceBlockActive, ...aiChartFlowProps } =
    useGenerateAiChartFlowProps({
      setAddingChart: setEditingChart,
      onInsertChartBlock: (editedChart) => {
        if (!chartSelection) return;
        onChange(editedChart, chartSelection);
      },
      fromBlock: block,
      selection,
      aiContext,
      initialChartState: {
        type: 'editing',
        chart: block.data.chartData,
        pastMessages: block.data.pastMessages,
      },
    });

  return <AiChartFlow {...aiChartFlowProps} />;
};
