import React from 'react';
import Title from '../../../design-system/zeck/Title.js';
import { allowBreakOnSlash } from './allowBreakOnSlash.js';
import { CommentsButton } from './publish/commentsSidebar/CommentsButton.js';
import { CommentsStateForSection } from './publish/commentsSidebar/useComments/useComments.js';
import styles from './SectionView.module.scss';

type TitleCardProps = {
  title: string;
  readTime: null | number;
  commentsState?: CommentsStateForSection;
};

const TitleCard = React.forwardRef<HTMLDivElement, TitleCardProps>(
  function TitleCard({ title, readTime, commentsState }, forwardedRef) {
    return (
      <div
        className={styles.titleCard}
        ref={forwardedRef}
        data-testid="title-card"
      >
        <Title className={styles.titleCard__title}>
          {allowBreakOnSlash(title)}
        </Title>
        <div className={styles.titleCard__subtitle}>
          {readTime !== null && (
            <div className={styles.titlecard__readTime}>
              {readTime} min read
            </div>
          )}

          {commentsState && (
            <div className={styles.titleCard__comments}>
              <CommentsButton state={commentsState} />
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default TitleCard;
