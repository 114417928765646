import replaceSelectionWith from '../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import { ContentPatch } from '../ContentPatch.js';
import { EditorConfiguration, EditorState } from '../EditorAction.js';
import { textSelection } from '../selection/TextSelection.js';
import ContentSelection, {
  contentSelection,
} from '../selection/contentSelection/ContentSelection.js';

type PressEnterBlockInterface<BlockType> = {
  pressEnter: (selection: ContentSelection) => ContentPatch<BlockType[]>;
};

const pressEnter =
  <BlockType>({
    generateBlockEditor,
    createDefaultBlock,
  }: EditorConfiguration<BlockType, PressEnterBlockInterface<BlockType>>) =>
  (initialState: EditorState<BlockType>) =>
    replaceSelectionWith(initialState, {
      textSelection: (selectedBlock, selection) => {
        const targetBlockEditor = generateBlockEditor(selectedBlock);

        return targetBlockEditor.pressEnter(selection);
      },
      blockSelection: () => {
        return {
          contentSubset: [createDefaultBlock([])],
          selection: textSelection(0, contentSelection(0)),
        };
      },
    });

export default pressEnter;
