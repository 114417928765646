import cx from 'classnames';
import { useState } from 'react';
import ConfirmationModal from '../../../design-system/organisms/ConfirmationModal.tsx';
import { KebabItem } from '../../../design-system/organisms/KebabMenu.tsx';
import KebabMenuButton from '../../../design-system/organisms/KebabMenuButton.tsx';
import defaultImagePath from '../../../images/defaultZeckCoverBackground.jpg';
import {
  zeckAnalyticsPath,
  zeckEditPath,
  zeckPreviewPath,
  zeckPublishPath,
} from '../../../services/Paths.ts';
import formatTimeAgo from '../../../services/formatTimeAgo.ts';
import isTouchDeviceFlow from '../../../services/isTouchDeviceFlow.ts';
import { Result } from '../../../services/useFetch/useFetch.ts';
import { ZeckLite } from '../../../types.ts';
import { ZeckFolderWithCount } from '../../../types/ZeckFolder.ts';
import UploadedImage from '../../zeck/UploadedImage.tsx';
import ConfirmDuplicatePermissions from './ConfirmDuplicatePermissions.tsx';
import MoveZeckModal from './MoveZeckModal.tsx';
import ZeckCard from './ZeckCard.tsx';
import styles from './ZeckCardWithMenu.module.scss';

type ZeckCardProps = {
  zeck: ZeckLite;
  folders: Result<ZeckFolderWithCount[]> | null;
  onClickDelete: () => void;
  onClickDuplicate: (permissions: boolean) => void;
  onChangeZeckName: (name: string) => void;
  onMoveZeck: (folderId: string | null) => Promise<void>;
  to: string;
};

const ZeckCardWithMenu = ({
  zeck,
  onClickDelete,
  onClickDuplicate,
  onChangeZeckName,
  onMoveZeck,
  to,
  folders,
}: ZeckCardProps) => {
  const publishedAtString = zeck.publishedAt
    ? `Published ${formatTimeAgo(zeck.publishedAt)}`
    : '';
  const canMoveFolders = zeck.isAdmin && folders;
  const canPreviewAndEdit =
    (zeck.isContributor || zeck.isAdmin) && !isTouchDeviceFlow();
  const [isRenaming, setIsRenaming] = useState(false);

  const maybeMoveLink: KebabItem[] = canMoveFolders
    ? [
        {
          iconName: 'folderMove',
          label: 'Move',
          hideInDrawer: true,
          Modal: ({ isOpen, onClose }) => (
            <MoveZeckModal
              isOpen={isOpen}
              onClose={onClose}
              folders={folders}
              currentFolderId={zeck.folderId}
              onMove={onMoveZeck}
            />
          ),
        },
      ]
    : [];

  const kebabMenuItems: KebabItem[] = [
    {
      label: 'Edit',
      hide: !canPreviewAndEdit,
      href: zeckEditPath(zeck),
      iconName: 'edit',
    },
    {
      label: 'Analytics',
      hide: !zeck.isAdmin,
      iconName: 'chart',
      href: zeckAnalyticsPath(zeck),
      hideInDrawer: false,
    },
    {
      label: 'Preview',
      hide: !canPreviewAndEdit,
      iconName: 'preview',
      href: zeckPreviewPath(zeck),
      hideInDrawer: true,
    },
    {
      label: 'Go to Site',
      hide: !zeck.isAdmin && !zeck.isViewer,
      iconName: 'goTo',
      href: zeckPublishPath(zeck),
      disabled: !zeck.publishedAt,
    },
    {
      hide: !zeck.isAdmin,
      iconName: 'rename',
      label: 'Rename',
      onClick: () => {
        setIsRenaming(true);
        setIsMenuOpen(false);
      },
      hideInDrawer: true,
    },
    ...maybeMoveLink,
    {
      label: 'Duplicate',
      iconName: 'copy',
      hide: !zeck.isAdmin,
      hideInDrawer: true,
      Modal: ({ isOpen, onClose }) => (
        <ConfirmDuplicatePermissions
          name={zeck.name}
          isOpen={isOpen}
          onClose={onClose}
          onDuplicate={() => {
            onClose();
            onClickDuplicate(true);
          }}
          onNotDuplicate={() => {
            onClose();
            onClickDuplicate(false);
          }}
        />
      ),
    },
    {
      label: 'Move to Trash',
      hide: !zeck.isAdmin,
      color: 'danger',
      iconName: 'trash',
      Modal: ({ isOpen, onClose }) => (
        <ConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          onClickConfirm={() => {
            onClose();
            onClickDelete();
          }}
          body={
            <>
              Move <i>{zeck.name}</i> to Trash?
              <br />
              Viewers will no longer have access to the published version.
            </>
          }
          confirmText="Yes, Move to Trash"
          confirmTextForMobile="Yes, Move to Trash"
          title="Move to Trash?"
        />
      ),
    },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <ZeckCard
        {...{
          name: zeck.name,
          coverImage: zeck.coverImageId ? (
            <UploadedImage
              imageId={zeck.coverImageId}
              imagePreset="thumbnail"
              spinnerSize="medium"
              alt="zeck cover"
            />
          ) : (
            <img src={defaultImagePath} alt="zeck cover" />
          ),
          to,
          rightSlot: (
            <KebabMenuButton
              onOpen={() => setIsMenuOpen(true)}
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              items={kebabMenuItems}
              ariaLabel={'manage zeck'}
              className={cx(
                styles.adminZeckCard__kebab,
                isMenuOpen && styles.adminZeckCard__kebab__open,
              )}
              drawerHeading="Manage Zeck"
              enableDrawer={true}
              usePortal
            />
          ),
          bottomSlot: publishedAtString,
          isRenaming: isRenaming,
          onRename(name) {
            setIsRenaming(false);
            onChangeZeckName(name);
          },
          className: styles.zeckCard,
        }}
      />
    </>
  );
};

export default ZeckCardWithMenu;
