import { getHighlightFromElement } from 'editor-content/html/HighlightElement.ts';
import { useEffect } from 'react';
import arrayIsNotEmpty from '../../../junkDrawer/arrayIsNotEmpty.js';
import isHTMLElement from '../../../junkDrawer/isHTMLElement.js';
import { highlightSelector } from './highlights.js';

const useHighlightHover = () => {
  useEffect(() => {
    const style = document.createElement('style');

    document.head.appendChild(style);

    const cleanups = Array.from(
      document.querySelectorAll('[data-nodetype="highlight"]'),
    ).map((el) => {
      const enterListener = () => {
        if (!isHTMLElement(el)) return;
        const highlight = getHighlightFromElement(el, []);
        if (!highlight) return;
        if (!arrayIsNotEmpty(highlight.ids)) return;
        // arbitrarily pick first highlight id in array
        const [highlightId] = highlight.ids;

        style.innerHTML = `${highlightSelector(highlightId)} { background-color: var(--color-yellow-3)!important }`;
      };
      el.addEventListener('mouseenter', enterListener);

      const leaveListener = () => {
        style.innerHTML = '';
      };

      el.addEventListener('mouseleave', leaveListener);

      return () => {
        el.removeEventListener('mouseenter', enterListener);
        el.removeEventListener('mouseleave', leaveListener);
      };
    });

    return () => {
      document.head.removeChild(style);
      cleanups.forEach((fn) => fn());
    };
  });
};

export default useHighlightHover;
