import { BrandKitResource } from '../../design-system/zeck/WithBrandKit.tsx';
import { useDocumentTitle } from '../../junkDrawer/useDocumentTitleFromResult.ts';
import usePageTracking from '../../services/usePageTracking.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import ManagementPage from '../ManagementPage.tsx';
import PageLayoutFetchesFolders from '../PageLayoutFetchesFolders.tsx';
import styles from './BrandKitPage.module.scss';
import BrandLogoTile from './BrandLogoTile.tsx';
import PrimaryColorPickerTile from './PrimaryColorPickerTile.tsx';

type BrandKitPageProps = {
  userAndCompany: UserAndCompany;
  onClickLogout: () => void;
  brandKitResource: BrandKitResource;
};

function BrandKitPage({
  userAndCompany,
  onClickLogout,
  brandKitResource,
}: BrandKitPageProps) {
  useDocumentTitle('Brand Kit');
  usePageTracking('brand_kit', userAndCompany);
  switch (brandKitResource.result.type) {
    case 'loading':
    case 'error':
      return null;
    case 'success':
  }

  const { brandColorPrimary, brandLogoId } = brandKitResource.result.data;

  return (
    <PageLayoutFetchesFolders
      title={'Brand Kit'}
      userAndCompany={userAndCompany}
      onClickLogout={onClickLogout}
      activePage="brand-kit"
    >
      <ManagementPage title={'Brand Kit'}>
        <div className={styles.brandKit__sections}>
          <div className={styles.brandKit__section}>
            <h2 className={styles.brandKit__heading}>Brand Logos</h2>
            <BrandLogoTile
              brandLogoId={brandLogoId}
              onUpdateBrandLogo={(brandLogoId) => {
                brandKitResource.update({ brandLogoId });
              }}
              onDeleteBrandLogo={() => {
                brandKitResource.update({ brandLogoId: null });
              }}
            />
          </div>
          <div className={styles.brandKit__section}>
            <h2 className={styles.brandKit__heading}>Brand Colors</h2>
            <div className={styles.brandKit__cards}>
              <PrimaryColorPickerTile
                onUpdatePrimaryColor={(brandColorPrimary) => {
                  brandKitResource.update({ brandColorPrimary });
                }}
                onDeletePrimaryColor={() => {
                  brandKitResource.update({
                    brandColorPrimary: null,
                  });
                }}
                primaryColor={brandColorPrimary}
              />
            </div>
          </div>
        </div>
      </ManagementPage>
    </PageLayoutFetchesFolders>
  );
}

export default BrandKitPage;
