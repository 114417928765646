import {
  NumberedListItemBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import React from 'react';
import { NumberedListItem } from '../../../../design-system/zeck/NumberedList.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import EditableProps from './EditableProps.ts';
import useZeckContentEditable from './useZeckContentEditable.ts';

const NumberedListItemEditable = React.forwardRef<
  HTMLElement,
  {
    className?: string;
    indent?: number;
  } & EditableProps<NumberedListItemBlock>
>(function NumberedListItemEditable(
  { value, selection, onChange, onSelect, linkables, ...otherProps },
  forwardedRef,
) {
  const contentEditableProps = useZeckContentEditable(
    {
      content: value.content,
      selection,
    },
    ({ content, selection }) => {
      onChange(updateTextBlock(value, content), selection);
    },
    onSelect,
    { linkables },
  );
  return (
    <NumberedListItem
      {...{
        ...otherProps,
        indent: value.indent,
        ...contentEditableProps,
        ref: mergeRefs([contentEditableProps.ref, forwardedRef]),
      }}
    />
  );
});

export default NumberedListItemEditable;
