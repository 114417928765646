import { useCallback, useState } from 'react';
import Button from '../atoms/Button.tsx';
import IconButton from '../atoms/IconButton.tsx';
import Input from '../atoms/Input.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../organisms/Modal.tsx';
import LoadingButton from './LoadingButton.tsx';
import styles from './NewFolderModal.module.scss';

type NewFolderSidebarItemProps = {
  isOpen: boolean;
  onSubmit: (folderName: string) => Promise<void>;
  onClose: () => void;
};

const NewFolderSidebarItem: React.FC<NewFolderSidebarItemProps> = ({
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [value, setValue] = useState('');
  const buttonDisabled = !value.length;

  const submitForm = useCallback(async () => {
    setIsCreatingFolder(true);
    await onSubmit(value);
    setIsCreatingFolder(false);
    setValue('');
  }, [onSubmit, value]);

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader className={styles.newFolderSidebar__header}>
        <div>New Folder</div>
        <div>
          <IconButton name="close" aria-label="close modal" onClick={onClose} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={styles.newFolderSidebar__input}>
          <Input
            autoFocus
            label="Folder Name"
            value={value}
            disabled={isCreatingFolder}
            type={'text'}
            onChange={setValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitForm();
              }
            }}
          />
        </div>
      </ModalBody>
      <ModalActions>
        {isCreatingFolder ? (
          <LoadingButton />
        ) : (
          <Button
            size={'small'}
            disabled={buttonDisabled}
            color={'primary'}
            onClick={() => submitForm()}
          >
            Create Folder
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
};

export default NewFolderSidebarItem;
