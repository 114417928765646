import cx from 'classnames';
import { Icon, IconName } from 'icons';
import React from 'react';
import styles from './IconButton.module.scss';

type IconLinkProps = {
  className?: string;
  name: IconName;
  'aria-label': string;
  href: string;
  active?: boolean;
  target?: string;
};

const IconLink = React.forwardRef<
  HTMLAnchorElement,
  IconLinkProps & React.HTMLAttributes<HTMLAnchorElement>
>(function IconLink(
  { className, name, href, active = false, ...otherProps },
  forwardedRef,
) {
  return (
    <a
      {...otherProps}
      ref={forwardedRef}
      href={href}
      className={cx(
        className,
        styles.iconButton,
        active && styles.iconButton_active,
      )}
    >
      <Icon name={name} />
    </a>
  );
});

export default IconLink;
