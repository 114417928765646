import { createParagraphBlock } from 'editor-content/Block.js';
import splitTextNodesFromContentSelection from '../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.js';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { textSelection } from '../../../../editor/selection/TextSelection.js';
import { pipe } from '../../../../result/Result.js';
import { EditorContent } from '../../edit/useEditorState.js';
import BodyEditor from '../BodyEditor/BodyEditor.js';
import {
  applyBodyContent,
  applyBodySelection,
  applyBodyState,
  applyHeadlineContent,
  applyHeadlineSelection,
} from '../ZeckEditor/ZeckEditorState.js';
import ZeckEditorSelection from '../ZeckEditorSelection.js';

const pressEnterZeck = (
  content: EditorContent,
  selection: ZeckEditorSelection,
): {
  content: EditorContent;
  selection: ZeckEditorSelection;
} | void => {
  if (!selection) return;

  switch (selection.target) {
    case 'title': {
      return pipe(
        { content, selection },
        applyHeadlineSelection(contentSelection(0)),
      );
    }
    case 'headline': {
      const value = content.headline;

      const [content1, , content2] = splitTextNodesFromContentSelection(
        value,
        selection,
      );

      return pipe(
        { content, selection },
        applyHeadlineContent(content1),
        applyBodyContent([createParagraphBlock(content2), ...content.body]),
        applyBodySelection(textSelection(0, contentSelection(0))),
      );
    }
    case 'body': {
      const bodyState = BodyEditor.pressEnter({
        content: content.body,
        selection: selection,
      });

      return (
        bodyState && pipe({ content, selection }, applyBodyState(bodyState))
      );
    }
  }
};

export default pressEnterZeck;
