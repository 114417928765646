import { BulletedListItemBlock } from 'editor-content/Block.js';
import { BlockRenderable } from '../../BlockRenderable.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import BulletedListItemEditable from './BulletedListItemEditable.js';
import { BulletedListItemFormattingMenu } from './BulletedListItemFormattingMenu.js';

export function createBulletedListItemBlockRenderable(
  block: BulletedListItemBlock,
  updateBlock: (newBlock: BulletedListItemBlock) => void,
  selection: ContentSelection | null,
  select: (contentSelection: ContentSelection) => void,
  isInBlockSelection: boolean,
): BlockRenderable<BulletedListItemBlock> {
  const updateContent = function (newBlock: BulletedListItemBlock) {
    updateBlock({ ...block, content: newBlock.content });
  };

  return {
    block,
    updateContent,
    selection,
    select,
    isInBlockSelection,
    Editable: BulletedListItemEditable,
    FormattingMenu: BulletedListItemFormattingMenu,
  };
}
