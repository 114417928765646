import cx from 'classnames';
import {
  CommentContent,
  commentTagNode,
} from 'editor-content/CommentContent.js';
import FuzzySearch from 'fuzzy-search';
import clamp from 'lodash/clamp.js';
import React, { useRef, useState } from 'react';
import TagMenu, {
  TagMenuItem,
  TagMenuList,
} from '../design-system/organisms/TagMenu.js';
import HoverNextToElement from '../domHelpers/hoverNextTo/HoverNextToElement.js';
import { leftAlignedBelowElement } from '../domHelpers/hoverNextTo/positionStrategy/leftAlignedBelow.js';
import withinContainer from '../domHelpers/hoverNextTo/withinContainer.js';
import {
  callHandlers,
  handleKey,
  preventDefault,
  stopPropagation,
} from '../editor/domFacing/events/isKeyMatch.js';
import ContentSelection from '../editor/selection/contentSelection/ContentSelection.js';
import createUseContentEditableWithSelection from '../editor/useContentEditableWithSelection.js';
import { getSafePlaintextFromClipboardData } from '../junkDrawer/getSafePlaintextFromClipboardData.js';
import mergeRefs from '../junkDrawer/mergeRefs.js';
import noop from '../junkDrawer/noop.js';
import useUndoRedo from '../pages/zeck/editor/useUndoRedo.js';
import { AvailableTag } from '../types/AvailableTag.js';
import styles from './CommentEditable.module.scss';
import CommentEditor, { SelectionCommentEditorState } from './CommentEditor.js';
import CommentEditorAdapter from './CommentEditorAdapter.js';

type MenuSelection = ContentSelection & {
  menuIndex: number;
};

function isMenuSelection(
  obj: MenuSelection | ContentSelection | null,
): obj is MenuSelection {
  return !!obj && (obj as MenuSelection).menuIndex !== undefined;
}

function moveMenuSelectionUp(
  selection: ContentSelection & { menuIndex: number },
  availableTags: AvailableTag[],
): MenuSelection | ContentSelection {
  if (selection.menuIndex === 0) {
    return {
      anchorOffset: selection.anchorOffset,
      focusOffset: selection.focusOffset,
    };
  }

  return {
    ...selection,
    menuIndex: clamp(selection.menuIndex - 1, 0, availableTags.length - 1),
  };
}

function moveMenuSelectionDown(
  selection: ContentSelection & { menuIndex: number },
  availableTags: AvailableTag[],
): MenuSelection {
  return {
    ...selection,
    menuIndex: clamp(selection.menuIndex + 1, 0, availableTags.length - 1),
  };
}

function moveToMenuSelection(selection: ContentSelection): MenuSelection {
  return {
    ...selection,
    menuIndex: 0,
  };
}

type CommentUISelection = ContentSelection | MenuSelection | null;

const useCommentContentEditable =
  createUseContentEditableWithSelection(CommentEditorAdapter);

const useTagMenu = (
  {
    content,
    selection,
  }: {
    content: CommentContent;
    selection: CommentUISelection;
  },
  availableTags: AvailableTag[],
) => {
  const tagSelectedText = CommentEditor.getPossibleTagMatch({
    content: content,
    selection,
  });

  let matchingTags: AvailableTag[] = [];
  if (tagSelectedText) {
    const searcher = new FuzzySearch(availableTags, ['displayName'], {
      caseSensitive: false,
    });
    matchingTags = searcher.search(tagSelectedText.text);
  }

  const canInsertTag = matchingTags.length !== 0;

  return {
    matchingTags,
    canInsertTag,
  };
};

type CommentInputProps = {
  onChange(newValue: CommentContent): void;
  commentContent: CommentContent;
  placeholder: string;
  onPressEnter(): void;
  availableTags: AvailableTag[];
  tagTypeDisplayName: string;
  className?: string;
  scrollContainerRef: React.RefObject<HTMLElement>;
  autofocus?: boolean;
} & Omit<React.ComponentProps<'p'>, 'onChange'>;

const CommentEditable = React.forwardRef<
  HTMLParagraphElement,
  CommentInputProps
>(function CommentInput(
  {
    autofocus,
    onChange,
    commentContent,
    placeholder,
    onPressEnter,
    availableTags,
    tagTypeDisplayName,
    className,
    scrollContainerRef,
    ...props
  },
  forwardedRef,
) {
  const ref = useRef<HTMLParagraphElement>(null);
  const [selection, setSelection] = useState<CommentUISelection>(
    autofocus
      ? CommentEditor.getInitialAutofocusSelection(commentContent)
      : null,
  );

  const { undo, redo, setValue, value } =
    useUndoRedo<SelectionCommentEditorState>(
      {
        content: commentContent,
        selection: selection,
      },
      (newValue) => {
        onChange(newValue.content);
        setSelection(newValue.selection);
      },
    );

  const { matchingTags, canInsertTag } = useTagMenu(
    {
      content: value.content,
      selection,
    },
    availableTags,
  );

  function insertTag(tag: AvailableTag) {
    const newState = CommentEditor.insertTag(
      {
        content: value.content,
        selection,
      },
      commentTagNode(tag.userId, tag.displayName),
    );

    setValue({
      content: newState.content,
      selection: newState.selection,
    });
  }

  const contentEditableProps = useCommentContentEditable<HTMLParagraphElement>(
    value,
    setValue,
    (selection) => {
      setValue({
        content: value.content,
        selection,
      });
    },
    {},
  );

  const copyPasteHandlers = {
    onCut: stopPropagation(noop), // default but don't bubble up to editor
    onCopy: stopPropagation(noop),
    onPaste: (event: React.ClipboardEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();

      const text = getSafePlaintextFromClipboardData(event.clipboardData);
      const strings = text.split('\n');
      const insertedContent = strings.join(' ');
      document.execCommand('insertText', false, insertedContent);
    },
  };

  const undoRedoHandlers: ((e: React.KeyboardEvent) => boolean)[] = [
    handleKey(
      { key: 'z', ctrlKey: true },
      stopPropagation(preventDefault(undo)),
    ),
    handleKey(
      { key: 'y', ctrlKey: true },
      stopPropagation(preventDefault(redo)),
    ),

    handleKey(
      { key: 'z', metaKey: true },
      stopPropagation(preventDefault(undo)),
    ),
    handleKey(
      {
        key: 'z',
        metaKey: true,
        shiftKey: true,
      },
      stopPropagation(preventDefault(redo)),
    ),
  ];

  const tagMenuHandlers: ((e: React.KeyboardEvent) => boolean)[] = [
    handleKey(
      { key: 'Enter' },
      stopPropagation(
        preventDefault(() => {
          if (isMenuSelection(selection)) {
            const tag = matchingTags[selection.menuIndex];
            tag && insertTag(tag);
          } else {
            onPressEnter();
          }
        }),
      ),
    ),
    handleKey(
      { key: 'ArrowUp' },
      isMenuSelection(selection)
        ? preventDefault(
            stopPropagation(() =>
              setSelection(moveMenuSelectionUp(selection, matchingTags)),
            ),
          )
        : noop,
    ),
    handleKey(
      { key: 'ArrowDown' },
      canInsertTag && selection
        ? stopPropagation(
            preventDefault(() => {
              if (isMenuSelection(selection)) {
                setSelection(moveMenuSelectionDown(selection, matchingTags));
                return;
              }

              setSelection(moveToMenuSelection(selection));
            }),
          )
        : noop,
    ),
  ];

  return (
    <>
      <p
        {...props}
        {...contentEditableProps}
        {...copyPasteHandlers}
        className={cx(styles.commentForm__input, className)}
        ref={mergeRefs([contentEditableProps.ref, forwardedRef, ref])}
        /*
            // @ts-expect-error placeholder is valid */
        placeholder={placeholder}
        tabIndex={0}
        onKeyDown={callHandlers([...tagMenuHandlers, ...undoRedoHandlers])}
      />
      {canInsertTag && (
        <HoverNextToElement
          usePortal
          elementRef={ref}
          viewportPolicy="none"
          positionStrategy={withinContainer(
            scrollContainerRef,
            leftAlignedBelowElement,
          )}
        >
          <TagMenu>
            <TagMenuList title={tagTypeDisplayName}>
              {matchingTags.map((tag, idx) => {
                const isSelected =
                  isMenuSelection(selection) && selection.menuIndex === idx;
                return (
                  <TagMenuItem
                    key={tag.userId}
                    isSelected={isSelected}
                    onClick={() => insertTag(tag)}
                  >
                    {tag.displayName}
                  </TagMenuItem>
                );
              })}
            </TagMenuList>
          </TagMenu>
        </HoverNextToElement>
      )}
    </>
  );
});

export default CommentEditable;
