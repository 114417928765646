import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import TimeInput from '../../../../dateTime/TimeInput.tsx';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import TransparentInput from '../../../../design-system/molecules/TransparentInput.tsx';
import HoverMenu, {
  HoverMenuButtonItem,
} from '../../../../design-system/organisms/HoverMenu.tsx';
import { AgendaItem } from '../../../../design-system/zeck/Agenda.tsx';
import { rightAlignedBelowElement } from '../../../../domHelpers/hoverNextTo/positionStrategy/rightAlignedBelow.ts';
import useHover from '../../../../junkDrawer/useHover.ts';
import usePlaintextEditable from '../usePlaintextEditable.ts';
import styles from './AgendaEditable.module.scss';
import getAgendaItemCustomDisplayName from './getAgendaItemCustomDisplayName.ts';
import placeholderStyles from './Placeholder.module.scss';

type UIState = 'menu-closed' | 'menu-open' | 'renaming';

type AgendaItemCustomEditableProps = Omit<
  React.ComponentProps<'div'>,
  'children'
> & {
  name: string;
  description: string;
  startTime: number | null;
  timeZone: string | null;
  onChangeName: (newName: string) => void;
  onChangeDescription: (newName: string) => void;
  onChangeStartTime: (values: {
    startTime: number | null;
    timeZone: string;
  }) => void;
  onClickDelete: () => void;
};

const IconButtonMotion = motion(IconButton);

const AgendaItemCustomEditable = React.forwardRef<
  HTMLDivElement,
  AgendaItemCustomEditableProps
>(
  (
    {
      name,
      description,
      startTime,
      timeZone,
      onChangeName,
      onChangeDescription,
      onChangeStartTime,
      onClickDelete,
      ...props
    },
    ref,
  ) => {
    const descriptionEditableProps = usePlaintextEditable<HTMLParagraphElement>(
      description,
      onChangeDescription,
    );
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { isHovered, listeners: hoverListeners } = useHover();
    const [uiState, setUIState] = useState<UIState>('menu-closed');
    const transitionUIState =
      (transitions: { [A in UIState]: UIState }) => (fromState: UIState) => {
        setUIState(transitions[fromState]);
      };

    const showHover = isHovered || uiState === 'menu-open';

    const showMenu = uiState === 'menu-open';

    return (
      <>
        <div
          ref={ref}
          {...props}
          data-testid="agenda-item"
          className={cx(
            styles.agendaEditable__item,
            showHover && styles.agendaEditable__item_hover,
          )}
          {...hoverListeners}
        >
          <AgendaItem
            {...{
              name: (
                <div className={styles.agendaEditable__titleRow}>
                  {uiState === 'renaming' ? (
                    <TransparentInput
                      value={name}
                      onSubmit={(newName) => {
                        setUIState('menu-closed');
                        onChangeName(newName);
                      }}
                      aria-label="agenda item name"
                      onCopy={(event) => {
                        event.stopPropagation();
                      }}
                      onPaste={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  ) : (
                    getAgendaItemCustomDisplayName(name)
                  )}
                  <AnimatePresence>
                    {showHover && (
                      <IconButtonMotion
                        ref={buttonRef}
                        name="kebab"
                        aria-label="manage agenda item"
                        onClick={() => {
                          transitionUIState({
                            'menu-closed': 'menu-open',
                            'menu-open': 'menu-closed',
                            renaming: 'menu-open',
                          })(uiState);
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      />
                    )}
                  </AnimatePresence>
                </div>
              ),
              description: (
                <div
                  {...descriptionEditableProps}
                  className={placeholderStyles.placeholder}
                  /*
                    // @ts-expect-error placeholder is valid */
                  placeholder="Item description"
                />
              ),
              startTime: (
                <TimeInput
                  label="Start time"
                  time={startTime}
                  timeZone={timeZone}
                  onChange={({ time, timeZone }) =>
                    onChangeStartTime({ startTime: time, timeZone })
                  }
                />
              ),
            }}
          />
        </div>
        <HoverMenu
          elementRef={buttonRef}
          positionStrategy={rightAlignedBelowElement(8)}
          onClose={() => {
            transitionUIState({
              'menu-closed': 'menu-closed',
              'menu-open': 'menu-closed',
              renaming: 'renaming',
            })(uiState);
          }}
          isOpen={showMenu}
        >
          <HoverMenuButtonItem
            iconName="rename"
            color="normal"
            onClick={() => {
              setUIState('renaming');
            }}
          >
            Rename
          </HoverMenuButtonItem>
          <HoverMenuButtonItem
            iconName="trash"
            color="danger"
            onClick={onClickDelete}
          >
            Delete
          </HoverMenuButtonItem>
        </HoverMenu>
      </>
    );
  },
);

export default AgendaItemCustomEditable;
