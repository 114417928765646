import getSelectedBlock from '../../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import replaceBlockAt from '../../../pages/zeck/editor/BodyEditor/replaceBlockAt.js';
import {
  applySelection,
  ContentPatch,
} from '../../../pages/zeck/editor/BodyEditor/replaceSelectionWith.js';
import EditorData from '../../../pages/zeck/editor/EditorData.js';
import {
  ActionResultWithEffect,
  EditorConfiguration,
  MaybeHandled,
} from '../../EditorAction.js';
import { isBlockSelection, isTextSelection } from '../../EditorSelection.js';
import { EditorStateGeneric } from '../../EditorStateGeneric.js';
import { getStartOfBlockSelection } from '../../selection/BlockSelection.js';
import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import splitContentByBlockSelection from '../../selection/splitContentByBlockSelection.js';
import { textSelection } from '../../selection/TextSelection.js';

type BlockEditorCutInterface<BlockType> = {
  cut: (
    selection: ContentSelection,
  ) => MaybeHandled<
    [newValue: ContentPatch<BlockType[]>, returnValue: EditorData<BlockType>]
  >;
};

const cut =
  <BlockType>({
    generateBlockEditor,
    createDefaultBlock,
  }: EditorConfiguration<BlockType, BlockEditorCutInterface<BlockType>>) =>
  (
    initialState: EditorStateGeneric<BlockType>,
  ): MaybeHandled<ActionResultWithEffect<BlockType, EditorData<BlockType>>> => {
    const { content, selection } = initialState;

    // this is basically the same as `replaceSelectionWith` but with a return value
    if (isBlockSelection(selection)) {
      const [beforeBlocks, selectedBlocks, afterBlocks] =
        splitContentByBlockSelection(content, selection);

      return [
        {
          content: [...beforeBlocks, createDefaultBlock([]), ...afterBlocks],
          selection: textSelection(
            getStartOfBlockSelection(selection),
            contentSelection(0),
          ),
        },
        { type: 'block', content: selectedBlocks },
      ];
    }

    if (isTextSelection(selection)) {
      const currentBlock = getSelectedBlock(content, selection);
      if (!currentBlock) return;

      const result = generateBlockEditor(currentBlock).cut(selection.offset);
      if (!result) return;

      const contentPatch = result[0];

      return [
        {
          content: replaceBlockAt(
            content,
            contentPatch.contentSubset,
            selection.index,
          ),
          selection: applySelection(selection, contentPatch.selection),
        },
        result[1],
      ];
    }

    return;
  };

export default cut;
