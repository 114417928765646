import { Session } from './api/ApiSession.js';
import WithBrandKit from './design-system/zeck/WithBrandKit.js';
import { Routes } from './Routes.js';
import makeAuthenticatedRoutes from './routes/authenticatedRoutes.js';
import makeNoCompanyAccessRoutes from './routes/makeNoCompanyAccessRoutes.js';
import WithUserInfo from './services/useUserInfo.js';
import FetchUserAndCompany from './userAndCompany/FetchUserAndCompany.js';

export const CompanyDependentRoutes: React.FC<{ apiSession: Session }> = ({
  apiSession,
}) => {
  return (
    <FetchUserAndCompany
      apiSession={apiSession}
      renderWithCompanyAccess={(userAndCompany) => (
        <WithUserInfo userAndCompany={userAndCompany}>
          <WithBrandKit userAndCompany={userAndCompany}>
            {(brandKitResource) => (
              <Routes
                routes={makeAuthenticatedRoutes({
                  userAndCompany,
                  brandKitResource,
                  ...apiSession,
                })}
                company={userAndCompany.activeCompany}
              />
            )}
          </WithBrandKit>
        </WithUserInfo>
      )}
      renderWithNoCompanyAccess={(user) => (
        <Routes
          routes={makeNoCompanyAccessRoutes({
            user,
            ...apiSession,
          })}
        />
      )}
    />
  );
};
