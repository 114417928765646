import cx from 'classnames';
import React from 'react';
import styles from './Table.module.scss';

type TableProps = {
  drawGridlines?: boolean;
} & React.ComponentProps<'table'>;

export const Table = React.forwardRef<HTMLTableElement, TableProps>(
  function Table({ drawGridlines, ...props }, forwardedRef) {
    return (
      <table
        {...props}
        ref={forwardedRef}
        className={cx(
          styles.table,
          drawGridlines && styles.table_drawGridlines,
          props.className,
        )}
      />
    );
  },
);
