import ContentSelection from '../../../../../editor/selection/contentSelection/ContentSelection.ts';
import { PublishedCommentHighlight } from '../commentsSidebar/useComments/selectionComments/SelectionComments.js';

export const addSelectionHighlightIfNeeded = (
  highlights: PublishedCommentHighlight[],
  shouldShowForm: boolean,
  selection: ContentSelection | null,
): PublishedCommentHighlight[] => {
  const selectionHighlight =
    selection && shouldShowForm
      ? [
          {
            type: 'valid' as const, // TODO new type and move this logic into atom
            commentId: 'new-highlight',
            selection,
          },
        ]
      : [];

  return [...(highlights ?? []), ...selectionHighlight];
};
