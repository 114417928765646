import { User } from 'api-client/types.ts';
import { atom, useAtomValue } from 'jotai';

export const userAtom = atom<User | undefined>(undefined);

export const useUser: () => User = () => {
  const user = useAtomValue(userAtom);
  if (!user) {
    throw new Error('User not found');
  }
  return user;
};
