import cx from 'classnames';
import { Icon } from 'icons';
import React from 'react';
import styles from './FolderSidebarItem.module.scss';
import { SidebarLink } from './SidebarItem.tsx';

type FolderSidebarItemProps = {
  active?: boolean;
  hover?: boolean;
  collapse?: boolean;
  kebab?: React.ReactNode;
  label?: React.ReactNode;
  href: string;
  isEditing: boolean;
  onClick?: () => void;
};

const FolderSidebarItem = React.forwardRef<
  HTMLAnchorElement,
  FolderSidebarItemProps
>(function FolderSidebarItem(
  { active, isEditing, collapse, kebab, label, ...props },
  forwardedRef,
) {
  return (
    <SidebarLink
      {...props}
      ref={forwardedRef}
      active={active}
      data-testid="zeck-folder-item"
      icon={
        <Icon
          name="folder"
          className={cx(
            styles.folderSidebarItem__icon,
            active && styles.folderSidebarItem__icon_active,
          )}
        />
      }
      label={<span className={styles.folderSidebarItem__label}>{label}</span>}
      iconOnly={collapse}
      kebab={kebab}
      isEditing={isEditing}
    />
  );
});

export default FolderSidebarItem;
