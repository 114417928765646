import { createDividerBlock } from 'editor-content/DividerBlock.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import { v4 } from 'uuid';
import { textSelection } from '../../../../editor/selection/TextSelection.ts';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import { HydratedBlock } from '../../../../types/HydratedBlock.ts';
import { cleanupBlocksForPaste } from '../cleanupBlocksForPaste.ts';
import { BodyState } from './BodyEditor.ts';

const insertAiContentAbove = (
  initialState: {
    content: BodyState['content'];
  },
  newBlocks: HydratedBlock[],
  linkables: Linkable[],
) => {
  return {
    content: [
      ...cleanupBlocksForPaste(newBlocks, linkables).map((block) => ({
        ...block,
        id: v4(),
      })),
      createDividerBlock(),
      ...initialState.content,
    ],
    selection: textSelection(0, contentSelection(0)),
  };
};

export default insertAiContentAbove;
