import isEqual from 'lodash/isEqual.js';
import { Section, Zeck } from '../types.ts';

function isZeckChangedSinceLastPublish(
  zeck: Zeck,
  editorSectionState: Section | void,
) {
  if (!zeck.publishedZeck) {
    return true;
  }

  const { publishedZeck } = zeck;

  if (
    zeck.name !== publishedZeck.name ||
    zeck.coverImageId !== publishedZeck.coverImageId ||
    zeck.sections.length !== publishedZeck.sections.length
  ) {
    return true;
  }

  return zeck.sections.some((zeckSection) => {
    const publishedSection = publishedZeck.sections.find(
      (publishedSection) => publishedSection.sectionId === zeckSection.id,
    );

    if (!publishedSection) return true;

    const currentSectionState =
      editorSectionState && zeckSection.id === editorSectionState.id
        ? editorSectionState
        : zeckSection;

    return (
      currentSectionState.title !== publishedSection.title ||
      currentSectionState.position !== publishedSection.position ||
      currentSectionState.supplemental !== publishedSection.supplemental ||
      !isEqual(currentSectionState.headline, publishedSection.headline) ||
      currentSectionState.version !== publishedSection.version ||
      currentSectionState.coverImageId !== publishedSection.coverImageId ||
      currentSectionState.coverImageEnabled !==
        publishedSection.coverImageEnabled
    );
  });
}

export default isZeckChangedSinceLastPublish;
