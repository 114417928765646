import { Icon } from 'icons';
import { useCallback, useState } from 'react';
import useApi from '../../../../api/useApi.js';
import { DeletedSection } from '../../../../types/DeletedSection.js';
import styles from './DeletedSectionsModal.module.scss';
import ConfirmDeleteSectionModal from './ui/ConfirmDeleteSectionModal.tsx';
import InfiniteScrollingModal from './ui/InfiniteScrollingModal.tsx';

type DeletedSectionsModalProps = {
  zeckId: string;
  onRestore: (sectionId: string) => Promise<void>;
};

const DeletedSectionRow = ({
  deletedSection,
  onRestoreSection,
  onRemoveFromTrash,
}: {
  deletedSection: DeletedSection;
  onRestoreSection: (sectionId: string) => void;
  onRemoveFromTrash: (sectionId: string) => Promise<void>;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <div
      data-testid={`section-trash-row-${deletedSection.id}`}
      className={styles.deletedSections__row}
    >
      {isDeleting && (
        <ConfirmDeleteSectionModal
          isOpen={true}
          sectionTitle={deletedSection.title}
          onDelete={() => onRemoveFromTrash(deletedSection.id)}
          onCancel={() => setIsDeleting(false)}
        />
      )}
      <div className={styles.deletedSections__sectionTitle}>
        {deletedSection.title}
      </div>

      <div className={styles.deletedSections__restoreButtonWrapper}>
        <div
          data-testid={`section-trash-restore-button-${deletedSection.id}`}
          className={styles.deletedSections__restoreButton}
          onClick={() => onRestoreSection(deletedSection.id)}
        >
          <Icon name="restore" />
          <div>Restore</div>
        </div>
      </div>

      <div className={styles.deletedSections__deleteButtonWrapper}>
        <div
          data-testid={`remove-from-trash-button-${deletedSection.id}`}
          className={styles.deletedSections__deleteButton}
          onClick={() => setIsDeleting(true)}
        >
          <Icon name="trash" />
          <div>Delete Permanently</div>
        </div>
      </div>
    </div>
  );
};

const DeletedSectionsModal = ({
  zeckId,
  onRestore,
}: DeletedSectionsModalProps) => {
  const api = useApi();

  const [removedSectionIds, setRemovedSectionIds] = useState<string[]>([]);

  const onRemoveFromTrash = useCallback(
    async (sectionId: string) => {
      setRemovedSectionIds((current) => [...current, sectionId]);
      await api.removeSectionFromTrash(zeckId, sectionId);
    },
    [api, zeckId],
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const onRestoreSection = async (sectionId: string) => {
    setIsUpdating(true);
    await onRestore(sectionId);
  };

  const fetchDeletedSections = useCallback(
    (cursor: string | undefined | null) =>
      api.fetchZeckTrashedSections(zeckId, cursor ?? null),
    [api, zeckId],
  );

  const filterDeletedSections = useCallback(
    (models: DeletedSection[]) =>
      models.filter((model) => !removedSectionIds.includes(model.id)),
    [removedSectionIds],
  );

  return (
    <InfiniteScrollingModal
      fetchFunc={fetchDeletedSections}
      title="Deleted Sections"
      emptyMessage="There are no deleted sections in the Trash"
      isUpdating={isUpdating}
      filterModelsFunc={filterDeletedSections}
      renderRow={(deletedSection) => (
        <DeletedSectionRow
          deletedSection={deletedSection}
          onRestoreSection={onRestoreSection}
          onRemoveFromTrash={onRemoveFromTrash}
        />
      )}
    />
  );
};

export default DeletedSectionsModal;
