import { TextBlock } from 'editor-content/Block.js';
import Linkable from 'editor-content/html/Linkable.js';
import React, { ForwardedRef } from 'react';
import { SelectionCommentsFeatureAtom } from '../publish/commentsSidebar/useComments/selectionComments/SelectionComments.js';
import { withRenderTextNodes } from './withRenderTextNodes.js';
import { withRenderTextNodesWithComments } from './withRenderTextNodesWithComments.js';

type WithTextBlockProps<B extends TextBlock> = {
  block: B;
  linkables: Linkable[]; // SectionLinkFeatureAtom -> dependent on published sections and meeting minutes
  selectionCommentsFeatureAtom?: SelectionCommentsFeatureAtom;
  className?: string;
};

export const withTextBlock = <B extends TextBlock, P>(
  Wrapped: React.ComponentType<P>,
) => {
  const WrappedWithComments = withRenderTextNodesWithComments(Wrapped);

  const WrappedWithoutComments = withRenderTextNodes(Wrapped);

  return React.forwardRef(function WithBlock(
    { selectionCommentsFeatureAtom, ...otherProps }: P & WithTextBlockProps<B>,
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    if (!selectionCommentsFeatureAtom) {
      // @ts-expect-error // TODO: HOC typing issues
      return <WrappedWithoutComments ref={forwardedRef} {...otherProps} />;
    }

    return (
      // @ts-expect-error // TODO: HOC typing issues
      <WrappedWithComments
        ref={forwardedRef}
        selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
        {...otherProps}
      />
    );
  });
};
