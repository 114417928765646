import cx from 'classnames';
import DOMPurify from 'dompurify';
import generateMeetingMinutesHtml from 'editor-content/MeetingMinutes/generateMeetingMinutesHtml.js';
import React from 'react';
import { MeetingMinutes } from '../api/endpoints/createMeetingMinutesApi.ts';
import blockLayoutStyles from '../design-system/zeck/BlockLayout.module.scss';
import zeckStyles from '../design-system/zeck/ZeckStyles.module.scss';
import styles from './MeetingMinutesView.module.scss';

type MeetingMinutesViewProps = {
  meetingMinutes: MeetingMinutes;
  additionalContent?: React.ReactNode;
  className?: string;
};
const MeetingMinutesView = React.forwardRef<
  HTMLDivElement,
  MeetingMinutesViewProps
>(function MeetingMinutesView(props, forwardedRef) {
  const content =
    props.meetingMinutes.archivedHtml ??
    generateMeetingMinutesHtml(props.meetingMinutes);

  return (
    <div
      className={cx(
        zeckStyles.zeck,
        styles.minutesView,
        blockLayoutStyles.blockLayout,
        props.className,
      )}
      ref={forwardedRef}
    >
      <div
        data-testid="meeting-minutes"
        className={styles.minutesView__blocks}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content, { FORBID_TAGS: ['form'] }),
        }}
      />
      {props.additionalContent}
    </div>
  );
});

export default MeetingMinutesView;
