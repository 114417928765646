import cx from 'classnames';
import { Icon, IconName } from 'icons';
import IconLink from '../../../../design-system/atoms/IconLink.tsx';
import WithTooltip from '../../../../design-system/organisms/WithTooltip.tsx';
import styles from './GoToSite.module.scss';

type GoToSiteProps = {
  iconName: IconName;
  disabled?: boolean;
  href: string;
};

export const GoToSite = ({
  iconName,
  disabled,
  ...otherProps
}: GoToSiteProps) => {
  if (disabled) {
    return (
      <div
        data-testid="goToSiteDisabled"
        aria-label="go to site"
        {...{
          className: cx(
            styles.zeckSidebar__goToSite,
            styles.zeckSidebar__goToSite_disabled,
          ),
        }}
      >
        <Icon name={iconName} />
      </div>
    );
  }
  return (
    <WithTooltip<HTMLAnchorElement> text="Go to Published Site">
      {(ref, listeners) => (
        <IconLink
          name={iconName}
          data-testid="goToSiteLink"
          aria-label="go to site"
          ref={ref}
          {...{
            ...otherProps,
            ...listeners,
            className: styles.zeckSidebar__goToSite,
          }}
        />
      )}
    </WithTooltip>
  );
};
