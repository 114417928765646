import { slugify } from '../services/Paths.js';
import { PublishedSection, Section } from '../types.js';
import { getTitleFromSection } from './Section.js';

export const isPublishedSection = (
  section: Pick<Section, 'id'> | Pick<PublishedSection, 'id' | 'sectionId'>,
): section is PublishedSection => {
  return section.hasOwnProperty('sectionId');
};

export const getSlugFromPublishedSection = (
  section: Pick<PublishedSection, 'sectionId' | 'title'>,
): string => {
  const titleSlug = slugify(getTitleFromSection(section).slice(0, 16));
  const { sectionId } = section;
  return `${titleSlug}-${sectionId}`;
};
