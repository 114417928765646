import cx from 'classnames';
import { Icon } from 'icons';
import { useState } from 'react';
import { hasStatus } from '../../api/makeMakeApiRequest.ts';
import Button from '../../design-system/atoms/Button.tsx';
import IconButton from '../../design-system/atoms/IconButton.tsx';
import InputCheckbox from '../../design-system/atoms/InputCheckbox.tsx';
import LoadingButton from '../../design-system/molecules/LoadingButton.tsx';
import Modal from '../../design-system/organisms/Modal.tsx';
import emailRegex from '../../emailRegex.ts';
import styles from './InvitePeople.module.scss';

type InvitePeopleProps = {
  sendInvitation: (emails: Array<string>, sendEmails: boolean) => Promise<void>;
  className?: string;
};

export default function InvitePeople({
  sendInvitation,
  className,
}: InvitePeopleProps) {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [emails, setEmails] = useState('');
  const [sendEmails, setSendEmails] = useState(true);
  const [emailsInvalid, setEmailsInvalid] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onInviteClick = async () => {
    const trimmedEmails = emails.split(',').map((str) => str.trim());
    const allEmailsValid = trimmedEmails.every((email) =>
      emailRegex.test(email),
    );

    if (allEmailsValid) {
      try {
        setSubmitting(true);
        await sendInvitation(trimmedEmails, sendEmails);
        setInviteModalOpen(false);
        setEmails('');
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        if (hasStatus(error) && error.status === 400) {
          setEmailsInvalid(true);
        } else {
          throw error;
        }
      }
    } else {
      setEmailsInvalid(true);
    }
  };

  return (
    <>
      <Button
        className={cx(className, styles.invitePeopleButton)}
        onClick={() => setInviteModalOpen(true)}
        color="primary"
        size="medium"
      >
        <Icon name="plus" /> Invite People
      </Button>

      <Modal
        isOpen={inviteModalOpen}
        onRequestClose={() => setInviteModalOpen(false)}
        className={styles.invitePeopleModal}
      >
        <div className={styles.invitePeopleModal__header}>
          <span>Invite People</span>
          <IconButton
            name="close"
            aria-label="close modal"
            onClick={() => setInviteModalOpen(false)}
          />
        </div>

        <p className={styles.invitePeopleModal__instructions}>
          Unless you opt not to send them, everyone you invite will get an email
          invitation to your company on Zeck.
        </p>

        <label>
          <span className={styles.invitePeopleModal__label}>
            Email addresses
          </span>

          {emailsInvalid && (
            <span className={styles.invitePeopleModal__errorDescription}>
              You’ve entered an invalid email address.
            </span>
          )}

          <textarea
            data-testid="invite-people-email-textarea"
            value={emails}
            onChange={(e) => {
              setEmails(e.target.value);
              setEmailsInvalid(false);
            }}
            placeholder="Separate multiple email address with a comma (ex. seth@jtmarlin.com, jim@jtmarlin.com)"
            className={cx(
              styles.invitePeopleModal__input,
              emailsInvalid && styles.invitePeopleModal__input_invalid,
            )}
            autoCapitalize="off"
            autoCorrect="off"
          />
        </label>

        <InputCheckbox
          className={styles.invitePeopleModal__emailCheckbox}
          label="Don't send email invitations"
          value={!sendEmails}
          onChange={() => setSendEmails(!sendEmails)}
        />

        {submitting ? (
          <LoadingButton className={styles.invitePeopleModal__loadingButton} />
        ) : (
          <Button
            className={styles.invitePeopleModal__submit}
            color="primary"
            size="medium"
            disabled={submitting}
            onClick={onInviteClick}
          >
            Invite
          </Button>
        )}
      </Modal>
    </>
  );
}
