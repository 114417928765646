import cx from 'classnames';
import times from 'lodash/times.js';
import { useEffect, useId, useRef, useState } from 'react';
import DetectsOutsideClick from '../junkDrawer/DetectsOutsideClick.tsx';
import mergeRefs from '../junkDrawer/mergeRefs.ts';
import styles from './ManualTimePicker.module.scss';
import { Picker, PickerItem } from './Picker.tsx';

type ManualTimePickerProps = {
  time: number;
  onChange: (time: number) => void;
};

const minutesBetweenMidnightAndNoon = 60 * 12;

function toTime(hour: number, minute: number, isPm: boolean) {
  return hour * 60 + minute + (isPm ? 720 : 0);
}

export default function ManualTimePicker({
  time,
  onChange,
}: ManualTimePickerProps) {
  const isPm = time === null ? true : time >= minutesBetweenMidnightAndNoon;
  const hour = time === null ? 0 : Math.floor(time / 60) % 12;
  const minute = time === null ? 0 : time % 60;

  return (
    <div className={styles.picker} data-testid="manual-time-picker">
      <Select<number>
        label="hour"
        options={times(12, (i) => ({
          label: `${i === 0 ? 12 : i}`,
          value: i,
        }))}
        value={hour}
        onSelect={(hour) => {
          onChange(toTime(hour, minute, isPm));
        }}
      />
      <span>:</span>
      <Select<number>
        label="minute"
        options={times(60, (i) => ({
          label: `${i}`.padStart(2, '0'),
          value: i,
        }))}
        value={minute}
        onSelect={(minute) => {
          onChange(toTime(hour, minute, isPm));
        }}
      />{' '}
      <Select<boolean>
        label="ampm"
        options={[
          { label: 'am', value: false },
          { label: 'pm', value: true },
        ]}
        value={isPm}
        onSelect={(isPm) => {
          onChange(toTime(hour, minute, isPm));
        }}
      />
    </div>
  );
}

type SelectProps<Value> = {
  label: string;
  options: Array<{ label: string; value: Value }>;
  value: Value;
  onSelect: (v: Value) => void;
};

function Select<Value>({
  options,
  label,
  value,
  onSelect,
}: SelectProps<Value>) {
  const name = useId();
  const [open, setOpen] = useState(false);

  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!open || !container.current) return;

    const radioButton =
      container.current.querySelector<HTMLInputElement>('input[checked]');
    if (!radioButton) {
      container.current.scrollTop = 0;
      return;
    }

    const label = (radioButton.labels || [])[0];
    if (!label) {
      container.current.scrollTop = 0;
      return;
    }

    container.current.scrollTop =
      label.offsetTop -
      0.5 * (container.current.offsetHeight - label.offsetHeight);
  }, [open]);

  const selectedOption = options.find((o) => o.value === value);

  return (
    <div className={styles.selectContainer}>
      <span
        aria-label={label}
        className={cx(styles.selectInput, open && styles.openSelectInput)}
        onClick={() => {
          setOpen(true);
        }}
      >
        {selectedOption && selectedOption.label}
      </span>
      {open && (
        <DetectsOutsideClick
          onClick={() => {
            setOpen(false);
          }}
        >
          {(ref) => (
            <Picker
              ref={mergeRefs([container, ref])}
              className={styles.selectMenu}
            >
              {options.map((option) => (
                <PickerItem
                  key={option.label}
                  selected={value === option.value}
                  onSelect={() => {
                    onSelect(option.value);
                    setOpen(false);
                  }}
                  radioGroupName={name}
                >
                  {option.label}
                </PickerItem>
              ))}
            </Picker>
          )}
        </DetectsOutsideClick>
      )}
    </div>
  );
}
