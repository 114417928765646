import {
  Block,
  createParagraphBlock,
  getTextFromBlock,
  isTextBlock,
} from 'editor-content/Block.js';
import { createTableBlock, TableBlockRow } from 'editor-content/TableBlock.js';
import { getTextFromNodes, Text } from 'editor-content/TextNode.js';
import Papa from 'papaparse';
import { getSafePlaintextFromClipboardData } from '../../../junkDrawer/getSafePlaintextFromClipboardData.ts';
import EditorData from './EditorData.js';
import {
  createZeckClipboardData,
  extractZeckClipboardData,
} from './ZeckClipboardData.js';

export function writeEditorDataToClipboard(
  editorData: EditorData<Block>,
  companyId: string,
  dataTransfer: Pick<DataTransfer, 'setData'>,
) {
  dataTransfer.setData(
    'application/json',
    createZeckClipboardData(editorData, companyId),
  );

  switch (editorData.type) {
    case 'block': {
      const blocks = editorData.content;

      dataTransfer.setData(
        'text/plain',
        blocks.filter(isTextBlock).map(getTextFromBlock).join('\n'),
      );

      return;
    }
    case 'text': {
      dataTransfer.setData('text/plain', getTextFromNodes(editorData.content));

      return;
    }
  }
}

const imageKinds = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/webp',
  'image/svg+xml',
];

function isPastingOutsideImageContent(
  e: Pick<DataTransfer, 'types' | 'files'>,
): boolean {
  if (!e.types || e.types.length === 0) {
    return false;
  }
  if (!e.files) {
    return false;
  }

  const includesFiles = e.types.includes('Files');
  const hasAnImageFile = Array.from(e.files).some((f) =>
    imageKinds.includes(f.type),
  );

  return includesFiles && hasAnImageFile;
}

function isPastingPlaintextContent(e: Pick<DataTransfer, 'types'>): boolean {
  return e.types.includes('text/plain');
}

function isPastingOutsideTableContent(
  e: Pick<DataTransfer, 'types' | 'getData'>,
): boolean {
  if (!e.types.includes('text/html')) {
    return false;
  }

  const d = new DOMParser().parseFromString(
    e.getData('text/html'),
    'text/html',
  );

  return !!d.querySelector('table');
}

function handlePastingTableContent(
  onPasteEditorData: (
    editorData: EditorData<Block>,
    sourceCompanyId: null,
  ) => void,
  dataTransfer: Pick<DataTransfer, 'getData' | 'types' | 'files'>,
) {
  const rawContent = getSafePlaintextFromClipboardData(dataTransfer);
  const parseResult = Papa.parse<string[]>(rawContent, { delimiter: '\t' });
  if (parseResult.errors.length > 0) {
    console.log('Error parsing pasted table');
    console.log(parseResult.errors);
  }

  const blockData: { cols: number; rowsData: string[][] }[] = [];
  parseResult.data.forEach((row) => {
    const cols = row.length;
    const lastItem = blockData[blockData.length - 1];
    if (lastItem && lastItem.cols > 1 && cols > 1) {
      lastItem.rowsData.push(row);
    } else {
      blockData.push({ cols, rowsData: [row] });
    }
  });

  const blocks = blockData.map((blockData) => {
    if (blockData.cols > 1) {
      return createTableBlock({
        rows: blockData.rowsData.map((dataRow) => TableBlockRow(dataRow)),
      });
    }

    // if only 1 column, assume it shouldn't be a table block
    // this will result in a 1 column table pasting as a paragraph
    // but that is less likely than mixed paragraph and table content
    return createParagraphBlock([
      Text(blockData.rowsData.map((row) => row[0]).join('')),
    ]);
  });

  return onPasteEditorData(
    {
      type: 'block',
      content: blocks,
    },
    null,
  );
}

function handlePasteImageContent(
  onPasteImage: (imageFile: File) => void,
  dataTransfer: Pick<DataTransfer, 'files'>,
) {
  const imageFile: File | undefined = Array.from(dataTransfer.files).find((f) =>
    imageKinds.includes(f.type),
  );
  if (imageFile) {
    onPasteImage(imageFile);
  }
  return;
}

function handlePasteZeckContent(
  onPasteEditorData: (
    editorData: EditorData<Block>,
    sourceCompanyId: string,
  ) => void,
  dataTransfer: Pick<DataTransfer, 'getData'>,
): void {
  const zeckClipboardData = extractZeckClipboardData(dataTransfer);
  if (zeckClipboardData) {
    const editorData = zeckClipboardData.data;
    onPasteEditorData(editorData, zeckClipboardData.sourceCompanyId);
  }
}

export function handlePaste(
  {
    onPasteEditorData,
    onPastePlaintext,
    onPasteImage,
  }: {
    onPasteEditorData: (
      editorData: EditorData<Block>,
      sourceCompanyId: string | null,
    ) => void;
    onPastePlaintext: (text: string) => void;
    onPasteImage: (imageFile: File) => void;
  },
  dataTransfer: Pick<DataTransfer, 'getData' | 'types' | 'files'>,
) {
  const zeckClipboardData = extractZeckClipboardData(dataTransfer);
  if (zeckClipboardData) {
    return handlePasteZeckContent(onPasteEditorData, dataTransfer);
  }

  if (isPastingOutsideTableContent(dataTransfer)) {
    return handlePastingTableContent(onPasteEditorData, dataTransfer);
  }

  if (isPastingPlaintextContent(dataTransfer)) {
    return onPastePlaintext(getSafePlaintextFromClipboardData(dataTransfer));
  }

  if (isPastingOutsideImageContent(dataTransfer)) {
    return handlePasteImageContent(onPasteImage, dataTransfer);
  }
}
