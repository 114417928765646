import { includes } from 'lodash';
import { useState } from 'react';
import { v4 } from 'uuid';
import ContentSelection from '../../../editor/selection/contentSelection/ContentSelection.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import { VoteBlockHydrated } from '../../../VoteBlockHydrated.js';
import ConfirmResetPrevotesModal from './ConfirmResetPrevotesModal.js';

export type EditBlockHandler = (
  blockIndex: number,
  newValue: HydratedBlock,
  selection: ContentSelection,
) => void;

type ResetPrevotesExperienceProps = {
  onEditBlock: EditBlockHandler;
  prevotedBlockIds: string[];
  children: (onEditBlock: EditBlockHandler) => React.ReactNode;
};

type UIState =
  | {
      type: 'not-confirming';
    }
  | {
      type: 'confirming';
      data: {
        blockIndex: number;
        newValue: VoteBlockHydrated;
        selection: ContentSelection;
      };
    };

function blockNeedsConfirmation(
  newValue: HydratedBlock,
  prevotedBlockIds: string[],
): newValue is VoteBlockHydrated {
  return newValue.type === 'vote' && includes(prevotedBlockIds, newValue.id);
}

function resetPrevotes(newValue: VoteBlockHydrated) {
  return {
    ...newValue,
    id: v4(),
  };
}

const ResetPrevotesExperience: React.FC<ResetPrevotesExperienceProps> = ({
  onEditBlock,
  prevotedBlockIds,
  children,
}) => {
  const [uiState, setUiState] = useState<UIState>({ type: 'not-confirming' });

  return (
    <>
      {children((blockIndex, newValue, selection) => {
        if (blockNeedsConfirmation(newValue, prevotedBlockIds)) {
          setUiState({
            type: 'confirming',
            data: {
              blockIndex,
              newValue,
              selection,
            },
          });
        } else {
          onEditBlock(blockIndex, newValue, selection);
        }
      })}

      <ConfirmResetPrevotesModal
        isOpen={uiState.type === 'confirming'}
        onReset={() => {
          setUiState({ type: 'not-confirming' });

          if (uiState.type !== 'confirming') return;
          onEditBlock(
            uiState.data.blockIndex,
            resetPrevotes(uiState.data.newValue),
            uiState.data.selection,
          );
        }}
        onCancel={() => setUiState({ type: 'not-confirming' })}
      />
    </>
  );
};

export default ResetPrevotesExperience;
