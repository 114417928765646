import cx from 'classnames';
import React from 'react';
import ButtonBase from './ButtonBase.tsx';
import styles from './OutlineButton.module.scss';

export type OutlineButtonProps = {
  children: React.ReactNode;
  className?: string;
  size: React.ComponentProps<typeof ButtonBase>['size'];
  color: 'primary' | 'secondary';
  isFullWidth?: boolean;
};

const colorToClass = (color: OutlineButtonProps['color']) => {
  switch (color) {
    case 'primary':
      return styles.button_primary;
    case 'secondary':
      return styles.button_secondary;
    default:
      return styles.button_primary;
  }
};

const OutlineButton = React.forwardRef<
  HTMLButtonElement,
  OutlineButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(function OutlineButton({ children, className, size, color, ...props }, ref) {
  return (
    <ButtonBase
      {...{
        ref,
        className: cx(className, styles.outlineButton, colorToClass(color)),
        size,
        ...props,
      }}
    >
      {children}
    </ButtonBase>
  );
});

export default OutlineButton;
