import cx from 'classnames';
import { Icon, IconName } from 'icons';
import { useNavigate } from 'react-router-dom';
import HoverNextToElement from '../../domHelpers/hoverNextTo/HoverNextToElement.tsx';
import DetectsOutsideClick from '../../junkDrawer/DetectsOutsideClick.tsx';
import mergeRefs from '../../junkDrawer/mergeRefs.ts';
import useDropdownAnimation from '../../services/useDropdownAnimation.tsx';
import styles from './HoverMenu.module.scss';

type HoverMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
  usePortal?: boolean;
} & React.ComponentProps<typeof HoverNextToElement>;

function HoverMenu({
  isOpen,
  onClose,
  children,
  className,
  ...props
}: HoverMenuProps) {
  const { elementRef: dropdownElRef, show } =
    useDropdownAnimation<HTMLDivElement>(isOpen);

  if (!show) {
    return null;
  }

  return (
    <HoverNextToElement {...props}>
      <DetectsOutsideClick<HTMLDivElement>
        onClick={() => {
          onClose();
        }}
      >
        {(detectClickRef) => (
          <div
            className={cx(styles.hoverMenu, className)}
            ref={mergeRefs([dropdownElRef, detectClickRef])}
            data-testid="menu"
          >
            {children}
          </div>
        )}
      </DetectsOutsideClick>
    </HoverNextToElement>
  );
}

type HoverMenuButtonItemProps = {
  children: React.ReactNode;
  iconName: IconName;
  className?: string;
  iconClassName?: string;
  color: 'danger' | 'normal';
  onClick(e: React.MouseEvent): void;
  disabled?: boolean;
} & React.ComponentProps<'button'>;

type HoverMenuLinkItemProps = {
  children: React.ReactNode;
  iconName: IconName;
  className?: string;
  iconClassName?: string;
  color: 'danger' | 'normal';
  to: string;
  disabled?: boolean;
  target?: string;
};

const colorToClassName = (color: HoverMenuButtonItemProps['color']) => {
  switch (color) {
    case 'danger':
      return styles.hoverMenuItem_danger;
    case 'normal':
      return null;
  }
};

export const HoverMenuButtonItem = ({
  iconName,
  children,
  onClick,
  className,
  iconClassName,
  color,
  disabled,
  ...otherProps
}: HoverMenuButtonItemProps) => {
  return (
    <button
      className={cx(styles.hoverMenuItem, colorToClassName(color), className)}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      <Icon
        name={iconName}
        className={cx(styles.hoverMenuItem__icon, iconClassName)}
      />
      {children}
    </button>
  );
};

export const HoverMenuLinkItem = ({
  iconName,
  children,
  className,
  iconClassName,
  color,
  to,
  disabled = false,
  target,
}: HoverMenuLinkItemProps) => {
  const navigate = useNavigate();

  if (disabled) {
    return (
      <div
        className={cx(
          styles.hoverMenuItem,
          styles.hoverMenuItem_disabled,
          className,
        )}
      >
        <Icon
          name={iconName}
          className={cx(styles.hoverMenuItem__icon, iconClassName)}
        />
        {children}
      </div>
    );
  }

  return (
    <a
      href={to}
      target={target}
      className={cx(styles.hoverMenuItem, colorToClassName(color), className)}
      onClick={(e) => {
        if (target !== '_blank') {
          navigate(to);
          e.preventDefault();
        }
      }}
    >
      <Icon name={iconName} className={cx(styles.hoverMenuItem__icon)} />
      {children}
    </a>
  );
};

export default HoverMenu;
