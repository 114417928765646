import { DividerBlock } from 'editor-content/DividerBlock.js';
import React, { useRef } from 'react';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import Divider from '../../Divider.tsx';
import BlockActions from './BlockActions.ts';
import SelectableDiv from './SelectableDiv.tsx';

type DividerEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  'data-testid'?: string;
} & BlockActions<DividerBlock>;

export default React.forwardRef<HTMLElement, DividerEditableWithKeyboardProps>(
  function DividerEditableWithKeyboard(
    {
      className,
      block,
      selection,
      onSelect,
      isInBlockSelection,
      'data-testid': dataTestid,
    },
    forwardedRef,
  ) {
    const ref = useRef<HTMLDivElement>(null);

    return (
      <SelectableDiv
        ref={mergeRefs([forwardedRef, ref])}
        className={className}
        onSelect={onSelect}
        internalSelection={selection}
        isInBlockSelection={isInBlockSelection}
        data-testid={dataTestid}
      >
        <Divider block={block} />
      </SelectableDiv>
    );
  },
);
