import {
  Block,
  getTextFromBlock,
  isTextBlock,
  newTextBlockOfType,
  TextBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import { getTextFromNodes, TextNode } from 'editor-content/TextNode.js';
import EditorData from '../../../pages/zeck/editor/EditorData.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import { ContentPatch } from '../../ContentPatch.js';
import ContentSelection, {
  contentSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import { textSelection } from '../../selection/TextSelection.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';
import getTextBlockLength from './getTextBlockLength.js';

const splitTextBlockWithBlocks = <
  ThisBlock extends TextBlock,
  AvailableBlocks extends Block,
>(
  block: ThisBlock,
  selection: ContentSelection,
  newBlocks: (AvailableBlocks | ThisBlock)[],
): ContentPatch<(AvailableBlocks | ThisBlock)[]> | void => {
  // this should probably be block colleague
  const lastPastedBlock = newBlocks[newBlocks.length - 1];
  if (!lastPastedBlock) return;
  const contentSelectionInLastBlock = contentSelection(
    isTextBlock(lastPastedBlock) ? getTextFromBlock(lastPastedBlock).length : 0,
  );

  const selectionRelativeToNewBlocks = {
    index: newBlocks.length - 1,
    offset: contentSelectionInLastBlock,
  };

  if (getTextBlockLength(block) === 0) {
    return {
      contentSubset: newBlocks,
      selection: selectionRelativeToNewBlocks,
    };
  }

  const [beforeContent, , afterContent] = splitTextNodesFromContentSelection(
    block.content,
    selection,
  );

  const afterContentIsEmpty = getTextFromNodes(afterContent).length === 0;
  const beforeContentIsEmpty = getTextFromNodes(beforeContent).length === 0;

  // at start of block
  if (beforeContentIsEmpty) {
    return {
      contentSubset: [...newBlocks, updateTextBlock(block, afterContent)],
      selection: selectionRelativeToNewBlocks,
    };
  }

  // at end of block
  if (afterContentIsEmpty) {
    return {
      contentSubset: [updateTextBlock(block, beforeContent), ...newBlocks],
      selection: {
        ...selectionRelativeToNewBlocks,
        index: selectionRelativeToNewBlocks.index + 1,
      },
    };
  }

  // in middle of block
  return {
    contentSubset: [
      updateTextBlock(block, beforeContent),
      ...newBlocks,
      newTextBlockOfType(block, afterContent),
    ],
    selection: {
      ...selectionRelativeToNewBlocks,
      index: selectionRelativeToNewBlocks.index + 1,
    },
  };
};

export const textBlockInsertTextStrategy = <BlockType extends TextBlock>(
  currentBlock: BlockType,
  selection: ContentSelection,
  textNodes: TextNode[],
): { block: BlockType; selection: ContentSelection } => {
  const [beforeNodes, , afterNodes] = splitTextNodesFromContentSelection(
    currentBlock.content,
    selection,
  );

  return {
    block: updateTextBlock(currentBlock, [
      ...beforeNodes,
      ...textNodes,
      ...afterNodes,
    ]),
    selection: contentSelection(
      getTextFromNodes([...beforeNodes, ...textNodes]).length,
    ),
  };
};

const textBlockReplaceSelectedContentStrategy = <
  BlockType extends TextBlock,
  AvailableBlock extends HydratedBlock,
>(
  currentBlock: BlockType,
  selection: ContentSelection,
  data: EditorData<AvailableBlock | BlockType>,
): ContentPatch<(AvailableBlock | BlockType)[]> | void => {
  switch (data.type) {
    case 'text': {
      const newNodes = data.content;

      const { block, selection: updatedSelection } =
        textBlockInsertTextStrategy(currentBlock, selection, newNodes);

      return {
        contentSubset: [block],
        selection: textSelection(0, updatedSelection),
      };
    }
    case 'block': {
      return splitTextBlockWithBlocks(currentBlock, selection, data.content);
    }
  }
};

export default textBlockReplaceSelectedContentStrategy;
