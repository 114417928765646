import { HeadingBlock, updateTextBlock } from 'editor-content/Block.js';
import React from 'react';
import Heading from '../../../../design-system/zeck/Heading.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import EditableProps from './EditableProps.ts';
import useZeckContentEditable from './useZeckContentEditable.ts';

const HeadingEditable = React.forwardRef<
  HTMLElement,
  {
    className?: string;
  } & EditableProps<HeadingBlock>
>(function HeadingEditable(
  { value, selection, onChange, onSelect, linkables, ...otherProps },
  forwardedRef,
) {
  const contentEditableProps = useZeckContentEditable(
    {
      content: value.content,
      selection,
    },
    ({ content, selection }) => {
      onChange(updateTextBlock(value, content), selection);
    },
    onSelect,
    { linkables },
  );
  return (
    <Heading
      {...{
        ...otherProps,
        ...contentEditableProps,
        ref: mergeRefs([contentEditableProps.ref, forwardedRef]),
      }}
    />
  );
});

export default HeadingEditable;
