import cx from 'classnames';
import { Icon } from 'icons';
import Button from '../../design-system/atoms/Button.tsx';
import { ReactComponent as NoZecksImage } from '../../images/NoZecksPlaceholder.svg';
import useBreakpoints from '../../services/useBreakpoints.ts';
import styles from './ZeckListEmpty.module.scss';

type ZeckListEmptyProps = {
  onClickCreateZeck: () => void;
  className?: string;
};

const ZeckListEmpty: React.FunctionComponent<ZeckListEmptyProps> = ({
  onClickCreateZeck,
  className = '',
}) => {
  const { isMobile, isTablet } = useBreakpoints();

  return (
    <div
      className={cx(
        className,
        styles.zeckList,
        isMobile && styles.zeckList_mobile,
      )}
    >
      <div className={styles.zeckList__emptyContainer}>
        <div className={styles.zeckList__emptyImageContainer}>
          <NoZecksImage />
        </div>
        <div className={styles.zeckList__emptyTextContainer}>
          <p>
            <strong>You Don't Have Any Zecks Yet</strong>
          </p>
          <p className={styles.zeckList__emptyDescription}>
            {isMobile || isTablet
              ? 'Start a Zeck by visiting this page on a laptop or desktop. Then celebrate with some ice cream maybe.'
              : 'Get started by clicking the button below'}
          </p>
        </div>
        {!isMobile && !isTablet && (
          <Button
            {...{
              className: styles.zeckList__createButton,
              size: 'large',
              color: 'primary',
              onClick: onClickCreateZeck,
            }}
          >
            <Icon name="plus" /> Create A New Zeck
          </Button>
        )}
      </div>
    </div>
  );
};

export default ZeckListEmpty;
