import { baseBlock, createBlock } from './BaseBlock.js';
import { Block } from './Block.js';
import { ChartBlockData } from 'blocks/src/ChartBlock.js';

export const isChartBlock = (block: Block): block is ChartBlock =>
  block.type === 'chart';

export type ChartBlockSourceData = Array<Array<string | number>>;

export type ChartBlock = {
  id: string;
  type: 'chart';
  guid: string;
  data: {
    chartData: ChartBlockData;
    sourceData: ChartBlockSourceData;
    pastMessages: string[];
  };
};

export const ChartBlock = (
  id: string,
  guid: string,
  chartData: ChartBlockData,
  sourceData: ChartBlockSourceData,
  pastMessages: string[],
): ChartBlock =>
  baseBlock('chart', id, {
    guid,
    data: {
      chartData,
      sourceData,
      pastMessages,
    },
  });

export const createChartBlock = (
  guid: string,
  chartData: ChartBlockData,
  sourceData: ChartBlockSourceData,
  pastMessages: string[],
): ChartBlock =>
  createBlock('chart', {
    guid, // must be globally unique, used for artifacts
    data: {
      chartData,
      sourceData,
      pastMessages,
    },
  });

export type AssetContainingBlock = ChartBlock;

export const isAssetContainingBlock = (
  block: Block,
): block is AssetContainingBlock => isChartBlock(block);
