import cx from 'classnames';
import { ImageBlock } from 'editor-content/Block.js';
import React, { useCallback } from 'react';
import Image from '../../../../design-system/atoms/Image.tsx';
import ImageCaption from '../../../../design-system/atoms/ImageCaption.tsx';
import ImageBlockLayout from '../../../../design-system/atoms/ImageLayout.tsx';
import { callHandlers } from '../../../../editor/domFacing/events/isKeyMatch.ts';
import { contentSelection } from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import { getSafePlaintextFromClipboardData } from '../../../../junkDrawer/getSafePlaintextFromClipboardData.ts';
import useFetchImage from '../../../../services/useFetchImage.tsx';
import usePlaintextEditable from '../usePlaintextEditable.ts';
import BlockActions from './BlockActions.ts';
import styles from './ImageWithHover.module.scss';
import keyDownHandlerLetsInputsWork from './keyDownHandlerLetsInputsWork.ts';
import SelectableDiv from './SelectableDiv.tsx';

type ImageEditableWithKeyboardProps = {
  isInBlockSelection: boolean;
  className?: string;
} & BlockActions<ImageBlock>;

const ImageEditableWithKeyboard = React.forwardRef<
  HTMLDivElement,
  ImageEditableWithKeyboardProps
>(function ImageEditableWithKeyboard(
  { block, onChange, onSelect, selection, isInBlockSelection, className },
  forwardedRef,
) {
  const captionEditableProps = usePlaintextEditable<HTMLDivElement>(
    block.caption,
    (caption) => {
      onChange({ ...block, caption }, contentSelection(0));
    },
  );

  const isDirectlySelected = !!selection;

  const { guid, dimensions, width, align } = block;

  const src = useFetchImage(guid);

  return (
    <ImageBlockLayout
      {...{
        className,
        width,
        align,
        image: (
          <SelectableDiv
            data-testid="selectable-image-container"
            isInBlockSelection={isInBlockSelection}
            internalSelection={selection}
            onSelect={onSelect}
            shouldFocus={useCallback(() => {
              return (
                document.activeElement !== captionEditableProps.ref.current
              );
            }, [captionEditableProps.ref])}
          >
            <Image ref={forwardedRef} src={src} dimensions={dimensions} />
          </SelectableDiv>
        ),
        caption: (
          <ImageCaption
            {...{
              ...captionEditableProps,
              onFocus() {
                onSelect(contentSelection(0));
              },
              onCopy(e) {
                e.stopPropagation();
              },
              onPaste(e) {
                e.stopPropagation();
                e.preventDefault();
                if (!e.clipboardData) return;

                const text = getSafePlaintextFromClipboardData(e.clipboardData);
                const values = text.split('\n');
                document.execCommand('insertText', false, values.join(' '));
              },
              className: cx(
                styles.imageCaption,
                isDirectlySelected && styles.imageCaption_selected,
              ),
              'aria-label': 'image caption',
              'data-placeholder':
                'Click here to add an image caption (optional)',
              onKeyDown: callHandlers([keyDownHandlerLetsInputsWork]),
            }}
          />
        ),
      }}
    />
  );
});

export default ImageEditableWithKeyboard;
