import { isTextBlock, TextBlock } from 'editor-content/Block.ts';
import { CommentContent } from 'editor-content/CommentContent.js';
import {
  isTableBlock,
  TableBlock,
  tableCellUncompress,
} from 'editor-content/TableBlock.js';
import { useAtomValue, useSetAtom } from 'jotai';
import { match, P } from 'ts-pattern';
import Toolbar, {
  ToolbarButton,
  ToolbarGroup,
} from '../../../../../design-system/organisms/Toolbar.tsx';
import Point from '../../../../../domHelpers/Point.js';
import HoverNextToPoint from '../../../../../domHelpers/hoverNextTo/HoverNextToPoint.js';
import useBreakpoints from '../../../../../services/useBreakpoints.js';
import {
  SelectionCommentsBlockFeatureAtom,
  SelectionCommentsFeatureAtom,
} from '../commentsSidebar/useComments/selectionComments/SelectionComments.js';
import PublishedSelectionCommentForm from './PublishedSelectionCommentForm.tsx';

const PublishedSelectionCommentMenu = ({
  selectionCommentsFeatureAtom,
  block,
  publishedCommentSelectionUIStateAtom,
  getMenuPosition,
}: {
  selectionCommentsFeatureAtom: SelectionCommentsFeatureAtom;
  block: TextBlock | TableBlock;
  publishedCommentSelectionUIStateAtom: SelectionCommentsBlockFeatureAtom;
  getMenuPosition: (childElement: HTMLElement) => Point | null;
}) => {
  const { isMobile } = useBreakpoints();

  const atoms = useAtomValue(publishedCommentSelectionUIStateAtom);
  const shouldShowToolbar = useAtomValue(atoms.shouldShowToolbar);
  const shouldShowForm = useAtomValue(atoms.shouldShowForm);
  const snippetSelection = useAtomValue(atoms.selection);
  const closeCommentForm = useSetAtom(atoms.closeCommentForm);
  const openCommentForm = useSetAtom(atoms.openCommentForm);

  const selectionCommentsFeatureAtoms = useAtomValue(
    selectionCommentsFeatureAtom,
  );
  const postComment = useSetAtom(selectionCommentsFeatureAtoms.postCommentAtom);
  const viewers = useAtomValue(selectionCommentsFeatureAtoms.viewersAtom);
  const currentUserName = useAtomValue(
    selectionCommentsFeatureAtoms.currentUserNameAtom,
  );
  const canAddNewComment = useAtomValue(
    selectionCommentsFeatureAtoms.canAddNewCommentAtom,
  );

  if (!canAddNewComment) return null;

  // will move into atom
  const onSubmit = async (commentInfo: {
    content: CommentContent;
    isDirectMessage: boolean;
  }) => {
    const commentSelection = match({ block, snippetSelection })
      .with(
        {
          block: P.when(isTextBlock),
          snippetSelection: {
            anchorOffset: P.number,
            focusOffset: P.number,
          },
        },
        (a) => a,
      )
      .with(
        {
          block: P.when(isTableBlock),
          snippetSelection: {
            rowIndex: P.number,
            columnIndex: P.number,
          },
        },
        ({ block, snippetSelection }) => {
          const tableCell =
            block.data.rows[snippetSelection.rowIndex]?.cells?.[
              snippetSelection.columnIndex
            ];
          if (!tableCell)
            throw new Error('Unable to find table cell at selection location');
          const content = tableCellUncompress(tableCell).content;

          return {
            block: {
              type: 'table-cell' as const,
              id: block.id,
              content,
            },
            snippetSelection,
          };
        },
      )
      .otherwise(() => {
        throw new Error(
          'Tried to create a comment with mismatched selection and block type',
        );
      });

    await postComment(
      commentInfo.content,
      commentInfo.isDirectMessage,
      commentSelection,
    );

    closeCommentForm();
  };
  return (
    <>
      {shouldShowForm &&
        snippetSelection &&
        (isMobile ? (
          <PublishedSelectionCommentForm
            onClose={closeCommentForm}
            viewers={viewers}
            currentUserName={currentUserName}
            onSubmit={onSubmit}
          />
        ) : (
          <HoverNextToPoint viewportPolicy="none" getPoint={getMenuPosition}>
            <PublishedSelectionCommentForm
              onClose={closeCommentForm}
              viewers={viewers}
              currentUserName={currentUserName}
              onSubmit={onSubmit}
            />
          </HoverNextToPoint>
        ))}

      {shouldShowToolbar && (
        <HoverNextToPoint viewportPolicy="none" getPoint={getMenuPosition}>
          <Toolbar>
            <ToolbarGroup>
              <ToolbarButton
                data-testid="comment-button"
                iconName="speechBubbleWithText"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openCommentForm();
                }}
              >
                Comment
              </ToolbarButton>
            </ToolbarGroup>
          </Toolbar>
        </HoverNextToPoint>
      )}
    </>
  );
};

export default PublishedSelectionCommentMenu;
