import { TextNode } from 'editor-content/TextNode.js';
import Emittery from 'emittery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PublishedSection, Section } from '../../../types.ts';
import { HydratedBlock } from '../../../types/HydratedBlock.ts';
import isEditorContentEqual from './isEditorContentEqual.ts';

function getContentFromSection(
  section: Section | PublishedSection,
): EditorContent {
  return {
    title: section.title,
    headline: section.headline,
    body: section.body,
  };
}

export type EditorContent = {
  title: string;
  headline: TextNode[];
  body: HydratedBlock[];
};

function applyContentToSection(
  section: Section,
  newContent: EditorContent,
): Section {
  return {
    ...section,
    title: newContent.title,
    headline: newContent.headline,
    body: newContent.body,
  };
}

function isSaved(editorContent: EditorContent, section: Section) {
  return isEditorContentEqual(editorContent, getContentFromSection(section));
}

const useEditorState = ({
  section,
  onChangeSection,
}: {
  section: Section;
  onChangeSection(section: Section): Promise<void>;
}) => {
  const [editorContent, setEditorContent] = useState<EditorContent>(
    getContentFromSection(section),
  );
  const emitter = useMemo(() => new Emittery(), []);

  useEffect(() => {
    // reset state when section changes
    setEditorContent(getContentFromSection(section));
  }, [section.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const editTitle = useCallback(() => {
    emitter.emit('edit-title');
  }, [emitter]);

  return {
    isSaved: isSaved(editorContent, section),
    editorContent,
    setEditorContent,
    saveEditorContent: (newContent: EditorContent) =>
      onChangeSection(applyContentToSection(section, newContent)),
    editTitle,
    subscribeToEditTitle: (callback: () => void) => {
      emitter.on('edit-title', callback);
      return () => {
        emitter.off('edit-title', callback);
      };
    },
  };
};

export default useEditorState;
