import cx from 'classnames';
import IconButton from '../../../design-system/atoms/IconButton.tsx';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import { UserAndCompany } from '../../../userAndCompany/FetchUserAndCompany.tsx';
import CompanyDrawerMenu from './companyMenu/CompanyDrawerMenu.tsx';
import CompanyDropdownMenu from './companyMenu/CompanyDropdownMenu.tsx';
import styles from './Sidebar.module.scss';

const SidebarHeader: React.FC<{
  className?: string;
  collapsed: boolean;
  children: React.ReactNode;
}> = ({ className, collapsed, children }) => (
  <div
    className={cx(
      styles.sidebar__header,
      collapsed && styles.sidebar__header_collapse,
      className,
    )}
  >
    {children}
  </div>
);
export type SidebarTopProps = {
  className?: string;
  menuIsOpen: boolean;
  setMenuIsOpen: (menuIsOpen: boolean) => void;
  userAndCompany: UserAndCompany;
};

export const SidebarTop = ({
  className,
  menuIsOpen,
  setMenuIsOpen,
  userAndCompany,
}: SidebarTopProps) => {
  const { isTablet, isMobile } = useBreakpoints();

  if (isTablet) {
    return (
      <SidebarHeader className={className} collapsed={!menuIsOpen}>
        {menuIsOpen ? (
          <div className={styles.companyNameContainer_tablet}>
            <CompanyDropdownMenu userAndCompany={userAndCompany} />
            <span className={styles.companyNameContainer__close_tablet}>
              <IconButton
                {...{
                  onClick() {
                    setMenuIsOpen(false);
                  },
                  'aria-label': 'Close Menu',
                  name: 'doubleChevronLeft',
                }}
              />
            </span>
          </div>
        ) : (
          <IconButton
            {...{
              onClick() {
                setMenuIsOpen(!menuIsOpen);
              },
              'aria-label': 'Open Menu',
              name: 'hamburger',
            }}
          />
        )}
      </SidebarHeader>
    );
  }

  return (
    <SidebarHeader className={className} collapsed={!menuIsOpen}>
      {isMobile ? (
        <CompanyDrawerMenu userAndCompany={userAndCompany} />
      ) : (
        <CompanyDropdownMenu userAndCompany={userAndCompany} />
      )}
    </SidebarHeader>
  );
};
