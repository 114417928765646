import { pipe } from '../../../../result/Result.js';
import { EditorContent } from '../../edit/useEditorState.js';
import { BodyState } from '../BodyEditor/BodyEditor.js';
import { HeadlineState } from '../HeadlineEditor/HeadlineEditor.js';
import { TitleState } from '../TitleEditor/TitleEditor.js';
import ZeckEditorSelection, {
  getBodySelection,
  getHeadlineSelection,
  getTitleSelection,
} from '../ZeckEditorSelection.js';
import {
  applyBodyState,
  applyHeadlineState,
  applyTitleState,
  ZeckEditorState,
} from './ZeckEditorState.js';

export const nonSelectionBasedTitleAction =
  <ArgsType extends unknown[], ReturnType>(
    action: (
      state: TitleState,
      ...args: ArgsType
    ) => [newState: TitleState, returnValue: ReturnType],
  ) =>
  (
    content: EditorContent,
    selection: ZeckEditorSelection,
    ...args: ArgsType
  ): [newState: ZeckEditorState, returnValue: ReturnType] => {
    const [state, result] = action(
      {
        content: content.title,
        selection: getTitleSelection(selection),
      },
      ...args,
    );

    return [pipe({ content, selection }, applyTitleState(state)), result];
  };

export const nonSelectionBasedHeadlineAction =
  <ArgsType extends unknown[], ReturnType>(
    action: (
      state: HeadlineState,
      ...args: ArgsType
    ) => [newState: HeadlineState, returnValue: ReturnType],
  ) =>
  (
    content: EditorContent,
    selection: ZeckEditorSelection,
    ...args: ArgsType
  ): [newState: ZeckEditorState, returnValue: ReturnType] => {
    const [state, result] = action(
      {
        content: content.headline,
        selection: getHeadlineSelection(selection),
      },
      ...args,
    );

    return [pipe({ content, selection }, applyHeadlineState(state)), result];
  };

export const nonSelectionBasedBodyAction =
  <ArgsType extends unknown[], ReturnType>(
    action: (
      state: BodyState,
      ...args: ArgsType
    ) => [newState: BodyState, returnValue: ReturnType],
  ) =>
  (
    content: EditorContent,
    selection: ZeckEditorSelection,
    ...args: ArgsType
  ): [newState: ZeckEditorState, returnValue: ReturnType] => {
    const [state, result] = action(
      {
        content: content.body,
        selection: getBodySelection(selection),
      },
      ...args,
    );

    return [pipe({ content, selection }, applyBodyState(state)), result];
    // This does not ensure vote blocks are not deleted. Why?
  };
