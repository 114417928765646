import cx from 'classnames';
import ButtonBase from '../../../design-system/atoms/ButtonBase.tsx';
import styles from './EditVoteButton.module.scss';

type EditVoteButtonProps = {
  className?: string;
  onClick: () => void;
};

export default function EditVoteButton({
  className,
  onClick,
}: EditVoteButtonProps) {
  return (
    <ButtonBase
      onClick={onClick}
      size="small"
      className={cx(styles.button, className)}
      data-testid="editVoteButton"
    >
      Edit Vote
    </ButtonBase>
  );
}
