import cx from 'classnames';
import React from 'react';
import styles from './BulletedList.module.scss';

type BulletedListProps = {
  children: React.ReactNode;
  className?: string;
};

const BulletedList = ({
  children,
  className,
  ...props
}: BulletedListProps & React.HTMLAttributes<HTMLUListElement>) => {
  return (
    <ul
      {...{
        ...props,
        className: cx(className, styles.bulletedList),
      }}
    >
      {children}
    </ul>
  );
};

type BulletedListItemProps = {
  children?: React.ReactNode;
  className?: string;
  indent: number;
};

export const BulletedListItem = React.forwardRef<
  HTMLLIElement,
  BulletedListItemProps & React.HTMLAttributes<HTMLLIElement>
>(({ children, indent, className, ...props }, forwardedRef) => {
  return (
    <li
      {...{
        ...props,
        ref: forwardedRef,
        className: cx(
          className,
          styles.bulletedListItem,
          indent && styles.indent,
        ),
      }}
    >
      {children}
    </li>
  );
});

export default BulletedList;
