import isEqual from 'lodash/isEqual.js';
import { useCallback, useEffect, useState } from 'react';
import { Subtract } from 'utility-types';
import useDebounce from './useDebounce.ts';
import { EditorContent } from './useEditorState.tsx';

export type WithEditorSaveProps = {
  setEditorContent(newContent: EditorContent): void;
  saveEditorContent(newContent: EditorContent): Promise<void>;
  editorContent: EditorContent;
};

export type WithEditorSaveInjectedProps = {
  editorContent: EditorContent;
  setEditorContent(newContent: EditorContent): void;
};

const withEditorSave =
  <P extends WithEditorSaveInjectedProps>(
    Wrapped: React.ComponentType<P>,
  ): React.FunctionComponent<
    WithEditorSaveProps & Subtract<P, WithEditorSaveInjectedProps>
  > =>
  ({ setEditorContent, saveEditorContent, editorContent, ...otherProps }) => {
    const debouncedSaveOnUpdate = useDebouncedSave(
      editorContent,
      saveEditorContent,
    );

    return (
      <Wrapped
        {...otherProps}
        {...({
          editorContent,
          setEditorContent: useCallback(
            (newContent) => {
              if (!isEqual(editorContent, newContent)) {
                debouncedSaveOnUpdate(newContent);
                setEditorContent(newContent);
              }
            },
            [editorContent, debouncedSaveOnUpdate, setEditorContent],
          ),
        } as P)}
      />
    );
  };

export default withEditorSave;

export function useDebouncedSave<ContentType>(
  content: ContentType,
  save: (content: ContentType) => void,
) {
  const [hasSaveInFlight, setHasSaveInFlight] = useState(false);
  const [hasPendingSave, setHasPendingSave] = useState(false);

  const oneAtATimeSave = async (
    newContent: ContentType,
    hasSaveInFlight: boolean,
  ) => {
    if (hasSaveInFlight) {
      return;
    }

    // this flag prevents saves on re-renders until
    setHasSaveInFlight(true);

    // this causes a render on EditSectionPage and causes us to be re-rendered with updated saveEditorContent

    try {
      await save(newContent);
    } finally {
      // setting this flag to false allows saves on re-renders, which we know have the updated saveEditorContent from the previous line
      setHasSaveInFlight(false);
    }
  };

  const debouncedSave = useDebounce(oneAtATimeSave, 4000, 6000);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // The render lifecycle of this component is keeping editorContent and
    // saveEditorContent fresh. We only attempt to save if there is a pending save and no
    // save is currently in flight, then normal debounce procedures are in effect.
    if (hasPendingSave && !hasSaveInFlight) {
      setHasPendingSave(false);
      debouncedSave.call(content, hasSaveInFlight);
    }
  });

  useEffect(() => {
    return () => {
      // force immediate save on unmount if one in progress
      setHasPendingSave(false);
      debouncedSave.flush();
    };
  }, [debouncedSave]);

  const cb = useCallback(
    (newContent: ContentType) => {
      debouncedSave.call(newContent, hasSaveInFlight);

      // we know a save is in flight, our debounced save will be dropped (this is not always true)
      if (hasSaveInFlight) {
        setHasPendingSave(true);
      }
    },
    [debouncedSave, hasSaveInFlight],
  );

  return cb;
}
