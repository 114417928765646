import cx from 'classnames';
import styles from './LinkButton.module.scss';

type LinkButtonProps = {
  children: React.ReactNode;
  className?: string;
  size: 'small' | 'medium' | 'large';
  to: string;
  isFullWidth?: boolean;
};

const sizeToClass = (size: LinkButtonProps['size']) => {
  switch (size) {
    case 'small':
      return styles.linkButton_small;
    case 'medium':
      return styles.linkButton_medium;
    case 'large':
      return styles.linkButton_large;
    default:
      return styles.linkButton_medium;
  }
};

const LinkButton = ({
  children,
  className,
  size,
  to,
  isFullWidth,
}: LinkButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <button
      {...{
        onClick: () => {
          window.location.assign(to);
        },
        className: cx(
          className,
          styles.linkButton,
          sizeToClass(size),
          isFullWidth && styles.linkButton__fullWidth,
        ),
      }}
    >
      {children}
    </button>
  );
};

export default LinkButton;
