import { Company, CompanyLite } from '../../types.ts';
import Vote from '../../types/Vote.js';
import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

export type Invitation =
  | { signupType: 'EXISTING_COMPANY'; companyName: string; email: string }
  | { signupType: 'NEW_COMPANY' };

const createCompanyApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  getCompany: async (id: string): Promise<Company> => {
    return await makeLambdaApiRequest(`/company/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  createCompany: async (companyName: string): Promise<Company> => {
    return makeLambdaApiRequest(`/company`, {
      method: 'POST',
      body: JSON.stringify({
        name: companyName,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  updateCompany: (c: Company | CompanyLite): Promise<Company> => {
    return makeLambdaApiRequest(`/company/${c.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        brandColorPrimary: c.brandColorPrimary,
        brandLogoId: c.brandLogoId,
        name: c.name,
      }),
    });
  },

  getCompanyVotes: async (companyId: string): Promise<Vote[]> => {
    const result = await makeLambdaApiRequest(`/company/${companyId}/vote`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result.votes;
  },

  getCompaniesByName: async (
    name: string,
  ): Promise<{ id: string; name: string }[]> => {
    const result = await makeLambdaApiRequest(`/company?name=${name}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result.companies;
  },

  inviteToCompany: (
    companyId: string,
    emails: Array<string>,
    sendEmails: boolean,
  ): Promise<Company> => {
    return makeLambdaApiRequest(`/company/${companyId}/invitation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ emails, sendEmails: sendEmails }),
    });
  },

  validateInvitationCode: (invitationCode: string): Promise<Invitation> => {
    return makeLambdaApiRequest('/invitation-code-validate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ invitationCode }),
    });
  },
});

export default createCompanyApi;
