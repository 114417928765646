import cx from 'classnames';
import styles from './ClickToCopyButton.module.scss';
import OutlineButton, { OutlineButtonProps } from './OutlineButton.tsx';

type ClickToCopyButtonProps = {
  copyContent: string;
} & OutlineButtonProps;

const ClickToCopyButton: React.FunctionComponent<ClickToCopyButtonProps> = ({
  copyContent,
  children,
  className,
  size,
  color,
  ...otherProps
}) => {
  return (
    <OutlineButton
      size={size}
      color={color}
      className={cx(styles.clickToCopy__button, className)}
      onClick={() => {
        navigator.clipboard.writeText(copyContent);
      }}
      {...otherProps}
    >
      {children}
    </OutlineButton>
  );
};

export default ClickToCopyButton;
