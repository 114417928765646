import Link from '../../../design-system/atoms/Link.tsx';
import FloatingSidebar from '../../../design-system/molecules/FloatingSidebar.tsx';
import ZeckViewTableOfContents, {
  ZeckViewTableOfContentsHeading,
  ZeckViewTableOfContentsItem,
  ZeckViewTableOfContentsItemCollapse,
} from '../../../design-system/organisms/ZeckViewTableOfContents.tsx';
import not from '../../../junkDrawer/not.ts';
import { Section } from '../../../types.ts';
import { getTitleFromSection, isSupplemental } from '../../../types/Section.ts';
import styles from './ViewSidebar.module.scss';

type ViewSidebarProps = {
  onRequestClose(): void;
  scrollToSectionOrZeckCover: (sectionId?: string) => void;
  zeck: {
    name: string;
    path: string;
    homePath: string;
    sections: (Pick<Section, 'id' | 'supplemental' | 'title'> & {
      path: string;
    })[];
  };
  isOpen: boolean;
};

const ViewSidebar = ({
  zeck,
  onRequestClose,
  scrollToSectionOrZeckCover,
  isOpen,
}: ViewSidebarProps) => {
  const tableOfContentsSections = zeck.sections.filter(not(isSupplemental));
  const appendixSections = zeck.sections.filter(isSupplemental);

  return (
    <FloatingSidebar
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      homeHref={zeck.homePath}
    >
      <ZeckViewTableOfContentsHeading>
        <Link
          href={zeck.path}
          className={styles.zeckName}
          onClick={() => {
            scrollToSectionOrZeckCover();
          }}
        >
          {zeck.name}
        </Link>
      </ZeckViewTableOfContentsHeading>
      <ZeckViewTableOfContents>
        {tableOfContentsSections.map((section) => (
          <ZeckViewTableOfContentsItem
            key={section.id}
            to={section.path}
            onClick={() => {
              onRequestClose();
              scrollToSectionOrZeckCover(section.id);
            }}
          >
            {getTitleFromSection(section)}
          </ZeckViewTableOfContentsItem>
        ))}
        {appendixSections.length > 0 && (
          <ZeckViewTableOfContentsItemCollapse title="Appendix">
            {appendixSections.map((section) => (
              <ZeckViewTableOfContentsItem
                key={section.id}
                to={section.path}
                onClick={() => {
                  onRequestClose();
                  scrollToSectionOrZeckCover(section.id);
                }}
              >
                {getTitleFromSection(section)}
              </ZeckViewTableOfContentsItem>
            ))}
          </ZeckViewTableOfContentsItemCollapse>
        )}
      </ZeckViewTableOfContents>
    </FloatingSidebar>
  );
};

export default ViewSidebar;
