import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { AtomPick } from '../../../../../atoms/AtomTypeUtils.js';
import { SelectionCommentsFeatureAtom } from '../commentsSidebar/useComments/selectionComments/SelectionComments.js';

const useHandleHighlightClick = (
  selectionCommentsFeatureAtom: AtomPick<
    SelectionCommentsFeatureAtom,
    'selectCommentAtom'
  >,
) => {
  const selectComment = useSetAtom(
    useAtomValue(selectionCommentsFeatureAtom).selectCommentAtom,
  );

  return useCallback(
    (event: MouseEvent) => {
      const findSpanWithHighlights = (
        element: HTMLElement,
        depth: number = 0,
      ): HTMLElement | null => {
        if (depth > 5) return null; // Limit recursion to 5 levels

        const isHighlightSpan = (el: HTMLElement | null) =>
          el?.tagName.toLowerCase() === 'span' && el?.dataset.highlightids;

        if (isHighlightSpan(element)) {
          return element;
        }

        return element.parentElement
          ? findSpanWithHighlights(element.parentElement, depth + 1)
          : null;
      };

      const containsLink = (element: HTMLElement) => {
        const linksInElement = element.getElementsByTagName('a');
        return linksInElement.length !== 0;
      };

      const highlightSpan = findSpanWithHighlights(event.target as HTMLElement);
      if (!highlightSpan || !highlightSpan.dataset.highlightids) return;
      if (containsLink(highlightSpan)) return;

      event.stopPropagation();
      const [firstHighlightId] = highlightSpan.dataset.highlightids.split(' ');

      if (firstHighlightId) {
        selectComment(firstHighlightId);
      }
    },
    [selectComment],
  );
};

export default useHandleHighlightClick;
