import { useSyncExternalStore } from 'react';
import HoverNextToPoint from '../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { MeetingMinutesEditorCoordinator } from '../../MeetingMinutes.ts';

type EditorDebugOverlayProps = {
  editor: MeetingMinutesEditorCoordinator;
};

const EditorDebugOverlay: React.FC<EditorDebugOverlayProps> = ({ editor }) => {
  const editorState = useSyncExternalStore(
    (callback) => editor.subscribeToContentChanges(callback),
    () => editor.getState(),
  );
  return (
    <HoverNextToPoint
      usePortal
      getPoint={(childElement) => {
        const { height } = childElement.getBoundingClientRect();
        return [0, window.innerHeight - height];
      }}
    >
      <pre>{JSON.stringify(editorState.selection, null, 2)}</pre>
    </HoverNextToPoint>
  );
};

export default EditorDebugOverlay;
