import cx from 'classnames';
import { useState } from 'react';
import styles from './Tabs.module.scss';

type TabBarProps = {
  children?: React.ReactNode;
  light?: boolean;
};

export const TabBar: React.FC<TabBarProps> = ({ children, light }) => {
  return (
    <nav className={cx(styles.tabBar, light && styles.tabBar__light)}>
      {children}
    </nav>
  );
};

type TabProps = {
  active: boolean;
  onClick: () => void;
  children?: React.ReactNode;
  light?: boolean;
};

export const Tab: React.FC<TabProps> = ({
  children,
  active,
  onClick,
  light,
}) => {
  return (
    <button
      className={cx(
        styles.tab,
        active && styles.tab_active,
        light && styles.tab__light,
      )}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </button>
  );
};

type TabBodyProps = {
  children?: React.ReactNode;
  className?: string;
};

export const TabBody: React.FC<TabBodyProps> = ({ children, className }) => {
  return <div className={cx(styles.tabBody, className)}>{children}</div>;
};

type TabDefinition = {
  id: string;
  displayName: string;
  render: () => JSX.Element;
  tooltip?: () => JSX.Element;
};

type TabsProps = {
  tabs: [TabDefinition, ...TabDefinition[]];
  light?: boolean;
  bodyClassName?: string;
};

const Tabs: React.FC<TabsProps> = ({ tabs, light, bodyClassName }) => {
  const [currentTabId, setCurrentTabId] = useState(tabs[0].id);

  const currentTab = tabs.find(({ id }) => currentTabId === id) || tabs[0];

  return (
    <>
      <TabBar light={light}>
        {tabs.map(({ id, displayName, tooltip }) => (
          <Tab
            key={id}
            light={light}
            onClick={() => {
              setCurrentTabId(id);
            }}
            active={id === currentTabId}
          >
            {displayName}
            {!!tooltip && <div className={styles.tabToolTip}>{tooltip()}</div>}
          </Tab>
        ))}
      </TabBar>
      <TabBody className={bodyClassName}>{currentTab.render()}</TabBody>
    </>
  );
};

export default Tabs;
