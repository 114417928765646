import getSelectedBlock from '../../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import EditorData from '../../../pages/zeck/editor/EditorData.js';
import { isBlockSelection, isTextSelection } from '../../EditorSelection.js';
import { EditorStateGeneric } from '../../EditorStateGeneric.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import splitContentByBlockSelection from '../../selection/splitContentByBlockSelection.js';

const copy =
  <BlockType>(
    generateBlockEditor: (b: BlockType) => {
      copy: (selection: ContentSelection) => EditorData<BlockType> | void;
    },
  ) =>
  (
    initialState: EditorStateGeneric<BlockType>,
  ): EditorData<BlockType> | void => {
    const { content, selection } = initialState;

    // this is basically the same as `replaceSelectionWith` but with a return value
    if (isBlockSelection(selection)) {
      const [, selectedBlocks] = splitContentByBlockSelection(
        content,
        selection,
      );

      return { type: 'block', content: selectedBlocks };
    }

    if (isTextSelection(selection)) {
      const currentBlock = getSelectedBlock(content, selection);
      if (!currentBlock) return;

      return generateBlockEditor(currentBlock).copy(selection.offset);
    }

    return;
  };

export default copy;
