import OutlineButton from '../atoms/OutlineButton.tsx';
import { ButtonSizedLoader } from './LoadingButton.tsx';

type OutlineButtonWithLoadingProps = React.ComponentProps<
  typeof OutlineButton
> & {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
};

const OutlineButtonWithLoading: React.FC<OutlineButtonWithLoadingProps> = ({
  onClick,
  isLoading,
  children,
  ...props
}) => {
  return (
    <OutlineButton {...props} onClick={onClick}>
      <span style={isLoading ? { opacity: 0 } : {}}>{children}</span>
      {isLoading && <ButtonSizedLoader style={{ position: 'absolute' }} />}
    </OutlineButton>
  );
};

export default OutlineButtonWithLoading;
