import cx from 'classnames';
import Linkable from 'editor-content/html/Linkable.ts';
import { Editor, TextFormat, TextNode } from 'editor-content/TextNode.js';
import React from 'react';
import { Subtract } from 'utility-types';
import TextFormattingMenu from '../../../../design-system/organisms/TextFormattingMenu.tsx';
import { ToolbarGroup } from '../../../../design-system/organisms/Toolbar.tsx';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import { getSelectionFormat } from '../../../../editor/selection/contentSelection/getSelectionFormat.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import { SelectionCommentsBehavior } from '../comments/SelectionCommentsBehavior.ts';
import useTextBlockKeyboardEvents from '../selection/useTextBlockKeyboardEvents.ts';
import styles from './BlockWithHint.module.scss';
import {
  BoldButton,
  ItalicsButton,
  UnderlineButton,
} from './FormatTextButton.tsx';
import HeadlineEditable from './HeadlineEditable.tsx';
import { WithHoverNextToSelection } from './HoverNextToSelection.tsx';

type ExpectedProps = {
  selection: ContentSelection | null;
  className?: string;
};

type InjectedProps = {
  className?: string;
};

const withHintText = <P extends InjectedProps>(
  Wrapped: React.ComponentType<P>,
) =>
  React.forwardRef<HTMLElement, ExpectedProps & Subtract<P, InjectedProps>>(
    (props, forwardedRef) => {
      const { selection, className } = props;
      return (
        <Wrapped
          ref={forwardedRef}
          {...props}
          {...{
            'data-block-type': 'Headline',
          }}
          {...({
            className: cx(
              className,
              styles.blockWithHint,
              !!selection && styles.blockWithHint_selected,
            ),
          } as P)}
        />
      );
    },
  );

type HeadlineEditableWithKeyboardProps = {
  value: TextNode[];
  onChange: (
    newContent: TextNode[],
    contentSelection: ContentSelection,
  ) => void;
  onSelect(contentSelection: ContentSelection): void;
  linkables: Linkable[];
  className?: string;
  selection: ContentSelection | null;
  onNavUp(): void;
  onNavDown(): void;
  onSelectOut(): void;
  onToggleFormat: (formatName: keyof TextFormat) => void;
  onAddLink: (link: Editor.LinkType) => void;
  selectionCommentsBehavior: SelectionCommentsBehavior;
};

const HeadlineEditableWithKeyboard = React.forwardRef<
  HTMLElement,
  HeadlineEditableWithKeyboardProps
>(
  (
    {
      onSelect,
      value,
      onChange,
      selection,
      onNavUp,
      onNavDown,
      onSelectOut,
      onToggleFormat,
      onAddLink,
      selectionCommentsBehavior,
      linkables,
      ...otherProps
    },
    ref,
  ) => {
    const { handleKeyDown, ref: keyboardEventRef } =
      useTextBlockKeyboardEvents<HTMLParagraphElement>({
        onArrowUpOut() {
          onNavUp();
        },
        onArrowDownOut() {
          onNavDown();
        },
        onSelectOut() {
          onSelectOut();
        },
      });

    const format = getSelectionFormat(
      {
        type: 'paragraph',
        id: 'fake-id-because-we-did-not-extract-logic-from-with-keyboard',
        content: value,
      },
      selection,
    );

    return (
      <WithHoverNextToSelection
        selection={selection}
        hoverContent={
          !selectionCommentsBehavior.isAddingNewComment && (
            <TextFormattingMenu
              linkables={linkables}
              onAddLink={onAddLink}
              onClickAddComment={selectionCommentsBehavior.startNewComment}
              blockDisplayName={'headline'}
              turnIntoables={[]}
              otherActions={
                <ToolbarGroup>
                  <BoldButton {...{ format, onToggleFormat }} />
                  <ItalicsButton {...{ format, onToggleFormat }} />
                  <UnderlineButton {...{ format, onToggleFormat }} />
                </ToolbarGroup>
              }
            />
          )
        }
      >
        {(selectionRef) => (
          <HeadlineEditable
            {...otherProps}
            {...{
              value,
              onChange,
              selection,
              onSelect,
              linkables,
              onKeyDown: handleKeyDown,
            }}
            ref={mergeRefs([ref, keyboardEventRef, selectionRef])}
          />
        )}
      </WithHoverNextToSelection>
    );
  },
);

export default withHintText(HeadlineEditableWithKeyboard);
