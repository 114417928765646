import slugify from '@sindresorhus/slugify';
import Linkable from 'editor-content/html/Linkable.ts';
import { ZeckResource } from '../types.ts';

const getZeckResourcesUrl = (
  zeckResource: ZeckResource,
  companyId: string,
  mode: 'edit' | 'preview' | 'publish',
): string => {
  if (zeckResource.type === 'meeting-minutes') {
    return `/company/${companyId}/minutes/${zeckResource.itemId}`;
  }

  if (zeckResource.type === 'section') {
    if (zeckResource.title) {
      const titleSlug = slugify(zeckResource.title?.slice(0, 16) || '');
      const path = `/company/${companyId}/s/${titleSlug}-${zeckResource.itemId}`;

      if (mode === 'publish') {
        return path;
      }

      return path + '/' + mode;
    }

    return '#';
  }

  console.warn('No url for zeckResource', zeckResource);
  return '#';
};

export const zeckResourceToLinkable = (
  zeckResource: ZeckResource,
  companyId: string,
  mode: 'edit' | 'preview' | 'publish',
): Linkable => {
  return {
    url: getZeckResourcesUrl(zeckResource, companyId, mode),
    ...zeckResource,
  };
};
