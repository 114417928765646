import cx from 'classnames';
import { Icon } from 'icons';
import { useState } from 'react';
import Button from '../../../../design-system/atoms/Button.tsx';
import { ButtonSizedLoader } from '../../../../design-system/molecules/LoadingButton.tsx';
import WithTooltip from '../../../../design-system/organisms/WithTooltip.tsx';
import isZeckChangedSinceLastPublish from '../../../../services/isZeckChangedSinceLastPublish.ts';
import { Section } from '../../../../types.ts';
import PublishFlow from '../../publishAndManageViewerPermissions/PublishFlow.tsx';
import zeckStyles from '../EditPage.module.scss';
import ZeckWithActions from '../zeckCover/ZeckWithActions.ts';
import styles from './Publish.module.scss';

type PublishProps = {
  zeck: ZeckWithActions;
  currentSection?: Section;
};

export default function Publish({ zeck, currentSection }: PublishProps) {
  const [open, setOpen] = useState(false);
  const [publishing, setPublishing] = useState(false);

  const changesToPublish = isZeckChangedSinceLastPublish(zeck, currentSection);
  const hasBeenPublishedBefore = !!zeck.publishedAt;
  const disablePublishButton = !changesToPublish || publishing;
  let tooltip = '';
  if (hasBeenPublishedBefore && changesToPublish) {
    tooltip = 'Publish Changes to Viewers';
  } else if (!disablePublishButton) {
    tooltip = 'Publish Site to Viewers';
  } else if (disablePublishButton) {
    tooltip = 'You Have No Changes to Publish';
  }

  return (
    <>
      <WithTooltip<HTMLButtonElement> text={tooltip}>
        {(ref, listeners) => (
          <span ref={ref} {...listeners}>
            {/* Disabled buttons do not fire mouse events */}
            <Button
              size="medium"
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
              disabled={disablePublishButton}
              className={cx(
                styles.publishButton,
                publishing && styles.publishButton__publishing,
              )}
              data-testid="publishButton"
            >
              {
                <div className={styles.loadingSpinner}>
                  <ButtonSizedLoader />
                </div>
              }

              <div className={styles.publishButton__content}>
                <Icon
                  name="publishArrow"
                  className={zeckStyles.zeckSidebar__headerRight__icon}
                />
                {changesToPublish && zeck.publishedAt
                  ? 'Publish Changes'
                  : 'Publish'}
              </div>
            </Button>
          </span>
        )}
      </WithTooltip>

      <PublishFlow
        zeck={zeck}
        isOpen={open}
        onCancel={() => {
          setOpen(false);
        }}
        canPublish={isZeckChangedSinceLastPublish(zeck, currentSection)}
        onPublish={async () => {
          setOpen(false);
          setPublishing(true);
          await zeck.actions.publish();
          setPublishing(false);
        }}
      />
    </>
  );
}
