import { useState } from 'react';
import useApi from '../../api/useApi.ts';
import Toast from '../../design-system/molecules/toast/Toast.tsx';
import { useDocumentTitle } from '../../junkDrawer/useDocumentTitleFromResult.ts';
import WithResultLoading from '../../result/WithResultLoading.tsx';
import useFetchUpdate from '../../services/useFetch/useFetchUpdate.ts';
import usePageTracking from '../../services/usePageTracking.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import LoaderSpinnerOverlay from '../LoaderSpinnerOverlay.tsx';
import {
  ManagementPageContent,
  ManagementPageShell,
  ManagementPageTitle,
} from '../ManagementPage.tsx';
import PageLayoutFetchesFolders from '../PageLayoutFetchesFolders.tsx';
import InvitePeople from './InvitePeople.tsx';
import PeopleList from './PeopleList.tsx';

type PeoplePageProps = {
  userAndCompany: UserAndCompany;
  onClickLogout: () => void;
};

const PeoplePage = ({ userAndCompany, onClickLogout }: PeoplePageProps) => {
  useDocumentTitle('People');
  usePageTracking('people_page', userAndCompany);
  const { getCompany, inviteToCompany } = useApi();
  const [showInviteToast, setShowInviteToast] = useState(false);

  const { result: companyResult, update: setCompany } = useFetchUpdate(
    () => getCompany(userAndCompany.activeCompany.id),
    [userAndCompany],
  );

  return (
    <PageLayoutFetchesFolders
      {...{
        userAndCompany,
        onClickLogout,
        activePage: 'people',
        title: 'People',
      }}
    >
      <WithResultLoading
        result={companyResult}
        duration={400}
        renderLoading={() => <LoaderSpinnerOverlay />}
      >
        {(company) => {
          async function sendInvitation(
            emails: Array<string>,
            sendEmails: boolean,
          ) {
            setCompany(await inviteToCompany(company.id, emails, sendEmails));
            setShowInviteToast(true);
          }

          return (
            <ManagementPageShell>
              <ManagementPageTitle title="People" />

              <InvitePeople sendInvitation={sendInvitation} />

              <ManagementPageContent>
                <PeopleList
                  currentUser={userAndCompany.user}
                  company={company}
                  setCompany={setCompany}
                  sendInvitation={(emails) => sendInvitation(emails, true)}
                />
              </ManagementPageContent>

              <Toast
                message="People Added"
                isOpen={showInviteToast}
                onClose={() => setShowInviteToast(false)}
              />
            </ManagementPageShell>
          );
        }}
      </WithResultLoading>
    </PageLayoutFetchesFolders>
  );
};
export default PeoplePage;
