import { rangeToContentSelection } from '../ContentSelection.js';
import getCaretRangeFromPoint from './getCaretRangeFromPoint.js';

export function getColumnMatchedContentSelectionFromBottom(
  currentRange: Range,
  nextBlockEl: HTMLElement,
) {
  const prevBlockContentRange = new Range();
  prevBlockContentRange.selectNodeContents(nextBlockEl);

  const prevBlockRect = prevBlockContentRange.getBoundingClientRect();
  const currentSelectionRect = currentRange.getBoundingClientRect();

  const targetCaretRange = getCaretRangeFromPoint(
    currentSelectionRect.x,
    prevBlockRect.y + prevBlockRect.height - 10,
  );

  if (!nextBlockEl.contains(targetCaretRange.commonAncestorContainer)) {
    throw new Error(
      'Fancy nav selection is outside of container. This is probably because it is offscreen, or outside of the scroll container',
    );
  }

  return rangeToContentSelection(nextBlockEl, targetCaretRange);
}

export function getColumnMatchedContentSelectionFromTop(
  currentRange: Range,
  nextBlockEl: HTMLElement,
) {
  const nextBlockContentRange = new Range();
  nextBlockContentRange.selectNodeContents(nextBlockEl);

  const nextBlockRect = nextBlockContentRange.getBoundingClientRect();
  const currentSelectionRect = currentRange.getBoundingClientRect();

  const targetCaretRange = getCaretRangeFromPoint(
    currentSelectionRect.x,
    nextBlockRect.y + 10,
  );

  if (!nextBlockEl.contains(targetCaretRange.commonAncestorContainer)) {
    throw new Error(
      'Fancy nav selection is outside of container. This is probably because it is offscreen, or outside of the scroll container',
    );
  }

  return rangeToContentSelection(nextBlockEl, targetCaretRange);
}
