import cx from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { getSystemTimezone } from './timestamp.ts';
import styles from './TimeView.module.scss';

type TimeViewProps = {
  timestamp: number;
  className?: string;
};

export function TimeView({ timestamp, className }: TimeViewProps) {
  return (
    <div className={cx(styles.timeView, className)}>
      {formatInTimeZone(timestamp, getSystemTimezone(), 'h:mmaaa zzz')}
    </div>
  );
}
