import { updateTextBlock } from 'editor-content/Block.js';
import { isBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import {
  contentSelection,
  getEndOfSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import {
  isCollapsedSelection,
  textSelection,
} from '../../../../editor/selection/TextSelection.js';
import cond from '../../../../junkDrawer/cond.js';
import guard from '../../../../junkDrawer/guard.js';
import identity from '../../../../junkDrawer/identity.js';
import addHighlightToTextNodes from './addHighlightToTextNodes.js';
import { BodyStateSelected } from './BodyEditor.js';
import { hydratedIsTextBlock } from './hydratedBlockGuards.js';

export function addHighlight(
  initialState: BodyStateSelected,
  highlightId: string,
): BodyStateSelected {
  const { content, selection } = initialState;
  if (isBlockSelection(selection)) return initialState;
  if (isCollapsedSelection(selection)) return initialState;

  return {
    content: content.map(
      cond(
        (a, i) => i === selection.index,
        guard(
          hydratedIsTextBlock,
          (block) =>
            updateTextBlock(
              block,
              addHighlightToTextNodes(
                block.content,
                selection.offset,
                highlightId,
              ),
            ),
          identity,
        ),
        identity,
      ),
    ),
    selection: textSelection(
      selection.index,
      contentSelection(getEndOfSelection(selection.offset)),
    ),
  };
}
