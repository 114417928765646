export async function sleep(time: number) {
  await new Promise((resolve) => setTimeout(resolve, time));
}

export const MAX_RETRIES = 600;
// low effort backoff. Network delay already built in to polling, but want to have low latency for common AI response times
export const getSleepDuration = (count: number) => {
  if (count < 200) {
    return 100;
  }

  if (count < 300) {
    return 500;
  }

  return 1000;
};
