import normalizeTextNodes from 'editor-content/normalizeTextNodes.js';
import {
  Editor,
  getTextNodesLength,
  TextFormat,
  TextNode,
} from 'editor-content/TextNode.js';
import textBlockToggleFormatStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockToggleFormatStrategy.js';
import ContentSelection, {
  contentSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import addHighlightToTextNodes from '../BodyEditor/addHighlightToTextNodes.js';
import addLinkToTextNodes from '../BodyEditor/addLinkToTextNodes.js';

export type HeadlineContent = TextNode[];
export type HeadlineSelection = ContentSelection;

export type HeadlineStateSelected = {
  content: HeadlineContent;
  selection: ContentSelection;
};

export type HeadlineState = {
  content: HeadlineContent;
  selection: ContentSelection | null;
};

const HeadlineEditor = {
  edit: (
    _initialState: HeadlineStateSelected,
    editedState: HeadlineStateSelected,
  ) => ({
    content: normalizeTextNodes(editedState.content),
    selection: editedState.selection,
  }),

  select: (initialState: HeadlineState, newSelection: HeadlineSelection) => ({
    content: initialState.content,
    selection: newSelection,
  }),

  addHighlight: (
    initialState: HeadlineStateSelected,
    highlightId: string,
  ): HeadlineStateSelected => ({
    content: normalizeTextNodes(
      addHighlightToTextNodes(
        initialState.content,
        initialState.selection,
        highlightId,
      ),
    ),
    selection: contentSelection(initialState.selection.focusOffset),
  }),

  toggleFormat: (
    { content, selection }: HeadlineStateSelected,
    formatName: keyof TextFormat,
  ): HeadlineStateSelected => ({
    content: normalizeTextNodes(
      textBlockToggleFormatStrategy(
        {
          type: 'headline',
          content: content,
          id: 'whatever',
        },
        selection,
        formatName,
      ).content,
    ),
    selection,
  }),

  addLink: (
    { content, selection }: HeadlineStateSelected,
    link: Editor.LinkType,
  ): HeadlineStateSelected => ({
    content: normalizeTextNodes(addLinkToTextNodes(content, selection, link)),
    selection,
  }),

  receiveBackspace: (
    { content }: HeadlineState,
    receivedContent: TextNode[],
  ): HeadlineStateSelected => ({
    content: normalizeTextNodes([...content, ...receivedContent]),
    selection: contentSelection(getTextNodesLength(content)),
  }),
};

export default HeadlineEditor;
