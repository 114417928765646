import { Navigate } from 'react-router-dom';
import EditorTestPage from '../EditorTestPage.tsx';
import { SignInData, SignUpData } from '../api/ApiSession.tsx';
import ExpiredInvite from '../pages/auth/ExpiredInvite.tsx';
import SigninPage from '../pages/auth/signin/SigninPage.tsx';
import SignupPage from '../pages/auth/signup/SignupPage.tsx';
import { NotFoundPage } from '../pages/error/notFound/NotFoundPage.tsx';
import MagicLinkPage from '../pages/magicLink/MagicLinkPage.tsx';
import RequestMagicLinkPage from '../pages/magicLink/RequestMagicLinkPage.tsx';
import ResetPasswordPage from '../pages/resetPassword/ResetPasswordPage.tsx';
import ResetPasswordRequestPage from '../pages/resetPasswordRequest/ResetPasswordRequestPage.tsx';
import TestPage from '../pages/testPage/TestPage.tsx';

type Parameters = {
  signUp: (
    userData: SignUpData,
  ) => Promise<'SUCCESS' | 'INVALID_CREDENTIALS' | 'EMAIL_TAKEN'>;
  signIn: (userLoginData: SignInData) => Promise<string | null>;
  setAccessToken: (token: string) => void;
};

export default function makeUnauthenticatedRoutes({
  signUp,
  signIn,
  setAccessToken,
}: Parameters) {
  return [
    {
      path: '/company/:id/:slug',
      element: <RequestMagicLinkPage zeckUrl={true} onSignIn={signIn} />,
    },
    {
      path: '/company/:id/zeck-trash',
      element: <SigninPage onSignIn={signIn} />,
    },
    {
      path: '/company/:id/s/:slug',
      element: <RequestMagicLinkPage zeckUrl={false} onSignIn={signIn} />,
    },
    {
      path: '/magic-link/:id',
      element: (
        <MagicLinkPage onSignIn={signIn} setAccessToken={setAccessToken} />
      ),
    },
    { path: '/company/:id/minutes', element: <SigninPage onSignIn={signIn} /> },
    { path: '/profile', element: <SigninPage onSignIn={signIn} /> },
    { path: '/company/:id/voting', element: <SigninPage onSignIn={signIn} /> },
    {
      path: '/company/:id/brand-kit',
      element: <SigninPage onSignIn={signIn} />,
    },
    { path: '/company/:id/people', element: <SigninPage onSignIn={signIn} /> },
    { path: '/company/:id/*', element: <SigninPage onSignIn={signIn} /> },
    { path: '/company/:id', element: <SigninPage onSignIn={signIn} /> },
    {
      path: '/grant-access/:accessRequestId',
      element: <SigninPage onSignIn={signIn} />,
    },
    { path: '/signin', element: <SigninPage onSignIn={signIn} /> },
    { path: '/login', element: <Navigate to="/signin" replace /> },
    { path: '/signup', element: <SignupPage onSignUp={signUp} /> },
    { path: '/editor', element: <EditorTestPage /> },
    { path: '/reset-password-request', element: <ResetPasswordRequestPage /> },
    { path: '/reset-password', element: <ResetPasswordPage /> },
    { path: '/expired-invite', element: <ExpiredInvite /> },
    { path: '/test', element: <TestPage /> },
    { path: '/', element: <Navigate to="/signin" /> },
    { path: '*', element: <NotFoundPage to="signin" /> },
  ];
}
