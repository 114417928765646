import { z } from 'zod';

const ChartTitleSchema = z.object({
  text: z.string(),
  position: z.union([
    z.literal('top'),
    z.literal('left'),
    z.literal('right'),
    z.literal('bottom'),
  ]),
});

const ChartSubtitleSchema = z.object({
  text: z.string(),
});

const ChartLegendSchema = z.object({
  display: z.boolean(),
  position: z.union([
    z.literal('top'),
    z.literal('left'),
    z.literal('right'),
    z.literal('bottom'),
  ]),
});

const ChartDatasetsSchema = z.array(
  z.object({
    label: z.string(),
    data: z.array(z.number()),
    backgroundColor: z.union([z.string(), z.array(z.string())]),
    borderColor: z.union([z.string(), z.array(z.string())]),
    borderWidth: z.number(),
    order: z.number(),
  }),
);

const ChartDatasetsPieSchema = z.array(
  z.object({
    data: z.array(z.number()),
    backgroundColor: z.union([z.string(), z.array(z.string())]),
    borderWidth: z.number(),
    order: z.number(),
    label: z.string(),
  }),
);

const ChartScaleTitleSchema = z.object({
  text: z.string(),
});

const ChartScaleTypeSchema = z.union([
  z.literal('category'),
  z.literal('linear'),
  z.literal('logarithmic'),
]);

export const ChartBlockLineDataSchema = z.object({
  version: z.literal(1),
  type: z.literal('line'),
  title: ChartTitleSchema,
  subtitle: ChartSubtitleSchema,
  legend: ChartLegendSchema,
  scales: z.object({
    x: z.object({
      title: ChartScaleTitleSchema,
      position: z.union([z.literal('top'), z.literal('bottom')]),
      type: ChartScaleTypeSchema,
      min: z.union([z.number(), z.null()]),
    }),
    y: z.object({
      title: ChartScaleTitleSchema,
      position: z.union([z.literal('left'), z.literal('right')]),
      type: ChartScaleTypeSchema,
      min: z.union([z.number(), z.null()]),
    }),
  }),
  labels: z.array(z.string()),
  datasets: ChartDatasetsSchema,
});

export const ChartBlockBarDataSchema = z.object({
  version: z.literal(1),
  type: z.literal('bar'),
  indexAxis: z.union([z.literal('x'), z.literal('y')]),
  title: ChartTitleSchema,
  subtitle: ChartSubtitleSchema,
  legend: ChartLegendSchema,
  scales: z.object({
    x: z.object({
      title: ChartScaleTitleSchema,
      position: z.union([z.literal('top'), z.literal('bottom')]),
      type: ChartScaleTypeSchema,
      min: z.union([z.number(), z.null()]),
      stacked: z.boolean(),
    }),
    y: z.object({
      title: ChartScaleTitleSchema,
      position: z.union([z.literal('left'), z.literal('right')]),
      type: ChartScaleTypeSchema,
      min: z.union([z.number(), z.null()]),
      stacked: z.boolean(),
    }),
  }),
  labels: z.array(z.string()),
  datasets: ChartDatasetsSchema,
});

export const ChartBlockPieDataSchema = z.object({
  version: z.literal(1),
  type: z.literal('pie'),
  cutout: z.string(),
  title: ChartTitleSchema,
  subtitle: ChartSubtitleSchema,
  legend: ChartLegendSchema,
  labels: z.array(z.string()),
  datasets: ChartDatasetsPieSchema,
});

export type ChartBlockPieData = z.infer<typeof ChartBlockPieDataSchema>;
export type ChartBlockLineData = z.infer<typeof ChartBlockLineDataSchema>;
export type ChartBlockBarData = z.infer<typeof ChartBlockBarDataSchema>;

// add type guards here
export const isChartBlockLineData = (
  data: ChartBlockData,
): data is ChartBlockLineData => data.type === 'line';

export const isChartBlockBarData = (
  data: ChartBlockData,
): data is ChartBlockBarData => data.type === 'bar';

export const isChartBlockPieData = (
  data: ChartBlockData,
): data is ChartBlockPieData => data.type === 'pie';

export type ChartBlockData =
  | ChartBlockLineData
  | ChartBlockBarData
  | ChartBlockPieData;

export const ChartBlockDataSchema = z.union([
  ChartBlockLineDataSchema,
  ChartBlockBarDataSchema,
  ChartBlockPieDataSchema,
]);

export const ChartBlockSourceDataSchema = z.array(
  z.array(z.union([z.number(), z.string()])),
);

const ChartBlockPastMessagesSchema = z.array(z.string());

export const ChartBlockSchema = z.object({
  type: z.literal('chart'),
  id: z.string().uuid(),
  guid: z.string().uuid(),
  data: z.object({
    chartData: ChartBlockDataSchema,
    sourceData: ChartBlockSourceDataSchema,
    pastMessages: ChartBlockPastMessagesSchema,
  }),
});
