import { Comment } from 'api-client/types.js';
import { reduce, sumBy } from 'lodash';

const countCommentsAndReplies = (data: { [sectionId: string]: Comment[] }) => {
  return reduce(
    data,
    (result, value, key) => {
      const count = sumBy(
        value.filter((comment) => comment.type == 'Comment'),
        (comment) => 1 + comment.replies.length,
      ); // Counting the comment itself and its replies
      result[key] = count;
      return result;
    },
    {} as { [sectionId: string]: number },
  );
};

export default countCommentsAndReplies;
