import { Icon } from 'icons';
import ReactModal from 'react-modal';
import IconButton from '../../design-system/atoms/IconButton.tsx';
import styles from './CannotRemoveAdminModal.module.scss';

type CannotRemoveAdminModalProp = {
  isOpen: boolean;
  onClose: () => void;
};

export default function CannotRemoveAdminModal({
  isOpen,
  onClose,
}: CannotRemoveAdminModalProp) {
  return (
    <ReactModal
      onRequestClose={onClose}
      isOpen={isOpen}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      ariaHideApp={false}
    >
      <div className={styles.header}>
        <Icon className={styles.icon} name="danger" />
        <span className={styles.title}>Cannot Remove Admin</span>
        <IconButton
          className={styles.closeButton}
          name="close"
          aria-label="close modal"
          onClick={onClose}
        />
      </div>

      <div className={styles.body}>
        You must have at least one Admin user in your company. In order to edit
        this person’s user type you must first set another person’s user type to
        Admin.
      </div>
    </ReactModal>
  );
}
