import { BlockContent } from 'editor-content/Block.js';
import replaceBlocksAt from '../../../../editor/blocks/replaceBlocksAt.js';
import { EditorStateGeneric } from '../../../../editor/EditorStateGeneric.js';
import {
  BlockSelection,
  getStartOfBlockSelection,
  isBlockSelection,
} from '../../../../editor/selection/BlockSelection.js';
import ContentSelection, {
  contentSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import splitContentByBlockSelection from '../../../../editor/selection/splitContentByBlockSelection.js';
import {
  TextSelection,
  textSelection,
} from '../../../../editor/selection/TextSelection.js';
import { BodyStateSelected } from './BodyEditor.js';
import getSelectedBlock from './getSelectedBlock.js';
import replaceBlockAt from './replaceBlockAt.js';

export type PressBackspaceStrategyResult<BlockType> =
  | { type: 'merge'; content: BlockContent }
  | { type: 'change'; content: BlockType; selection: ContentSelection }
  | { type: 'remove' }
  | void;

export type ReceiveBackspaceStrategyResult<BlockType> =
  | { type: 'accept-selection'; selection: ContentSelection }
  | { type: 'accept-merge'; content: BlockType; selection: ContentSelection };

type PressBackspaceResult<BlockType> =
  | {
      type: 'change';
      content: BlockType[];
      selection: BodyStateSelected['selection'];
    }
  | {
      type: 'merge'; // out of body
      content: BlockType[];
      blockContent: BlockContent;
    }
  | void;

export type PressBackspaceBlockStrategies<BlockType> = {
  pressBackspace: (
    selection: ContentSelection,
  ) => PressBackspaceStrategyResult<BlockType>;
  receiveBackspace: (
    content: BlockContent,
  ) => ReceiveBackspaceStrategyResult<BlockType>;
};

const pressBackspace = <BlockType>(
  generateBlockEditor: (
    block: BlockType,
  ) => PressBackspaceBlockStrategies<BlockType>,
  createDefaultBlock: (content: BlockContent) => BlockType,
) => {
  const pressBackspaceBlockSelection = (
    content: BlockType[],
    selection: BlockSelection,
  ) => {
    const [blocksBefore, , blocksAfter] = splitContentByBlockSelection(
      content,
      selection,
    );

    const newContent = [
      ...blocksBefore,
      createDefaultBlock([]),
      ...blocksAfter,
    ];

    return {
      type: 'change' as const,
      content: newContent,
      selection: textSelection(
        getStartOfBlockSelection(selection),
        contentSelection(0),
      ),
    };
  };

  const pressBackspaceTextSelection = (
    selection: TextSelection,
    content: BlockType[],
  ): PressBackspaceResult<BlockType> => {
    const selectedBlock = getSelectedBlock(content, selection);
    if (!selectedBlock) return { type: 'change', content, selection };

    const backspaceResult = generateBlockEditor(selectedBlock).pressBackspace(
      selection.offset,
    );
    if (!backspaceResult) return;

    const previousBlock = content[selection.index - 1];

    if (!previousBlock) {
      switch (backspaceResult.type) {
        case 'remove':
          return {
            type: 'merge',
            blockContent: [],
            content: content.slice(1, content.length),
          };
        case 'merge':
          return {
            type: 'merge',
            blockContent: backspaceResult.content,
            content: content.slice(1, content.length),
          };
        case 'change':
          return {
            type: 'change',
            content: replaceBlockAt(
              content,
              [backspaceResult.content],
              selection.index,
            ),
            selection: textSelection(
              selection.index,
              backspaceResult.selection,
            ),
          };
      }
    }

    switch (backspaceResult.type) {
      case 'remove': {
        const receiveBackspaceResult = generateBlockEditor(
          previousBlock,
        ).receiveBackspace([]);

        switch (receiveBackspaceResult.type) {
          case 'accept-selection': {
            return {
              type: 'change',
              content: replaceBlocksAt(
                content,
                [previousBlock],
                selection.index - 1,
                2,
              ),
              selection: textSelection(
                selection.index - 1,
                receiveBackspaceResult.selection,
              ),
            };
          }
          case 'accept-merge': {
            return {
              type: 'change',
              content: replaceBlocksAt(
                content,
                [receiveBackspaceResult.content],
                selection.index - 1,
                2,
              ),
              selection: textSelection(
                selection.index - 1,
                receiveBackspaceResult.selection,
              ),
            };
          }
        }
        return;
      }
      case 'merge': {
        const receiveBackspaceResult = generateBlockEditor(
          previousBlock,
        ).receiveBackspace(backspaceResult.content);

        switch (receiveBackspaceResult.type) {
          case 'accept-selection': {
            return {
              type: 'change',
              content,
              selection: textSelection(
                selection.index - 1,
                receiveBackspaceResult.selection,
              ),
            };
          }
          case 'accept-merge': {
            return {
              type: 'change',
              content: [
                ...content.slice(0, selection.index - 1),
                receiveBackspaceResult.content,
                ...content.slice(selection.index + 1, content.length),
              ],
              selection: textSelection(
                selection.index - 1,
                receiveBackspaceResult.selection,
              ),
            };
          }
        }
        return;
      }
      case 'change': {
        return {
          type: 'change',
          content: replaceBlockAt(
            content,
            [backspaceResult.content],
            selection.index,
          ),
          selection: textSelection(selection.index, backspaceResult.selection),
        };
      }
    }
  };

  return ({
    content,
    selection,
  }: EditorStateGeneric<BlockType>): PressBackspaceResult<BlockType> => {
    if (!selection) return;

    if (isBlockSelection(selection)) {
      return pressBackspaceBlockSelection(content, selection);
    }

    return pressBackspaceTextSelection(selection, content);
  };
};

export default pressBackspace;
