import debounce from 'lodash/debounce.js';
import { EditorSelection } from './EditorSelection.js';
import { createStore, Store } from './Store.js';

type StateWithSelection<ContentType> = {
  content: ContentType;
  selection: EditorSelection;
};

export type HistoryStore<ContentType> = Store<
  StateWithSelection<ContentType>
> & {
  undo: () => void;
  redo: () => void;
};

export const createHistoryStore = <ContentType>(
  state: StateWithSelection<ContentType>,
): HistoryStore<ContentType> => {
  const store = createStore(state);
  let undoStack: StateWithSelection<ContentType>[] = [];
  let redoStack: StateWithSelection<ContentType>[] = [];

  const debouncedPushUndoStack = debounce(
    (newState: StateWithSelection<ContentType>) => {
      undoStack.push(newState);
    },
    400,
    {
      leading: true,
      trailing: false,
    },
  );

  return {
    set(newState) {
      const currentState = store.get();

      if (currentState.content === newState.content) {
        store.set(newState);
        return;
      }

      debouncedPushUndoStack(currentState);

      if (undoStack.length > 50) {
        undoStack = undoStack.slice(-50);
      }

      redoStack = [];
      store.set(newState);
    },
    get: store.get,
    undo() {
      const prevValue = undoStack.pop();
      if (!prevValue) return;
      redoStack.push(store.get());
      store.set(prevValue);
    },
    redo() {
      const nextValue = redoStack.pop();
      if (!nextValue) return;
      undoStack.push(store.get());
      store.set(nextValue);
    },
  };
};
