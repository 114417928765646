import { ValueType, Workbook } from 'exceljs';
import { MicrosoftExcelIcon } from 'icons';
import { isError } from 'lodash';
import React, { useState } from 'react';
import OutlineButton from '../../../../../../design-system/atoms/OutlineButton.tsx';
import AsyncButton from '../../../../../../design-system/molecules/AsyncButton.tsx';
import {
  ModalActions,
  ModalHeader,
  ModalPanel,
} from '../../../../../../design-system/organisms/Modal.tsx';
import {
  SectionList,
  SectionListItem,
} from '../../../../../../design-system/organisms/SectionList.tsx';
import { logError } from '../../../../../../logging/logger.ts';
import IntegrationLoader from '../IntegrationLoader.tsx';
import { DocumentMeta } from '../integrationModalTypes.ts';
import { createExcelWorkbookData } from '../utils/excel/createExcelWorkbookData.ts';
import { getArrayBufferOfOneDriveItem } from '../utils/excel/getArrayBufferOfOneDriveItem.ts';
import { useLoadMicrosoftFiles } from '../utils/excel/useLoadMicrosoftFiles.ts';
import styles from './MicrosoftDocumentPicker.module.scss';

type MicrosoftDocumentPickerProps = {
  accessToken: string;
  onClose: () => void;
  onPickWorkbook: (data: {
    documentMeta: DocumentMeta;
    workbook: Workbook;
    valueTypes: typeof ValueType;
  }) => void;
};

const MicrosoftDocumentPicker: React.FC<MicrosoftDocumentPickerProps> = ({
  accessToken,
  onClose,
  onPickWorkbook,
}) => {
  const {
    files,
    loading,
    error: loadFileError,
  } = useLoadMicrosoftFiles(accessToken);
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  if (loading) {
    return (
      <ModalPanel>
        <IntegrationLoader />
      </ModalPanel>
    );
  }

  if (loadFileError || error) {
    return (
      <>
        <ModalPanel>
          <ModalHeader className={styles.modalHeader} onClickClose={onClose}>
            {null}
          </ModalHeader>
          <div className={styles.errorMessage}>{loadFileError || error}</div>
        </ModalPanel>
      </>
    );
  }

  return (
    <ModalPanel className={styles.modalPanel}>
      <ModalHeader className={styles.modalHeader}>
        Recent Excel Files
      </ModalHeader>
      <SectionList className={styles.documentList}>
        {files.map((file) => (
          <SectionListItem
            key={file.remoteItem.id}
            active={file.remoteItem.id === selectedFileId}
            onClick={() => {
              setSelectedFileId(file.remoteItem.id);
            }}
            light
            icon={<MicrosoftExcelIcon className={styles.documentIcon} />}
            className={styles.documentItem}
          >
            {file.name}
          </SectionListItem>
        ))}
      </SectionList>
      <ModalActions className={styles.modalActions}>
        <OutlineButton color="secondary" size="medium" onClick={onClose}>
          Cancel
        </OutlineButton>
        <AsyncButton
          color="primary"
          size="medium"
          disabled={selectedFileId === null}
          onClick={async () => {
            if (!selectedFileId) {
              return;
            }

            const selectedFile = files.find(
              (file) => file.remoteItem.id === selectedFileId,
            );

            try {
              const fileBuffer = await getArrayBufferOfOneDriveItem(
                accessToken,
                selectedFileId,
                selectedFile?.remoteItem?.parentReference?.driveId,
              );

              const documentName = selectedFile?.name || 'Unknown File';

              const workbookData = await createExcelWorkbookData(fileBuffer, {
                documentId: selectedFileId,
                documentName,
                driveId: selectedFile?.remoteItem?.parentReference?.driveId,
              });

              onPickWorkbook(workbookData);
            } catch (e) {
              if (isError(e)) {
                logError(e);
              }

              const message =
                (e as { message?: string }).message ??
                'There was an error processing this file. Please try again or try a different file.';

              setError(message);
            }
          }}
        >
          Select
        </AsyncButton>
      </ModalActions>
    </ModalPanel>
  );
};

export default MicrosoftDocumentPicker;
