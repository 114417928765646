import {
  MeetingMinutes,
  MinutesSignature,
} from '../../../api/endpoints/createMeetingMinutesApi.ts';
import MeetingMinutesView from '../../../meetingMinutes/MeetingMinutesView.tsx';
import { User } from '../../../types.ts';
import MeetingMinutesPageLayout from './components/MeetingMinutesPageLayout.tsx';
import MeetingMinutesSignatureFlow from './components/MeetingMinutesSignatureFlow.tsx';
import MeetingMinutesTopBar from './components/MeetingMinutesTopBar.tsx';

export default function MeetingMinutesArchivePageView(props: {
  meetingMinutes: MeetingMinutes;
  company: { id: string };
  user: User;
  signMeetingMinutes: (
    meetingMinutes: Pick<MeetingMinutes, 'id' | 'version'>,
    signature: MinutesSignature,
  ) => Promise<void>;
  scrollContainer: React.RefObject<HTMLDivElement>;
}) {
  const minutesListLink = `/company/${props.company.id}/minutes`;
  return (
    <MeetingMinutesPageLayout
      headerSlot={
        <MeetingMinutesTopBar
          minutesListLink={minutesListLink}
          isSaved={false}
          title={props.meetingMinutes.title}
        />
      }
    >
      <MeetingMinutesView
        meetingMinutes={props.meetingMinutes}
        additionalContent={
          <MeetingMinutesSignatureFlow
            meetingMinutes={props.meetingMinutes}
            signMeetingMinutes={props.signMeetingMinutes}
            user={props.user}
          />
        }
      />
    </MeetingMinutesPageLayout>
  );
}
