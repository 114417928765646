import cx from 'classnames';
import styles from './PeopleListItemLayout.module.scss';

type PeopleListItemLayoutProps = {
  cells: React.ReactNode[];
};

export const PeopleListItemLayout: React.FunctionComponent<
  PeopleListItemLayoutProps
> = ({ cells }) => {
  return (
    <tr data-testid="people-list-row" className={styles.peopleList__row}>
      {cells.map((cell, i) => (
        <td key={i} className={cx(styles.peopleList__cell)}>
          {cell}
        </td>
      ))}
    </tr>
  );
};
