import { AiChartGenerateResponseErrorType } from '../../../../api/endpoints/createAiApi.ts';
import { AiWizardBadge } from '../../../../design-system/atoms/AiWizardBadge.js';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import { AiChartFlowBuildingContainer } from './AiChartFlowBuildingContainer.tsx';
import styles from './AiChartFlowError.module.scss';

export type AiChartFlowErrorState = {
  type: 'error';
  errorType: AiChartGenerateResponseErrorType;
};

type AiChartFlowErrorProps = {
  onClose: () => void;
  errorType: AiChartGenerateResponseErrorType;
};

const errorMessages: Record<AiChartGenerateResponseErrorType, string> = {
  unknownChartType:
    "I'm not able to create that chart type, please request another chart type.",
  missingData:
    "I didn't find the data you're asking me to chart. Please update the source data or try a different request",
  calculationsRequired:
    "I didn't find the data you're asking me to chart or it requires calculations which I can't do...yet. Please update the source data and try again.",
  unknown: 'Chart failed to generate. Please try again.',
  timeout: 'Chart failed to generate. Please try again.',
};

export const AiChartFlowError: React.FC<AiChartFlowErrorProps> = ({
  onClose,
  errorType,
}) => {
  return (
    <AiChartFlowBuildingContainer>
      <div className={styles.errorContainer}>
        <AiWizardBadge />
        <div className={styles.errorMessage}>{errorMessages[errorType]}</div>
        <IconButton name="close" aria-label="Close" onClick={onClose} />
      </div>
    </AiChartFlowBuildingContainer>
  );
};
