import initials from 'initials';
import React from 'react';
import UserInitials from '../../../../../design-system/atoms/UserInitials.js';
import formatTimeAgo from '../../../../../services/formatTimeAgo.js';
import styles from './CommentNameplate.module.scss';

const CommentNameplate: React.FC<{
  userName: string;
  timestamp?: number;
  actionsSlot?: React.ReactNode;
}> = ({ userName, timestamp, actionsSlot }) => {
  return (
    <div className={styles.commentOrReply__header}>
      <UserInitials size={'medium'}>{initials(userName)}</UserInitials>
      <div className={styles.commentOrReply__headerStack}>
        <address className={styles.commentOrReply__userName}>
          {userName}
        </address>
        {timestamp && (
          <time
            className={styles.commentOrReply__time}
            dateTime={new Date(timestamp).toISOString()}
          >
            {formatTimeAgo(timestamp)}
          </time>
        )}
      </div>
      {actionsSlot}
    </div>
  );
};

export default CommentNameplate;
