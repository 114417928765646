import useApi from '../../../../api/useApi.js';
import Spinner from '../../../../design-system/atoms/Spinner.tsx';
import Tabs from '../../../../design-system/organisms/Tabs.tsx';
import { useFeatureFlags } from '../../../../feature-flags/FeatureFlagsContext.ts';
import { UserAndPermission } from '../../../../types.ts';

import fromPairs from 'lodash/fromPairs.js';
import { isSuccessResult } from '../../../../result/Result.ts';
import useFetch from '../../../../services/useFetch/useFetch.ts';
import AnalyticsEmptyModal from './AnalyticsEmptyModal.tsx';
import { AnalyticsZeckWithPublishedSections } from './AnalyticsTypes.ts';
import styles from './AnalyticsWrapper.module.scss';
import ContentEngagementModal from './ContentEngagementModal.tsx';
import ViewerEngagementModal from './ViewerEngagmentModel/ViewerEngagementModal.tsx';
import { buildUserSortedSectionsWithTotals } from './util/buildUserSortedSectionsWithTotals.ts';
import useAnalyticsData from './util/useAnalyticsData.ts';

type AnalyticsWrapperProps = {
  modal?: boolean;
  users: UserAndPermission[];
  zeck: AnalyticsZeckWithPublishedSections;
};

export const getZeckScrollGoLiveDate = () => {
  return new Date('2023-05-03');
};

export const getZeckViewGoLiveDate = () => {
  return new Date('2023-04-07');
};

export const AnalyticsWrapper: React.FC<AnalyticsWrapperProps> = (props) => {
  const { zeck, users } = props;
  const { firstPublishedAt } = props.zeck;
  const { showAllAnalytics } = useFeatureFlags();
  const hideContentEngagement =
    !showAllAnalytics &&
    (!firstPublishedAt ||
      new Date(firstPublishedAt) <= getZeckScrollGoLiveDate());

  const api = useApi();
  const commentsResult = useFetch(async () => {
    const zeckComments = await api.getZeckComments(zeck.id);

    const publishedSections = zeck.publishedZeck?.sections ?? [];
    return fromPairs(
      publishedSections.map((section) => [
        section.sectionId,
        zeckComments.filter(
          (comment) => comment.sectionId === section.sectionId,
        ),
      ]),
    );
  }, [zeck.id]);

  const analyticsData = useAnalyticsData(props, commentsResult);

  const publishedSections = zeck.publishedZeck?.sections ?? [];

  const userSortedSectionsWithTotals = buildUserSortedSectionsWithTotals(
    analyticsData,
    commentsResult,
    users,
    zeck,
  );

  if (!zeck.firstPublishedAt) {
    return (
      <AnalyticsEmptyModal
        title="This Zeck is not yet published."
        body="Publish in order to start tracking viewer analytics"
      />
    );
  }

  if (!zeck.publishedZeck || !zeck.publishedZeck.sections.length) {
    return (
      <AnalyticsEmptyModal
        title="This Zeck doesn't have any published sections."
        body="Add sections and publish."
      />
    );
  }

  if (!analyticsData) {
    return (
      <div className={styles.modalBodyWrapper}>
        <h2 className={styles.modalHeader}>Analytics</h2>

        <div data-testid="analytics-spinner" className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modalBodyWrapper}>
      <h2 className={styles.modalHeader}>Analytics</h2>

      {hideContentEngagement && (
        <ViewerEngagementModal
          analyticsSummary={analyticsData}
          zeckScrollGoLiveDate={getZeckScrollGoLiveDate()}
          zeckViewGoLiveDate={getZeckViewGoLiveDate()}
          userSortedSectionsWithTotals={userSortedSectionsWithTotals}
          {...props}
        />
      )}

      {!hideContentEngagement && (
        <Tabs
          light={true}
          tabs={[
            {
              id: 'viewer-engagement',
              displayName: 'Viewer Engagement',
              render: () => (
                <ViewerEngagementModal
                  {...props}
                  analyticsSummary={analyticsData}
                  zeckScrollGoLiveDate={getZeckScrollGoLiveDate()}
                  zeckViewGoLiveDate={getZeckViewGoLiveDate()}
                  modal={props.modal}
                  userSortedSectionsWithTotals={userSortedSectionsWithTotals}
                />
              ),
            },
            {
              id: 'content-engagement',
              displayName: 'Content Engagement',
              render: () => (
                <ContentEngagementModal
                  commentsResult={commentsResult}
                  analyticsSummary={analyticsData}
                  companyId={zeck.companyId}
                  publishedSections={publishedSections}
                  modal={props.modal}
                />
              ),
            },
          ]}
          bodyClassName={styles.tabBodyWrapper}
        />
      )}
    </div>
  );
};

const AnalyticsUserLoadingWrapper: React.FC<
  Omit<AnalyticsWrapperProps, 'users'>
> = (props) => {
  const api = useApi();

  const users = useFetch(
    () =>
      api.getPossibleZeckViewers({
        zeckId: props.zeck.id,
        cursor: null,
        getAllResults: true,
      }),
    [api, props.zeck.id],
  );

  if (isSuccessResult(users)) {
    return <AnalyticsWrapper {...props} users={users.data.models} />;
  }

  return null;
};

export default AnalyticsUserLoadingWrapper;
