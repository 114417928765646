import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from '../../design-system/atoms/Button.tsx';
import SessionLayout from '../../design-system/layouts/SessionLayout.tsx';
import useBreakpoints from '../../services/useBreakpoints.ts';
import styles from './ResetPasswordRequestSent.module.scss';

const ResetPasswordRequestSent = () => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();

  return (
    <SessionLayout>
      <h1 className={cx(isMobile ? styles.title_mobile : styles.title)}>
        Password Reset
      </h1>
      <p className={styles.passwordReset__description}>
        If there's a Zeck account connected to this email address, we'll email
        you password reset instructions. If you don't receive the email, please
        try again and make sure you enter the email address associated with your
        Zeck account.
      </p>
      <Button
        {...{
          className: styles.form__submit,
          size: 'large',
          color: 'primary',
          onClick: () => {
            navigate('/login');
          },
        }}
      >
        Go to Secure Sign In
      </Button>
    </SessionLayout>
  );
};

export default ResetPasswordRequestSent;
