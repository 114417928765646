import h from 'hyperscript';
import {
  DragEventHandler,
  FocusEventHandler,
  RefObject,
  useState,
} from 'react';
import ContentSelection, {
  baseAndExtentToContentSelection,
  contentSelectionToBaseAndExtent,
} from '../../../editor/selection/contentSelection/ContentSelection.js';
import { IPromiseThisHTMLStringIsSafeToRender } from '../../../editor/useContentEditable/SafeHTMLString.js';
import createUseContentEditableWithSelection from '../../../editor/useContentEditableWithSelection.js';
import { getSafePlaintextFromClipboardData } from '../../../junkDrawer/getSafePlaintextFromClipboardData.ts';

const escapeHtml = (unsafeContent: string) =>
  IPromiseThisHTMLStringIsSafeToRender(h('div', unsafeContent).innerHTML);

export const usePlaintextEditableWithSelection =
  createUseContentEditableWithSelection<string, void>(
    {
      toHTMLString(content) {
        return escapeHtml(content);
      },
      fromHTMLString(html) {
        const p = new DOMParser();
        const d = p.parseFromString(html, 'text/html');

        return d.body.textContent ?? '';
      },
      toBaseAndExtent(selection, el) {
        return contentSelectionToBaseAndExtent(el, selection);
      },
      fromBaseAndExtent(baseAndExtent, el) {
        return baseAndExtentToContentSelection(el, baseAndExtent);
      },
    },
    true,
  );

export type PlaintextEditableHookReturn<ElementType> = {
  ref: RefObject<ElementType>;
  style: React.CSSProperties;
  onInput(): void;
  onDragOver: DragEventHandler<ElementType>;
  onDrop: DragEventHandler<ElementType>;
  onFocus: FocusEventHandler<ElementType>;
  onBlur: FocusEventHandler<ElementType>;
  onCopy(e: React.ClipboardEvent<HTMLElement>): void;
  onCut(e: React.ClipboardEvent<HTMLElement>): void;
  onPaste(e: React.ClipboardEvent<HTMLElement>): void;
};

// this function is intended to sanitize HTML out of value to safely render without HTML injection
export default function usePlaintextEditable<ElementType extends HTMLElement>(
  value: string,
  onChange: (newValue: string) => void,
  initialSelection: ContentSelection | null = null,
): PlaintextEditableHookReturn<ElementType> {
  const [selection, setSelection] = useState<ContentSelection | null>(
    initialSelection,
  );

  const contentEditableProps = usePlaintextEditableWithSelection<ElementType>(
    { content: value, selection },
    (newValue) => {
      onChange(newValue.content);
      setSelection(newValue.selection);
    },
    setSelection,
  );

  return {
    ...contentEditableProps,
    // in safari, contenteditable does not work inside of a draggable
    onFocus: (e) => {
      e.currentTarget
        .closest('[draggable]')
        ?.setAttribute('draggable', 'false');
    },
    onBlur: (e) => {
      e.currentTarget.closest('[draggable]')?.setAttribute('draggable', 'true');
    },
    onCopy: (e) => {
      e.stopPropagation();
    },
    onCut: (e) => {
      e.stopPropagation();
    },
    onPaste: (e) => {
      e.preventDefault();
      e.stopPropagation();

      const text = getSafePlaintextFromClipboardData(e.clipboardData);
      const strings = text.split('\n');
      document.execCommand('insertText', false, strings.join(' '));
    },
  };
}
