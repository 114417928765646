import { PeopleListResponsiveViewProps } from './PeopleList.tsx';
import { PeopleListBoardDirectorToggle } from './PeopleListBoardDirectorToggle.tsx';
import { PeopleListContainer } from './PeopleListContainer.tsx';
import styles from './PeopleListDesktop.module.scss';
import { PeopleListEmail } from './PeopleListEmail.tsx';
import { PeopleListItemLayout } from './PeopleListItemLayout.tsx';
import { PeopleListUsername } from './PeopleListUsername.tsx';
import RoleDropdown from './RoleDropdown.tsx';

const headers = [
  {
    label: 'Person',
    tooltip: null,
  },
  {
    label: 'Email Address',
    tooltip: null,
  },
  {
    label: 'User Type',
    tooltip: null,
  },
  {
    label: 'Board Director',
    tooltip:
      'Board Directors can cast pre-votes on votes included in published Zecks.',
  },
];

const PeopleListDesktop = ({
  users,
  onUpdateBoardDirector,
  onUpdateRole,
  onResendInvitation,
  onDelete,
  currentUser,
}: PeopleListResponsiveViewProps) => {
  return (
    <PeopleListContainer headers={headers} isMobile={false}>
      {users.map((user, index) => {
        if (user.pending) {
          return (
            <PeopleListItemLayout
              key={index}
              cells={[
                null,
                <PeopleListEmail pending key="email">
                  {user.email}
                </PeopleListEmail>,
                <RoleDropdown
                  key="role"
                  user={user}
                  onUpdateRole={(role) => onUpdateRole(user, role)}
                  onResendInvitation={() => onResendInvitation(user)}
                  onDelete={() => onDelete(user)}
                />,
                <div className={styles.boardDirector__wrap} key="director">
                  <PeopleListBoardDirectorToggle
                    isBoardDirector={user.boardDirector}
                    onChange={(val) => onUpdateBoardDirector(user, val)}
                  />
                </div>,
              ]}
            />
          );
        }

        return (
          <PeopleListItemLayout
            key={index}
            cells={[
              <PeopleListUsername
                key="username"
                initialContent={user.firstName.charAt(0)}
                nameContent={`${user.firstName} ${user.lastName}`}
              />,
              <PeopleListEmail key="email">{user.email}</PeopleListEmail>,
              <RoleDropdown
                key="role"
                user={user}
                onUpdateRole={(role) => onUpdateRole(user, role)}
                onResendInvitation={() => onResendInvitation(user)}
                onDelete={() => onDelete(user)}
                disableRoleChange={user.id === currentUser.id}
              />,
              <div className={styles.boardDirector__wrap} key="director">
                <PeopleListBoardDirectorToggle
                  isBoardDirector={user.boardDirector}
                  onChange={(val) => onUpdateBoardDirector(user, val)}
                />
              </div>,
            ]}
          />
        );
      })}
    </PeopleListContainer>
  );
};

export default PeopleListDesktop;
