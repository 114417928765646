import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useState } from 'react';
import not from '../../../../junkDrawer/not.js';
import { Section } from '../../../../types.js';
import {
  getTitleFromSection,
  isSupplemental,
} from '../../../../types/Section.js';
import SectionItem from './ui/SectionItem.js';
import TableOfContentsList from './ui/TableOfContentsList.js';
import useReorderSections, { SectionOrder } from './useReorderSections.js';

export const SortableItem = ({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) => {
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({
      id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isDragging && {
      pointerEvents: 'none' as const,
      opacity: 0.5,
    }),
  };

  // note, we choose not to set attributes from useSortable on the li because it only contains accessibility features
  // for use with the keyboard sensor. If we use KeyboardSensor, we'll have to bring them back
  // In the meantime, they interfere with selectors in e2e tests and make the tab navigation more confusing
  return (
    <li ref={setNodeRef} style={style} {...listeners}>
      {children}
    </li>
  );
};

export const TableOfContentsListDroppable = ({
  id,
  disabled,
  title,
  children,
}: {
  id: string;
  disabled: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  const { setNodeRef } = useDroppable({ id, disabled });

  return (
    <TableOfContentsList ref={setNodeRef} title={title}>
      {children}
    </TableOfContentsList>
  );
};

type WithSectionDragAndDropProps = {
  sections: Section[];
  currentSection: Section | undefined;
  onReorder: (sectionOrder: SectionOrder) => void;
  children: (props: {
    sectionOrder: SectionOrder;
    moveToContainer: (
      id: string,
      containerId: 'appendix' | 'table-of-contents',
    ) => void;
  }) => React.ReactNode;
};
export const WithSectionDragAndDrop: React.FC<WithSectionDragAndDropProps> = ({
  sections,
  currentSection,
  onReorder,
  children,
}) => {
  const { sectionOrder, onDragEnd, onDragOver, onDragStart, moveToContainer } =
    useReorderSections(
      {
        tableOfContents: sections
          .filter(not(isSupplemental))
          .map(({ id }) => id),
        appendix: sections.filter(isSupplemental).map(({ id }) => id),
      },
      onReorder,
    );

  const [draggedSection, setDraggedSection] = useState<null | {
    name: string;
    active: boolean;
  }>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    setDraggedSection(null);
    onDragEnd(event);
  };

  const handleDragOver = (event: DragOverEvent) => {
    onDragOver(event);
  };

  const handleDragStart = (event: DragStartEvent) => {
    onDragStart();
    const section = sections.find(({ id }) => id === event.active.id);

    if (!section) return;

    setDraggedSection({
      name: getTitleFromSection(section),
      active: !!currentSection && currentSection.id === section.id,
    });
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <DragOverlay>
        {draggedSection && (
          <SectionItem
            name={draggedSection.name}
            to={''}
            active={draggedSection.active}
            onRenameSection={() => {
              //
            }}
            onRenameActiveSection={() => {
              //
            }}
            actions={[]}
          />
        )}
      </DragOverlay>
      {children({ sectionOrder, moveToContainer })}
    </DndContext>
  );
};

type TableOfContentsSortableProps = {
  items: string[];
  disabled: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
  containerId: string;
};

export const TableOfContentsListSortable: React.FC<
  TableOfContentsSortableProps
> = ({ items, disabled, containerId, title, children }) => {
  return (
    <SortableContext
      items={items}
      strategy={verticalListSortingStrategy}
      disabled={disabled}
    >
      <TableOfContentsListDroppable
        id={containerId}
        disabled={items.length > 0}
        title={title}
      >
        {children}
      </TableOfContentsListDroppable>
    </SortableContext>
  );
};
