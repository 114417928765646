import { AiWizardBadge } from '../../../../design-system/atoms/AiWizardBadge.js';
import IconButton from '../../../../design-system/atoms/IconButton.js';
import styles from './AiTableKeyTakeawaysError.module.scss';

export type AiTableKeyTakeawaysFlowErrorState = {
  type: 'error';
};

type AiTableKeyTakeawaysFlowErrorProps = {
  onClose: () => void;
};

export const AiTableKeyTakeawaysError: React.FC<
  AiTableKeyTakeawaysFlowErrorProps
> = ({ onClose }) => {
  return (
    <div className={styles.errorContainer}>
      <AiWizardBadge />
      <div className={styles.errorMessage}>
        Something went wrong. Please try again.
      </div>
      <IconButton name="close" aria-label="Close" onClick={onClose} />
    </div>
  );
};
