import cx from 'classnames';
import styles from './ZackLogo.module.scss';

export default function ZackLogo(props: React.ComponentProps<'div'>) {
  return (
    <div {...props} className={cx(styles.zackLogo, props.className)}>
      <span className={styles.z}>z</span>
      <span className={styles.a}>a</span>
      <span className={styles.c}>c</span>
      <span className={styles.k}>k</span>
    </div>
  );
}
