import Rect, { getRectFromEl } from '../../../../domHelpers/Rect.js';
import { ElementAndData } from '../../../../junkDrawer/useElementAndDataArray.js';

const getPrimarySectionInContainer = (
  sectionsWithRef: ElementAndData<{ id: string }>[],
  scrollContainerRef: React.RefObject<HTMLElement>,
) => {
  const scrollContainer = scrollContainerRef.current;
  if (!scrollContainer) {
    return;
  }
  const scrollContainerRect = getRectFromEl(scrollContainer);
  const sectionRects = sectionsWithRef
    .map(({ getEl, data: section }) => ({
      el: getEl(),
      id: section.id,
    }))
    .filter(
      (elWithId): elWithId is { el: HTMLElement; id: string } => !!elWithId.el,
    )
    .map(({ el, id }) => ({ rect: getRectFromEl(el), id }));

  return getPrimaryRectInContainer(sectionRects, scrollContainerRect);
};

export const getPrimaryRectInContainer = (
  rectWithIds: { id: string; rect: Rect }[],
  containerRect: Rect,
): string | undefined => {
  const containerMidPoint = (containerRect[0][1] + containerRect[1][1]) / 2;

  const currentRect = rectWithIds.find(({ rect }) => {
    return rect[0][1] <= containerMidPoint && rect[1][1] >= containerMidPoint;
  });

  return currentRect?.id;
};

export default getPrimarySectionInContainer;
