import Linkable from 'editor-content/html/Linkable.js';
import { MouseEvent as ReactMouseEvent, useRef } from 'react';
import isHTMLElement from '../../../../junkDrawer/isHTMLElement.js';
import { useSignal } from '../../../../signals/index.js';
import useThrottle from '../../edit/useThrottle.js';
import { TEXT_NODE_TYPE_ATTRIBUTE_NAME } from '../../editor/textNode/TextNode.js';
import { getLinkDataFromElement, LinkData } from './getLinkDataFromElement.js';
import { getClosestRectToMouse, isMouseHoveringLink } from './PixelLogic.js';

const getLinkEl = (el: EventTarget): HTMLElement | null => {
  if (!isHTMLElement(el)) return null;

  const link = el.closest(
    `a[${TEXT_NODE_TYPE_ATTRIBUTE_NAME}], span[${TEXT_NODE_TYPE_ATTRIBUTE_NAME}]`,
  );

  if (!link) return null;

  if (!isHTMLElement(link)) return null;

  return link;
};

export type MousePosition = { x: number; y: number };

export type LinkTooltipState =
  | {
      type: 'hovered';
      link: LinkData;
      linkRect: {
        x: number;
        y: number;
        width: number;
        height: number;
      };
    }
  | {
      type: 'hidden';
      linkRect: {
        x: number;
        y: number;
        width: number;
        height: number;
      };
    }
  | null;

const useLinkTooltips = ({
  onClickLink,
  linkables,
}: {
  onClickLink?: (content: Linkable) => void;
  linkables: Linkable[];
}) => {
  const tooltipStateSignal = useSignal<LinkTooltipState>(null);

  const hoverZoneRef = useRef<HTMLDivElement | null>(null);

  const closeTooltip = () => {
    tooltipStateSignal.set(null);
  };

  const onMouseMove = useThrottle((e: ReactMouseEvent) => {
    const tooltipState = tooltipStateSignal.get();

    if (!tooltipState) return;

    if (
      !isMouseHoveringLink(tooltipState.linkRect, hoverZoneRef.current, {
        x: e.clientX,
        y: e.clientY,
      })
    ) {
      closeTooltip();
    }
  }, 30);

  return {
    linkTooltipState: {
      setHoverZoneEl: (el: HTMLDivElement) => {
        hoverZoneRef.current = el;
      },
      tooltipStateSignal: tooltipStateSignal,
      closeTooltip,
    },
    onScroll: () => {
      const tooltipState = tooltipStateSignal.get();
      if (!tooltipState) return;

      tooltipStateSignal.set({
        type: 'hidden',
        linkRect: tooltipState.linkRect,
      });
    },
    linkListeners: {
      onClick(e: ReactMouseEvent) {
        if (!onClickLink) return;
        const link = getLinkEl(e.target);

        if (!link) return;
        closeTooltip();

        const linkData = getLinkDataFromElement(link, linkables);

        // kinda breaks LinkData abstraction
        if (linkData.type === 'internal') {
          // does this need to be here?
          e.preventDefault();

          // this doesn't feel like this guy's job but not sure where to put it
          if (linkData.status === 'full') {
            onClickLink(linkData.content);
          }
        }
      },
      onMouseOver(e: ReactMouseEvent) {
        const link = getLinkEl(e.target);

        if (!link) return;

        const linkData = getLinkDataFromElement(link, linkables);
        const rect = getClosestRectToMouse(link, {
          x: e.clientX,
          y: e.clientY,
        });

        if (!rect) return;

        tooltipStateSignal.set({
          type: 'hovered',
          link: linkData,
          linkRect: rect,
        });
      },
      onMouseMove: onMouseMove,
    },
  };
};

export type LinkTooltipBehavior = ReturnType<typeof useLinkTooltips>;

export default useLinkTooltips;
