import cx from 'classnames';
import React from 'react';
import styles from './TableScrollShadowContainer.module.scss';

export const TableScrollShadowContainer = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>(function TableScrollShadowContainer(
  { children, className, ...otherProps },
  forwardedRef,
) {
  return (
    <div
      ref={forwardedRef}
      {...otherProps}
      className={cx(styles.tableShadowScrollContainer, className)}
    >
      {children}
    </div>
  );
});

export default TableScrollShadowContainer;
