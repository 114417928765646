import sortBy from 'lodash/sortBy.js';
import SelectionModal from '../../../design-system/organisms/SelectionModal.tsx';
import { ZeckLite } from '../../../types.ts';

type GenerateMinutesFromZeckProps = {
  zecks: Array<ZeckLite>;
  onSubmit: (zeck: ZeckLite) => void;
  onCancel: () => void;
};

export default function GenerateMinutesFromZeck({
  zecks,
  onSubmit,
  onCancel,
}: GenerateMinutesFromZeckProps) {
  const publishedZecks = zecks.filter((zeck) => zeck.publishedAt);
  const sortedZecks = sortBy(publishedZecks, (z) => -(z.publishedAt ?? 0));

  return (
    <SelectionModal
      items={sortedZecks}
      onSubmit={onSubmit}
      onCancel={onCancel}
      title="Choose a Published Zeck"
    />
  );
}
