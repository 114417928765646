import {
  sectionPreviewPath,
  zeckHomePath,
  zeckPreviewPath,
} from '../../../../services/Paths.ts';
import { Zeck } from '../../../../types.ts';
import ViewSidebar from '../ViewSidebar.tsx';

type PreviewSidebarProps = {
  onRequestClose(): void;
  zeck: Zeck;
  isOpen: boolean;
  scrollToSectionOrZeckCover: (sectionId?: string) => void;
};

const PreviewSidebar = ({
  zeck,
  onRequestClose,
  isOpen,
  scrollToSectionOrZeckCover,
}: PreviewSidebarProps) => {
  return (
    <ViewSidebar
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      scrollToSectionOrZeckCover={scrollToSectionOrZeckCover}
      zeck={{
        ...zeck,
        path: zeckPreviewPath(zeck),
        homePath: zeckHomePath(zeck),
        sections: zeck.sections.map((section) => ({
          ...section,
          path: sectionPreviewPath(section, zeck.companyId),
        })),
      }}
    />
  );
};

export default PreviewSidebar;
