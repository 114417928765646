import { useState } from 'react';
import {
  ErrorResult,
  isSuccessResult,
  LoadingResult,
  SuccessResult,
} from '../../result/Result.js';
import useExecuteOnLoad from '../../result/useExecuteOnLoad.js';
import { Result } from './useFetch.js';

const useDependentFetchInvalidate = <DependentType, ResultType>(
  dependentResult: Result<DependentType>,
  fn: (data: DependentType) => Promise<ResultType>,
) => {
  const [result, setResult] = useState<Result<ResultType>>(LoadingResult());

  useExecuteOnLoad({
    result: dependentResult,
    onLoad: async (data) => {
      try {
        setResult(SuccessResult(await fn(data)));
      } catch (e) {
        setResult(ErrorResult(undefined));
      }
    },
  });

  const invalidate = async (
    action: () => Promise<void>,
    optimisticData?: ResultType,
  ) => {
    if (!isSuccessResult(dependentResult)) {
      return;
    }

    if (optimisticData) {
      setResult(SuccessResult(optimisticData));
    }

    await action();

    try {
      setResult(SuccessResult(await fn(dependentResult.data)));
    } catch (e) {
      setResult(ErrorResult(undefined));
    }
  };

  return { result, invalidate };
};

export default useDependentFetchInvalidate;
