import { z } from 'zod';

const TextFormatSchema = z.object({
  bold: z.boolean().optional(),
  italic: z.boolean().optional(),
  underline: z.boolean().optional(),
});

export const TextSchema = z.object({
  type: z.literal('text'),
  text: z.string(),
  format: TextFormatSchema.optional(),
});

export const ExternalLinkSchema = z.object({
  type: z.literal('external-link'),
  content: z.array(TextSchema),
  url: z.string(), // .url() would be ideal but not safe with legacy data
});

export const SectionLinkSchema = z.object({
  type: z.literal('section-link'),
  content: z.array(TextSchema),
  sectionId: z.string().uuid(),
});

export const MeetingMinutesLinkSchema = z.object({
  type: z.literal('meeting-minutes-link'),
  content: z.array(TextSchema),
  meetingMinutesId: z.string().uuid(),
});

const NonHighlightTextNodeSchema = z.union([
  TextSchema,
  ExternalLinkSchema,
  SectionLinkSchema,
  MeetingMinutesLinkSchema,
]);

export const HighlightSchema = z.object({
  type: z.literal('highlight'),
  ids: z.array(z.string().uuid()),
  content: z.array(NonHighlightTextNodeSchema),
});

export const TextNodeSchema = z.union([
  NonHighlightTextNodeSchema,
  HighlightSchema,
]);
