import { Block, isTextBlock } from 'editor-content/Block.js';
import { TurnIntoable } from '../../design-system/molecules/TurnIntoMenu.js';
import HoverNextToPoint from '../../domHelpers/hoverNextTo/HoverNextToPoint.js';
import { subtractVector } from '../../domHelpers/Point.js';
import { getRectFromEl } from '../../domHelpers/Rect.js';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import { isBlockSelection } from '../EditorSelection.js';
import { EditorStateGeneric } from '../EditorStateGeneric.js';
import { getStartOfBlockSelection } from '../selection/BlockSelection.js';
import splitContentByBlockSelection from '../selection/splitContentByBlockSelection.js';
import MultiblockFormattingMenu from './MultiblockFormattingMenu.js';

type BlockFormattingMenuProps<AvailableBlock extends Block> = {
  turnIntoables: TurnIntoable[];
  // arguably this should just take Editor with a particular interface
  // instead of digging into state
  editorState: EditorStateGeneric<AvailableBlock>;
  blocksWithEl: ElementAndData<AvailableBlock>[];
  renderAdditionalActions?: (
    selectedBlocks: AvailableBlock[],
  ) => React.ReactNode;
};

const MultiblockFormattingExperience = <AvailableBlock extends Block>({
  turnIntoables,
  editorState,
  blocksWithEl,
  renderAdditionalActions,
}: BlockFormattingMenuProps<AvailableBlock>) => {
  const { selection, content } = editorState;
  if (!isBlockSelection(selection)) return null;
  const [, selectedBlocks] = splitContentByBlockSelection(content, selection);

  if (!selectedBlocks.every(isTextBlock)) return null;

  const additionalActions =
    renderAdditionalActions && renderAdditionalActions(selectedBlocks);

  return (
    <HoverNextToPoint
      usePortal
      viewportPolicy="none"
      getPoint={(childElement) => {
        const el = blocksWithEl[getStartOfBlockSelection(selection)]?.getEl();

        if (!el) {
          return [NaN, NaN];
        }

        const rect = getRectFromEl(el);
        const { height, width } = childElement.getBoundingClientRect();

        return subtractVector(
          [(rect[0][0] + rect[1][0]) / 2, rect[0][1]],
          [width / 2, height + 8],
        );
      }}
    >
      <MultiblockFormattingMenu
        turnIntoables={turnIntoables}
        additionalActions={additionalActions}
      />
    </HoverNextToPoint>
  );
};

export default MultiblockFormattingExperience;
