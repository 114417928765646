import { TableBlock } from 'editor-content/TableBlock.js';
import { isTextSelection } from '../../../../editor/selection/TextSelection.js';
import cond from '../../../../junkDrawer/cond.js';
import guard from '../../../../junkDrawer/guard.js';
import identity from '../../../../junkDrawer/identity.js';
import { BodyStateSelected } from './BodyEditor.js';
import { hydratedIsTableBlock } from './hydratedBlockGuards.js';

export function replaceTable(
  initialState: BodyStateSelected,
  newTable: Pick<TableBlock, 'data' | 'frozenRows' | 'frozenColumns'>,
): BodyStateSelected {
  const { content, selection } = initialState;

  if (!isTextSelection(selection)) return initialState;

  return {
    content: content.map(
      cond(
        (a, i) => i === selection.index,
        guard(
          hydratedIsTableBlock,
          (block): TableBlock => ({
            ...block,
            data: newTable.data,
            frozenRows: newTable.frozenRows,
            frozenColumns: newTable.frozenColumns,
          }),
          identity,
        ),
        identity,
      ),
    ),
    selection,
  };
}
