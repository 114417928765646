import {
  addMinutes,
  format,
  getHours,
  getMinutes,
  hoursToMinutes,
  startOfDay,
} from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

export function convertTimeToTimestamp(time: string) {
  return Date.parse(`1970-01-01T${time}:00.000`);
}

export function convertTimestampToTime(timestamp: number) {
  return format(timestamp, 'HH:mm');
}

export function setDate(timestamp: number, dayTimestamp: number) {
  const date = format(dayTimestamp, 'yyyy-MM-dd');
  const time = format(timestamp, 'HH:mm');
  return Date.parse(`${date}T${time}:00.000`);
}

export function formatMinutesFromMidnight(time: number) {
  return format(addMinutes(startOfDay(Date.now()), time), 'h:mm aaa');
}

export function getMinutesFromMidnight(date: Date) {
  return hoursToMinutes(getHours(date)) + getMinutes(date);
}

export function getMinutesFromMidnightInTimeZone(
  date: Date,
  timeZone: string,
): number {
  const maybe = toZonedTime(date, timeZone);
  // black magic bs
  // given a date D and a timeZone T,
  // this function will give you a D2 back
  // that has the minute and hour values appropriate for timeZone T
  // even though D2 no longer represents the same time
  return hoursToMinutes(getHours(maybe)) + getMinutes(maybe);
}

function getStartOfDayInTimeZone(date: Date, timeZone: string): Date {
  return fromZonedTime(startOfDay(toZonedTime(date, timeZone)), timeZone);
}

export function setMinutesFromMidnightInTimeZone(
  date: Date,
  minutes: number,
  timeZone: string,
): Date {
  const startOfDay = getStartOfDayInTimeZone(date, timeZone);

  return addMinutes(startOfDay, minutes);
}

export function getSystemTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
