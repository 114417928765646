import { z } from 'zod';
import { TextSchema } from './TextNode.js';

export const TableBlockMergeSchema = z.object({
  startRow: z.number(),
  rowSpan: z.number(),
  columnSpan: z.number(),
  startColumn: z.number(),
});

export const TableBorderSchema = z.union([
  z.literal('thick'),
  z.literal('thin'),
]);

export const TableBlockCellUncompressedSchema = z.object({
  type: z.literal('table-cell'),
  content: z.array(TextSchema),

  format: z
    .object({
      alignHorizontal: z
        .union([z.literal('left'), z.literal('right'), z.literal('center')])
        .optional(),
      alignVertical: z
        .union([z.literal('top'), z.literal('middle'), z.literal('bottom')])
        .optional(),
      wrap: z.union([z.literal('wrap'), z.literal('clip')]).optional(),
      border: z
        .object({
          left: TableBorderSchema.optional(),
          right: TableBorderSchema.optional(),
          top: TableBorderSchema.optional(),
          bottom: TableBorderSchema.optional(),
        })
        .optional(),
    })
    .optional(),
});

export const TableBlockCellSchema = z.union([
  TableBlockCellUncompressedSchema,
  z.string(),
]);

export const TableBlockRowSchema = z.object({
  type: z.literal('table-row'),
  cells: z.array(TableBlockCellSchema),
});

export const TableBlockSchema = z.object({
  type: z.literal('table'),
  id: z.string().uuid(),
  integrationId: z.string().uuid().optional(),
  data: z.object({
    rows: z.array(TableBlockRowSchema),
    merges: z.array(TableBlockMergeSchema).optional(),
    columnWidths: z.record(z.string(), z.number()).optional(),
    rowHeights: z.record(z.string(), z.number()).optional(),
  }),
  frozenColumns: z.union([z.literal(1), z.literal(2)]).optional(),
  frozenRows: z.union([z.literal(1), z.literal(2)]).optional(),
});
