import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './Label.module.scss';

type LabelProps = {
  children?: ReactNode;
  className?: string;
};

const Label = React.forwardRef<HTMLDivElement, LabelProps>(
  function Label(props, forwardedRef) {
    return (
      <p
        {...{
          ...props,
          ref: forwardedRef,
          className: cx(styles.label, props.className),
        }}
      >
        {props.children}
      </p>
    );
  },
);

export default Label;
