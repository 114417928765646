import cx from 'classnames';
import useBreakpoints from '../../services/useBreakpoints.ts';
import styles from './Input.module.scss';
import InputError from './InputError.js';

type InputProps = {
  label: string;
  value: string;
  type: string;
  onChange: (value: string) => void;
  error?: React.ReactNode;
} & Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value' | 'type'
>;

const Input = ({
  label,
  value,
  type,
  onChange,
  error,
  ...props
}: InputProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <label className={styles.inputContainer}>
      {error && (
        <InputError
          className={styles.input__error}
          size={isMobile ? 'small' : 'normal'}
        >
          {error}
        </InputError>
      )}

      <div className={styles.input}>
        <div
          {...{
            className: cx(
              styles.input__label,
              !!value && styles.input__label_hidden,
            ),
          }}
        >
          {label}
        </div>
        <input
          {...{
            ...props,
            type,
            className: cx(
              styles.input__input,
              error && styles.input__input_error,
              props.className,
            ),
            value,
            onChange(e) {
              onChange(e.currentTarget.value);
            },
          }}
        />
      </div>
    </label>
  );
};

export default Input;
