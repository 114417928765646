import TagMenu, {
  TagMenuItem,
  TagMenuList,
} from '../../../design-system/organisms/TagMenu.tsx';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { AvailableTag } from '../../../types/AvailableTag.ts';

export type TagSelection = number | null;

type InsertTagMenuProps = {
  matchingTags: AvailableTag[];
  onInsertTag: (tag: AvailableTag) => void;
  getSelectionRect: () => DOMRect | void;
  tagSelection: TagSelection;
};

export default function InsertTagMenu({
  matchingTags,
  onInsertTag,
  getSelectionRect,
  tagSelection,
}: InsertTagMenuProps) {
  if (matchingTags.length === 0) return null;
  return (
    <HoverNextToPoint
      getPoint={() => {
        const rect = getSelectionRect();
        if (!rect) return null;

        return [rect.left - 19, rect.bottom + 8];
      }}
      usePortal
    >
      <TagMenu>
        <TagMenuList title={'All People'}>
          {matchingTags.map((tag, index) => (
            <TagMenuItem
              key={tag.userId}
              isSelected={tagSelection === index}
              onClick={() => {
                onInsertTag(tag);
              }}
            >
              {tag.displayName}
            </TagMenuItem>
          ))}
        </TagMenuList>
      </TagMenu>
    </HoverNextToPoint>
  );
}
