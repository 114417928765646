import { Icon } from 'icons';
import Button from '../../design-system/atoms/Button.tsx';
import Modal, {
  ModalBody,
  ModalHeader,
} from '../../design-system/organisms/Modal.tsx';
import { User } from '../../types.ts';
import styles from './ConflictModal.module.scss';

type ConflictModalProps = {
  isOpen: boolean;
  user: User | null;
  description: string;
};

export default function ConflictModal({
  isOpen,
  user,
  description,
}: ConflictModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      className={styles.modal}
      data-testid="conflict-modal"
    >
      <ModalHeader className={styles.header}>
        <Icon name="danger" /> Someone Else is Editing
      </ModalHeader>
      <ModalBody>
        {user ? (
          <span className={styles.name}>
            {user.firstName} {user.lastName[0]}
          </span>
        ) : (
          'Someone'
        )}{' '}
        {description}
        <Button
          onClick={() => window.location.reload()}
          className={styles.cta}
          color="primary"
          size="medium"
        >
          Got It
        </Button>
      </ModalBody>
    </Modal>
  );
}
