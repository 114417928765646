import BaseAndExtent from '../selection/contentSelection/BaseAndExtent.js';
import ContentSelection from '../selection/contentSelection/ContentSelection.js';

export function setContentSelectionOnContainer<ElementType extends HTMLElement>(
  selection: ContentSelection | null,
  containerEl: ElementType,
  toBaseAndExtent: (
    selection: ContentSelection,
    el: ElementType,
  ) => BaseAndExtent,
) {
  const domSelection = window.getSelection();

  if (domSelection && selection) {
    const { anchorNode, anchorOffset, focusNode, focusOffset } =
      toBaseAndExtent(selection, containerEl);

    domSelection.setBaseAndExtent(
      anchorNode,
      anchorOffset,
      focusNode,
      focusOffset,
    );

    containerEl.focus();
  }
}
