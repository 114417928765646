import { useState } from 'react';
import Button from '../../design-system/atoms/Button.tsx';
import OutlineButton from '../../design-system/atoms/OutlineButton.tsx';
import BrandKitTile from '../../design-system/molecules/BrandKitTile.tsx';
import ColorPickerDialog from '../../design-system/organisms/ColorPickerDialog.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../design-system/organisms/Modal.tsx';

type PrimaryColorPickerTileProps = {
  primaryColor: string | null;
  onUpdatePrimaryColor: (color: string) => void;
  onDeletePrimaryColor: () => void;
};

export default function PrimaryColorPickerTile({
  primaryColor,
  onUpdatePrimaryColor,
  onDeletePrimaryColor,
}: PrimaryColorPickerTileProps) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onRequestClose = () => {
    setModalIsOpen(false);
  };

  const onDelete = () => {
    setModalIsOpen(false);
    onDeletePrimaryColor();
  };

  return (
    <>
      <BrandKitTile
        onClick={() => setShowColorPicker(!showColorPicker)}
        onClickDelete={() => setModalIsOpen(true)}
        label="Primary Color"
        data={primaryColor}
      >
        <div
          style={{
            transition: '200ms background-color',
            backgroundColor: primaryColor ? primaryColor : 'transparent',
            alignSelf: 'stretch',
            justifySelf: 'stretch',
            flex: '1',
          }}
        />
      </BrandKitTile>
      {showColorPicker && (
        <ColorPickerDialog
          initialColor={primaryColor || '#000000'}
          onCancel={() => setShowColorPicker(false)}
          onSubmit={(color) => {
            setShowColorPicker(false);
            onUpdatePrimaryColor(color.trim());
          }}
        />
      )}

      <Modal isOpen={modalIsOpen} onRequestClose={onRequestClose}>
        <ModalHeader onClickClose={onRequestClose}>
          Delete Primary Color?
        </ModalHeader>
        <ModalBody>
          Deleting the primary color will remove it from all of your Zecks.
        </ModalBody>
        <ModalActions>
          <OutlineButton
            size="medium"
            onClick={onRequestClose}
            color="secondary"
          >
            Cancel
          </OutlineButton>
          <Button size="medium" color="danger" onClick={onDelete}>
            Yes, Delete
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
}
