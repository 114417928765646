import { useEffect, useState } from 'react';
import { MeetingMinutes } from '../../api/endpoints/createMeetingMinutesApi.ts';
import useApi from '../../api/useApi.js';
import {
  ErrorResult,
  LoadingResult,
  Result,
  SuccessResult,
} from '../../result/Result.js';

const useFetchMeetingMinutes = (meetingMinutesId: string) => {
  const { fetchMeetingMinutes } = useApi();

  const [meetingMinutesResult, setMeetingMinutesResult] =
    useState<Result<unknown, MeetingMinutes>>(LoadingResult());

  useEffect(() => {
    (async () => {
      try {
        setMeetingMinutesResult(
          SuccessResult(await fetchMeetingMinutes(meetingMinutesId)),
        );
      } catch (e) {
        setMeetingMinutesResult(ErrorResult(e));
      }
    })();
  }, [meetingMinutesId, fetchMeetingMinutes]);

  const mutation =
    <T extends unknown[]>(fn: (...args: T) => Promise<MeetingMinutes>) =>
    async (...args: T) => {
      try {
        const apiResponse = await fn(...args);
        setMeetingMinutesResult(SuccessResult(apiResponse));
      } catch (e) {
        setMeetingMinutesResult(ErrorResult(e));
      }
    };

  return {
    result: meetingMinutesResult,
    mutation,
  };
};

export default useFetchMeetingMinutes;
