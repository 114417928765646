import {
  TableBlock,
  TableBlockCell,
  TableBlockRow,
} from 'editor-content/TableBlock.ts';

export const getTableCellValue = (cell: TableBlockCell): string => {
  if (typeof cell === 'string') {
    return cell;
  }

  return cell.content.map((textNode) => textNode.text).join('') ?? '';
};

// Helper function to check if a row contains numeric values
const rowContainsNumericValues = (row: TableBlockRow | undefined): boolean => {
  if (!row) {
    return false;
  }

  const rowValues = row.cells.map(getTableCellValue);

  return rowValues.some((value) => !isNaN(Number(value.replace(/[$,%]/g, ''))));
};

const isChartWorthy = (block: TableBlock): boolean => {
  const { data } = block;

  // Check first data row
  if (rowContainsNumericValues(data.rows[1])) {
    return true;
  }

  // Check second data row handles tables with two header rows
  if (rowContainsNumericValues(data.rows[2])) {
    return true;
  }

  return false;
};

export default isChartWorthy;
