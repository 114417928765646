import { addMinutes, parseISO } from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { useState } from 'react';
import Button from '../design-system/atoms/Button.tsx';
import DetectsOutsideClick from '../junkDrawer/DetectsOutsideClick.tsx';
import DatePicker from './DatePicker.tsx';
import styles from './DateTimeInput.module.scss';
import PopupForm from './PopupForm.tsx';
import TimePicker from './TimePicker.tsx';
import {
  getMinutesFromMidnightInTimeZone,
  getSystemTimezone,
} from './timestamp.ts';
import TimeZoneForm from './TimeZoneForm.tsx';

type DateTimeFormProps = {
  timestamp: number | null;
  timeZone: string | null;
  onSubmit: (values: { timestamp: number | null; timeZone: string }) => void;
  onCancel: () => void;
};

const DateTimeForm: React.FC<DateTimeFormProps> = ({
  timestamp,
  timeZone,
  onSubmit,
  onCancel,
}) => {
  const defaultedTimeZone = timeZone || getSystemTimezone();

  const [uiState, setUiState] = useState<'picking-date' | 'picking-time-zone'>(
    'picking-date',
  );
  const [selectedDate, setSelectedDate] = useState<string | null>(
    timestamp === null
      ? null
      : formatInTimeZone(timestamp, defaultedTimeZone, 'yyyy-MM-dd'),
  );
  const [selectedTime, setSelectedTime] = useState<number>(
    timestamp === null
      ? 720
      : getMinutesFromMidnightInTimeZone(
          new Date(timestamp),
          defaultedTimeZone,
        ),
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(defaultedTimeZone);
  return (
    <DetectsOutsideClick onClick={onCancel}>
      {(ref) => {
        switch (uiState) {
          case 'picking-date':
            return (
              <PopupForm
                ref={ref}
                data-testid="date-time-picker"
                actions={
                  <>
                    <Button
                      onClick={() => {
                        setUiState('picking-time-zone');
                      }}
                      size="small"
                      color="secondary"
                    >
                      Time Zone
                    </Button>

                    <Button
                      disabled={!selectedDate || !selectedTime}
                      onClick={() => {
                        if (!selectedTime || !selectedDate) return;

                        const newStartTime = fromZonedTime(
                          addMinutes(parseISO(selectedDate), selectedTime),
                          selectedTimeZone,
                        );
                        onSubmit({
                          timeZone: selectedTimeZone,
                          timestamp: newStartTime.getTime(),
                        });
                      }}
                      size="small"
                      color="primary"
                    >
                      OK
                    </Button>
                  </>
                }
              >
                <div className={styles.inputs}>
                  <DatePicker
                    date={selectedDate}
                    onChange={setSelectedDate}
                    className={styles.datePicker}
                  />
                  <TimePicker
                    time={selectedTime}
                    onChange={setSelectedTime}
                    className={styles.timePicker}
                  />
                </div>
              </PopupForm>
            );

          case 'picking-time-zone':
            return (
              <TimeZoneForm
                onSubmit={(newTimeZone) => {
                  setSelectedTimeZone(newTimeZone);
                  setUiState('picking-date');
                }}
                onCancel={() => {
                  setUiState('picking-date');
                }}
                timeZone={selectedTimeZone}
              />
            );
        }
      }}
    </DetectsOutsideClick>
  );
};

export default DateTimeForm;
