import { Comment, CommentReply } from 'api-client/types.js';
import { CommentContent } from 'editor-content/CommentContent.js';
import { useSetAtom } from 'jotai';
import { UserAndCompany } from '../../../../../../userAndCompany/FetchUserAndCompany.js';
import { CommentsAtoms } from './createCommentsServerStateAtom.js';

const useActionsForComments = (
  userAndCompany: UserAndCompany,
  commentsAtoms: CommentsAtoms,
) => {
  const editComment = useSetAtom(commentsAtoms.editComment);
  const deleteComment = useSetAtom(commentsAtoms.removeComment);
  const updateResolved = useSetAtom(commentsAtoms.updateResolved);
  const updateStarred = useSetAtom(commentsAtoms.updateStarred);
  const addCommentReply = useSetAtom(commentsAtoms.addCommentReply);
  const editCommentReply = useSetAtom(commentsAtoms.editCommentReply);
  const removeCommentReply = useSetAtom(commentsAtoms.removeCommentReply);

  const getActionsForComment = (comment: Comment) => {
    const edit = async (content: CommentContent) => {
      await editComment(comment, content);
    };

    const deleteAction = async () => {
      await deleteComment(comment);
    };

    const resolveAction = async () => {
      await updateResolved(comment, true);
    };

    const unresolveAction = async () => {
      await updateResolved(comment, false);
    };

    const toggleStarAction = async () => {
      await updateStarred(comment, !comment.starred);
    };

    const reply = async (content: CommentContent) => {
      await addCommentReply(comment, content);
    };

    const isAuthor = comment.userId === userAndCompany.user.id;
    const isResolved = comment.resolved;
    const canEdit = isAuthor && !isResolved;
    const canDelete =
      isAuthor || userAndCompany.activeCompany.permissions.canManageZecks;
    const canResolve = !isResolved;
    const canUnresolve = isResolved;

    return {
      edit: canEdit ? edit : undefined,
      delete: canDelete ? deleteAction : undefined,
      reply,
      resolve: canResolve ? resolveAction : undefined,
      unresolve: canUnresolve ? unresolveAction : undefined,
      toggleStar: toggleStarAction,
    };
  };

  const getActionsForCommentReply = (commentReply: CommentReply) => {
    const edit = async (content: CommentContent) => {
      await editCommentReply(commentReply, content);
    };

    const deleteReply = async () => {
      await removeCommentReply(commentReply);
    };

    const isAuthor = commentReply.userId === userAndCompany.user.id;
    if (isAuthor) {
      return {
        edit,
        delete: deleteReply,
      };
    }

    if (userAndCompany.activeCompany.permissions.canManageZecks) {
      return {
        delete: deleteReply,
      };
    }

    return {};
  };
  return { getActionsForComment, getActionsForCommentReply };
};

export default useActionsForComments;
