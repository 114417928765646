import throttle from 'lodash/throttle.js';
import { useMemo, useRef } from 'react';

const useDownsampleScrollEvents = <
  EventType extends { sectionId: string; timestamp: number },
>(
  onEmit: (event: EventType) => void,
  sampleWindowMs: number,
): ((event: EventType) => void) => {
  const lastSectionIdRef = useRef<string | null>(null);

  const fnRef = useRef(onEmit);
  fnRef.current = onEmit;

  return useMemo(() => {
    const res = throttle(
      (event: EventType) => {
        fnRef.current(event);
      },
      sampleWindowMs,
      { leading: true, trailing: true },
    );

    return (event: EventType) => {
      if (event.sectionId !== lastSectionIdRef.current) {
        res.cancel();
        lastSectionIdRef.current = event.sectionId;
      }
      return res(event);
    };
  }, [sampleWindowMs]);
};

export default useDownsampleScrollEvents;
