import './design-system/colors.css';
import './design-system/variables.css';
import './global.css';

import { characterOffsetToBaseAndExtent } from 'selection-character-offset';
import ApiSession from './api/ApiSession.tsx';
import { CompanyDependentRoutes } from './CompanyDependentRoutes.js';
import HoverLayer from './domHelpers/hoverNextTo/HoverLayer.tsx';
import ErrorToaster from './errorToaster/ErrorToaster.tsx';
import FeatureFlags from './feature-flags/FeatureFlags.tsx';
import { RootEventListener } from './junkDrawer/useRootEventListener.js';
import { Routes } from './Routes.js';
import makeUnauthenticatedRoutes from './routes/unauthenticatedRoutes.tsx';
import useViewportHeight from './services/useViewportHeight.ts';

Object.assign(window, { _testhelpers: { characterOffsetToBaseAndExtent } });

export default function App() {
  useViewportHeight();

  return (
    <ErrorToaster>
      <FeatureFlags>
        <RootEventListener>
          <ApiSession>
            {(apiSession) => {
              if (apiSession.authenticated) {
                return <CompanyDependentRoutes apiSession={apiSession} />;
              } else {
                return (
                  <Routes routes={makeUnauthenticatedRoutes(apiSession)} />
                );
              }
            }}
          </ApiSession>
        </RootEventListener>
      </FeatureFlags>
      <HoverLayer />
    </ErrorToaster>
  );
}
