import styles from './spacing.module.scss';

export const getSpacing = (spacing?: Spacing): string => {
  switch (spacing) {
    case 'l':
      return styles.spaceLarge as string;
    case 'm':
      return styles.spaceMedium as string;
    case 's':
      return styles.spaceSmall as string;
    default:
      return '';
  }
};
export type Spacing = 'l' | 'm' | 's';

/**
 * Adds spacing to blocks based on neighbors
 * Note: As implemented, this doesn't account for the outer element of lists
 * @param renderNodes
 */
const addSpacing = <Item extends { data: { block: { type: string } } }>(
  renderNodes: Array<Item>,
): Array<Item & { spacing?: Spacing }> => {
  return renderNodes.map((renderNode, i) => {
    const prevNode = renderNodes[i - 1];
    const nextNode = renderNodes[i + 1];
    const thisBlock = renderNode.data.block;
    const prevBlock = prevNode?.data?.block;
    const nextBlock = nextNode?.data?.block;

    if (i === 0 || !prevBlock) {
      return renderNode;
    } else if (
      thisBlock.type === 'bulleted-list-item' ||
      thisBlock.type === 'numbered-list-item'
    ) {
      if (prevBlock.type === thisBlock.type) {
        return { ...renderNode, spacing: 's' };
      }

      return renderNode;
    } else if (prevBlock.type === 'heading') {
      return { ...renderNode, spacing: 's' };
    } else if (prevBlock.type === 'label') {
      return { ...renderNode, spacing: 's' };
    } else if (thisBlock.type === 'heading') {
      return { ...renderNode, spacing: 'l' };
    } else if (thisBlock.type === 'label') {
      if (nextBlock && nextBlock.type === 'heading') {
        return { ...renderNode, spacing: 'l' };
      }
      return { ...renderNode, spacing: 'm' };
    } else {
      return { ...renderNode, spacing: 'm' };
    }
  });
};

export default addSpacing;
