import { Signal as BaseSignal } from '@preact/signals-core';
import { useEffect, useState } from 'react';

/**
 * Signals are containers for values that can be passed to different components
 * to enable coordination between them without the needless re-rendering of the
 * nearest common ancestor.
 */
export class Signal<Value> {
  /* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */

  #signal: BaseSignal<Value>;

  constructor(value: Value) {
    this.#signal = new BaseSignal(value);
  }

  /**
   * Read the value of this Signal from within a React component and re-render
   * that component whenever the value changes.
   *
   * This should only be called once per component.
   */
  useValue(): Value {
    const [value, setValue] = useState<Value>(() => this.#signal.peek());
    useEffect(() => this.#signal.subscribe(setValue), [this.#signal]);

    return value;
  }

  /**
   * Read the value of this Signal.
   */
  get(): Value {
    return this.#signal.peek();
  }

  /**
   * Set the value of this Signal (from anywhere) and cause all subscribed React
   * components to re-render with the updated value.
   */
  set(value: Value) {
    this.#signal.value = value;
  }
}
