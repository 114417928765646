import { getTextFromBlock, isTextBlock } from 'editor-content/Block.js';
import { getTextFromNodes } from 'editor-content/TextNode.js';
import { Section } from '../types.js';

export const WORDS_PER_MINUTE = 275;
const NON_TEXT_BLOCK_READ_TIME_MINUTES = 0.25;

export default function readTimeForSection(
  section: Pick<Section, 'title' | 'headline' | 'body'>,
) {
  return Math.ceil(
    readTimeForString(section.title) +
      readTimeForString(getTextFromNodes(section.headline)) +
      section.body.reduce(
        (sum, block) =>
          sum +
          (isTextBlock(block)
            ? readTimeForString(getTextFromBlock(block))
            : NON_TEXT_BLOCK_READ_TIME_MINUTES),
        0,
      ),
  );
}

function readTimeForString(str: string) {
  return (
    str.split(/\s/).filter((substr) => substr.length > 0).length /
    WORDS_PER_MINUTE
  );
}
