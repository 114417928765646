import cx from 'classnames';
import React from 'react';
import IconButton from '../../../../design-system/atoms/IconButton.js';
import CompanyName from '../../CompanyName.js';
import styles from './CompanyMenuButton.module.scss';

export const CompanyMenuButton = React.forwardRef<
  HTMLLabelElement,
  {
    className?: string;
    companyName: string;
    onClickDropdown?: () => void;
  }
>(function CompanyMenuButton(
  { className, companyName, onClickDropdown },
  forwardedRef,
) {
  return (
    <label
      ref={forwardedRef}
      className={cx(styles.companyMenuButton, className)}
    >
      <CompanyName companyName={companyName} />
      {onClickDropdown && (
        <IconButton
          name="chevronDown"
          data-testid="companyChevron"
          aria-label="show other companies"
          onClick={onClickDropdown}
        />
      )}
    </label>
  );
});
