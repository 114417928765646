import { useMemo } from 'react';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import { EditorContent } from '../edit/useEditorState.js';
import { adaptZeckEditorObject } from './adaptZeckEditorActionToReactState.js';
import EditorData from './EditorData.js';
import ZeckEditor from './ZeckEditor/ZeckEditor.js';
import ZeckEditorSelection, {
  getBodySelection,
} from './ZeckEditorSelection.js';

const useZeckEditor = (
  state: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  },
  onChange: (state: {
    content: EditorContent;
    selection: ZeckEditorSelection;
  }) => void,
) => {
  const { content, selection } = state;

  const bodyContent = content.body;

  const adaptZeckEditor = useMemo(
    () => adaptZeckEditorObject(state, onChange),
    [state, onChange],
  );

  return {
    ...adaptZeckEditor(ZeckEditor),
    copy: (): EditorData<HydratedBlock> | void =>
      ZeckEditor.copy(content, selection),
    bodySelection: getBodySelection(selection),
    bodyContent,
  };
};

// HEY YOU
// don't put logic in here, this layer exists to adapt functional zeck editor actions
// to a stateful react world

export default useZeckEditor;
