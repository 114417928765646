import React from 'react';
// It's a paragraph block
// Manages the content
// Manages the selection

import { ParagraphBlock, updateTextBlock } from 'editor-content/Block.js';

import mergeRefs from '../../../junkDrawer/mergeRefs.ts';

import cx from 'classnames';
import Paragraph from '../../../design-system/zeck/Paragraph.tsx';
import styles from '../../../pages/zeck/editor/editableBlocks/SelectableBlock.module.scss';
import ContentSelection from '../../selection/contentSelection/ContentSelection.ts';
import useMeetingMinutesContentEditable from '../../useMeetingMinutesContentEditable.ts';

type ParagraphEditableProps = {
  value: ParagraphBlock;
  onChange: (newBlock: ParagraphBlock) => void;
  selection: ContentSelection | null;
  onSelect: (newSelection: ContentSelection) => void;
  isInBlockSelection: boolean;
  className?: string;
};

export default React.forwardRef<HTMLDivElement, ParagraphEditableProps>(
  function ParagraphEditable(
    { value, onChange, selection, onSelect, className, isInBlockSelection },
    forwardedRef,
  ) {
    const contentEditableProps = useMeetingMinutesContentEditable(
      {
        content: value.content,
        selection,
      },
      ({ content, selection }) => {
        onSelect(selection);
        onChange(updateTextBlock(value, content));
      },
      onSelect,
      { linkables: [] },
    );
    return (
      <Paragraph
        {...{
          ...contentEditableProps,
          className: cx(
            className,
            styles.selectableBlock,
            isInBlockSelection && styles.selectableBlock_selected,
          ),
          'data-testid': 'paragraph-block',
          ref: mergeRefs([contentEditableProps.ref, forwardedRef]),
        }}
      />
    );
  },
);
