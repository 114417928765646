import { TextBlock } from 'editor-content/Block.js';
import { TableBlock } from 'editor-content/TableBlock.js';
import { atom } from 'jotai';
import { PrimitiveAtom } from 'jotai/vanilla/atom';
import { match, P } from 'ts-pattern';
import { isCollapsed } from '../../../../../../../editor/selection/contentSelection/ContentSelection.js';
import {
  PublishedCommentSelectionRegion,
  PublishedSelectionCommentsUIState,
  SelectionCommentsBlockFeatureAtom,
} from './SelectionComments.js';

export function createSelectionCommentsBlockFeatureAtom(
  block: TextBlock | TableBlock,
  uiStateAtom: PrimitiveAtom<PublishedSelectionCommentsUIState>,
): SelectionCommentsBlockFeatureAtom {
  const blockId = block.id;

  const thisBlockSelectionAtom = atom((get) => {
    const uiState = get(uiStateAtom);

    switch (uiState.type) {
      case 'not-selected':
        return null;
      case 'selected':
        return uiState.currentSelection.blockId === blockId
          ? uiState.currentSelection.selection
          : null;
      case 'form-open':
        return uiState.targetSelection.blockId === blockId
          ? uiState.targetSelection.selection
          : null;
    }
  });

  return atom(() => ({
    shouldShowToolbar: atom((get) => {
      const uiState = get(uiStateAtom);
      return (
        uiState.type === 'selected' &&
        uiState.currentSelection.blockId === blockId
      );
    }),
    shouldShowForm: atom((get) => {
      const uiState = get(uiStateAtom);
      return (
        uiState.type === 'form-open' &&
        uiState.targetSelection.blockId === blockId
      );
    }),
    selection: thisBlockSelectionAtom,

    changeSelection: atom(
      null,
      (get, set, newSelection: PublishedCommentSelectionRegion | null) => {
        set(uiStateAtom, (uiState) => {
          switch (uiState.type) {
            case 'not-selected':
            case 'selected': {
              return match(newSelection)
                .with(null, (): PublishedSelectionCommentsUIState => {
                  if (get(thisBlockSelectionAtom) !== null) {
                    return { type: 'not-selected' };
                  }

                  return uiState;
                })
                .with(
                  {
                    anchorOffset: P.number,
                    focusOffset: P.number,
                  },
                  (selection): PublishedSelectionCommentsUIState => {
                    if (isCollapsed(selection)) return { type: 'not-selected' };

                    return {
                      type: 'selected',
                      currentSelection: {
                        blockId,
                        selection,
                      },
                    };
                  },
                )
                .with(
                  {
                    rowIndex: P.number,
                    columnIndex: P.number,
                  },
                  (selection) => ({
                    type: 'selected' as const,
                    currentSelection: { blockId, selection },
                  }),
                )
                .exhaustive();
            }
            case 'form-open':
              return uiState;
          }
        });
      },
    ),
    openCommentForm: atom(null, (_get, set) => {
      set(uiStateAtom, (uiState) => {
        switch (uiState.type) {
          case 'not-selected':
          case 'form-open':
            return uiState;
          case 'selected':
            return {
              type: 'form-open',
              targetSelection: uiState.currentSelection,
            };
        }
      });
    }),
    closeCommentForm: atom(null, (_get, set) => {
      set(uiStateAtom, (uiState) => {
        switch (uiState.type) {
          case 'not-selected':
          case 'selected':
            return uiState;
          case 'form-open':
            return { type: 'not-selected' };
        }
      });
    }),
  }));
}
