import cx from 'classnames';
import { ImageBlock } from 'editor-content/Block.js';
import { useCallback, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import isTouchDeviceFlow from '../../services/isTouchDeviceFlow.ts';
import IconButton from '../atoms/IconButton.tsx';
import styles from './ImageModal.module.scss';

import QuickPinchZoomDefault, {
  make3dTransformValue,
} from 'react-quick-pinch-zoom';

// Workaround for incorrect types in library
const QuickPinchZoom: typeof QuickPinchZoomDefault.default =
  QuickPinchZoomDefault as unknown as typeof QuickPinchZoomDefault.default;

type ImageModalProps = {
  className?: string;
  imageBlock: ImageBlock;
  src: string;
  isOpen: boolean;
  onRequestClose(): void;
};

const ImageModal = ({
  src,
  imageBlock,
  isOpen,
  onRequestClose,
  className,
}: ImageModalProps) => {
  const isTouch = isTouchDeviceFlow();
  const element = useRef<HTMLImageElement>(null);

  const onPanOrZoom = useCallback(
    ({ x, y, scale }: { x: number; y: number; scale: number }) => {
      if (element.current) {
        element.current.style.setProperty(
          'transform',
          make3dTransformValue({ x, y, scale }),
        );
      }
    },
    [],
  );

  useEffect(() => {
    if (isTouch) {
      // Reset viewport zoom so it doesn't conflict without
      // our JavaScript solution for zooming the image
      const viewport: HTMLMetaElement | null = document.querySelector(
        'meta[name="viewport"]',
      );
      if (!viewport) {
        return;
      }
      viewport.content = 'initial-scale=1';
      viewport.content = 'width=device-width';
    }
  });

  return (
    <ReactModal
      {...{
        isOpen,
        onRequestClose,
        testId: 'modal',
        ariaHideApp: false,
        closeTimeoutMS: !import.meta.env.TEST ? 200 : undefined,
        className: {
          base: cx(
            styles.imageModal,
            !isTouch && styles.imageModal_notTouch,
            className,
          ),
          afterOpen: styles.imageModal_afterOpen,
          beforeClose: styles.imageModal_beforeClose,
        } as ReactModal.Classes,
        overlayClassName: {
          base: styles.imageModal__overlay,
          afterOpen: styles.imageModal__overlay_afterOpen,
          beforeClose: styles.imageModal__overlay_beforeClose,
        } as ReactModal.Classes,
        overlayElement: (props, children) => (
          <div
            {...props}
            {...{
              ...(!isTouch && {
                onClick: onRequestClose,
                onScroll: onRequestClose,
                onWheel: onRequestClose,
                onKeyDown: onRequestClose,
              }),
            }}
          >
            {children}
          </div>
        ),
      }}
    >
      <IconButton
        className={styles.imageModal__closeButton}
        name="shrink"
        aria-label="close image"
        onClick={onRequestClose}
      />
      {isTouch ? (
        <QuickPinchZoom
          containerProps={{ className: styles.imageModal__pinchZoom }}
          onUpdate={onPanOrZoom}
        >
          <div className={styles.imageModal__imageContainer} ref={element}>
            <img
              className={cx(
                styles.imageModal__image,
                imageBlock.caption.length === 0 &&
                  styles.imageModal__image_withoutCaption,
              )}
              data-testid="full-screen-image"
              src={src}
              alt=""
            />
          </div>
        </QuickPinchZoom>
      ) : (
        <img
          className={cx(
            styles.imageModal__image,
            imageBlock.caption.length === 0 &&
              styles.imageModal__image_withoutCaption,
          )}
          data-testid="full-screen-image"
          src={src}
          alt=""
        />
      )}
      {imageBlock.caption.length > 0 && (
        <div className={cx(styles.imageModal__caption)}>
          {imageBlock.caption.length > 500
            ? imageBlock.caption.slice(0, 500) + '...'
            : imageBlock.caption}
        </div>
      )}
    </ReactModal>
  );
};

export default ImageModal;
