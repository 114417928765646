import meetingMinutesSignatureConsent from 'editor-content/MeetingMinutes/meetingMinutesSignatureConsent.js';
import { Icon } from 'icons';
import { useState } from 'react';
import {
  MeetingMinutes,
  MinutesSignature,
} from '../../../../api/endpoints/createMeetingMinutesApi.ts';
import Button from '../../../../design-system/atoms/Button.tsx';
import InputCheckbox from '../../../../design-system/atoms/InputCheckbox.tsx';
import OutlineButton from '../../../../design-system/atoms/OutlineButton.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../../design-system/organisms/Modal.tsx';
import styles from '../../../../meetingMinutes/MeetingMinutesSignature.module.scss';
import MeetingMinutesSignature from '../../../../meetingMinutes/MeetingMinutesSignature.tsx';
import { User } from '../../../../types.ts';
import { isSignedMinutes } from '../../MeetingMinutes.ts';

const SignatureModal = ({
  isOpen,
  onClose,
  onSignMeetingMinutes,
  user,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSignMeetingMinutes: (
    signatureName: string,
    signatureTitle: string | null,
  ) => Promise<void>;
  user: User;
}) => {
  const [isSignatureChecked, setIsSignatureChecked] = useState(false);
  const [userName, setUserName] = useState(
    `${user.firstName} ${user.lastName}`,
  );

  const [title, setTitle] = useState<string | undefined>(undefined);

  return (
    <Modal isOpen={isOpen} className={styles.meetingMinutesSignature__modal}>
      <ModalHeader
        onClickClose={onClose}
        className={styles.meetingMinutesSignature__modalHeader}
      >
        <div className={styles.meetingMinutesSignature__row}>
          <div>
            <Icon
              name="signature"
              className={styles.meetingMinutesSignature__icon}
            />
          </div>
          Signature Consent
        </div>
      </ModalHeader>

      <ModalBody>
        <div className={styles.meetingMinutesSignature__signatureWrapper}>
          <input
            autoFocus={true}
            className={styles.meetingMinutesSignature__signatureInput}
            type="text"
            data-testid="meeting-minutes-signature-input"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />

          <div className={styles.meetingMinutesSignature__row}>
            <input
              placeholder="Title, ex. Board Secretary"
              className={styles.meetingMinutesSignature__titleInput}
              type="text"
              data-testid="meeting-minutes-signature-title"
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.meetingMinutesSignature__row}>
          <div>
            <InputCheckbox
              label=""
              value={isSignatureChecked}
              onChange={() => setIsSignatureChecked(!isSignatureChecked)}
            />
          </div>
          <div
            className={styles.meetingMinutesSignature__text}
            data-testid="signature-modal-consent"
            dangerouslySetInnerHTML={{ __html: meetingMinutesSignatureConsent }}
          />
        </div>
      </ModalBody>

      <ModalActions className={styles.meetingMinutesSignature__modalActions}>
        <OutlineButton
          onClick={onClose}
          size="medium"
          color="secondary"
          data-testid="signature-modal-cancel-button"
        >
          Cancel
        </OutlineButton>
        <Button
          disabled={!isSignatureChecked || !userName.length}
          color="primary"
          size="medium"
          onClick={() =>
            isSignatureChecked && onSignMeetingMinutes(userName, title ?? null)
          }
          data-testid="signature-modal-sign-button"
        >
          Sign
        </Button>
      </ModalActions>
    </Modal>
  );
};

const MeetingMinutesSignatureFlow = ({
  meetingMinutes,
  signMeetingMinutes,
  user,
}: {
  meetingMinutes: MeetingMinutes;
  signMeetingMinutes: (
    meetingMinutes: Pick<MeetingMinutes, 'id' | 'version'>,
    signature: MinutesSignature,
  ) => Promise<void>;
  user: User;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSignMeetingMinutes = async (name: string, title: string | null) => {
    await signMeetingMinutes(
      {
        id: meetingMinutes.id,
        version: meetingMinutes.version,
      },
      {
        consentText: meetingMinutesSignatureConsent,
        name,
        title,
      },
    );

    setIsOpen(false);
  };

  if (isSignedMinutes(meetingMinutes)) {
    return <MeetingMinutesSignature signature={meetingMinutes.signatures[0]} />;
  }

  return (
    <div
      className={styles.minutesPage__signature}
      data-testid="meetingMinutes__signatureWrapper"
    >
      <OutlineButton
        size="large"
        color="primary"
        onClick={() => setIsOpen(true)}
        className={styles.meetingMinutesSignature__button}
      >
        <Icon name="signature" />
        Add Signature
      </OutlineButton>

      <SignatureModal
        isOpen={isOpen}
        user={user}
        onClose={() => setIsOpen(false)}
        onSignMeetingMinutes={onSignMeetingMinutes}
      />
    </div>
  );
};

export default MeetingMinutesSignatureFlow;
