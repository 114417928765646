import { useEffect, useState } from 'react';
import ThreeDotLoading from '../../../../../design-system/molecules/ThreeDotLoading.tsx';
import {
  ModalBody,
  ModalPanel,
} from '../../../../../design-system/organisms/Modal.tsx';
import styles from './AiLoadingModal.module.scss';
import AiModalHeader from './AiModalHeader.tsx';
import trivia, { Trivia } from './trivia.ts';

const QUESTION_DURATION = 7000;
const ANSWER_DURATION = 2500;
type AiLoadingModalProps = {
  shuffleTrivia?: boolean;
};

const AiLoadingModal: React.FC<AiLoadingModalProps> = ({ shuffleTrivia }) => {
  const [shuffledTrivia] = useState<Trivia[]>(
    shuffleTrivia === false
      ? trivia
      : [...trivia.sort(() => Math.random() - 0.5)],
  );

  const [triviaIndex, setTriviaIndex] = useState(-1);
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    const nextTrivaIndex = () => {
      setTriviaIndex((old) => (old + 1) % trivia.length);
    };

    const cycleTrivia = () => {
      setShowAnswer(false);

      setTimeout(() => {
        setShowAnswer(true);
      }, QUESTION_DURATION);

      nextTrivaIndex();
    };

    cycleTrivia();

    const interval = setInterval(
      cycleTrivia,
      QUESTION_DURATION + ANSWER_DURATION,
    );

    return () => clearInterval(interval);
  }, []);

  const quote = showAnswer
    ? shuffledTrivia[triviaIndex]?.answer
    : shuffledTrivia[triviaIndex]?.question;

  return (
    <ModalPanel className={styles.modalPanel}>
      <AiModalHeader />
      <ModalBody className={styles.modalBody}>
        <div className={styles.bodyHeader}>Zeck AI is writing.</div>
        <ThreeDotLoading className={styles.loadingIcon} />
        <div className={styles.quote}>{quote}</div>
      </ModalBody>
    </ModalPanel>
  );
};

export default AiLoadingModal;
