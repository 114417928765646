import {
  CommentContent,
  CommentContentNode,
  CommentTagNode,
  commentTextNode,
  isCommentTagNode,
} from 'editor-content/CommentContent.js';
import ContentSelection, {
  contentSelection,
} from '../editor/selection/contentSelection/ContentSelection.js';
import assertUnreachable from '../junkDrawer/assertUnreachable.js';
import splitCommentContentNodes from './splitCommentContentNodes.js';

const getNodeSelectionLength = (node: CommentContentNode): number => {
  switch (node.type) {
    case 'text':
      return node.text.length;
    case 'tag':
      return 1;
  }
};

const getNodesSelectionLength = (nodes: CommentContentNode[]): number =>
  nodes.reduce((acc, node) => acc + getNodeSelectionLength(node), 0);

export type SelectionCommentEditorState = {
  content: CommentContentNode[];
  selection: ContentSelection | null;
};

type SelectedText = {
  text: string;
  selection: ContentSelection;
};

function canFirstNodeInsertTag(
  nodes: CommentContentNode[],
  selectionIndex: number,
): SelectedText | null {
  if (nodes.length === 0) {
    return null;
  }

  const [contentNode, ...rest] = nodes;
  if (!contentNode) {
    return null;
  }

  const recurse = (advanceBy: number) => {
    const result = canFirstNodeInsertTag(rest, selectionIndex - advanceBy);
    if (result) {
      return {
        ...result,
        selection: {
          anchorOffset: result.selection.anchorOffset + advanceBy,
          focusOffset: result.selection.focusOffset + advanceBy,
        },
      };
    }
    return null;
  };

  const nodeLength = getNodeSelectionLength(contentNode);
  const isSelectionPastNode = selectionIndex > nodeLength;

  if (isSelectionPastNode) {
    return recurse(nodeLength);
  }

  if (contentNode.type === 'tag') {
    return null;
  }

  const inputString = contentNode.text.slice(0, selectionIndex);
  const regex = /@([A-Z ]*)$/i;
  const match = regex.exec(inputString);
  if (match && typeof match[1] == 'string') {
    return {
      text: match[1],
      selection: {
        anchorOffset: match.index,
        focusOffset: selectionIndex,
      },
    };
  }

  return null;
}

function getPossibleTagMatch(
  state: SelectionCommentEditorState,
): SelectedText | null {
  if (!state.selection) {
    return null;
  }
  if (state.selection.anchorOffset !== state.selection.focusOffset) {
    return null;
  }
  return canFirstNodeInsertTag(state.content, state.selection.anchorOffset);
}

function insertTag(
  state: SelectionCommentEditorState,
  tag: CommentTagNode,
): SelectionCommentEditorState {
  if (!state.selection) return state;

  const insertTagSelectedText = getPossibleTagMatch(state);
  if (!insertTagSelectedText) {
    return state;
  }
  const { selection: insertTagSelection } = insertTagSelectedText;

  const [before, rest] = splitCommentContentNodes(
    state.content,
    insertTagSelection.anchorOffset,
  );
  const offset =
    insertTagSelection.focusOffset - insertTagSelection.anchorOffset;
  const [, after] = splitCommentContentNodes(rest, offset);

  const content = [...before, tag, commentTextNode(' ')];

  return {
    content: [...content, ...after],
    selection: contentSelection(getNodesSelectionLength(content)),
  };
}

function canSubmit(state: SelectionCommentEditorState): boolean {
  return state.content.some((node) => {
    switch (node.type) {
      case 'tag':
        return true;
      case 'text':
        return node.text.length > 0;
    }

    return assertUnreachable(node);
  });
}

function getInitialAutofocusSelection(
  content: CommentContent,
): ContentSelection {
  return contentSelection(getNodesSelectionLength(content));
}

function getTaggedUserIds(content: CommentContent): string[] {
  return content.filter(isCommentTagNode).map((c) => c.userId);
}

const CommentEditor = {
  getPossibleTagMatch,
  getTaggedUserIds,
  insertTag,
  canSubmit,
  getInitialAutofocusSelection,
};

export default CommentEditor;
