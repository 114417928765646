import cx from 'classnames';
import React from 'react';
import styles from '../zeck/Image.module.scss';

type ImageCaptionProps = {
  className?: string;
  children?: React.ReactNode;
} & React.ComponentProps<'div'>;

const ImageCaption = React.forwardRef<HTMLDivElement, ImageCaptionProps>(
  function ImageCaption({ className, ...otherProps }, forwardedRef) {
    return (
      <div
        data-testid="image-caption"
        ref={forwardedRef}
        className={cx(styles.image__caption, className)}
        {...otherProps}
      />
    );
  },
);

export default ImageCaption;
