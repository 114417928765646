import { TextNode } from 'editor-content/TextNode.js';
import Linkable from 'editor-content/html/Linkable.js';
import React, { useEffect } from 'react';
import BlockEditorAdapter from '../../editor/BlockEditorAdapter.js';

export function useRenderTextNodesAsHTMLString(
  textNodes: TextNode[],
  linkables: Linkable[],
  renderElRef: React.RefObject<HTMLElement>,
) {
  useEffect(() => {
    const el = renderElRef.current;

    if (el) {
      const newHTML = BlockEditorAdapter.toHTMLString(textNodes, {
        linkables,
      });
      if (el.innerHTML !== newHTML) {
        el.innerHTML = newHTML;
      }
    }
  }, [linkables, renderElRef, textNodes]);
}
