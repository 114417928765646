import { ElementAndData } from '../../../../junkDrawer/useElementAndDataArray.js';
import getSelectionRangeSafely from '../getSelectionRangeSafely.js';
import { isOnLastLine } from './isOnLastLine.js';

export const isOnLastLineOfBlock =
  <BlockEditor>(blockEditorsWithEl: ElementAndData<BlockEditor>[]) =>
  (blockIndex: number) => {
    const selectedBlockEditorWithEl = blockEditorsWithEl[blockIndex];
    const selectionRange = getSelectionRangeSafely();

    if (!(selectionRange && selectedBlockEditorWithEl)) return false;

    const blockEl = selectedBlockEditorWithEl.getEl();
    if (!blockEl) return false;

    return isOnLastLine(blockEl, selectionRange);
  };
