import { FormEventHandler, useState } from 'react';
import Button from '../../design-system/atoms/Button.tsx';
import InputText from '../../design-system/atoms/InputText.tsx';
import { useDocumentTitle } from '../../junkDrawer/useDocumentTitleFromResult.ts';
import usePageTracking from '../../services/usePageTracking.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import ManagementPage from '../ManagementPage.tsx';
import PageLayoutFetchesFolders from '../PageLayoutFetchesFolders.tsx';
import styles from './CompanySettingsPage.module.scss';

type CompanySettingsPageProps = {
  userAndCompany: UserAndCompany;
  onClickLogout: () => void;
};

const CompanySettingsPage: React.VFC<CompanySettingsPageProps> = ({
  userAndCompany,
  onClickLogout,
}) => {
  useDocumentTitle('Company Settings');
  usePageTracking('company_settings', userAndCompany);

  const company = userAndCompany.activeCompany;

  const [newCompanyName, setNewCompanyName] = useState(company.name);

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    userAndCompany.updateActiveCompany({
      ...company,
      name: newCompanyName,
    });
  };

  const disabled =
    company.name === newCompanyName || newCompanyName.length === 0;
  return (
    <PageLayoutFetchesFolders
      title="Company Settings"
      userAndCompany={userAndCompany}
      onClickLogout={onClickLogout}
      activePage={'home'}
    >
      <ManagementPage title={'Company Settings'}>
        <form onSubmit={onSubmit} className={styles.companySettingsPage__form}>
          <div className={styles.companySettingsPage__description}>
            <h2 className={styles.companySettingsPage__sectionHeading}>
              Company Name
            </h2>
            <p className={styles.companySettingsPage__sectionSubtitle}>
              Set what company name your contibutors and viewers will see.
            </p>
          </div>
          <InputText
            label={'Company Name'}
            value={newCompanyName}
            onChange={setNewCompanyName}
          />
          <Button size={'medium'} color={'primary'} disabled={disabled}>
            Update
          </Button>
        </form>
      </ManagementPage>
    </PageLayoutFetchesFolders>
  );
};

export default CompanySettingsPage;
