import ReactModal from 'react-modal';
import { match } from 'ts-pattern';
import FileInput from '../../../../design-system/atoms/FileInput.tsx';
import Modal from '../../../../design-system/organisms/Modal.tsx';
import useImageUpload, {
  IMAGE_FILE_TYPES,
} from '../../../../services/imageUpload.ts';
import { useActiveCompany } from '../../../../userAndCompany/activeCompanyAtom.js';
import styles from './ImageUploadModal.module.scss';

type ImageUploadModalProps = {
  instructions?: string;
  onUploadSuccess(image: {
    imageId: string;
    width: number;
    height: number;
  }): void;
  onClose(): void;
  isOpen: boolean;
  showToast: (message: string, duration?: number) => void;
};

const ImageUploadModal = ({
  instructions,
  onUploadSuccess,
  onClose,
  isOpen,
  showToast,
  ...otherProps
}: ImageUploadModalProps &
  Omit<ReactModal.Props, 'isOpen' | 'overlayClassName'>) => {
  const uploadImage = useImageUpload();
  const activeCompany = useActiveCompany();

  return (
    <Modal
      {...{
        ...otherProps,
        isOpen,
        dark: true,
        className: styles.imageUploadModal,
        testId: 'image-upload-modal',
        onRequestClose: onClose,
      }}
    >
      {instructions && (
        <div className={styles.imageUploadModal__instructions}>
          {instructions}
        </div>
      )}
      <FileInput
        onChange={async (e) => {
          const file = e.currentTarget.files && e.currentTarget.files[0];

          if (!file) return;

          const result = await uploadImage(file, activeCompany.id);
          match(result)
            .with({ type: 'success' }, (result) => onUploadSuccess(result.data))
            .with({ type: 'error' }, (result) => {
              showToast(result.message, 5000);
              onClose();
            });
        }}
        accept={IMAGE_FILE_TYPES.join(',')}
      >
        Choose Image
      </FileInput>
    </Modal>
  );
};

export default ImageUploadModal;
