import { z } from 'zod';
import { FileBlockSchema } from './FileBlock.js';

export enum VoteType {
  None = 'none',
  Other = 'other',
  MeetingMinutesApproval = 'meeting_minutes_approval',
  StockOptionApproval = 'stack_option_approval',
  ValuationApproval = '409a_valuation_approval',
}

export const VoteTypeSchema = z.nativeEnum(VoteType);

export const VoteBlockSchema = z.object({
  id: z.string().uuid(),
  type: z.literal('vote'),
  title: z.string(),
  details: z.string(),
  supportingDocuments: z.array(FileBlockSchema),
  voteType: VoteTypeSchema,
  prevoteEnabled: z.boolean(),
});
