import cx from 'classnames';
import React from 'react';
import Button from '../atoms/Button.tsx';
import styles from './LoadingButton.module.scss';

export const ButtonSizedLoader: React.FC<React.ComponentProps<'div'>> = (
  props,
) => (
  <div {...props} data-testid="spinner" className={cx(styles.spinner)}>
    <div />
    <div />
    <div />
  </div>
);

type LoadingButtonProps = {
  className?: string;
  size?: React.ComponentProps<typeof Button>['size'];
  color?: React.ComponentProps<typeof Button>['color'];
};

const LoadingButton = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (
    { className, size = 'medium', color = 'primary', ...props },
    forwardedRef,
  ) => {
    return (
      <Button
        {...props}
        {...{
          ref: forwardedRef,
          className: cx(className, styles.loadingButton),
        }}
        size={size}
        color={color}
        disabled
      >
        <ButtonSizedLoader />
      </Button>
    );
  },
);

export default LoadingButton;
