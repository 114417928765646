import {
  AgendaBlock,
  AgendaItem,
  AgendaItemCustom,
  AgendaItemSection,
  changeAgendaBlockItems,
} from 'editor-content/AgendaBlock.js';
import Linkable from 'editor-content/html/Linkable.ts';
import React, { useState } from 'react';
import DateTimeInput from '../../../../dateTime/DateTimeInput.tsx';
import BlackBox from '../../../../design-system/atoms/BlackBox.tsx';
import ButtonWithIcon from '../../../../design-system/atoms/ButtonWithIcon.tsx';
import {
  SectionList,
  SectionListItem,
} from '../../../../design-system/organisms/SectionList.tsx';
import Tabs from '../../../../design-system/organisms/Tabs.tsx';
import {
  AgendaContainer,
  AgendaDescription,
  AgendaPanel,
  AgendaTitle,
} from '../../../../design-system/zeck/Agenda.tsx';
import { ReactComponent as PlusSVG } from '../../../../images/AddAgendaItemPlus.svg';
import changeItemAt from '../../../../junkDrawer/changeItemAt.ts';
import DetectsOutsideClick from '../../../../junkDrawer/DetectsOutsideClick.tsx';
import useSortableWithEls from '../../../../junkDrawer/sortable/useSortableWithEls.ts';
import usePlaintextEditable from '../usePlaintextEditable.ts';
import styles from './AgendaEditable.module.scss';
import AgendaItemEditable from './AgendaItemEditable.tsx';
import keyDownHandlerLetsInputsWork from './keyDownHandlerLetsInputsWork.ts';
import placeholderStyles from './Placeholder.module.scss';

type AgendaEditableProps = {
  value: AgendaBlock;
  onChange: (v: AgendaBlock) => void;
  linkables: Linkable[];
};

const AgendaEditable = React.forwardRef<HTMLDivElement, AgendaEditableProps>(
  function AgendaEditable({ value, onChange, linkables }, forwardedRef) {
    const [isAdding, setIsAdding] = useState(false);
    const [formState, setFormState] = useState('');

    const itemsSorted = useSortableWithEls(
      value.items.map((item, i) => {
        // we set key here so that it remains stable with reorders
        // we should probably use a library instead
        const key = item.type === 'custom' ? item.name : item.sectionId;
        return {
          ...item,
          key: `${key}-${i}`,
        };
      }),
      (newItems) => {
        onChange({
          ...value,
          items: newItems.map(({ key: _key, ...item }): AgendaItem => item),
        });
      },
    );

    const onChangeItemAt = (i: number) => (newItem: AgendaItem) => {
      onChange(
        changeAgendaBlockItems(
          value,
          value.items.map((item, index) => (index === i ? newItem : item)),
        ),
      );
    };

    const titleEditableProps = usePlaintextEditable<HTMLHeadingElement>(
      value.title,
      (s) =>
        onChange({
          ...value,
          title: s,
        }),
    );

    const descriptionEditableProps = usePlaintextEditable<HTMLParagraphElement>(
      value.description,
      (s) =>
        onChange({
          ...value,
          description: s,
        }),
    );

    const deleteItemAt = (i: number) => {
      onChange(
        changeAgendaBlockItems(
          value,
          value.items.filter((_, index) => index !== i),
        ),
      );
    };

    return (
      <AgendaContainer
        ref={forwardedRef}
        onKeyDown={keyDownHandlerLetsInputsWork}
      >
        <AgendaTitle
          {...titleEditableProps}
          {...{
            placeholder: 'Agenda Title',
            className: placeholderStyles.placeholder,
          }}
        />

        <DateTimeInput
          label="Set Date and Start Time"
          timestamp={value.startTime}
          timeZone={value.timeZone}
          onChange={(newStartTime) => {
            onChange({
              ...value,
              timeZone: newStartTime.timeZone,
              startTime: newStartTime.timestamp,
            });
          }}
        />

        <AgendaDescription
          {...descriptionEditableProps}
          {...{
            placeholder: 'Add meeting details here.',
            className: placeholderStyles.placeholder,
          }}
        />
        <AgendaPanel isMobile={false} onDragOver={itemsSorted.dragMove}>
          {itemsSorted.items.map(
            ({ data: { key, ...item }, setEl, dragStart }, i) => (
              <AgendaItemEditable
                key={key}
                ref={setEl}
                item={item}
                timeZone={value.timeZone}
                linkables={linkables}
                onChangeItem={onChangeItemAt(i)}
                onChangeStartTime={({ startTime, timeZone }) => {
                  onChange({
                    ...value,
                    timeZone: timeZone,
                    items: changeItemAt(value.items, i, (item) => ({
                      ...item,
                      startTime,
                    })),
                  });
                }}
                onDeleteItem={() => {
                  deleteItemAt(i);
                }}
                draggable
                onDragStart={dragStart}
                onDragEnd={() => {
                  itemsSorted.dragEnd();
                }}
              />
            ),
          )}

          <div className={styles.agendaEditable__addButtonContainer}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsAdding(true);
              }}
              className={styles.agendaEditable__addButton}
            >
              <span className={styles.agendaEditable__addButtonIcon}>
                <PlusSVG />
              </span>
              Add Agenda Item
            </button>
          </div>

          {isAdding && (
            <DetectsOutsideClick onClick={() => setIsAdding(false)}>
              {(ref) => (
                <BlackBox
                  className={styles.agendaEditable__addItemMenu}
                  ref={ref}
                  data-testid="agenda-add-item-menu"
                >
                  <Tabs
                    tabs={[
                      {
                        id: 'section',
                        displayName: 'Section',
                        render: () => (
                          <div>
                            <SectionList>
                              {linkables
                                .filter(
                                  (linkable) => linkable.type === 'section',
                                )
                                .map(({ itemId, title }) => (
                                  <SectionListItem
                                    key={itemId}
                                    onClick={() => {
                                      onChange({
                                        ...value,
                                        items: [
                                          ...value.items,
                                          AgendaItemSection(itemId, ''),
                                        ],
                                      });
                                      setIsAdding(false);
                                    }}
                                  >
                                    {title}
                                  </SectionListItem>
                                ))}
                            </SectionList>
                          </div>
                        ),
                      },
                      {
                        id: 'custom',
                        displayName: 'Custom',
                        render: () => (
                          <form
                            className={styles.agendaEditable__form}
                            onSubmit={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onChange({
                                ...value,
                                items: [
                                  ...value.items,
                                  AgendaItemCustom(formState, ''),
                                ],
                              });
                              setIsAdding(false);
                              setFormState('');
                            }}
                          >
                            <input
                              className={styles.agendaEditable__addItemInput}
                              type="text"
                              placeholder="Custom Item"
                              value={formState}
                              onChange={(e) => {
                                setFormState(e.currentTarget.value);
                              }}
                              onPaste={(e) => {
                                e.stopPropagation();
                              }}
                              onCut={(e) => {
                                e.stopPropagation();
                              }}
                              onKeyDown={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <ButtonWithIcon
                              iconName="keyboardReturn"
                              size="medium"
                              color="primary"
                              type="submit"
                              position="right"
                            >
                              Done
                            </ButtonWithIcon>
                          </form>
                        ),
                      },
                    ]}
                  />
                </BlackBox>
              )}
            </DetectsOutsideClick>
          )}
        </AgendaPanel>
      </AgendaContainer>
    );
  },
);

export default AgendaEditable;
