import {
  contentSelection,
  isCollapsed,
} from '../../../../editor/selection/contentSelection/ContentSelection.js';
import { pipe } from '../../../../result/Result.js';
import { EditorContent } from '../../edit/useEditorState.js';
import BodyEditor from '../BodyEditor/BodyEditor.js';
import HeadlineEditor from '../HeadlineEditor/HeadlineEditor.js';
import ZeckEditorSelection, {
  getHeadlineSelection,
  isBodySelection,
  isHeadlineSelection,
} from '../ZeckEditorSelection.js';
import { applyBodyState } from './applyBodyState.js';
import {
  applyBodyContent,
  applyHeadlineState,
  applyTitleSelection,
  ZeckEditorState,
} from './ZeckEditorState.js';

const pressBackspace = (
  content: EditorContent,
  selection: ZeckEditorSelection,
): ZeckEditorState | void => {
  if (isBodySelection(selection)) {
    const bodyResult = BodyEditor.pressBackspace({
      content: content.body,
      selection: selection,
    });

    if (!bodyResult) return;

    switch (bodyResult.type) {
      case 'change':
        return applyBodyState({ content, selection }, bodyResult);
      case 'merge':
        return pipe(
          { content, selection },
          applyBodyContent(bodyResult.content),
          applyHeadlineState(
            HeadlineEditor.receiveBackspace(
              {
                content: content.headline,
                selection: getHeadlineSelection(selection),
              },
              bodyResult.blockContent,
            ),
          ),
        );
    }
  } else if (isHeadlineSelection(selection)) {
    if (isCollapsed(selection) && selection.anchorOffset === 0) {
      return pipe(
        { content, selection },
        applyTitleSelection(contentSelection(content.title.length)),
      );
    }
  }
  return;
};

export default pressBackspace;
