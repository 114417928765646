import {
  AiChartGenerateDTO,
  isAiChartGenerateErrorResponse,
} from 'api-client/aiContract.ts';
import { ChartBlockDataSchema } from 'blocks/src/ChartBlock.ts';
import { chartJsUnionSchema } from 'editor-content/lib/chartBlockConversions.ts';
import { z } from 'zod';
import { ErrorWithContext, logError } from '../../../logging/logger.js';
import { RequestError } from '../../makeMakeApiRequest.js';
import AiApiClient from '../AiApiClient.js';
import { AiChartGenerateResponse } from '../createAiApi.js';
import { getSleepDuration, MAX_RETRIES, sleep } from './aiRequestRetry.js';

export function createAiChartGenerateApiMethod(
  aiApiClient: typeof AiApiClient,
  accessToken: string | null,
) {
  return async (
    payload: AiChartGenerateDTO,
  ): Promise<AiChartGenerateResponse> => {
    const jobResponse = await aiApiClient.createChartJob({
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
      body: payload,
    });

    switch (jobResponse.status) {
      case 200: {
        if (isAiChartGenerateErrorResponse(jobResponse.body)) {
          return jobResponse.body.errorBody;
        }

        const s3Url = jobResponse.body.jobUrl;

        return await fetchS3JobResponse(s3Url);
      }
      // adapting from ts-rest returning error codes
      // to our code that expects thrown errors
      default: {
        throw new RequestError(
          'aiApiClient.createChartJob',
          '',
          jobResponse.status,
          jobResponse,
        );
      }
    }
  };
}

const s3JobResponseSchema = z.object({
  data: z.object({
    chart: ChartBlockDataSchema,
    // 25-03-31: afaict, lastSchema is not returned, and is not written to s3
    // But it was in the code & types when I wrote the schema, so I will keep it in
    lastSchema: chartJsUnionSchema.optional(),
    pastMessages: z.array(z.string()),
  }),
});

async function fetchS3JobResponse(
  s3Url: string,
): Promise<AiChartGenerateResponse> {
  for (let count = 0; count < MAX_RETRIES; count++) {
    const sleepDuration = getSleepDuration(count);
    await sleep(sleepDuration);
    const response = await fetch(s3Url);
    if (response.status === 404) {
      continue;
    }
    if (!response.ok) {
      logError(
        new RequestError(
          'aiApiClient.createChartJob',
          '',
          response.status,
          response,
        ),
      );
      return { errorType: 'unknown' };
    }
    const data = await response.json();

    const s3JobResponseParse = s3JobResponseSchema.safeParse(data);
    if (!s3JobResponseParse.success) {
      logError(
        new ErrorWithContext(
          'Failed to parse AI Chart Job data from s3',
          s3JobResponseParse.error.format(),
          'AIChartParseError',
        ),
      );
      return { errorType: 'unknown' as const };
    }

    return {
      chart: s3JobResponseParse.data.data.chart,
      lastSchema: s3JobResponseParse.data.data.lastSchema,
      pastMessages: s3JobResponseParse.data.data.pastMessages || [],
    };
  }
  return { errorType: 'timeout' };
}
