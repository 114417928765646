import { z } from 'zod';
import { TextNodeSchema } from './TextNode.js';

const TextBlockContentSchema = z.array(TextNodeSchema);

export const ParagraphBlockSchema = z.object({
  type: z.literal('paragraph'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
});

export const HeadingBlockSchema = z.object({
  type: z.literal('heading'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
});

export const LabelBlockSchema = z.object({
  type: z.literal('label'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
});

export const BulletedListItemBlockSchema = z.object({
  type: z.literal('bulleted-list-item'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
  indent: z.number(),
});

export const NumberedListItemBlockSchema = z.object({
  type: z.literal('numbered-list-item'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
  indent: z.number(),
});

export const TextBlockSchema = z.union([
  ParagraphBlockSchema,
  HeadingBlockSchema,
  LabelBlockSchema,
  BulletedListItemBlockSchema,
  NumberedListItemBlockSchema,
]);
