import { Highlight, TextNode } from 'editor-content/TextNode.js';
import splitTextNodesFromContentSelection from '../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.js';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.js';
import assertUnreachable from '../../../../junkDrawer/assertUnreachable.js';

export default function addHighlightToTextNodes(
  nodes: TextNode[],
  selection: ContentSelection,
  id: string,
): TextNode[] {
  const [beforeSelectionNodes, inSelectionNodes, afterSelectionNodes] =
    splitTextNodesFromContentSelection(nodes, selection);

  let prevHighlightIds: string[] = [];
  const lastBeforeSelectionNode =
    beforeSelectionNodes[beforeSelectionNodes.length - 1];

  if (lastBeforeSelectionNode?.type === 'highlight')
    prevHighlightIds = lastBeforeSelectionNode.ids;

  return [
    ...beforeSelectionNodes,
    ...inSelectionNodes.map((textNode) => {
      switch (textNode.type) {
        case 'highlight': {
          let nodeIds = textNode.ids;
          // bring latest highlight to front if before node is highlighted with a different highlight
          if (textNode.ids.some((id) => prevHighlightIds.includes(id))) {
            nodeIds = [id, ...textNode.ids];
          } else {
            nodeIds = [...textNode.ids, id];
          }
          return Highlight(nodeIds, textNode.content);
        }
        case 'text':
        case 'external-link':
        case 'section-link':
        case 'meeting-minutes-link':
          return Highlight([id], [textNode]);
      }

      return assertUnreachable(textNode);
    }),
    ...afterSelectionNodes,
  ];
}
