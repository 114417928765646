import cx from 'classnames';
import { Icon } from 'icons';
import { useState } from 'react';
import SpeechBubble from '../../../../../design-system/molecules/SpeechBubble.tsx';
import Modal, {
  ModalBody,
  ModalHeader,
} from '../../../../../design-system/organisms/Modal.tsx';
import useBreakpoints from '../../../../../services/useBreakpoints.ts';
import { MaybePendingUser } from '../../../../../types.ts';
import styles from './CommentVisibilityHelp.module.scss';

const getTooltipStringFromViewer = (viewer: MaybePendingUser) => {
  if (viewer.pending) {
    return viewer.email;
  }

  return `${viewer.firstName} ${viewer.lastName[0]}`;
};

type CommentVisibilityHelpProps = {
  viewers: MaybePendingUser[];
};

const WhoCanSeeThisModal: React.FC<
  CommentVisibilityHelpProps & { isOpen: boolean; onClose: () => void }
> = ({ viewers, isOpen, onClose }) => {
  return (
    <Modal className={styles.comments__viewers__modal} isOpen={isOpen}>
      <ModalHeader
        onClickClose={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <div className={styles.comments__viewers__modal__header}>
          <Icon
            name="secure"
            className={cx(
              styles.comments__newComment__who__secureIcon,
              styles.comments__viewers__modal__header__secureIcon,
            )}
          />{' '}
          <span>Who Can See This?</span>{' '}
        </div>
      </ModalHeader>
      <ModalBody>
        <span className={styles.comments__newComment__who__tooltip__names}>
          {viewers.map((u) => getTooltipStringFromViewer(u)).join(', ')}
        </span>{' '}
        <span>will see your comment.</span>
      </ModalBody>
    </Modal>
  );
};

const CommentVisibilityHelpMobile: React.FC<CommentVisibilityHelpProps> = ({
  viewers,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      data-testid="comment-visibility-help"
      className={styles.comments__newComment__who}
      onClick={() => setIsModalOpen(true)}
    >
      <WhoCanSeeThisModal
        viewers={viewers}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <span className={styles.comments__newComment__who__description}>
        Who Can See This?
      </span>{' '}
      <span className={styles.comments__newComment__who__people}>
        {viewers.length} People
      </span>{' '}
      <Icon
        name="help"
        className={styles.comments__newComment__who__helpIcon}
      />
    </div>
  );
};

const CommentVisibilityHelpDesktop: React.FC<CommentVisibilityHelpProps> = ({
  viewers,
}) => {
  return (
    <div
      data-testid="comment-visibility-help"
      className={styles.comments__newComment__who}
    >
      <Icon
        name="secure"
        className={styles.comments__newComment__who__secureIcon}
      />{' '}
      <span className={styles.comments__newComment__who__description}>
        Who Can See This?
      </span>{' '}
      <span className={styles.comments__newComment__who__people}>
        {viewers.length} People
      </span>{' '}
      <Icon
        name="help"
        className={styles.comments__newComment__who__helpIcon}
      />
      <SpeechBubble
        data-testid="comment-visibility-help-tooltip"
        className={styles.comments__newComment__who__tooltip}
      >
        <span className={styles.comments__newComment__who__tooltip__names}>
          {viewers.map((u) => getTooltipStringFromViewer(u)).join(', ')}
        </span>{' '}
        <span>will see your comment.</span>
      </SpeechBubble>
    </div>
  );
};

const sortViewers = (viewers: MaybePendingUser[]): MaybePendingUser[] => {
  const pendingViewers = viewers.filter((v) => v.pending);
  const approvedViewers = viewers.filter((v) => !v.pending);

  const sortedApprovedViewers = approvedViewers.sort((a, b) => {
    if (a.lastName === b.lastName) {
      return a.firstName.localeCompare(b.firstName);
    }
    return a.lastName.localeCompare(b.lastName);
  });

  const sortedPendingViewers = pendingViewers.sort((a, b) =>
    a.email.localeCompare(b.email),
  );

  return [...sortedApprovedViewers, ...sortedPendingViewers];
};

const CommentVisibilityHelp: React.FC<CommentVisibilityHelpProps> = ({
  viewers,
}) => {
  const { isMobile } = useBreakpoints();
  const sortedViewers = sortViewers(viewers);

  return isMobile ? (
    <CommentVisibilityHelpMobile viewers={sortedViewers} />
  ) : (
    <CommentVisibilityHelpDesktop viewers={sortedViewers} />
  );
};

export default CommentVisibilityHelp;
