import { Comment, CommentReply } from 'api-client/types.js';
import { CommentContent } from 'editor-content/CommentContent.js';
import { Atom, atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { useEffect, useMemo, useState } from 'react';
import useApi from '../../../../../../api/useApi.js';
import {
  MaybePendingUser,
  PublishedSection,
  PublishedZeck,
} from '../../../../../../types.ts';
import { UserAndCompany } from '../../../../../../userAndCompany/FetchUserAndCompany.js';
import { commentsSort } from './commentsSort.js';
import createCommentsServerStateAtoms from './createCommentsServerStateAtom.js';
import filterComments from './filterComments.js';
import isFilteredCommentsExisty from './isFilteredCommentsExisty.js';
import {
  CommentQuoteStatus,
  getCommentQuoteStatus,
} from './selectionComments/CommentQuoteStatus.js';
import useActionsForComments from './useActionsForComments.js';
import useCommentsUIState, { FilterState } from './useCommentsUIState.js';

export type CommentsStateForSection = {
  visibleCommentsAtom: Atom<Comment[]>;
  activeCommentCount: number;
  filteredCommentsWithActions: CommentWithActions[];
  findComment: (commentId: string) => Comment | undefined;
  newComment: {
    canAddNewComment: boolean;
    openNewComment: () => void;
    cancelNewComment: () => void;
    isNewCommentFormOpen: boolean;
  };
  refs: {
    setCommentElementRef: (
      commentId: string,
    ) => (el: HTMLElement | null) => void;
    setCommentSectionRef: React.RefCallback<HTMLElement>;
    setSectionContentRef: React.RefCallback<HTMLElement>;
  };
  selectedComment: {
    getSelectedCommentThreadId: () => string | null;
    unselectComment: () => void;
    selectCommentThread: (
      commentId: string,
      scrollToSelection?: boolean,
    ) => void;
  };
  openComment: (commentId: string) => void;
  openSectionComments: () => void;
  postComment: (
    content: CommentContent,
    asDirectMessage: boolean,
    selection?: Comment['selection'],
  ) => Promise<Comment>;
  scrollSectionIntoViewIfNeeded: () => void;
  sidebarOpen: boolean;
  isCommentToCelebrate: (commentId: string) => boolean;

  // these are kinda jank but was useful in
  // a globalstateless world
  section: {
    id: string;
    title: string;
  };
  userName: string;
  viewers: MaybePendingUser[];
};

export type CommentsState = {
  sidebarOpen: boolean;
  totalCommentCountAtom: Atom<number>;
  filteredCommentsExistAtom: Atom<boolean>;
  isCommentsLoaded: boolean;
  filterState: FilterState;
  setCommentsPanelRef: React.RefCallback<HTMLElement>;
  setZeckScrollContainerRef: React.RefCallback<HTMLElement>;
  setFilter: (filterState: FilterState) => void;
  openComments: () => void;
  closeComments: () => void;
  forSection: (publishedSection: PublishedSection) => CommentsStateForSection;
} | null;

function useLoadComments(publishedZeck: PublishedZeck) {
  const api = useApi();
  const { getZeckViewers } = api;

  const [viewers, setViewers] = useState<MaybePendingUser[]>([]);
  const [isCommentsLoaded, setIsCommentsLoaded] = useState(false);
  const commentsAtoms = useMemo(
    () => createCommentsServerStateAtoms(api, publishedZeck),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, publishedZeck.id],
  );

  const loadComments = useSetAtom(commentsAtoms.loadCommentsAtom);

  const zeckId = publishedZeck.zeckId;
  const zeckDisabledComments = publishedZeck.settings.disableComments;

  useEffect(() => {
    if (zeckDisabledComments) {
      return;
    }

    loadComments().then(() => setIsCommentsLoaded(true));
    getZeckViewers(zeckId).then(setViewers);
  }, [
    loadComments,
    getZeckViewers,
    zeckId,
    zeckDisabledComments,
    publishedZeck.sections,
  ]);

  return {
    isCommentsLoaded,
    viewers,
    commentsAtoms,
  };
}

export function useCommentsForZeck(
  userAndCompany: UserAndCompany,
  publishedZeck: PublishedZeck,
): CommentsState {
  const { isCommentsLoaded, viewers, commentsAtoms } =
    useLoadComments(publishedZeck);
  const addComment = useSetAtom(commentsAtoms.addComment);
  // ^ server state

  const [selectedCommentThreadId, setSelectedCommentThreadId] = useState<
    string | null
  >(null);
  // can be selected from sidebar
  // which causes scroll and highlight on selection comments
  // can be selected from content (highlight)
  // which causes scroll in sidebar

  const {
    setCommentSectionRef,
    setSectionContentRef,
    setCommentElementRef,
    setZeckScrollContainerRef,
    openNewCommentFor,
    openCommentsToSection,
    scrollToComment,
    openComments,
    closeComments,
    isSidebarOpen,
    filterState,
    setFilter,
    setCommentsPanelRef,
    forSection: uiStateForSection,
  } = useCommentsUIState();
  // "ui state" controls
  // - opening/closing sidebar
  // - filtering
  // - open comment form
  // - scrolling to a comment or comment section
  // - hiding new comment button when filter is active

  const {
    getActionsForComment: _getActionsForComment,
    getActionsForCommentReply: _getActionsForCommentReply,
  } = useActionsForComments(userAndCompany, commentsAtoms);
  // Comment sidebar
  // actions that are connected to a specific comment
  // - edit
  // - delete
  // - star
  // - resolve
  // - reply

  const commentsAndDMsBySection = useAtomValue(
    commentsAtoms.commentsBySectionAtom,
  );
  // organizing data

  const totalCommentCountAtom = useMemo(
    () =>
      atom((get) =>
        Object.values(get(commentsAtoms.commentsBySectionAtom)).reduce(
          (count, comments) =>
            count +
            comments.length +
            comments.reduce(
              (count, comment) => count + comment.replies.length,
              0,
            ),
          0,
        ),
      ),
    [commentsAtoms.commentsBySectionAtom],
  );
  // total comment count

  const commentToCelebrate = useAtomValue(commentsAtoms.commentToCelebrateAtom);
  // comment celebration

  const visibleCommentsAtomFamily = useMemo(
    () =>
      atomFamily((sectionId: string) => {
        const sectionCommentsAtom =
          commentsAtoms.sectionCommentsAtomFamily(sectionId);

        return atom((get) => {
          const sectionComments = get(sectionCommentsAtom);
          const { activeComments, resolvedComments, starredComments } =
            filterComments(sectionComments);

          switch (filterState) {
            case 'resolved':
              return resolvedComments;
            case 'starred':
              return starredComments;
            case null:
              return activeComments;
          }
        });
      }),
    [filterState, commentsAtoms],
  );

  const doFilteredCommentsExistAtom = useMemo(
    () =>
      atom((get) =>
        isFilteredCommentsExisty(
          filterState,
          get(commentsAtoms.commentsBySectionAtom),
        ),
      ),
    [filterState, commentsAtoms.commentsBySectionAtom],
  );
  // comment filtering
  // specifically: empty state

  if (publishedZeck.settings.disableComments) {
    return null;
  }

  return {
    isCommentsLoaded,
    filterState,
    totalCommentCountAtom: totalCommentCountAtom,
    filteredCommentsExistAtom: doFilteredCommentsExistAtom,
    setFilter,
    sidebarOpen: isSidebarOpen || !!selectedCommentThreadId,
    setZeckScrollContainerRef,
    setCommentsPanelRef,
    openComments,
    closeComments: () => {
      closeComments();
      setSelectedCommentThreadId(null);
    },
    forSection(publishedSection: PublishedSection): CommentsStateForSection {
      const sectionId = publishedSection.sectionId;

      const {
        isNewCommentFormOpen,
        openSectionComments,
        closeNewComment,
        scrollSectionIntoViewIfNeeded,
        canAddNewComment,
        scrollToCommentInZeck,
      } = uiStateForSection(sectionId);

      const commentsForSection = commentsAndDMsBySection[sectionId] || [];

      const userName =
        userAndCompany.user.firstName + ' ' + userAndCompany.user.lastName;

      const { activeComments, resolvedComments, starredComments } =
        filterComments(commentsForSection);

      const filteredComments = (() => {
        switch (filterState) {
          case 'resolved':
            return resolvedComments;
          case 'starred':
            return starredComments;
          case null:
            return activeComments;
        }
      })();

      const findComment = (commentId: string): Comment | undefined => {
        return commentsForSection.find((c) => c.id === commentId);
      };

      const filteredCommentsWithActions = filteredComments
        .map((comment): CommentWithActions => {
          return {
            ...comment,
            replies: comment.replies.map((commentReply) => ({
              ...commentReply,
              actions: _getActionsForCommentReply(commentReply),
            })),
            actions: _getActionsForComment(comment),
            quoteStatus: getCommentQuoteStatus(comment, publishedSection),
          };
        })
        .sort(commentsSort(publishedSection));

      const openComment = (commentId: string) => {
        openCommentsToSection(sectionId, false);
        setSelectedCommentThreadId(commentId);

        setTimeout(() => {
          scrollToComment(commentId);
        }, 400);
      };

      const postComment = async (
        content: CommentContent,
        asDirectMessage = false,
        selection?: Comment['selection'],
      ) => {
        const comment = await addComment(
          sectionId,
          content,
          asDirectMessage,
          selection,
        );

        openSectionComments();
        openComment(comment.id);
        closeNewComment();

        return comment;
      };

      const activeCommentCount =
        activeComments.length +
        activeComments.reduce((count, c) => count + c.replies.length, 0);

      const isCommentToCelebrate = (commentId: string): boolean => {
        return commentToCelebrate?.id === commentId;
      };

      return {
        visibleCommentsAtom: visibleCommentsAtomFamily(sectionId),
        findComment,
        newComment: {
          canAddNewComment,
          cancelNewComment: closeNewComment,
          isNewCommentFormOpen,
          openNewComment: () => openNewCommentFor(sectionId),
        },
        refs: {
          setCommentElementRef,
          setCommentSectionRef: setCommentSectionRef(sectionId),
          setSectionContentRef: setSectionContentRef(sectionId),
        },
        section: {
          id: sectionId,
          title: publishedSection.title,
        },
        selectedComment: {
          getSelectedCommentThreadId: () => selectedCommentThreadId,
          unselectComment: () => setSelectedCommentThreadId(null),

          selectCommentThread: (commentId, scrollToSelection = false) => {
            setSelectedCommentThreadId(commentId);

            if (scrollToSelection) {
              scrollToCommentInZeck(commentId);
            }
          },
        },
        activeCommentCount,
        filteredCommentsWithActions,
        openComment,
        openSectionComments,
        postComment,
        scrollSectionIntoViewIfNeeded,
        sidebarOpen: isSidebarOpen,
        userName,
        viewers,
        isCommentToCelebrate,
      };
    },
  };
}

export type CommentReplyWithActions = CommentReply & {
  actions: {
    edit?: (content: CommentContent) => Promise<void>;
    delete?: () => Promise<void>;
  };
};

export type CommentWithActions = Omit<Comment, 'replies'> & {
  replies: CommentReplyWithActions[];
  actions: {
    toggleStar: (() => void) | undefined;
    edit?: (content: CommentContent) => Promise<void>;
    delete?: () => Promise<void>;
    reply: (content: CommentContent) => Promise<void>;
    resolve?: () => void;
    unresolve?: () => void;
  };
  quoteStatus: CommentQuoteStatus;
};
