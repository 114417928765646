import cx from 'classnames';
import { Icon } from 'icons';
import { useState } from 'react';
import { Invitation } from '../../../api/createApi.ts';
import Button from '../../../design-system/atoms/Button.tsx';
import InputEmail from '../../../design-system/atoms/InputEmail.tsx';
import InputPassword from '../../../design-system/atoms/InputPassword.tsx';
import InputText from '../../../design-system/atoms/InputText.tsx';
import Link from '../../../design-system/atoms/Link.tsx';
import Spinner from '../../../design-system/atoms/Spinner.tsx';
import emailRegex from '../../../emailRegex.ts';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import { validatePassword } from '../../../services/validations.ts';
import FormState from '../FormState.ts';
import styles from './SignupForm.module.scss';

export type UserSignupFormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName?: string;
};

type SignupFormProps = {
  invitation: Invitation;
  onSubmit: (
    data: UserSignupFormData,
  ) => Promise<'SUCCESS' | 'INVALID_CREDENTIALS' | 'EMAIL_TAKEN'>;
};

const SignupForm = ({ invitation, onSubmit }: SignupFormProps) => {
  const { isMobile } = useBreakpoints();
  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState<
    FormState<'firstName' | 'lastName' | 'email' | 'password' | 'companyName'>
  >({
    firstName: {
      value: '',
      error: null,
    },
    lastName: {
      value: '',
      error: null,
    },
    email: {
      value:
        invitation.signupType === 'EXISTING_COMPANY'
          ? invitation.email.trim()
          : '',
      error: null,
    },
    password: {
      value: '',
      error: null,
    },
    companyName: {
      value: '',
      error: null,
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitting) {
      return;
    }

    setFormState({
      firstName: {
        ...formState.firstName,
        error: formState.firstName.value
          ? null
          : 'Please enter your first name.',
      },
      lastName: {
        ...formState.lastName,
        error: formState.lastName.value ? null : 'Please enter your last name.',
      },
      email: {
        ...formState.email,
        error:
          formState.email.value && emailRegex.test(formState.email.value)
            ? null
            : 'Please enter a valid email address.',
      },
      password: {
        ...formState.password,
        error: !formState.password.value
          ? 'Please enter your password.'
          : !validatePassword(formState.password.value)
            ? 'Passwords must be 9 characters and a mix of letters and numbers.'
            : null,
      },
      companyName: {
        ...formState.companyName,
        error:
          invitation.signupType === 'NEW_COMPANY' && formState.companyName.value
            ? null
            : 'Please enter your company name.',
      },
    });

    if (
      formState.firstName.value &&
      formState.lastName.value &&
      formState.email.value &&
      emailRegex.test(formState.email.value) &&
      formState.password.value &&
      validatePassword(formState.password.value) &&
      (invitation.signupType === 'EXISTING_COMPANY' ||
        formState.companyName.value)
    ) {
      setSubmitting(true);

      const formData: UserSignupFormData = {
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
        email: formState.email.value,
        password: formState.password.value,
      };

      if (invitation.signupType === 'NEW_COMPANY') {
        Object.assign(formData, { companyName: formState.companyName.value });
      }

      const result = await onSubmit(formData);

      setSubmitting(false);

      if (result === 'INVALID_CREDENTIALS') {
        setFormState({
          ...formState,
          email: {
            ...formState.email,
            error: 'Please enter a valid email address.',
          },
        });
      } else if (result === 'EMAIL_TAKEN') {
        setFormState({
          ...formState,
          email: {
            ...formState.email,
            error: (
              <span>
                This email is already taken. <Link href="/">Sign In</Link> or{' '}
                <Link href="/request-password-reset">Reset Your Password</Link>.
              </span>
            ),
          },
        });
      }
    }
  };
  const setFormValue = (name: string) => (value: string) => {
    setFormState({
      ...formState,
      [name]: { value, error: null },
    });
  };

  return (
    <form
      {...{
        className: styles.form,
        noValidate: true,
        onSubmit: handleSubmit,
      }}
    >
      {isMobile && invitation.signupType === 'EXISTING_COMPANY' ? (
        <>
          <div className={styles.form__row}>
            <InputText
              {...{
                label: 'First Name',
                ...formState.firstName,
                onChange: setFormValue('firstName'),
              }}
            />
          </div>
          <div className={styles.form__row}>
            <InputText
              {...{
                label: 'Last Name',
                ...formState.lastName,
                onChange: setFormValue('lastName'),
              }}
            />
          </div>
        </>
      ) : (
        <div className={styles.form__row}>
          <InputText
            {...{
              label: 'First Name',
              ...formState.firstName,
              onChange: setFormValue('firstName'),
            }}
          />
          <InputText
            {...{
              label: 'Last Name',
              ...formState.lastName,
              onChange: setFormValue('lastName'),
            }}
          />
        </div>
      )}
      <div className={styles.form__row}>
        <InputEmail
          {...{
            label: 'Email Address',
            ...formState.email,
            onChange: setFormValue('email'),
            disabled: invitation.signupType === 'EXISTING_COMPANY',
          }}
        />
      </div>
      <div className={styles.form__row}>
        <InputPassword
          {...{
            label: 'Password',
            ...formState.password,
            onChange: setFormValue('password'),
          }}
        />
      </div>
      {invitation.signupType === 'NEW_COMPANY' && (
        <div className={styles.form__row}>
          <InputText
            {...{
              label: 'Company',
              ...formState.companyName,
              onChange: setFormValue('companyName'),
            }}
          />
        </div>
      )}
      <p
        className={cx(
          isMobile ? styles.form__terms_mobile : styles.form__terms,
        )}
      >
        By creating an account, you agree to our&nbsp;
        <a
          href="https://support.zeck.app/article/19-terms-of-use"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of Use
        </a>
        &nbsp;and&nbsp;
        <a
          href="https://support.zeck.app/article/17-privacy-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        .
      </p>
      <Button
        {...{
          className: styles.form__submit,
          size: 'large',
          color: 'primary',
          disabled: submitting,
        }}
      >
        {submitting ? (
          <Spinner className={styles.spinner} />
        ) : (
          <>
            <Icon name="lock" /> Create Secure Account
          </>
        )}
      </Button>
    </form>
  );
};

export default SignupForm;
