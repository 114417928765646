import Point from '../../domHelpers/Point.js';
import { Rect } from '../../domHelpers/Rect.js';

const isInRect = (point: Point, rect: Rect): boolean =>
  point[0] > rect[0][0] &&
  point[0] < rect[1][0] &&
  point[1] > rect[0][1] &&
  point[1] < rect[1][1];

const APPROXIMATE_BLOCK_CONTROLS_WIDTH = 96;

const getHoveredBlockIndex = (
  blockRects: Rect[],
  gutterRect: Rect,
  mousePosition: Point,
): number => {
  return blockRects.findIndex((rect) =>
    isInRect(
      mousePosition,
      Rect(
        Math.min(gutterRect[1][0], rect[0][0]) -
          APPROXIMATE_BLOCK_CONTROLS_WIDTH,
        rect[0][1],
        rect[1][0],
        rect[1][1],
      ),
    ),
  );
};

export default getHoveredBlockIndex;
