import { TextBlock } from 'editor-content/Block.js';
import Linkable from 'editor-content/html/Linkable.js';
import React, { ForwardedRef, useRef } from 'react';
import mergeRefs from '../../../../junkDrawer/mergeRefs.js';
import { removeHighlights } from '../publish/selectionComments/removeHighlights.js';
import { useRenderTextNodesAsHTMLString } from './useRenderTextNodesAsHTMLString.js';

export const withRenderTextNodes = <B extends TextBlock, P>(
  Wrapped: React.ComponentType<P>,
) =>
  React.forwardRef(function WithRenderTextNodes(
    {
      block,
      linkables,
      className,
      ...otherProps
    }: P & {
      block: B;
      linkables: Linkable[];
      className?: string;
    },
    forwardedRef: ForwardedRef<HTMLElement>,
  ) {
    const ref = useRef<HTMLElement>(null);

    useRenderTextNodesAsHTMLString(
      removeHighlights(block.content),
      linkables,
      ref,
    );

    return (
      <Wrapped
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...({ ...otherProps } as any)}
        ref={mergeRefs([ref, forwardedRef])}
        id={block.id}
        className={className}
      />
    );
  });
