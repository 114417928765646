import { useCallback } from 'react';
import useApi from '../api/useApi.ts';
import { useActiveCompany } from '../userAndCompany/activeCompanyAtom.tsx';
import { useUser } from '../userAndCompany/userAtom.tsx';

// convenient helper to add analytics event for current user and company
export const useAddZeckEvent = () => {
  const { addZeckEvent } = useApi();
  const user = useUser();
  const activeCompany = useActiveCompany();

  const callback = useCallback(
    (event: Parameters<typeof addZeckEvent>[0]) =>
      addZeckEvent(event, { user, activeCompany }),
    [user, activeCompany, addZeckEvent],
  );

  return callback;
};
