import { Comment, CommentReply, PublishedZeck } from 'api-client/types.js';
import { CommentContent } from 'editor-content/CommentContent.js';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import isEqual from 'lodash/isEqual.js';
import createCommentApi from '../../../../../../api/endpoints/createCommentApi.js';

const updateCommentInList =
  (commentId: string, updateComment: (comment: Comment) => Comment) =>
  (comments: Comment[]) =>
    comments.map((c) => (c.id === commentId ? updateComment(c) : c));

export type CommentApi = ReturnType<typeof createCommentApi>;

// we would probably put apiClient into an atom, but right now it's just an argument
function createCommentsServerStateAtoms(
  api: CommentApi,
  publishedZeck: PublishedZeck,
) {
  const sectionCommentsAtomFamily = atomFamily((_sectionId: string) =>
    atom<Comment[]>([]),
  );

  const commentsAtom = atom((get) =>
    publishedZeck.sections.reduce<{
      [sectionId: string]: Comment[];
    }>(
      (acc, section) => ({
        ...acc,
        [section.sectionId]: get(sectionCommentsAtomFamily(section.sectionId)),
      }),
      {},
    ),
  );

  const commentsByUserAtom = atom<{ [userId: string]: Array<Comment> }>(
    (get) => {
      const comments: { [userId: string]: Array<Comment> } = {};

      publishedZeck.sections.forEach((section) => {
        const sectionComments = get(
          sectionCommentsAtomFamily(section.sectionId),
        );
        sectionComments.forEach((sectionComment) => {
          if (comments[sectionComment.userId] === undefined) {
            comments[sectionComment.userId] = [];
          }

          comments[sectionComment.userId]?.push(sectionComment);
        });
      });

      return comments;
    },
  );

  const commentToCelebrateAtom = atom<Comment | null>(null);

  return {
    sectionCommentsAtomFamily: sectionCommentsAtomFamily,
    commentsBySectionAtom: commentsAtom,
    commentToCelebrateAtom: commentToCelebrateAtom,

    loadCommentsAtom: atom(null, async (_get, set) => {
      const comments = await api.getZeckComments(publishedZeck.zeckId);

      publishedZeck.sections.forEach((section) =>
        set(
          sectionCommentsAtomFamily(section.sectionId),
          comments.filter((c) => c.sectionId === section.sectionId),
        ),
      );
    }),

    addComment: atom(
      null,
      async (
        get,
        set,
        sectionId: string,
        content: CommentContent,
        asDirectMessage: boolean,
        selection?: Comment['selection'],
      ) => {
        const comment = await api.createComment(
          sectionId,
          content,
          asDirectMessage,
          selection,
        );

        set(sectionCommentsAtomFamily(sectionId), (comments) => [
          comment,
          ...comments,
        ]);

        if (get(commentsByUserAtom)[comment.userId]?.length === 1) {
          set(commentToCelebrateAtom, comment);

          // stop celebrating the comment after 3.3 seconds
          setTimeout(() => {
            set(commentToCelebrateAtom, null);
          }, 3300);
        }

        return comment;
      },
    ),

    removeComment: atom(null, async (get, set, commentToRemove: Comment) => {
      await api.deleteComment(commentToRemove);

      set(sectionCommentsAtomFamily(commentToRemove.sectionId), (comments) =>
        comments.filter((comment) => comment.id !== commentToRemove.id),
      );
    }),

    editComment: atom(
      null,
      async (get, set, targetComment: Comment, newContent: CommentContent) => {
        if (isEqual(newContent, targetComment.content)) return;

        const updatedComment = await api.updateComment(
          targetComment,
          newContent,
        );
        set(
          sectionCommentsAtomFamily(targetComment.sectionId),
          updateCommentInList(targetComment.id, () => updatedComment),
        );
      },
    ),

    updateStarred: atom(
      null,
      async (
        get,
        set,
        targetComment: {
          id: string;
          sectionId: string;
        },
        starred: boolean,
      ) => {
        if (starred) {
          await api.starComment(targetComment.id);
        } else {
          await api.unStarComment(targetComment.id);
        }

        set(
          sectionCommentsAtomFamily(targetComment.sectionId),
          updateCommentInList(targetComment.id, (c) => ({ ...c, starred })),
        );
      },
    ),

    updateResolved: atom(
      null,
      async (
        get,
        set,
        targetComment: {
          id: string;
          sectionId: string;
        },
        isResolved: boolean,
      ) => {
        if (isResolved) {
          await api.resolveComment(targetComment.id);
        } else {
          await api.unresolveComment(targetComment.id);
        }

        set(
          sectionCommentsAtomFamily(targetComment.sectionId),
          updateCommentInList(targetComment.id, (c) => ({
            ...c,
            resolved: isResolved,
          })),
        );
      },
    ),

    addCommentReply: atom(
      null,
      async (get, set, parentComment: Comment, content: CommentContent) => {
        const reply = await api.createCommentReply(parentComment, content);

        set(
          sectionCommentsAtomFamily(parentComment.sectionId),
          updateCommentInList(parentComment.id, (comment) => ({
            ...comment,
            replies: [...comment.replies, reply],
          })),
        );
      },
    ),

    editCommentReply: atom(
      null,
      async (
        get,
        set,
        targetCommentReply: CommentReply,
        newContent: CommentContent,
      ) => {
        if (isEqual(newContent, targetCommentReply.content)) return;

        const sectionId = publishedZeck.sections
          .map((section) => section.sectionId)
          .find((sectionId) =>
            get(sectionCommentsAtomFamily(sectionId)).some(
              (comment) => comment.id === targetCommentReply.parentId,
            ),
          ); // this kinda sucks, but is a result of our nested data

        if (!sectionId) return;

        const newCommentReply = await api.updateCommentReply(
          targetCommentReply,
          newContent,
        );

        set(
          sectionCommentsAtomFamily(sectionId),
          updateCommentInList(targetCommentReply.parentId, (c) => ({
            ...c,
            replies: c.replies.map((cr) =>
              cr.id === targetCommentReply.id ? newCommentReply : cr,
            ),
          })),
        );
      },
    ),

    removeCommentReply: atom(
      null,
      async (get, set, commentToRemove: CommentReply) => {
        const sectionId = publishedZeck.sections
          .map((section) => section.sectionId)
          .find((sectionId) =>
            get(sectionCommentsAtomFamily(sectionId)).some(
              (comment) => comment.id === commentToRemove.parentId,
            ),
          ); // this kinda sucks, but is a result of our nested data

        if (!sectionId) return;

        await api.deleteCommentReply(commentToRemove);

        set(
          sectionCommentsAtomFamily(sectionId),
          updateCommentInList(commentToRemove.parentId, (c) => ({
            ...c,
            replies: c.replies.filter((cr) => cr.id !== commentToRemove.id),
          })),
        );
      },
    ),
  };
}

export type CommentsAtoms = ReturnType<typeof createCommentsServerStateAtoms>;

export default createCommentsServerStateAtoms;
