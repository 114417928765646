import { useMemo, useRef } from 'react';
import useElementArrayRef from '../../../pages/zeck/useElementArrayRef.js';

/**
 * Provides refs for table elements needed for measurement for rendering sticky regions
 */
export const useTableRefs = () => {
  const { setElementAt: setRowElAt, getElementAt: getRowElAt } =
    useElementArrayRef();
  const { setElementAt: setColumnElAt, getElementAt: getColumnElAt } =
    useElementArrayRef();

  const tableRef = useRef<HTMLElement | null>(null);
  const scrollContainerRef = useRef<HTMLElement | null>(null);

  // potentially this could all be one big ref

  return useMemo(
    () => ({
      setScrollContainerEl: (scrollContainerEl: HTMLElement | null) => {
        scrollContainerRef.current = scrollContainerEl;
      },
      getScrollContainerEl: () => scrollContainerRef.current,
      setTableEl: (tableEl: HTMLElement | null) => {
        tableRef.current = tableEl;
      },
      getTableEl: () => tableRef.current,
      setRowElAt,
      setColumnElAt,
      getRowElAt: (index: number) => getRowElAt(index) || null,
      getColumnElAt: (index: number) => getColumnElAt(index) || null,
    }),
    [getColumnElAt, getRowElAt, setColumnElAt, setRowElAt],
  );
};

export type TableRefs = ReturnType<typeof useTableRefs>;
