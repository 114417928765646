import Color from 'color';
import { useEffect, useMemo } from 'react';
import { SuccessResult } from '../../result/Result.js';
import { getResult } from '../../services/useFetch/useFetch.ts';
import { Resource } from '../../services/useResource.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';

export type BrandKit = {
  brandColorPrimary: string | null;
  brandLogoId: string | null;
};

const getColor = (colorString: string | null): Color | null => {
  if (!colorString) return null;

  try {
    return Color(colorString);
  } catch {
    return null;
  }
};

export const getStyles = (brandKit: BrandKit): Record<string, string> => {
  const { brandColorPrimary: brandColorPrimaryString } = brandKit;

  const brandColorPrimary = getColor(brandColorPrimaryString);

  const brandKitVariables: Record<string, string> = brandColorPrimary
    ? {
        '--brand-color-primary': brandColorPrimary.hex(),
        '--color-primary': brandColorPrimary.hex(),
        '--color-primary-darker': brandColorPrimary.darken(0.2).hex(),
        '--color-primary-subtle': brandColorPrimary.lighten(0.2).hex(),
      }
    : {};

  const titleCardStyles: Record<string, string> = brandColorPrimary
    ? {
        '--title-card-color': 'white',
        '--title-card-background-color': 'var(--brand-color-primary)',
      }
    : {};

  return {
    ...brandKitVariables,
    ...titleCardStyles,
  };
};

export type BrandKitResource = Resource<BrandKit, Partial<BrandKit>>;

type BrandKitThemeProps = {
  children(resource: BrandKitResource): JSX.Element;
  userAndCompany: UserAndCompany;
};

export function useApplyStyles(styles: Record<string, string>) {
  useEffect(() => {
    Object.entries(styles).forEach(([k, v]) => {
      document.documentElement.style.setProperty(k, v);
    });

    return () => {
      Object.entries(styles).forEach(([k]) => {
        document.documentElement.style.removeProperty(k);
      });
    };
  }, [styles]);
}

function WithBrandKit({ children, userAndCompany }: BrandKitThemeProps) {
  const resource: BrandKitResource = {
    result: SuccessResult({
      brandColorPrimary: userAndCompany.activeCompany.brandColorPrimary,
      brandLogoId: userAndCompany.activeCompany.brandLogoId,
    }),
    // eslint-disable-next-line @typescript-eslint/require-await
    update: async (brandKitPatch) => {
      userAndCompany.updateActiveCompany({
        ...userAndCompany.activeCompany,
        ...brandKitPatch,
      });
    },
  };
  const styles = useMemo(
    () => getResult(getStyles, {}, {}, resource.result),
    [resource.result],
  );
  useApplyStyles(styles);

  return children(resource);
}

export default WithBrandKit;
