import { createParagraphBlock, isTextBlock } from 'editor-content/Block.js';
import cut from '../../../../../editor/actions/copyPaste/cut.js';
import textBlockCutStrategy from '../../../../../editor/blocks/textBlocksStrategies/textBlockCutStrategy.js';
import { textSelection } from '../../../../../editor/selection/TextSelection.js';
import { contentSelection } from '../../../../../editor/selection/contentSelection/ContentSelection.js';
import { HydratedBlock } from '../../../../../types/HydratedBlock.js';

export const zeckEditorCut = cut<HydratedBlock>({
  generateBlockEditor: (block) => {
    if (isTextBlock(block)) {
      return {
        cut: (selection) => textBlockCutStrategy(block, selection),
      };
    }
    return {
      cut: () => {
        return [
          {
            contentSubset: [createParagraphBlock([])],
            selection: textSelection(0, contentSelection(0)),
          },
          { type: 'block', content: [block] },
        ];
      },
    };
  },
  createDefaultBlock: createParagraphBlock,
});
