import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.tsx';
import ZeckCover from '../../../../design-system/zeck/ZeckCover.tsx';
import isTouchDeviceFlow from '../../../../services/isTouchDeviceFlow.ts';
import { getZeckBaseUrl } from '../../../../services/Paths.ts';
import { Zeck } from '../../../../types.ts';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import EmptyZeckIllustration from '../../EmptyZeckIllustration.tsx';
import PreviewPage from './PreviewPage.tsx';
import styles from './ZeckPreviewPageEmpty.module.scss';

type ZeckPreviewPageEmptyProps = {
  brandKitResource: BrandKitResource;
  zeck: Zeck & {
    actions: { publish: () => Promise<void> };
  };
  userAndCompany: UserAndCompany;
};

const ZeckPreviewPageEmpty = ({
  brandKitResource,
  zeck,
  userAndCompany,
}: ZeckPreviewPageEmptyProps) => {
  const navigate = useNavigate();
  const nextSectionRef = useRef<HTMLDivElement>(null);

  return (
    <PreviewPage
      brandKitResource={brandKitResource}
      zeck={zeck}
      userAndCompany={userAndCompany}
      onClickExitPreview={() => {
        if (isTouchDeviceFlow()) {
          navigate('/');
        } else {
          navigate(`/${getZeckBaseUrl(zeck)}/edit`);
        }
      }}
      scrollToSectionOrZeckCover={() => {
        // do nothing
      }}
    >
      <ZeckCover
        companyName={userAndCompany.activeCompany.name}
        zeck={zeck}
        navigation={() => {
          if (nextSectionRef.current) {
            nextSectionRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        }}
      />
      <div ref={nextSectionRef} className={styles.previewPageEmptyContent}>
        <EmptyZeckIllustration />
        <h2 className={styles.heading}>This Zeck Doesn’t Have Any Content</h2>
        <p className={styles.body}>
          Add a new section by visiting this Zeck on a laptop or desktop. Then
          celebrate with some ice cream maybe.
        </p>
      </div>
    </PreviewPage>
  );
};

export default ZeckPreviewPageEmpty;
