import { RefCallback } from 'react';
import { isWithinHoverLayer } from '../../../domHelpers/hoverNextTo/HoverLayer.tsx';
import DetectsOutsideClick from '../../../junkDrawer/DetectsOutsideClick.tsx';

function isInModalOrHoverLayer(el: Element) {
  return el.closest('.ReactModalPortal') || isWithinHoverLayer(el);
}

type DetectsOutsideEditorClickProps<ElementType> = {
  onOutsideClick: () => void;
  children: (containerRef: RefCallback<ElementType>) => JSX.Element;
};

const DetectsOutsideEditorClick = <ElementType extends HTMLElement>({
  children,
  onOutsideClick,
}: DetectsOutsideEditorClickProps<ElementType>) => {
  return (
    <DetectsOutsideClick<ElementType>
      onClick={(e) => {
        if (e.target instanceof Element && isInModalOrHoverLayer(e.target)) {
          return;
        }

        onOutsideClick();
      }}
    >
      {children}
    </DetectsOutsideClick>
  );
};

export default DetectsOutsideEditorClick;
