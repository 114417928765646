import { useRef, useState } from 'react';
import { rightAlignedCenteredElement } from '../../domHelpers/hoverNextTo/positionStrategy/rightAlignedCentered.ts';
import { useFolderDroppable } from '../../pages/home/FolderDragAndDrop.tsx';
import IconButton from '../atoms/IconButton.tsx';
import ConfirmModal from '../organisms/ConfirmationModal.tsx';
import KebabMenu from '../organisms/KebabMenu.js';
import WithTooltip from '../organisms/WithTooltip.tsx';
import FolderSidebarItem from './FolderSidebarItem.tsx';
import styles from './FolderSidebarItemEditableDroppable.module.scss';
import TransparentInput from './TransparentInput.tsx';

type FolderSidebarItemProps = {
  name: string;
  folderId: string;
  collapse?: boolean;
  href: string;
  active?: boolean;
  onUpdateFolder?: (folderId: string, folderName: string) => void;
  onDeleteFolder?: (folderId: string) => void;
  onClick?: () => void;
};

const FolderSidebarItemEditableDroppable: React.FC<FolderSidebarItemProps> = ({
  name,
  folderId,
  collapse,
  onUpdateFolder,
  onDeleteFolder,
  active,
  ...props
}) => {
  const kebabRef = useRef<HTMLButtonElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const shouldHideKebab = isEditing || !onUpdateFolder;

  const { isOver, setNodeRef } = useFolderDroppable(folderId);

  const kebabWidth = shouldHideKebab ? 0 : 24;

  const tooltipName = name.length > 40 ? name.slice(0, 40) + '...' : name;

  const label = isEditing ? (
    <TransparentInput
      value={name}
      aria-label="folder name"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onSubmit={(val) => {
        setIsEditing(false);
        if (!onUpdateFolder) return;

        onUpdateFolder(folderId, val);
      }}
    />
  ) : (
    <WithTooltip<HTMLDivElement>
      text={tooltipName}
      positionStrategy={rightAlignedCenteredElement(14 + kebabWidth)}
      tooltipClassName={styles.tooltip}
    >
      {(ref, listeners) => (
        <div data-testid="zeck-folder-name" ref={ref} {...listeners}>
          {name}
        </div>
      )}
    </WithTooltip>
  );

  const kebab = shouldHideKebab ? null : (
    <IconButton
      name="kebab"
      ref={kebabRef}
      aria-label="folder settings"
      onClick={(e) => {
        e.preventDefault();
        setMenuOpen(true);
      }}
    />
  );

  return (
    <>
      <FolderSidebarItem
        {...props}
        hover={isOver}
        ref={setNodeRef}
        active={active}
        label={label}
        collapse={collapse}
        kebab={kebab}
        isEditing={isEditing}
      />

      <KebabMenu
        isOpen={menuOpen}
        onClose={() => setMenuOpen(false)}
        elementRef={kebabRef}
        items={[
          {
            label: 'Rename',
            iconName: 'rename',
            color: 'normal',
            onClick: () => {
              setMenuOpen(false);
              setIsEditing(true);
            },
          },
          {
            label: 'Delete',
            iconName: 'trash',
            color: 'danger',
            onClick: () => {
              setMenuOpen(false);
              setIsConfirmingDelete(true);
            },
            hide: !onDeleteFolder,
          },
        ]}
      />

      <ConfirmModal
        isOpen={isConfirmingDelete}
        onClose={() => setIsConfirmingDelete(false)}
        onClickConfirm={() => {
          if (!onDeleteFolder) return;
          onDeleteFolder(folderId);
          setIsConfirmingDelete(false);
        }}
        body={
          <div>
            Delete <i>{name}</i> folder and move its contents to the Trash?
          </div>
        }
        confirmText="Yes, Delete"
        title="Delete Folder?"
      />
    </>
  );
};

export default FolderSidebarItemEditableDroppable;
