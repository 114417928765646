import { createMachine } from '@xstate/fsm';
import Linkable from 'editor-content/html/Linkable.ts';
import { Editor } from 'editor-content/TextNode.js';
import normalizeUrl from 'normalize-url';
import {
  callHandlers,
  handleKey,
} from '../../editor/domFacing/events/isKeyMatch.ts';
import useStateMachine from '../../services/useStateMachine.tsx';
import TurnIntoMenu, { TurnIntoable } from '../molecules/TurnIntoMenu.tsx';
import AddLinkMenu from './AddLinkMenu.tsx';
import styles from './TextFormattingMenu.module.scss';
import Toolbar, {
  ToolbarButton,
  ToolbarDropdownButton,
  ToolbarGroup,
} from './Toolbar.tsx';

type TextFormattingMenuState =
  | { value: 'open'; context: Record<string, never> }
  | { value: 'turning_into'; context: Record<string, never> }
  | { value: 'adding_link'; context: Record<string, never> }
  | { value: 'error_adding_link'; context: Record<string, never> };

type TextFormattingMenuEvent =
  | { type: 'CLICK_TURN_INTO' }
  | { type: 'TURN_INTO' }
  | { type: 'SUBMIT_ADD_LINK' }
  | { type: 'CLICK_ADD_LINK' }
  | { type: 'SUBMIT_INVALID_LINK' };

const formattingMenuMachine = createMachine<
  Record<string, never>,
  TextFormattingMenuEvent,
  TextFormattingMenuState
>({
  id: 'toggle',
  initial: 'open',
  states: {
    open: {
      on: { CLICK_TURN_INTO: 'turning_into', CLICK_ADD_LINK: 'adding_link' },
    },
    turning_into: {
      on: {
        TURN_INTO: 'open',
        CLICK_TURN_INTO: 'open',
        CLICK_ADD_LINK: 'adding_link',
      },
    },
    adding_link: {
      on: {
        CLICK_TURN_INTO: 'turning_into',
        CLICK_ADD_LINK: 'open',
        SUBMIT_ADD_LINK: 'open',
        SUBMIT_INVALID_LINK: 'error_adding_link',
      },
    },
    error_adding_link: {
      on: {
        CLICK_TURN_INTO: 'turning_into',
        CLICK_ADD_LINK: 'open',
        SUBMIT_ADD_LINK: 'open',
        SUBMIT_INVALID_LINK: 'error_adding_link',
      },
    },
  },
});

type TextFormattingMenuProps = {
  blockDisplayName: string;
  className?: string;
  onClickAddComment?: () => void;
  onAddLink?: (link: Editor.LinkType) => void;
  turnIntoables: TurnIntoable[];
  otherActions?: React.ReactNode;
  linkables: Linkable[];
};

const TextFormattingMenu = ({
  blockDisplayName,
  className,
  turnIntoables,
  onClickAddComment,
  onAddLink,
  linkables,
  otherActions,
}: TextFormattingMenuProps) => {
  const { state, send } = useStateMachine(formattingMenuMachine);
  const showTurnIntoMenu = state === 'turning_into';
  const showAddLinkMenu =
    state === 'adding_link' || state === 'error_adding_link';
  const invalidLink = state === 'error_adding_link';

  return (
    <div
      data-testid="text-formatting-menu"
      onKeyDown={callHandlers<React.KeyboardEvent>([
        handleKey({ key: 'Enter' }, (e) => {
          e.stopPropagation();
        }),
        handleKey({ key: 'Backspace' }, (e) => {
          e.stopPropagation();
        }),
        handleKey({ key: 'Delete' }, (e) => {
          e.stopPropagation();
        }),
      ])}
    >
      <Toolbar className={className}>
        {turnIntoables.length !== 0 && (
          <ToolbarGroup>
            <ToolbarDropdownButton
              onClick={() => {
                send('CLICK_TURN_INTO');
              }}
              active={showTurnIntoMenu}
            >
              {blockDisplayName}
            </ToolbarDropdownButton>
          </ToolbarGroup>
        )}

        {onAddLink && (
          <ToolbarGroup>
            <ToolbarButton
              iconName="link"
              onClick={() => {
                send('CLICK_ADD_LINK');
              }}
              active={showAddLinkMenu}
            >
              Link
            </ToolbarButton>
          </ToolbarGroup>
        )}

        {otherActions}

        {onClickAddComment && (
          <ToolbarGroup>
            <ToolbarButton
              iconName="speechBubbleWithText"
              onClick={onClickAddComment}
              active={false}
            >
              Comment
            </ToolbarButton>
          </ToolbarGroup>
        )}
      </Toolbar>
      {showAddLinkMenu && onAddLink && (
        <AddLinkMenu
          invalidLink={invalidLink}
          linkables={linkables}
          onAddExternalLink={(url) => {
            try {
              onAddLink({
                type: 'external-link',
                url: normalizeUrl(url, {
                  stripWWW: false,
                  removeQueryParameters: false,
                }),
              });
              send('SUBMIT_ADD_LINK');
            } catch {
              send('SUBMIT_INVALID_LINK');
            }
          }}
          onAddSectionLink={(sectionId) => {
            onAddLink({ type: 'section-link', sectionId });
            send('SUBMIT_ADD_LINK');
          }}
          onAddMeetingMinutesLink={(meetingMinutesId) => {
            onAddLink({ type: 'meeting-minutes-link', meetingMinutesId });
            send('SUBMIT_ADD_LINK');
          }}
        />
      )}
      {showTurnIntoMenu && (
        <TurnIntoMenu
          className={styles.turnIntoMenu}
          onClickMenuItem={() => {
            send('TURN_INTO');
          }}
          turnIntoables={turnIntoables}
        />
      )}
    </div>
  );
};
export default TextFormattingMenu;
