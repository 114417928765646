import { ParagraphBlock } from 'editor-content/Block.js';
import { TextFormat } from 'editor-content/TextNode.js';
import { TurnIntoable } from '../../../design-system/molecules/TurnIntoMenu.tsx';
import TextFormattingMenu from '../../../design-system/organisms/TextFormattingMenu.tsx';
import {
  ToolbarButton,
  ToolbarGroup,
} from '../../../design-system/organisms/Toolbar.tsx';
import {
  BoldButton,
  ItalicsButton,
  UnderlineButton,
} from '../../../pages/zeck/editor/editableBlocks/FormatTextButton.tsx';
import { PublicEditorEvent } from '../../EditorEvents.ts';
import ContentSelection from '../../selection/contentSelection/ContentSelection.ts';
import { getSelectionFormat } from '../../selection/contentSelection/getSelectionFormat.ts';
import { textBlockIsHighlightedStrategy } from '../textBlocksStrategies/textBlockToggleHighlightStrategy.ts';

export type ParagraphFormattingMenuProps = {
  block: ParagraphBlock;
  selection: ContentSelection;
  dispatch: (e: PublicEditorEvent) => void;
  turnIntoables: TurnIntoable[];
};

export const ParagraphFormattingMenu: React.FC<
  ParagraphFormattingMenuProps
> = ({ block, selection, dispatch, turnIntoables }) => {
  const format = getSelectionFormat(block, selection);

  const onToggleFormat = (formatName: keyof TextFormat) => {
    const event: PublicEditorEvent = {
      type: 'format',
      data: formatName,
    };
    dispatch(event);
  };

  const onHighlight = () => {
    dispatch({
      type: 'toggleHighlight',
    });
  };

  return (
    <TextFormattingMenu
      {...{
        turnIntoables,
        blockDisplayName: 'Text',
        linkables: [],
        meetingMinutesLinkables: [],
        otherActions: (
          <>
            <ToolbarGroup>
              <BoldButton {...{ format, onToggleFormat }} />
              <ItalicsButton {...{ format, onToggleFormat }} />
              <UnderlineButton {...{ format, onToggleFormat }} />
            </ToolbarGroup>
            <ToolbarGroup>
              <ToolbarButton
                iconName={'highlight'}
                onClick={onHighlight}
                active={textBlockIsHighlightedStrategy(block, selection)}
              >
                Highlight
              </ToolbarButton>
            </ToolbarGroup>
          </>
        ),
      }}
    />
  );
};
