import cx from 'classnames';
import {
  LogOutIcon,
  MinutesBookIcon,
  PaletteIcon,
  PeopleIcon,
  TrashIcon,
} from 'icons';
import {
  SidebarButton,
  SidebarLink,
} from '../../../design-system/molecules/SidebarItem.tsx';
import { Result } from '../../../services/useFetch/useFetch.ts';
import { ZeckFolderWithCount } from '../../../types/ZeckFolder.ts';
import { UserAndCompany } from '../../../userAndCompany/FetchUserAndCompany.tsx';
import { PageType } from '../../PageLayout.tsx';
import CurrentUserSidebarItem from './CurrentUserSidebarItem.tsx';
import HomeSidebarItemDroppable from './HomeSidebarItemDroppable.tsx';
import styles from './Sidebar.module.scss';
import SidebarFolders from './SidebarFolders.tsx';

type SidebarBodyProps = {
  userAndCompany: UserAndCompany;
  className?: string;
  collapse?: boolean;
  onClickLogout(): void;
  activePage: PageType;
  onUpdateFolder?: (folderId: string, folderName: string) => void;
  onDeleteFolder?: (folderId: string) => void;
  onCreateFolder?: (folderName: string) => Promise<void>;
  zeckFoldersResult: Result<ZeckFolderWithCount[]>;
  onExpand: () => void;
  onClickLink?: () => void;
};

export const Sidebar = ({
  userAndCompany,
  className,
  collapse,
  onExpand,
  onClickLogout,
  onClickLink,
  activePage,
  onCreateFolder,
  onUpdateFolder,
  onDeleteFolder,
  zeckFoldersResult,
}: SidebarBodyProps) => {
  const companyId = userAndCompany.activeCompany.id;
  const user = userAndCompany.user;

  return (
    <div className={cx(styles.sidebar, className)}>
      <div className={styles.sidebar_body} data-testid="sidebar-body">
        <div className={styles.sidebar_scroll}>
          <HomeSidebarItemDroppable
            {...{
              companyId: companyId,
              collapse,
              active: activePage === 'home',
              onClick: onClickLink,
            }}
          />
          <SidebarFolders
            className={styles.sidebar_folderSection}
            companyId={companyId}
            activePage={activePage}
            collapse={collapse}
            onClickCollapsedFolder={onExpand}
            onClickLink={onClickLink}
            zeckFoldersResult={zeckFoldersResult}
            onCreateFolder={onCreateFolder}
            onUpdateFolder={onUpdateFolder}
            onDeleteFolder={onDeleteFolder}
          />

          {userAndCompany.activeCompany.permissions.canManageZecks && (
            <SidebarLink
              {...{
                href: `/company/${companyId}/zeck-trash`,
                className: cx(styles.sidebarPush),
                icon: <TrashIcon />,
                label: 'Trash',
                iconOnly: collapse,
                active: activePage === 'zeck-trash',
                onClick: onClickLink,
                isEditing: false,
              }}
            />
          )}
        </div>

        {(userAndCompany.activeCompany.permissions.canManageMinutes ||
          userAndCompany.activeCompany.permissions.canManageBrand ||
          userAndCompany.activeCompany.permissions.canManagePeople) && (
          <div className={cx(styles.sidebarItem_divider, styles.sidebarPush)} />
        )}

        {userAndCompany.activeCompany.permissions.canManageMinutes && (
          <SidebarLink
            {...{
              tooltip: 'Manage Minutes and Votes',
              href: `/company/${companyId}/minutes`,
              className: cx(styles.sidebarItem_fixed),
              icon: <MinutesBookIcon />,
              label: 'Minutes Book',
              iconOnly: collapse,
              active: activePage === 'minutes_and_voting',
              isEditing: false,
              onClick: onClickLink,
            }}
          />
        )}

        {userAndCompany.activeCompany.permissions.canManageBrand && (
          <SidebarLink
            {...{
              href: `/company/${companyId}/brand-kit`,
              className: styles.sidebarItem_fixed,
              icon: <PaletteIcon />,
              label: 'Brand Kit',
              iconOnly: collapse,
              tooltip: 'Edit Brand Styles',
              active: activePage === 'brand-kit',
              isEditing: false,
              onClick: onClickLink,
            }}
          />
        )}

        {userAndCompany.activeCompany.permissions.canManagePeople && (
          <SidebarLink
            {...{
              className: styles.sidebarItem_fixed,
              href: `/company/${companyId}/people`,
              icon: <PeopleIcon />,
              label: 'People',
              iconOnly: collapse,
              tooltip: 'Invite & Manage Members',
              active: activePage === 'people',
              isEditing: false,
              onClick: onClickLink,
            }}
          />
        )}
      </div>
      <div className={styles.sidebar_footer}>
        <CurrentUserSidebarItem
          {...{
            className: styles.sidebarItem_withSublabel,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            active: activePage === 'profile',
            collapse,
          }}
        />
        <SidebarButton
          {...{
            className: cx(styles.sidebarButton),
            onClick(e) {
              e.preventDefault();
              onClickLogout();
            },
            icon: <LogOutIcon />,
            label: 'Log Out',
            iconOnly: collapse,
          }}
        />
      </div>
    </div>
  );
};
