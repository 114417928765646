import cx from 'classnames';
import { ReactComponent as ZeckLogo } from '../../images/ZeckBlocksLogoBlack.svg';
import useBreakpoints from '../../services/useBreakpoints.js';
import styles from './AccessPage.module.scss';

export const AccessPageLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <div className={cx(styles.page, isMobile && styles.page_mobile)}>
      <header className={styles.page__header}>
        <ZeckLogo className={styles.page__logo} />
      </header>
      <div className={styles.page__content}>{children}</div>
    </div>
  );
};
export const AccessPageContent: React.FC<{
  title: React.ReactNode;
  description: React.ReactNode;
  actions?: React.ReactNode;
  footnote?: React.ReactNode;
}> = ({ title, description, actions, footnote }) => {
  return (
    <div>
      <AccessPageTitle>{title}</AccessPageTitle>
      <AccessPageDescription>{description}</AccessPageDescription>
      {actions && <AccessPageActions>{actions}</AccessPageActions>}
      {footnote && (
        <>
          <hr className={styles.page__hr} />
          <p className={styles.page__footnote}>{footnote}</p>
        </>
      )}
    </div>
  );
};

const AccessPageTitle: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <h1 className={styles.page__title}>{children}</h1>;

const AccessPageDescription: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className={styles.page__subtitle}>{children}</div>;

const AccessPageActions: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className={styles.page__actions}>{children}</div>;
