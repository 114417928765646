import React from 'react';
// It's a bulleted list item block
// Manages the content
// Manages the selection

import ContentSelection from '../../selection/contentSelection/ContentSelection.ts';

import cx from 'classnames';
import {
  BulletedListItemBlock,
  updateTextBlock,
} from 'editor-content/Block.js';
import { BulletedListItem } from '../../../design-system/zeck/BulletedList.tsx';
import mergeRefs from '../../../junkDrawer/mergeRefs.ts';
import styles from '../../../pages/zeck/editor/editableBlocks/SelectableBlock.module.scss';
import useMeetingMinutesContentEditable from '../../useMeetingMinutesContentEditable.ts';

type BulletedListItemEditableProps = {
  value: BulletedListItemBlock;
  onChange: (newBlock: BulletedListItemBlock) => void;
  selection: ContentSelection | null;
  onSelect: (newSelection: ContentSelection) => void;
  isInBlockSelection: boolean;
  className?: string;
};

export default React.forwardRef<HTMLDivElement, BulletedListItemEditableProps>(
  function BulletedListItemEditable(
    { value, onChange, selection, onSelect, className, isInBlockSelection },
    forwardedRef,
  ) {
    const contentEditableProps = useMeetingMinutesContentEditable(
      {
        content: value.content,
        selection,
      },
      ({ content, selection }) => {
        onSelect(selection);
        onChange(updateTextBlock(value, content));
      },
      onSelect,
      { linkables: [] },
    );

    return (
      <BulletedListItem
        {...contentEditableProps}
        ref={mergeRefs([contentEditableProps.ref, forwardedRef])}
        indent={value.indent}
        className={cx(
          className,
          styles.selectableBlock,
          isInBlockSelection && styles.selectableBlock_selected,
        )}
        data-testid="bulleted-list-item-block"
      />
    );
  },
);
