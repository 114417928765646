import { ImageBlock } from 'editor-content/Block.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { useState } from 'react';
import { match } from 'ts-pattern';
import ImageFormattingMenu from '../../../design-system/organisms/ImageFormattingMenu.tsx';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { uploadAndCreateImageBlock } from '../../../editor/addBlock/zeck/uploadAndCreateImageBlock.js';
import {
  ImageUploadErrorModal,
  ImageUploadLoadingModal,
} from '../../../editor/domFacing/components/ImageUploadModal.js';
import { isSameSelection } from '../../../SelectionComment.ts';
import useImageUpload from '../../../services/imageUpload.js';
import { User } from '../../../types.ts';
import { useActiveCompany } from '../../../userAndCompany/activeCompanyAtom.js';
import { canAlignBlockImage } from './canAlignBlockImage.js';
import SelectionCommentsMenu from './comments/components/SelectionCommentsMenu.tsx';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.ts';

type ImageFormattingExperienceProps = {
  block: ImageBlock;
  getEl: () => HTMLElement | undefined;
  onSetImageWidth: (width: ImageBlock['width']) => void;
  onSetImageAlign: (align: ImageBlock['align']) => void;
  onReplaceImage: (newData: Pick<ImageBlock, 'guid' | 'dimensions'>) => void;
  onDeleteImage: () => void;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  user: User;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
};

type BlockInteractiveRenderState =
  | { type: 'formatting' }
  | { type: 'commenting' }
  | { type: 'replacing' }
  | { type: 'replacing-error'; message: string };

const ImageFormattingExperience: React.FC<ImageFormattingExperienceProps> = ({
  getEl,
  block,
  onSetImageWidth,
  onSetImageAlign,
  onReplaceImage,
  onDeleteImage,
  onAddSelectionComment,
  user,
  selectionComments,
  zeckId,
  sectionId,
}) => {
  const [interactiveState, setInteractiveState] =
    useState<BlockInteractiveRenderState>({ type: 'formatting' });

  const uploadImage = useImageUpload();
  const company = useActiveCompany();

  return (
    <>
      {interactiveState.type === 'formatting' && (
        <HoverNextToPoint
          viewportPolicy="none"
          containerStyles={{ zIndex: 'initial' }}
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height - 16,
            ];
          }}
          usePortal
        >
          <ImageFormattingMenu
            {...{
              onClickColumn: () => {
                onSetImageWidth('column');
              },
              onClickWide: () => {
                onSetImageWidth('wide');
              },
              onClickFullWidth: () => {
                onSetImageWidth('full-width');
              },
              onClickReplaceImage: async () => {
                setInteractiveState({ type: 'replacing' });

                const result = await uploadAndCreateImageBlock((file) =>
                  uploadImage(file, company.id),
                )();

                match(result)
                  .with({ type: 'cancel' }, () =>
                    setInteractiveState({ type: 'formatting' }),
                  )
                  .with({ type: 'success' }, (result) => {
                    setInteractiveState({ type: 'formatting' });
                    onReplaceImage(result.data);
                  })
                  .with({ type: 'error' }, (result) => {
                    setInteractiveState({
                      type: 'replacing-error',
                      message: result.message,
                    });
                  })
                  .exhaustive();
              },
              onClickDeleteImage: () => {
                onDeleteImage();
              },
              onClickComment: () => {
                setInteractiveState({ type: 'commenting' });
              },
              onClickLeftAlign: () => {
                onSetImageAlign('left');
              },
              onClickCenterAlign: () => {
                onSetImageAlign('center');
              },
              columnActive: block.width === 'column',
              wideActive: block.width === 'wide',
              fullWidthActive: block.width === 'full-width',
              leftAlignActive: block.align === 'left',
              centerAlignActive:
                block.align === 'center' || typeof block.align === 'undefined',
              canAlign: canAlignBlockImage(block),
            }}
          />
        </HoverNextToPoint>
      )}
      {interactiveState.type === 'commenting' && (
        <HoverNextToPoint
          usePortal
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height + 24,
            ];
          }}
        >
          <SelectionCommentsMenu
            user={user}
            comments={selectionComments.filter((selectionComment) =>
              isSameSelection(selectionComment, block),
            )}
            onPostComment={(content) =>
              onAddSelectionComment(block.id, content)
            }
            autofocus
            zeckId={zeckId}
            sectionId={sectionId}
          />
        </HoverNextToPoint>
      )}
      <ImageUploadLoadingModal isOpen={interactiveState.type === 'replacing'} />
      <ImageUploadErrorModal
        message={
          interactiveState.type === 'replacing-error'
            ? interactiveState.message
            : ''
        }
        onRequestClose={() => setInteractiveState({ type: 'formatting' })}
        isOpen={interactiveState.type === 'replacing-error'}
      />
    </>
  );
};

export default ImageFormattingExperience;
