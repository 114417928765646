import { Block } from 'editor-content/Block.js';
import { Fragment } from 'react';
import BulletedList from '../../../../design-system/zeck/BulletedList.tsx';
import NumberedList from '../../../../design-system/zeck/NumberedList.tsx';
import addSpacing, { getSpacing, Spacing } from './addSpacing.ts';

export type GroupRenderableType = 'item' | 'bulleted-list' | 'numbered-list';

export type GroupRenderableItemBaseType = {
  data: { block: { type: Block['type']; id: string } };
};

export type GroupedRenderables<ItemType> = {
  type: GroupRenderableType;
  content: Array<ItemType>;
}[];

/**
 * For a list of blocks, groups list items and add spacing to all blocks
 * @param items
 * @param renderGroupItem
 */
export const renderBlocksWithGroupingAndSpacing = <
  ItemType extends GroupRenderableItemBaseType,
>(
  items: Array<ItemType>,
  renderGroupItem: React.FC<
    ItemType & {
      i: number;
      groupedRenderableType: GroupRenderableType;
      spacingClassName: string;
    }
  >,
) => {
  const groups = groupListRenderables(addSpacing(items));

  let blockCount = 0;

  return groups.map((groupedRenderable, keyIndex) => {
    let itemKey = '';
    const contents = groupedRenderable.content.map(
      (props: ItemType & { spacing?: Spacing }) => {
        const i = blockCount;
        blockCount++;
        itemKey += props.data.block.id + '_';
        return renderGroupItem({
          ...props,
          spacingClassName: getSpacing(props.spacing),
          i,
          groupedRenderableType: groupedRenderable.type,
        });
      },
    );

    if (groupedRenderable.type === 'bulleted-list') {
      return (
        <BulletedList
          key={keyIndex + 'bulletedList'}
          className={getSpacing('m')}
        >
          {contents}
        </BulletedList>
      );
    }

    if (groupedRenderable.type === 'numbered-list') {
      return (
        <NumberedList
          key={keyIndex + 'numberedList'}
          className={getSpacing('m')}
        >
          {contents}
        </NumberedList>
      );
    }

    return <Fragment key={`${itemKey}item`}>{contents}</Fragment>;
  });
};

export function groupListRenderables<
  ItemType extends GroupRenderableItemBaseType,
>(blockRenderablesWithEl: Array<ItemType>) {
  const groupedRenderables: GroupedRenderables<ItemType> = [];
  let currentGroup: Array<ItemType> = [];
  let currentType: GroupRenderableType = 'item';
  blockRenderablesWithEl.forEach((item) => {
    if (item.data.block.type === 'bulleted-list-item') {
      if (currentType !== 'bulleted-list') {
        if (currentGroup.length) {
          groupedRenderables.push({
            type: currentType,
            content: currentGroup,
          });
          currentGroup = [];
        }
        currentType = 'bulleted-list';
      }
      currentGroup.push(item);
    } else if (item.data.block.type === 'numbered-list-item') {
      if (currentType !== 'numbered-list') {
        if (currentGroup.length) {
          groupedRenderables.push({
            type: currentType,
            content: currentGroup,
          });
          currentGroup = [];
        }
        currentType = 'numbered-list';
      }
      currentGroup.push(item);
    } else {
      if (currentGroup.length) {
        groupedRenderables.push({
          type: currentType,
          content: currentGroup,
        });
        currentGroup = [];
      }

      currentType = 'item';

      groupedRenderables.push({
        type: 'item',
        content: [item],
      });
    }
  });

  if (currentGroup.length) {
    groupedRenderables.push({
      type: currentType,
      content: currentGroup,
    });
  }

  return groupedRenderables;
}
