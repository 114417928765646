import cx from 'classnames';
import { Icon } from 'icons';
import { useRef, useState } from 'react';
import IconButton from '../../../design-system/atoms/IconButton.tsx';
import HoverMenu from '../../../design-system/organisms/HoverMenu.tsx';
import styles from './EditVoteResultInput.module.scss';

type EditVoteResultInputProps = {
  value: boolean;
  onChange: (newValue: boolean) => void;
};

export default function EditVoteResultInput({
  value,
  onChange,
}: EditVoteResultInputProps) {
  const [open, setOpen] = useState(false);
  const menuAnchor = useRef<HTMLLabelElement>(null);

  return (
    <>
      <label ref={menuAnchor} className={styles.input}>
        {value ? (
          <span className={styles.approvedValue}>Approved</span>
        ) : (
          <span className={styles.notApprovedValue}>Not Approved</span>
        )}
        <IconButton
          name="chevronDown"
          aria-label="vote result"
          className={styles.button}
          onClick={() => {
            setOpen(true);
          }}
        />
      </label>
      <HoverMenu
        elementRef={menuAnchor}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        usePortal
        positionStrategy={(anchor) => {
          const rect = anchor.getBoundingClientRect();
          return [rect.x + rect.width - 80, rect.y + rect.height + 10];
        }}
        className={styles.menu}
      >
        <button
          onClick={() => {
            setOpen(false);
            onChange(true);
          }}
          className={cx(styles.option, styles.approvedOption)}
        >
          Approved
          {value && <Icon name="checkmark" className={styles.check} />}
        </button>
        <button
          onClick={() => {
            setOpen(false);
            onChange(false);
          }}
          className={cx(styles.option, styles.notApprovedOption)}
        >
          Not Approved
          {!value && <Icon name="checkmark" className={styles.check} />}
        </button>
      </HoverMenu>
    </>
  );
}
