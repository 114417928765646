import { useState } from 'react';
import TurnIntoMenu, {
  TurnIntoable,
} from '../../design-system/molecules/TurnIntoMenu.js';
import Toolbar, {
  ToolbarDropdownButton,
  ToolbarGroup,
} from '../../design-system/organisms/Toolbar.js';
import styles from './MultiblockFormattingMenu.module.scss';

type Props = {
  turnIntoables: TurnIntoable[];
  additionalActions?: React.ReactNode;
};

export default function MultiblockFormattingMenu({
  turnIntoables,
  additionalActions,
}: Props) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  return (
    <>
      <Toolbar>
        <ToolbarGroup>
          <ToolbarDropdownButton
            onClick={() => {
              setDropdownIsOpen(true);
            }}
          >
            Turn Into
          </ToolbarDropdownButton>
        </ToolbarGroup>

        {additionalActions}
      </Toolbar>

      {dropdownIsOpen && (
        <TurnIntoMenu
          className={styles.turnIntoMenu}
          turnIntoables={turnIntoables}
          onClickMenuItem={() => {
            setDropdownIsOpen(false);
          }}
        />
      )}
    </>
  );
}
