import Linkable from 'editor-content/html/Linkable.js';
import React, { MouseEvent } from 'react';
import LinkTooltipUIComponent from '../../../../design-system/molecules/LinkTooltip.tsx';
import { LinkData } from './getLinkDataFromElement.js';
import useLinkTooltips from './useLinkTooltips.js';

const LinkTooltip: React.FC<{
  linkData: LinkData;
  hideNotFoundTooltip: boolean;
  onClick: (content: Linkable, e: MouseEvent) => void;
}> = ({ linkData, hideNotFoundTooltip, onClick }) => {
  switch (linkData.type) {
    case 'internal': {
      if (linkData.status === 'not-found') {
        if (hideNotFoundTooltip) {
          return null;
        }

        return (
          <LinkTooltipUIComponent
            href="#"
            iconName="danger"
            iconColor="red"
            onClick={(e) => e.preventDefault()}
          >
            This links to{' '}
            {linkData.contentType === 'meeting-minutes-link'
              ? 'deleted meeting minutes'
              : 'a deleted section'}{' '}
            and will not be published as a link.
          </LinkTooltipUIComponent>
        );
      }

      if (linkData.status === 'restricted') {
        return (
          <LinkTooltipUIComponent
            href="#"
            iconName="danger"
            iconColor="gray"
            onClick={(e) => e.preventDefault()}
          >
            You don't have access to the linked{' '}
            {linkData.content.type === 'meeting-minutes'
              ? 'meeting minutes'
              : 'section'}
            .
          </LinkTooltipUIComponent>
        );
      }

      const content = linkData.content;

      return (
        <LinkTooltipUIComponent
          href={content.url}
          iconName={'link'}
          onClick={(e) => onClick(content, e)}
        >
          {content.title}
        </LinkTooltipUIComponent>
      );
    }
    case 'external': {
      return (
        <LinkTooltipUIComponent href={linkData.url} iconName="goTo">
          {linkData.url}
        </LinkTooltipUIComponent>
      );
    }
  }

  return null;
};

type LinkTooltipsProps = {
  onClickLink?: (content: Linkable) => void;
  hideNotFoundTooltip: boolean;
  linkTooltipState: ReturnType<typeof useLinkTooltips>['linkTooltipState'];
};

const LinkTooltips: React.FC<LinkTooltipsProps> = ({
  linkTooltipState,
  onClickLink,
  hideNotFoundTooltip,
}) => {
  const { tooltipStateSignal, closeTooltip, setHoverZoneEl } = linkTooltipState;

  const tooltipState = tooltipStateSignal.useValue();

  if (tooltipState?.type !== 'hovered') return null;

  const { linkRect } = tooltipState;

  return (
    <div
      style={{
        paddingBottom: 8,
        position: 'fixed',
        top: linkRect.y,
        left: linkRect.x + linkRect.width / 2,
        transform: 'translate(-50%, -100%)',
        zIndex: 999,
      }}
      ref={setHoverZoneEl}
      data-testid="tooltip-hover-area"
    >
      <LinkTooltip
        linkData={tooltipState.link}
        hideNotFoundTooltip={hideNotFoundTooltip}
        onClick={(content, e) => {
          closeTooltip();

          if (onClickLink) {
            e.preventDefault();
            onClickLink(content);
          }
        }}
      />
    </div>
  );
};

export default LinkTooltips;
