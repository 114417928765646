import { OutstandingVoteBlock } from 'editor-content/VoteBlock.js';
import React from 'react';
import {
  isCompletedVoteBlock,
  VoteBlockHydrated,
} from '../../../../VoteBlockHydrated.ts';
import {
  generateVoteCapabilitiesForBlock,
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../../voting/VoteCapability.ts';
import CompletedVoteEditable from './CompletedVoteEditable.tsx';
import OutstandingVoteEditable from './OutstandingVoteEditable.tsx';

type VoteEditableProps = {
  className?: string;
  block: VoteBlockHydrated;
  onChange: (newValue: OutstandingVoteBlock) => void;
  tabIndex?: number;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
};

const VoteEditable = React.forwardRef<HTMLDivElement, VoteEditableProps>(
  function VoteEditable(
    {
      block,
      onChange,
      zeckPrevoteCapability,
      zeckFinalizeVoteCapability,
      ...otherProps
    },
    forwardedRef,
  ) {
    if (isCompletedVoteBlock(block)) {
      return (
        <CompletedVoteEditable
          {...otherProps}
          ref={forwardedRef}
          block={block}
        />
      );
    }

    const { prevoteCapability, finalizeVoteCapability } =
      generateVoteCapabilitiesForBlock({
        block,
        zeckFinalizeVoteCapability,
        zeckPrevoteCapability,
      });

    return (
      <OutstandingVoteEditable
        {...otherProps}
        ref={forwardedRef}
        block={block}
        onChange={onChange}
        prevoteCapability={prevoteCapability}
        finalizeVoteCapability={finalizeVoteCapability}
      />
    );
  },
);

export default VoteEditable;
