import { FocusedBlock } from '../../../editor/actions/pressForwardSlash.ts';
import { EditorContent } from '../edit/useEditorState.js';
import BodyEditor from './BodyEditor/BodyEditor.js';
import {
  applyBodyState,
  ZeckEditorState,
} from './ZeckEditor/ZeckEditorState.ts';
import ZeckEditorSelection from './ZeckEditorSelection.js';

const pressForwardSlash = (
  content: EditorContent,
  selection: ZeckEditorSelection,
): [ZeckEditorState, FocusedBlock] | void => {
  if (!selection) return;

  switch (selection.target) {
    case 'body': {
      const result = BodyEditor.pressForwardSlash({
        content: content.body,
        selection: selection,
      });

      if (!result) return;

      const [state, focusedBlock] = result;

      return [applyBodyState(state)({ content, selection }), focusedBlock];
    }
  }
  return;
};

export default pressForwardSlash;
