import { createMachine } from '@xstate/fsm';
import { useCallback, useEffect, useRef } from 'react';
import usePrevious from '../junkDrawer/usePrevious.js';
import useStateMachine from './useStateMachine.js';

type AnimationMachineState =
  | { value: 'entered'; context: Record<string, never> }
  | { value: 'entering'; context: Record<string, never> }
  | { value: 'exiting'; context: Record<string, never> }
  | { value: 'exited'; context: Record<string, never> };

type AnimationMachineEvent =
  | { type: 'TOGGLE' }
  | { type: 'ANIMATION_COMPLETE' };

const animationMachine = createMachine<
  Record<string, never>,
  AnimationMachineEvent,
  AnimationMachineState
>({
  id: 'toggle',
  initial: 'exited',
  states: {
    exited: { on: { TOGGLE: 'entering' } },
    entered: { on: { TOGGLE: 'exiting' } },
    entering: { on: { TOGGLE: 'exiting', ANIMATION_COMPLETE: 'entered' } },
    exiting: { on: { TOGGLE: 'entering', ANIMATION_COMPLETE: 'exited' } },
  },
});

const createUseAnimation =
  (...animateArgs: Parameters<Animatable['animate']>) =>
  <T extends HTMLElement>() => {
    const elementRef = useRef<T>(null);
    const animationRef = useRef<Animation | null>(null);

    return {
      ref: elementRef,
      forward: useCallback((cb: () => void) => {
        const element = elementRef.current;
        if (animationRef.current) {
          animationRef.current.reverse();
        } else {
          if (!element) return;
          animationRef.current = element.animate(...animateArgs);
          animationRef.current.addEventListener('finish', function onFinish() {
            if (!animationRef.current) {
              return;
            }
            animationRef.current.removeEventListener('finish', onFinish);
            animationRef.current = null;
            cb();
          });
        }
      }, []),
      reverse: useCallback((cb: () => void) => {
        const element = elementRef.current;
        if (animationRef.current) {
          animationRef.current.reverse();
        } else {
          if (!element) return;
          animationRef.current = element.animate(...animateArgs);
          animationRef.current.reverse();
          animationRef.current.addEventListener('finish', function onFinish() {
            if (!animationRef.current) {
              return;
            }
            animationRef.current.removeEventListener('finish', onFinish);
            animationRef.current = null;
            cb();
          });
        }
      }, []),
    };
  };

type AnimationReturn<T> = {
  show: boolean;
  elementRef: React.RefObject<T>;
};

const createUseEnterExitAnimation = (
  ...animateArgs: Parameters<Animatable['animate']>
) => {
  const useAnimation = createUseAnimation(...animateArgs);
  return <T extends HTMLElement>(isOpen: boolean): AnimationReturn<T> => {
    const { state, send } = useStateMachine(animationMachine);
    const { ref: elementRef, forward, reverse } = useAnimation<T>();

    const wasOpen = usePrevious(isOpen);

    useEffect(() => {
      switch (state) {
        case 'entering':
          forward(() => {
            send('ANIMATION_COMPLETE');
          });
          break;
        case 'exiting':
          reverse(() => {
            send('ANIMATION_COMPLETE');
          });
          break;
      }
    }, [state, send, forward, reverse]);

    useEffect(() => {
      if (wasOpen === null || wasOpen === isOpen) return;
      send('TOGGLE');
    }, [wasOpen, isOpen, send]);

    return {
      elementRef,
      show: state === 'entered' || state === 'entering' || state === 'exiting',
    };
  };
};
export default createUseEnterExitAnimation;
