import cx from 'classnames';
import Spinner from '../design-system/atoms/Spinner.tsx';
import styles from './LoaderSpinner.module.scss';

const LoaderSpinnerOverlay = ({
  className,
  ...props
}: React.ComponentProps<'div'>) => (
  <div
    data-testid="loader-spinner-overlay"
    {...props}
    className={cx(className, styles.loaderSpinner)}
  >
    <div className={styles.loaderSpinner__spinner}>
      <Spinner />
    </div>
  </div>
);

export default LoaderSpinnerOverlay;
