import { assertUnreachable, Block } from 'editor-content/Block.ts';
import Linkable from 'editor-content/html/Linkable.ts';
import React, { RefObject, useRef } from 'react';
import Agenda from '../../../../design-system/zeck/Agenda.tsx';
import { BulletedListItem } from '../../../../design-system/zeck/BulletedList.tsx';
import CartaCapTableView from '../../../../design-system/zeck/CartaCapTableView.tsx';
import Chart from '../../../../design-system/zeck/chart/chart.tsx';
import Heading from '../../../../design-system/zeck/Heading.tsx';
import ImageView from '../../../../design-system/zeck/ImageView.tsx';
import Label from '../../../../design-system/zeck/Label.tsx';
import { NumberedListItem } from '../../../../design-system/zeck/NumberedList.tsx';
import Paragraph from '../../../../design-system/zeck/Paragraph.tsx';
import TableView from '../../../../design-system/zeck/TableView.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import Divider from '../../Divider.tsx';
import FileWithDownload from '../../FileWithDownload.tsx';
import Video from '../../Video.tsx';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../../voting/VoteCapability.ts';
import VoteView from '../../voting/VoteView.tsx';
import { SelectionCommentsFeatureAtom } from '../publish/commentsSidebar/useComments/selectionComments/SelectionComments.js';
import { withTextBlock } from './withTextBlock.js';

export type BlockViewableProps = {
  block: Block;
  className?: string;
  selectionCommentsFeatureAtom?: SelectionCommentsFeatureAtom;
  scrollViewContainer: RefObject<HTMLElement>;
  linkables: Linkable[];
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  preview?: boolean;
  onFilePreview?: (pdfUrl: string) => void;
};

const BlockViewable = React.forwardRef<HTMLElement, BlockViewableProps>(
  function PublishedBlockViewable(
    {
      block,
      className,
      scrollViewContainer,
      linkables,
      zeckPrevoteCapability,
      zeckFinalizeVoteCapability,
      selectionCommentsFeatureAtom,
      preview,
      onFilePreview,
    },
    forwardedRef,
  ) {
    const ref = useRef<HTMLElement>(null);

    switch (block.type) {
      case 'agenda': {
        return (
          <Agenda
            ref={mergeRefs([forwardedRef])}
            block={block}
            className={className}
            linkables={linkables}
          />
        );
      }
      case 'vote':
        return (
          <VoteView
            className={className}
            block={block}
            ref={mergeRefs([forwardedRef])}
            zeckPrevoteCapability={zeckPrevoteCapability}
            zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
          />
        );
      case 'bulleted-list-item':
        return (
          <BulletedListItemWithBlock
            ref={mergeRefs([forwardedRef])}
            className={className}
            block={block}
            linkables={linkables}
            selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
            indent={block.indent}
          />
        );
      case 'numbered-list-item':
        return (
          <NumberedListItemWithBlock
            ref={mergeRefs([forwardedRef])}
            className={className}
            block={block}
            linkables={linkables}
            selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
            indent={block.indent}
          />
        );
      case 'label':
        return (
          <LabelWithBlock
            ref={mergeRefs([forwardedRef])}
            className={className}
            block={block}
            linkables={linkables}
            selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
          />
        );
      case 'paragraph':
        return (
          <ParagraphWithBlock
            ref={mergeRefs([forwardedRef, ref])}
            className={className}
            block={block}
            linkables={linkables}
            selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
          />
        );
      case 'image':
        return (
          <ImageView
            selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
            className={className}
            block={block}
            preview={preview}
            ref={mergeRefs([forwardedRef])}
            scrollContainerRef={scrollViewContainer}
          />
        );
      case 'file':
        return (
          <FileWithDownload
            className={className}
            block={block}
            ref={mergeRefs([forwardedRef])}
            onPreview={onFilePreview}
          />
        );
      case 'heading':
        return (
          <HeadingWithBlock
            ref={mergeRefs([forwardedRef])}
            className={className}
            block={block}
            linkables={linkables}
            selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
          />
        );
      case 'video':
        return <Video className={className} block={block} />;
      case 'divider':
        return <Divider className={className} block={block} />;
      case 'table':
        return (
          <TableView
            selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
            className={className}
            block={block}
          />
        );
      case 'carta-cap-table':
        return <CartaCapTableView className={className} block={block} />;
      case 'chart':
        return (
          <Chart
            className={className}
            block={block}
            onClick={() => {}}
            preview={false}
          />
        );
    }

    assertUnreachable(block);
  },
);

export default BlockViewable;

const BulletedListItemWithBlock = withTextBlock(BulletedListItem);
const NumberedListItemWithBlock = withTextBlock(NumberedListItem);
const ParagraphWithBlock = withTextBlock(Paragraph);
const HeadingWithBlock = withTextBlock(Heading);
const LabelWithBlock = withTextBlock(Label);
