import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import mergeRefs from '../../../junkDrawer/mergeRefs.js';
import useViewportHeight from '../../../services/useViewportHeight.ts';
import ReactChildrenProp from '../../../types/ReactChildrenProp.ts';
import styles from './EditPage.module.scss';

type Props = {
  sectionId: string;
  children: ReactChildrenProp;
  scrollContainerRef?: React.Ref<HTMLElement>;
  disableScroll?: boolean;
} & React.ComponentProps<'div'>;

const EditorScrollContainer = React.forwardRef<HTMLDivElement, Props>(
  function EditorScrollContainer(
    { sectionId, children, onScroll, disableScroll },
    forwardedRef,
  ) {
    const scrollContainer = useRef<HTMLDivElement>(null);
    useViewportHeight(scrollContainer);

    useEffect(() => {
      scrollContainer.current?.scrollTo({ top: 0 });
    }, [sectionId]);

    return (
      <div
        ref={mergeRefs([scrollContainer, forwardedRef])}
        className={cx(
          styles.zeckEditPage__content,
          disableScroll && styles.zeckEditPage__content_disabled,
        )}
        onScroll={onScroll}
      >
        {children}
      </div>
    );
  },
);
export default EditorScrollContainer;
