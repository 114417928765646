import { PublishedSection, PublishedZeck } from '../../types.ts';
import hydrateBlock from './hydrateBlock.js';
import HydratedPublishedSection from './types/HydratedPublishedSection.js';
import HydratedPublishedZeck from './types/HydratedPublishedZeck.js';

export default function hydratePublishedZeck(
  publishedZeck: PublishedZeck,
): HydratedPublishedZeck {
  return {
    ...publishedZeck,
    sections: publishedZeck.sections.map(
      (section: PublishedSection): HydratedPublishedSection => {
        return {
          ...section,
          body: section.body.map(hydrateBlock(section.votes)),
        };
      },
    ),
  };
}
