import { z } from 'zod';

const AgendaItemCustomSchema = z.object({
  type: z.literal('custom'),
  name: z.string(),
  description: z.string(),
  startTime: z.number().nullable(),
});

const AgendaItemSectionSchema = z.object({
  type: z.literal('section'),
  sectionId: z.string().uuid(),
  description: z.string(),
  startTime: z.number().nullable(),
});

export const AgendaBlockSchema = z.object({
  type: z.literal('agenda'),
  id: z.string().uuid(),
  title: z.string(),
  description: z.string(),
  startTime: z.number().nullable(),
  timeZone: z.string().nullable(),
  items: z.array(z.union([AgendaItemCustomSchema, AgendaItemSectionSchema])),
});
