import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import TimeInput from '../../../../dateTime/TimeInput.tsx';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import HoverMenu, {
  HoverMenuButtonItem,
} from '../../../../design-system/organisms/HoverMenu.tsx';
import { AgendaItem } from '../../../../design-system/zeck/Agenda.tsx';
import { rightAlignedBelowElement } from '../../../../domHelpers/hoverNextTo/positionStrategy/rightAlignedBelow.ts';
import useHover from '../../../../junkDrawer/useHover.ts';
import usePlaintextEditable from '../usePlaintextEditable.ts';
import styles from './AgendaEditable.module.scss';
import placeholderStyles from './Placeholder.module.scss';

type UIState = 'menu-closed' | 'menu-open';

type AgendaItemSectionEditableProps = {
  title: string;
  onClickDelete: () => void;
  description: string;
  startTime: number | null;
  timeZone: string | null;
  onChangeDescription: (newDescription: string) => void;
  onChangeStartTime: (values: {
    startTime: number | null;
    timeZone: string;
  }) => void;
} & Omit<React.ComponentProps<'div'>, 'children'>;

const IconButtonMotion = motion(IconButton);

const AgendaItemSectionEditable = React.forwardRef<
  HTMLDivElement,
  AgendaItemSectionEditableProps
>(function AgendaItemSectionEditable(
  {
    title,
    onClickDelete,
    description,
    startTime,
    timeZone,
    onChangeDescription,
    onChangeStartTime,
    ...props
  },
  ref,
) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isHovered, listeners: hoverListeners } = useHover();
  const [uiState, setUIState] = useState<UIState>('menu-closed');
  const showHover = isHovered || uiState === 'menu-open';
  const showMenu = uiState === 'menu-open';

  const descriptionEditableProps = usePlaintextEditable<HTMLParagraphElement>(
    description,
    onChangeDescription,
  );

  return (
    <>
      <div
        ref={ref}
        {...props}
        data-testid="agenda-item"
        className={cx(
          styles.agendaEditable__item,
          isHovered && styles.agendaEditable__item_hover,
        )}
        {...hoverListeners}
      >
        <AgendaItem
          {...{
            name: (
              <div className={styles.agendaEditable__titleRow}>
                {title}
                <AnimatePresence>
                  {showHover && (
                    <IconButtonMotion
                      ref={buttonRef}
                      name="kebab"
                      aria-label="manage agenda item"
                      onClick={() => {
                        setUIState('menu-open');
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    />
                  )}
                </AnimatePresence>
              </div>
            ),
            description: (
              <div
                {...descriptionEditableProps}
                className={placeholderStyles.placeholder}
                /*
                  // @ts-expect-error placeholder is valid */
                placeholder="Item description"
              />
            ),
            startTime: (
              <TimeInput
                label="Start time"
                time={startTime}
                timeZone={timeZone}
                onChange={({ time, timeZone }) =>
                  onChangeStartTime({ startTime: time, timeZone })
                }
              />
            ),
          }}
        />
      </div>
      <HoverMenu
        elementRef={buttonRef}
        positionStrategy={rightAlignedBelowElement(8)}
        onClose={() => {
          setUIState('menu-closed');
        }}
        isOpen={showMenu}
      >
        <HoverMenuButtonItem
          iconName="trash"
          color="danger"
          onClick={onClickDelete}
        >
          Delete
        </HoverMenuButtonItem>
      </HoverMenu>
    </>
  );
});

export default AgendaItemSectionEditable;
