import { Navigate } from 'react-router-dom';
import useApi from '../../api/useApi.ts';
import LinkButton from '../../design-system/atoms/LinkButton.js';
import {
  AccessPageContent,
  AccessPageLayout,
} from '../../design-system/organisms/AccessPage.js';
import WithResultLoading from '../../result/WithResultLoading.js';
import { useRequiredParams } from '../../routing/useRequiredParams.ts';
import useFetch from '../../services/useFetch/useFetch.ts';
import usePageTracking from '../../services/usePageTracking.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import LoaderSpinnerOverlay from '../LoaderSpinnerOverlay.js';

const AccessRequestPage: React.FC<{ userAndCompany: UserAndCompany }> = ({
  userAndCompany,
}) => {
  usePageTracking('grant_access', userAndCompany);

  const { accessRequestId } = useRequiredParams('accessRequestId');
  const { grantAccessRequest } = useApi();

  const result = useFetch(() => grantAccessRequest(accessRequestId), []);

  return (
    <AccessPageLayout>
      <WithResultLoading
        result={result}
        duration={400}
        renderError={() => <Navigate to="/404" />}
        renderLoading={() => <LoaderSpinnerOverlay />}
      >
        {({ zeckName, requesterEmail, zeckUrl }) => (
          <AccessPageContent
            {...{
              title: 'Access Granted',
              description: (
                <>
                  You’ve successfully granted Viewer access to{' '}
                  <b>
                    <i>{zeckName}</i>
                  </b>{' '}
                  to <b>{requesterEmail}</b>. They will get an email
                  notification that they now have access.
                </>
              ),
              actions: (
                <LinkButton size={'large'} to={zeckUrl} isFullWidth>
                  Go to My Zeck
                </LinkButton>
              ),
            }}
          ></AccessPageContent>
        )}
      </WithResultLoading>
    </AccessPageLayout>
  );
};

export default AccessRequestPage;
