import { Block } from 'editor-content/Block.js';
import { HydratedBlock } from '../../types/HydratedBlock.js';
import Vote from '../../types/Vote.js';

const hydrateBlock =
  (voteResults: Vote[]) =>
  (block: Block): HydratedBlock => {
    if (block.type !== 'vote') return block;

    const voteResult = voteResults.find(({ blockId }) => blockId === block.id);

    if (!voteResult) {
      return {
        ...block,
      };
    }

    return {
      ...block,
      title: voteResult.title,
      details: voteResult.details,
      approved: voteResult.approved,
      votedAt: voteResult.createdAt,
    };
  };

export default hydrateBlock;
