import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import HoverNextToPoint from '../../domHelpers/hoverNextTo/HoverNextToPoint.js';
import { getRectFromEl } from '../../domHelpers/Rect.js';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import getBlockHoverControlsLocation from './getBlockHoverControlsLocation.js';
import useHoveredBlockIndex from './useHoveredBlockIndex.js';

const HoversNextToBlocks: React.FC<{
  blocksWithEl: ElementAndData<unknown>[];
  leftGutterRef: React.RefObject<HTMLElement>;
  getEditorContainer: () => HTMLElement | null;
  children: (hoveredIndex: number) => React.ReactNode;
}> = ({ blocksWithEl, leftGutterRef, getEditorContainer, children }) => {
  const hoveredIndex = useHoveredBlockIndex(blocksWithEl, leftGutterRef);

  return (
    <AnimatePresence>
      {hoveredIndex >= 0 && (
        <motion.div
          key={hoveredIndex}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.2 } }}
          // exit={{ opacity: 0, transition: { duration: 0.1 } }}
          // 24-07-24: disabled because of a bug in framer motion
          // will be fixed when this is merged https://github.com/framer/motion/pull/2741
        >
          <HoverNextToPoint
            containerStyles={{ zIndex: 'initial' }} // glob save us from z index
            viewportPolicy="none"
            getPoint={(popoverEl) => {
              const blockEl = blocksWithEl[hoveredIndex]?.getEl();
              const leftGutterEl = leftGutterRef.current;
              const editorContainerEl = getEditorContainer();
              if (!blockEl || !leftGutterEl || !editorContainerEl) return null;

              const blockRect = getRectFromEl(blockEl);
              const leftGutterRect = getRectFromEl(leftGutterEl);
              const editorContainerRect = getRectFromEl(editorContainerEl);

              const buttonSize = popoverEl.getBoundingClientRect();

              return getBlockHoverControlsLocation(
                blockRect,
                leftGutterRect,
                editorContainerRect,
                buttonSize,
              );
            }}
          >
            {children(hoveredIndex)}
          </HoverNextToPoint>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HoversNextToBlocks;
