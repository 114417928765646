import BaseTextBlock from 'editor-content/BaseTextBlock.js';
import { updateTextBlock } from 'editor-content/Block.js';
import { Text } from 'editor-content/TextNode.js';
import { ContentPatch } from '../../ContentPatch.js';
import ContentSelection, {
  contentSelection,
  getStartOfSelection,
} from '../../selection/contentSelection/ContentSelection.js';
import { textSelection } from '../../selection/TextSelection.js';
import splitTextNodesFromContentSelection from '../textNode/splitTextNodesFromContentSelection.js';

const textBlockPressShiftEnterStrategy = <BlockType extends BaseTextBlock>(
  block: BlockType,
  selection: ContentSelection,
): ContentPatch<[BlockType]> => {
  const [before, , after] = splitTextNodesFromContentSelection(
    block.content,
    selection,
  );

  return {
    contentSubset: [updateTextBlock(block, [...before, Text('\n'), ...after])],
    selection: textSelection(
      0,
      contentSelection(getStartOfSelection(selection) + 1),
    ),
  };
};

export default textBlockPressShiftEnterStrategy;
