import cx from 'classnames';
import { Icon } from 'icons';
import React from 'react';
import IconButton from '../atoms/IconButton.tsx';
import WithTooltip from '../organisms/WithTooltip.tsx';
import styles from './File.module.scss';

type FileActionProps =
  | {
      clickable: false;
      onPreview?: never;
    }
  | {
      clickable: true;
      onPreview: (e: React.MouseEvent<HTMLButtonElement>) => void;
    };

type FileProps = {
  className?: string;
  filename: string;
} & FileActionProps &
  React.HTMLAttributes<HTMLDivElement>;

const File = React.forwardRef<HTMLDivElement, FileProps>(function File(
  { className, filename, clickable, onPreview, ...otherProps },
  forwardedRef,
) {
  const downloadTipText = clickable
    ? 'Download'
    : 'Viewers will be able to download this file.';
  const previewTipText = clickable
    ? 'View'
    : 'Viewers will be able to preview this file.';

  const isPdf = filename.endsWith('.pdf');

  return (
    <div
      {...otherProps}
      ref={forwardedRef}
      className={cx(styles.file, clickable && styles.file_clickable, className)}
    >
      <Icon name="paperclip" />
      <span>{filename}</span>

      <div className={styles.file__actions}>
        <WithTooltip<HTMLButtonElement>
          text={downloadTipText}
          tooltipClassName={styles.file__tooltipContent}
        >
          {(ref, listeners) => (
            <IconButton
              name="download"
              aria-label="Download"
              type="normal"
              onClick={() => {}}
              className={styles.file__actionIcon}
              ref={ref}
              {...listeners}
            />
          )}
        </WithTooltip>

        {isPdf && (
          <WithTooltip<HTMLButtonElement>
            text={previewTipText}
            tooltipClassName={styles.file__tooltipContent}
          >
            {(ref, listeners) => (
              <IconButton
                name="eye"
                aria-label="View"
                type="normal"
                onClick={onPreview}
                className={styles.file__actionIcon}
                ref={ref}
                {...listeners}
              />
            )}
          </WithTooltip>
        )}
      </div>
    </div>
  );
});

export default File;
