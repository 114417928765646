import { useRef, useState } from 'react';
import IconButton from '../../design-system/atoms/IconButton.tsx';
import HoverMenu, {
  HoverMenuButtonItem,
} from '../../design-system/organisms/HoverMenu.tsx';

function within(x: number, y: number, range: number): boolean {
  return Math.abs(x - y) <= range;
}

type VoteResultMenuProps = {
  onEditVote: () => void;
  onDeleteVote: () => void;
};

const VoteResultMenu: React.FunctionComponent<VoteResultMenuProps> = ({
  onEditVote,
  onDeleteVote,
}) => {
  const itemRef = useRef<HTMLDivElement>(null);
  const kebabButtonRef = useRef<HTMLButtonElement>(null);
  const previousPosition = useRef<[number, number]>();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div ref={itemRef}>
      <IconButton
        ref={kebabButtonRef}
        name="kebab"
        aria-label="vote result menu"
        onClick={() => {
          setShowMenu(true);
        }}
      />
      <HoverMenu
        elementRef={itemRef}
        positionStrategy={(el) => {
          const rect = el.getBoundingClientRect();
          const position: [number, number] = [
            rect.x + rect.width - rect.height / 2,
            rect.y + rect.height / 2,
          ];
          if (
            previousPosition.current &&
            (!within(position[0], previousPosition.current[0], 5) ||
              !within(position[1], previousPosition.current[1], 5))
          ) {
            setShowMenu(false);
          }
          previousPosition.current = position;
          return position;
        }}
        onClose={() => {
          setShowMenu(false);
        }}
        isOpen={showMenu}
      >
        <HoverMenuButtonItem
          iconName="edit"
          color="normal"
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu(false);
            onEditVote();
          }}
        >
          Edit
        </HoverMenuButtonItem>
        <HoverMenuButtonItem
          iconName="trash"
          color="danger"
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu(false);
            onDeleteVote();
          }}
        >
          Delete
        </HoverMenuButtonItem>
      </HoverMenu>
    </div>
  );
};
export default VoteResultMenu;
