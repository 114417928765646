import { pipe } from '../../../../result/Result.js';
import { EditorContent } from '../../edit/useEditorState.tsx';

import { BodyState, BodyStateSelected } from '../BodyEditor/BodyEditor.ts';
import {
  HeadlineState,
  HeadlineStateSelected,
} from '../HeadlineEditor/HeadlineEditor.ts';
import { TitleState, TitleStateSelected } from '../TitleEditor/TitleEditor.ts';
import ZeckEditorSelection from '../ZeckEditorSelection.ts';
import { applyBodyState } from './applyBodyState.ts';
import {
  applyHeadlineState,
  applyTitleState,
  ZeckEditorState,
} from './ZeckEditorState.ts';

export const selectionBasedAction =
  <ArgsType extends unknown[]>(handlers: {
    title?: (state: TitleStateSelected, ...args: ArgsType) => TitleState | void;
    headline?: (
      state: HeadlineStateSelected,
      ...args: ArgsType
    ) => HeadlineState | void;
    body?: (state: BodyStateSelected, ...args: ArgsType) => BodyState | void;
  }) =>
  (
    content: EditorContent,
    selection: ZeckEditorSelection,
    ...args: ArgsType
  ) => {
    if (!selection) return;

    switch (selection.target) {
      case 'title': {
        if (!handlers.title) return;

        const result = handlers.title(
          { content: content.title, selection },
          ...args,
        );

        if (!result) return;

        return pipe({ content, selection }, applyTitleState(result));
      }
      case 'headline': {
        if (!handlers.headline) return;

        const result = handlers.headline(
          {
            content: content.headline,
            selection,
          },
          ...args,
        );

        if (!result) return;

        return pipe({ content, selection }, applyHeadlineState(result));
      }
      case 'body': {
        if (!handlers.body) return;

        const result = handlers.body(
          {
            content: content.body,
            selection,
          },
          ...args,
        );

        if (!result) return;

        return applyBodyState({ content, selection }, result);
      }
    }
  };

export const selectionBasedActionWithEffect =
  <ArgsType extends unknown[], ReturnType>(handlers: {
    title?: (
      state: TitleStateSelected,
      ...args: ArgsType
    ) => [TitleState, ReturnType] | void;
    headline?: (
      state: HeadlineStateSelected,
      ...args: ArgsType
    ) => [HeadlineState, ReturnType] | void;
    body?: (
      state: BodyStateSelected,
      ...args: ArgsType
    ) => [BodyState, ReturnType] | void;
  }) =>
  (
    content: EditorContent,
    selection: ZeckEditorSelection,
    ...args: ArgsType
  ): [ZeckEditorState, ReturnType] | void => {
    if (!selection) return;

    switch (selection.target) {
      case 'title': {
        if (!handlers.title) return;

        const result = handlers.title(
          { content: content.title, selection },
          ...args,
        );

        if (!result) return;

        const [newState, effect] = result;
        return [
          pipe({ content, selection }, applyTitleState(newState)),
          effect,
        ];
      }
      case 'headline': {
        if (!handlers.headline) return;

        const result = handlers.headline(
          { content: content.headline, selection },
          ...args,
        );

        if (!result) return;

        const [newState, effect] = result;
        return [
          pipe({ content, selection }, applyHeadlineState(newState)),
          effect,
        ];
      }
      case 'body': {
        if (!handlers.body) return;

        const result = handlers.body(
          { content: content.body, selection },
          ...args,
        );

        if (!result) return;

        const [newState, effect] = result;
        return [applyBodyState({ content, selection }, newState), effect];
      }
    }
  };
