import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import {
  EditorConfiguration,
  EditorState,
  MaybeHandled,
} from '../EditorAction.js';
import { isTextSelection } from '../EditorSelection.js';
import { blockSelection, BlockSelection } from '../selection/BlockSelection.js';
import ContentSelection from '../selection/contentSelection/ContentSelection.js';

export type PressShiftArrowDownBlockInterface = {
  pressShiftArrowDown: (selection: ContentSelection) => boolean;
};

export default function pressShiftArrowDown<BlockType>({
  generateBlockEditor,
}: EditorConfiguration<BlockType, PressShiftArrowDownBlockInterface>) {
  return (
    initialState: EditorState<BlockType>,
  ): MaybeHandled<EditorState<BlockType, BlockSelection>> => {
    const { content, selection } = initialState;

    if (!selection) return;

    if (isTextSelection(selection)) {
      const selectedBlock = getSelectedBlock(content, selection);

      if (!selectedBlock) return;

      const targetBlockEditor = generateBlockEditor(selectedBlock);

      if (!targetBlockEditor.pressShiftArrowDown(selection.offset)) return;

      return {
        content,
        selection: blockSelection(selection.index, selection.index),
      };
    }

    if (selection.focusIndex >= content.length - 1) {
      return;
    }

    return {
      content,
      selection: blockSelection(
        selection.anchorIndex,
        selection.focusIndex + 1,
      ),
    };
  };
}
