import BaseBlock from 'editor-content/BaseBlock.js';
import insertItem from '../../junkDrawer/sortable/insertItem.js';
import replaceBlockAt from '../../pages/zeck/editor/BodyEditor/replaceBlockAt.js';
import { EditorConfiguration, EditorState } from '../EditorAction.js';
import ContentSelection, {
  contentSelection,
} from '../selection/contentSelection/ContentSelection.js';
import { textSelection } from '../selection/TextSelection.js';

type Selected<State extends EditorState<unknown>> =
  State extends EditorState<infer BlockType, infer SelectionType>
    ? {
        content: BlockType[];
        selection: Exclude<SelectionType, null>;
      }
    : State;

export type AddBlockBlockEditorInterface = {
  isEmptyDefaultBlock: () => boolean;
  getEndOfBlockSelection: () => ContentSelection;
};

const addNewBlock =
  <BlockType>({
    generateBlockEditor,
  }: EditorConfiguration<
    BlockType,
    Pick<AddBlockBlockEditorInterface, 'isEmptyDefaultBlock'>
  >) =>
  (
    initialState: EditorState<BlockType>,
    targetIndex: number,
    newBlock: BlockType,
  ): [newState: Selected<EditorState<BlockType>>, void] => {
    const targetBlock = initialState.content[targetIndex];
    if (!targetBlock) throw new Error('tried to add new block out of range');

    if (generateBlockEditor(targetBlock).isEmptyDefaultBlock()) {
      return [
        {
          content: replaceBlockAt(
            initialState.content,
            [newBlock],
            targetIndex,
          ),
          selection: textSelection(targetIndex, contentSelection(0)),
        },
        undefined,
      ];
    }

    return [
      {
        content: insertItem(initialState.content, targetIndex + 1, newBlock),
        selection: textSelection(targetIndex + 1, contentSelection(0)),
      },
      undefined,
    ];
  };

const replaceNewBlock =
  <BlockType extends BaseBlock>({
    generateBlockEditor,
  }: EditorConfiguration<
    BlockType,
    Pick<AddBlockBlockEditorInterface, 'getEndOfBlockSelection'>
  >) =>
  (
    initialState: EditorState<BlockType>,
    newContent: BlockType[],
    targetId: string,
  ): [newState: Selected<EditorState<BlockType>>, returnValue: void] => {
    const targetBlockIndex = initialState.content.findIndex(
      (block) => block.id === targetId,
    );

    if (targetBlockIndex < 0) throw new Error('new block was deleted');

    const lastBlock = newContent[newContent.length - 1];

    return [
      {
        content: replaceBlockAt(
          initialState.content,
          newContent,
          targetBlockIndex,
        ),
        selection: textSelection(
          targetBlockIndex + newContent.length - 1,
          (lastBlock &&
            generateBlockEditor(lastBlock).getEndOfBlockSelection()) ??
            contentSelection(0),
        ),
      },
      undefined,
    ];
  };

const AddBlockEditorActions = {
  addNewBlock,
  replaceNewBlock,
};

export default AddBlockEditorActions;
