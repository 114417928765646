import { useEffect, useRef } from 'react';
import IntegrationLoader from '../IntegrationLoader.tsx';
import { DocumentMeta } from '../integrationModalTypes.ts';
import getDocumentMetaFromSheetResponse from '../utils/google/getDocumentMetaFromSheetResponse.ts';
import { apiKey } from '../utils/google/googleEnvVariables.ts';
import { GoogleApis } from '../utils/google/useLoadGoogleAPIs.ts';
import './GoogleDocumentPicker.module.scss';

type GoogleDocumentPickerProps = {
  googleApis: GoogleApis;
  accessToken: string;
  onClose: () => void;
  onPickDocument: (documentMeta: DocumentMeta) => void;
};

const GoogleDocumentPicker: React.FC<GoogleDocumentPickerProps> = ({
  googleApis,
  accessToken,
  onClose,
  onPickDocument,
}) => {
  const onCloseRef = useRef(onClose);
  const onPickDocumentRef = useRef(onPickDocument);

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);
  useEffect(() => {
    onPickDocumentRef.current = onPickDocument;
  }, [onPickDocument]);

  useEffect(() => {
    if (apiKey) {
      const picker = new googleApis.google.picker.PickerBuilder()
        .addView(
          new googleApis.google.picker.DocsView(
            googleApis.google.picker.ViewId.SPREADSHEETS,
          )
            .setMimeTypes('application/vnd.google-apps.spreadsheet')
            .setMode(googleApis.google.picker.DocsViewMode.LIST),
        )
        .setOAuthToken(accessToken)
        .setDeveloperKey(apiKey)
        .enableFeature(googleApis.google.picker.Feature.NAV_HIDDEN)
        .hideTitleBar()
        .setCallback(async (pickerResponse) => {
          const action = pickerResponse.action;

          if (action === 'cancel') {
            return onCloseRef.current();
          }

          const documentId = pickerResponse.docs?.[0]?.id;

          if (action === 'picked' && documentId) {
            const sheetResponse =
              await googleApis.gapi.client.sheets.spreadsheets.get({
                spreadsheetId: documentId,
              });

            onPickDocumentRef.current(
              getDocumentMetaFromSheetResponse(sheetResponse.result),
            );
          }
        })
        .build();

      picker.setVisible(true);

      return () => {
        picker.setVisible(false);
      };
    }
  }, [accessToken, googleApis]);

  return <IntegrationLoader />;
};

export default GoogleDocumentPicker;
