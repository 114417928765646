import throttle from 'lodash/throttle.js';
import { isWithinHoverLayer } from '../../domHelpers/hoverNextTo/HoverLayer.js';
import Point from '../../domHelpers/Point.js';
import Rect, { getRectFromEl } from '../../domHelpers/Rect.js';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import { BlockSelection } from './BlockSelection.js';
import useMouseSelection from './useMouseSelection/useMouseSelection.js';
import useMouseSelectionDeadzone from './useMouseSelection/useMouseSelectionDeadzone.js';
import useResolveCoordinatesRelativeToDocument from './useMouseSelection/useResolveCoordinatesRelativeToDocument.js';

const throttledWarn = throttle(
  (...args: Parameters<typeof console.warn>) => console.warn(...args),
  500,
);

const getDefaultDeadzone = (p: Point): Rect => [
  [p[0] - 10, p[1] - 10],
  [p[0] + 10, p[1] + 10],
];

export const isInInputOrTextArea = (e: React.MouseEvent): boolean => {
  const target = e.target as HTMLElement;

  // currently ignoring drags in all overlays/portals as we don't want to trigger
  // selection changes for those (selecting text of a comment for instance).
  return !!(
    target.closest('input') ||
    target.closest('textarea') ||
    target.closest('button') ||
    target.closest('.ReactModalPortal') ||
    isWithinHoverLayer(target)
  );
};

const getContenteditableDeadzone = (e: React.MouseEvent): Rect | null => {
  const closestContentEditable = (e.target as HTMLElement).closest(
    '[contenteditable="true"]',
  );

  if (closestContentEditable) {
    const rect = closestContentEditable.getBoundingClientRect();
    return [
      [rect.left - 30, rect.top - 30],
      [rect.right + 30, rect.bottom + 30],
    ];
  }

  return null;
};

function isPrimaryMouseButtonPressed(e: React.MouseEvent): boolean {
  // why is this insane code this way: https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
  // buttons is like a bitwise flags field
  return (e.buttons & 1) === 1;
}

function useMouseSelectionOnEditor(
  documentRef: React.RefObject<HTMLDivElement>,
  blocksWithEl: ElementAndData<object>[],
  onSelectBody: (selection: BlockSelection | null) => void,
) {
  const { selectionStart, selectionMove, selectionEnd, isSelecting } =
    useResolveCoordinatesRelativeToDocument(
      useMouseSelectionDeadzone(useMouseSelection),
    )(
      () => {
        const documentEl = documentRef.current;

        // Block elements should never be null, but if we get a null, this
        // null-object pattern hopes that the math does not raise a runtime error.
        if (!documentEl) return [NaN, NaN];
        const rect = documentEl.getBoundingClientRect();
        return [rect.left, rect.top];
      },
      blocksWithEl.map(({ data, getEl }) => () => {
        const el = getEl();

        if (el) {
          return getRectFromEl(el);
        }

        // Block elements should never be null, but if we get a null, this
        // null-object pattern hopes that the math does not raise a runtime error.
        throttledWarn(`Element does not exist for block:
${JSON.stringify(data)}`);
        return [
          [NaN, NaN],
          [NaN, NaN],
        ];
      }),
      onSelectBody,
    );

  return {
    onMouseDown: (e: React.MouseEvent) => {
      const { clientX: x, clientY: y } = e;

      if (isInInputOrTextArea(e)) return null;

      selectionStart(
        [x, y],
        getContenteditableDeadzone(e) || getDefaultDeadzone([x, y]),
      );
    },

    onMouseMove: (e: React.MouseEvent) => {
      if (isPrimaryMouseButtonPressed(e)) {
        selectionMove([e.clientX, e.clientY]);
      } else {
        selectionEnd();
      }
    },
    onMouseUp: () => {
      selectionEnd();
    },
    isSelecting,
  };
}

export default useMouseSelectionOnEditor;
