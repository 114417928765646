import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from '../../design-system/atoms/Button.tsx';
import SessionLayout from '../../design-system/layouts/SessionLayout.tsx';
import useBreakpoints from '../../services/useBreakpoints.ts';
import styles from './ResetPasswordPage.module.scss';

const ResetPasswordInvalid = () => {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();

  return (
    <SessionLayout>
      <h1 className={cx(isMobile ? styles.title_mobile : styles.title)}>
        Reset Link is Invalid
      </h1>
      <p className={styles.resetPassword__description}>
        This reset password link is invalid. Click below to request new reset
        password instructions be sent to you.
      </p>
      <Button
        {...{
          className: styles.resetPassword__submit,
          size: 'large',
          color: 'primary',
          onClick: () => {
            navigate('/reset-password-request');
          },
        }}
      >
        Go to Password Reset Request Page
      </Button>
    </SessionLayout>
  );
};

export default ResetPasswordInvalid;
