import { useEffect } from 'react';
import { highlightSelector } from '../../../highlights/highlights.js';

export const useSelectedCommentHighlight = (
  selectedCommentId: string | null,
): void => {
  useEffect(() => {
    if (!selectedCommentId) return;

    const style = document.createElement('style');

    style.innerHTML = `
      span${highlightSelector(selectedCommentId)} {
        background-color: var(--color-yellow-3) !important;
        transition: background-color 0.4s ease-in-out;
      }
    `;

    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [selectedCommentId]);
};
