import { Block, isTextBlock } from 'editor-content/Block.js';
import isEqual from 'lodash/isEqual.js';
import { useEffect } from 'react';
import { EditorSelection } from '../../../editor/EditorSelection.js';
import { isBlockSelection } from '../../../editor/selection/BlockSelection.js';
import ContentSelection from '../../../editor/selection/contentSelection/ContentSelection.js';
import { textBlockGetSelectionRectStrategy } from '../../../editor/selection/getSelectionRect.js';
import { isTextSelection } from '../../../editor/selection/TextSelection.js';
import { ElementAndData } from '../../../junkDrawer/useElementAndDataArray.js';
import usePrevious from '../../../junkDrawer/usePrevious.js';
import {
  getScrollOffsetToPutBlockInView,
  getScrollOffsetToPutTextSelectionInView,
} from './getScrollOffset.js';

function keepTextSelectionInView(
  block: Block,
  selection: ContentSelection,
  blockEl: HTMLElement,
  scrollContainerEl: HTMLElement,
) {
  const scrollContainerRect = scrollContainerEl.getBoundingClientRect();

  if (isTextBlock(block)) {
    const rect = textBlockGetSelectionRectStrategy(selection, blockEl);
    const scrollOffset = getScrollOffsetToPutTextSelectionInView(
      rect,
      scrollContainerRect,
      {
        top: scrollContainerEl.scrollTop,
      },
    );

    if (!scrollOffset) return;

    scrollContainerEl.scrollTo({
      ...scrollOffset,
    });
  } else {
    const scrollOffset = getScrollOffsetToPutBlockInView(
      blockEl.getBoundingClientRect(),
      scrollContainerRect,
      {
        top: scrollContainerEl.scrollTop,
      },
    );

    if (!scrollOffset) return;

    scrollContainerEl.scrollTo({
      ...scrollOffset,
    });
  }
}

function keepBlockSelectionInView(
  scrollContainerEl: HTMLElement,
  selectionFocusBlockEl: HTMLElement,
) {
  const scrollContainerRect = scrollContainerEl.getBoundingClientRect();

  const scrollOffset = getScrollOffsetToPutBlockInView(
    selectionFocusBlockEl.getBoundingClientRect(),
    scrollContainerRect,
    {
      top: scrollContainerEl.scrollTop,
    },
  );

  if (!scrollOffset) return;

  scrollContainerEl.scrollTo({
    ...scrollOffset,
  });
}

function useKeepSelectionInView(
  blocksWithEl: ElementAndData<Block>[],
  selection: EditorSelection,
  isMouseSelecting: boolean,
  getScrollContainer: () => HTMLElement | null,
) {
  const prevSelection = usePrevious(selection);
  useEffect(() => {
    if (isMouseSelecting) return;
    if (isEqual(selection, prevSelection)) return;

    if (isBlockSelection(selection)) {
      const selectionFocusBlockWithEl = blocksWithEl[selection.focusIndex];

      const selectionFocusBlock = selectionFocusBlockWithEl?.data;
      const selectionFocusBlockEl = selectionFocusBlockWithEl?.getEl();
      const scrollContainerEl = getScrollContainer();
      if (!selectionFocusBlockEl || !scrollContainerEl || !selectionFocusBlock)
        return;

      keepBlockSelectionInView(scrollContainerEl, selectionFocusBlockEl);
    } else if (isTextSelection(selection)) {
      const selectedBlockWithEl = blocksWithEl[selection.index];

      const selectedBlock = selectedBlockWithEl?.data;
      const selectedBlockEl = selectedBlockWithEl?.getEl();
      const scrollContainerEl = getScrollContainer();

      if (!selectedBlockEl || !scrollContainerEl || !selectedBlock) return;

      keepTextSelectionInView(
        selectedBlockWithEl.data,
        selection.offset,
        selectedBlockEl,
        scrollContainerEl,
      );
    }
  }, [
    selection,
    prevSelection,
    isMouseSelecting,
    blocksWithEl,
    getScrollContainer,
  ]);
}

export default useKeepSelectionInView;
