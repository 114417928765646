import { useState } from 'react';
import useApi from '../../../api/useApi.ts';
import Button from '../../../design-system/atoms/Button.tsx';
import InputText from '../../../design-system/atoms/InputText.tsx';
import AdminPanel from '../adminPanel/AdminPanel.tsx';
import styles from './CreateCompany.module.scss';

type CreateCompanyProps = {
  setActiveCompany: (company: { id: string }) => void;
  hasSeparator?: boolean;
};

const CreateCompany: React.FunctionComponent<CreateCompanyProps> = ({
  setActiveCompany,
}) => {
  const { createCompany } = useApi();
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AdminPanel
      title={'15° Down Angle on the Bow Planes'}
      subtitle={'(Create a Company)'}
    >
      <form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();
          setIsLoading(true);
          setActiveCompany(await createCompany(companyName));
        }}
      >
        <InputText
          label="Company Name"
          disabled={isLoading}
          onChange={(newName) => {
            setCompanyName(newName);
          }}
          value={companyName}
        />
        <Button size="large" type="submit" color="primary" disabled={isLoading}>
          Create Company
        </Button>
      </form>
    </AdminPanel>
  );
};

export default CreateCompany;
