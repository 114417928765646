import {
  Block,
  ImageBlock,
  isImageBlock,
  isTextBlock,
  TextBlock,
} from 'editor-content/Block.ts';
import { Editor } from 'editor-content/TextNode.js';
import { equals } from 'lodash/fp.js';
import { match } from 'ts-pattern';

type SelectionBlockContext =
  | ImageBlock
  | TextBlock
  | { type: 'table-cell'; id: string; content: Editor.Text[] };

export const isBlockChangedForComments = (
  selectionBlock: SelectionBlockContext,
  sectionBlock: Block,
): boolean => {
  return match(selectionBlock)
    .with({ type: 'table-cell' }, () => false)
    .when(isTextBlock, (selectionBlock) => {
      if (!isTextBlock(sectionBlock)) return true;
      return !equals(selectionBlock.content, sectionBlock.content);
    })
    .when(isImageBlock, (selectionBlock) => {
      if (!isImageBlock(sectionBlock)) return true;
      return selectionBlock.guid !== sectionBlock.guid;
    })
    .exhaustive();
};
