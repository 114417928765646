import {
  ErrorCredentialResponse,
  ValidCredentialResponse,
} from '../../../../../api/endpoints/createCredentialApi.js';
import useApi from '../../../../../api/useApi.js';
import useAuthForCredential from './utils/useAuthForCredential.ts';

const useAddCredential = (
  onSuccess: (
    credential: ValidCredentialResponse | ErrorCredentialResponse,
  ) => void,
  type: 'microsoft' | 'google' | 'carta',
) => {
  const { createCredential } = useApi();

  const { doAuth, isLoading } = useAuthForCredential(onSuccess);

  const addCredential = async () => {
    const credentialResponse = await createCredential(type);
    doAuth(credentialResponse);
  };

  return {
    addCredential,
    isAddingCredential: isLoading,
  };
};

export default useAddCredential;
