export type Ord<A> = (a: A, b: A) => Ordering;

export type Ordering = -1 | 0 | 1;

export const concatAllOrd =
  <T>(...ords: Ord<T>[]): Ord<T> =>
  (a, b) => {
    for (const cmp of ords) {
      const result = cmp(a, b);
      if (result !== 0) return result;
    }
    return 0;
  };

export const concatOrd = <A, B>(ord1: Ord<A>, ord2: Ord<B>): Ord<A & B> =>
  concatAllOrd<A & B>(ord1, ord2);

export const ordNumberAsc: Ord<number> = (num1, num2) => {
  if (num1 > num2) return 1;
  if (num1 < num2) return -1;
  return 0;
};

export const ordNumberDesc: Ord<number> = (num1, num2) => {
  if (num1 > num2) return -1;
  if (num1 < num2) return 1;
  return 0;
};
