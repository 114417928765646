import { ElementAndData } from '../../../../junkDrawer/useElementAndDataArray.js';
import getSelectionRangeSafely from '../getSelectionRangeSafely.js';
import { isOnFirstLine } from './isOnFirstLine.js';

export const isOnFirstLineOfBlock =
  <BlockEditor>(blockEditorsWithEl: ElementAndData<BlockEditor>[]) =>
  (blockIndex: number): boolean => {
    const selectedBlockEditorWithEl = blockEditorsWithEl[blockIndex];
    const selectionRange = getSelectionRangeSafely();

    if (!(selectionRange && selectedBlockEditorWithEl)) return false;

    const blockEl = selectedBlockEditorWithEl.getEl();
    if (!blockEl) return false;

    return isOnFirstLine(blockEl, selectionRange);
  };
