import useApi from '../api/useApi.js';
import { isSuccessResult } from '../result/Result.js';
import useFetchInvalidate from '../services/useFetch/useFetchInvalidate.js';
import ZeckFolder from '../types/ZeckFolder.js';

const useHomepageFolders = (
  companyId: string,
  onCreate?: (folder: ZeckFolder) => void,
) => {
  const {
    getZeckFolders,
    createZeckFolder,
    updateZeckFolder,
    deleteZeckFolder,
  } = useApi();

  const { result: foldersResult, invalidate: invalidateFolders } =
    useFetchInvalidate(() => getZeckFolders(companyId), []);

  return {
    foldersResult: foldersResult,
    updateFolder: async (folderId: string, name: string) => {
      if (isSuccessResult(foldersResult)) {
        await invalidateFolders(
          async () => {
            await updateZeckFolder(folderId, name);
          },
          foldersResult.data.map((folder) =>
            folder.id === folderId
              ? {
                  ...folder,
                  name,
                }
              : folder,
          ),
        );
      } else {
        await invalidateFolders(async () => {
          await updateZeckFolder(folderId, name);
        });
      }
    },
    deleteZeckFolder: async (folderId: string) => {
      if (isSuccessResult(foldersResult)) {
        await invalidateFolders(
          async () => {
            await deleteZeckFolder(folderId);
          },
          foldersResult.data.filter((folder) => folder.id !== folderId),
        );
      } else {
        await invalidateFolders(async () => {
          await deleteZeckFolder(folderId);
        });
      }
    },
    createFolder: async (name: string) => {
      await invalidateFolders(async () => {
        const folder = await createZeckFolder(companyId, name);
        onCreate && onCreate(folder);
      });
    },
    invalidateFolders,
  };
};

export default useHomepageFolders;
