import { createImageBlock } from 'editor-content/Block.js';
import { BodyState, BodyStateSelected } from '../BodyEditor.js';
import pasteBlocks from './pasteBlocks.js';

export function pasteImage(
  initialState: BodyStateSelected,
  imageGuid: string,
  width: number,
  height: number,
): BodyState | void {
  const newImageBlock = createImageBlock(imageGuid, '', 'column', 'center', {
    width,
    height,
  });

  return pasteBlocks(initialState, [newImageBlock]);
}
