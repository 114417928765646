import cx from 'classnames';
import React from 'react';
import { PrevoteChoice } from '../../../types/Prevote.js';
import styles from './PrevoteChoiceLabel.module.scss';

export const PrevoteChoiceBox: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={cx(styles.prevoteChoiceBox, className)}>{children}</div>
  );
};

const prevoteDisplayText: Record<PrevoteChoice, string> = {
  abstained: 'Abstain',
  approved: 'Approve',
  not_approved: 'Not Approve',
};

const PrevoteChoiceLabel: React.FC<{ choice: PrevoteChoice }> = ({
  choice,
}) => {
  return (
    <span
      className={cx(styles.prevoteChoice, styles[`prevoteChoice__${choice}`])}
    >
      {prevoteDisplayText[choice]}
    </span>
  );
};

export default PrevoteChoiceLabel;
