import React, { useState } from 'react';
import Button from '../design-system/atoms/Button.tsx';
import OutlineButton from '../design-system/atoms/OutlineButton.tsx';
import PopupForm from './PopupForm.tsx';
import TimeZonePicker from './TimeZonePicker.tsx';

type TimeZoneFormProps = {
  onSubmit: (timeZone: string) => void;
  onCancel: () => void;
  timeZone: string;
};

const TimeZoneForm = React.forwardRef<HTMLDivElement, TimeZoneFormProps>(
  function TimeZoneForm({ onSubmit, onCancel, timeZone }, forwardedRef) {
    const [value, setValue] = useState(timeZone);

    return (
      <PopupForm
        ref={forwardedRef}
        data-testid="date-time-picker"
        actions={
          <>
            <OutlineButton
              onClick={() => {
                onCancel();
              }}
              size="small"
              color="secondary"
            >
              Cancel
            </OutlineButton>

            <Button
              onClick={() => {
                onSubmit(value);
              }}
              size="small"
              color="primary"
            >
              OK
            </Button>
          </>
        }
      >
        <TimeZonePicker value={value} onChange={setValue} />
      </PopupForm>
    );
  },
);

export default TimeZoneForm;
