import BaseTextBlock from 'editor-content/BaseTextBlock.js';
import { FocusedBlock } from '../../actions/pressForwardSlash.js';
import ContentSelection from '../../selection/contentSelection/ContentSelection.js';
import getTextBlockLength from './getTextBlockLength.ts';

const textBlockPressForwardSlashStrategy = <BlockType extends BaseTextBlock>(
  block: BlockType,
  _selection: ContentSelection,
): FocusedBlock | void => {
  if (getTextBlockLength(block) === 0) {
    return { blockId: block.id };
  }
  return;
};

export default textBlockPressForwardSlashStrategy;
