import pick from 'lodash/fp/pick.js';
import isEqual from 'lodash/isEqual.js';
import { DependencyList, useEffect, useRef, useState } from 'react';
import { isUpdateConflictErrorResponse } from '../../api/endpoints/createSectionApi.js';
import useApi from '../../api/useApi.js';
import {
  createDefaultSectionBody,
  createDefaultSectionHeadline,
  createDefaultSectionTitle,
} from '../../junkDrawer/DefaultContent.js';
import {
  ErrorResult,
  LoadingResult,
  mapResult,
  MutatingResult,
  Result,
  SuccessResult,
} from '../../result/Result.js';
import { Section, User, Zeck } from '../../types.ts';
import ZeckWithActions from './edit/zeckCover/ZeckWithActions.js';

type ErrorData = { type: 'conflict'; updatedBy: User | null } | null;

type ZeckWithActionsResult = Result<ErrorData, ZeckWithActions>;

const constVoid = (): void => {};

const updateSectionOnZeck = (zeck: Zeck, section: Section): Zeck => ({
  ...zeck,
  resources: zeck.resources.map((resource) => {
    if (resource.type !== 'section') return resource;

    if (resource.itemId !== section.id) return resource;

    return {
      ...resource,
      title: section.title,
    };
  }),
  sections: zeck.sections.map((s) => (s.id === section.id ? section : s)),
});

const useFetchZeck = (
  fetchFn: () => Promise<Zeck>,
  deps: DependencyList,
): ZeckWithActionsResult => {
  const inProgressPromisesRef = useRef<Promise<unknown>[]>([]);

  const areAllPromisesComplete = () =>
    inProgressPromisesRef.current.length === 0;
  const trackPromise = <T>(promise: Promise<T>) => {
    inProgressPromisesRef.current = [...inProgressPromisesRef.current, promise];
  };
  const untrackPromise = <T>(promise: Promise<T>) => {
    inProgressPromisesRef.current = inProgressPromisesRef.current.filter(
      (p) => p !== promise,
    );
  };

  const {
    createSection,
    reorderSections,
    deleteSection,
    fetchZeck,
    restoreSection,
    updateSection,
    updateZeck,
    publishZeck,
  } = useApi();

  useEffect(() => {
    (async () => {
      try {
        setZeckResult(SuccessResult(await fetchFn()));
      } catch (e) {
        setZeckResult(ErrorResult(null));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  const [zeckResult, setZeckResult] =
    useState<Result<ErrorData, Zeck>>(LoadingResult());

  const doMutation = async <ReturnType>(
    optimisticUpdate: (z: Zeck) => Zeck,
    fn: () => Promise<[ReturnType, (z: Zeck) => Zeck]>,
  ): Promise<ReturnType> => {
    setZeckResult((res) => {
      switch (res.type) {
        case 'loading':
        case 'error':
          return res;
        case 'success':
        case 'mutating':
          return MutatingResult(optimisticUpdate(res.data));
      }
    });
    const promise = fn();
    trackPromise(promise);
    const [returnVal, stateUpdater] = await promise.finally(() => {
      untrackPromise(promise);
    });

    setZeckResult((res) => {
      switch (res.type) {
        case 'loading':
        case 'error':
          return res;
        case 'success':
          return SuccessResult(stateUpdater(res.data));
        case 'mutating':
          if (areAllPromisesComplete()) {
            return SuccessResult(stateUpdater(res.data));
          }

          return MutatingResult(stateUpdater(res.data));
      }
    });
    return returnVal;
  };

  const doMutationVoid = async (
    optimisticUpdate: (z: Zeck) => Zeck,
    fn: () => Promise<void>,
  ): Promise<void> => {
    await doMutation(optimisticUpdate, async () => {
      await fn();
      return [constVoid(), (z: Zeck) => z];
    });
  };

  return mapResult((zeck: Zeck) => ({
    ...zeck,
    actions: {
      publish: async () =>
        await doMutation(
          (z) => z,
          async () => {
            const publishedZeck = await publishZeck(zeck.id);

            return [
              constVoid(),
              () => ({
                ...zeck,
                publishedAt: publishedZeck.publishedAt,
                firstPublishedAt:
                  zeck.firstPublishedAt ?? publishedZeck.publishedAt,
                publishedZeck,
              }),
            ];
          },
        ),
      reorderSections: async (
        sections: Pick<Section, 'id' | 'supplemental'>[],
      ) => {
        if (
          isEqual(
            zeck.sections.map(pick(['id', 'supplemental'])),
            sections.map(pick(['id', 'supplemental'])),
          )
        ) {
          return;
        }

        await doMutationVoid(
          (zeck) => {
            let sectionCount = 0;
            let appendixCount = 0;

            return {
              ...zeck,
              sections: sections.flatMap((s) => {
                const fullSection = zeck.sections.find(
                  (section) => s.id === section.id,
                );
                if (!fullSection) return [];
                const isSupplemental = s.supplemental;
                const newSection = {
                  ...fullSection,
                  position: isSupplemental ? appendixCount : sectionCount,
                  supplemental: isSupplemental,
                };

                if (isSupplemental) {
                  appendixCount++;
                } else {
                  sectionCount++;
                }

                return newSection;
              }),
            };
          },
          async () => {
            await reorderSections(zeck.id, sections, zeck.companyId);
          },
        );
      },
      restoreSection: async (sectionId: string) => {
        const newSection = await restoreSection(sectionId);
        const newZeck = await fetchFn();
        setZeckResult(SuccessResult(newZeck));
        return newSection;
      },
      createSection: async (isSupplemental: boolean) =>
        doMutation(
          (z) => z,
          async () => {
            const newSection = await createSection({
              zeckId: zeck.id,
              title: createDefaultSectionTitle(),
              headline: createDefaultSectionHeadline(),
              body: createDefaultSectionBody(),
              supplemental: isSupplemental,
            });

            return [
              newSection,
              (zeck) => ({
                ...zeck,
                sections: [...zeck.sections, newSection],
              }),
            ];
          },
        ),
      update: async (zeckPatch: Partial<Zeck>) => {
        await doMutationVoid(
          (zeck) => ({
            ...zeck,
            ...zeckPatch,
          }),
          async () => {
            await updateZeck(zeck.id, zeckPatch);
          },
        );
      },
    },
    sections: zeck.sections.map((section) => ({
      ...section,
      actions: {
        update: async (sectionPatch: Partial<Section>) => {
          try {
            await doMutation(
              (z) => z,
              async () => {
                const newSection = await updateSection({
                  id: section.id,
                  ...sectionPatch,
                });

                return [
                  constVoid(),
                  (zeck) => updateSectionOnZeck(zeck, newSection),
                ];
              },
            );
          } catch (error) {
            if (isUpdateConflictErrorResponse(error)) {
              setZeckResult(
                ErrorResult({
                  type: 'conflict',
                  updatedBy: error.body.updatedBy,
                }),
              );
            } else {
              // allow top level handler to handle
              throw error;
            }
          }
        },
        remove: async () => {
          await doMutation(
            (z) => z,
            async () => {
              await deleteSection(zeck.companyId, section.id);
              const reloadedZeck = await fetchZeck(zeck.id);
              return [constVoid(), (_zeck) => reloadedZeck];
            },
          );
        },
      },
    })),
  }))(zeckResult);
};

// for now , a conflict on section invalidates all the data (same as how it works currently)

export default useFetchZeck;
