import { z } from 'zod';

const ShareClassSchema = z.object({
  authorizedShares: z.string(),
  outstandingShares: z.string(),
  outstandingOwnershipPercent: z.string(),
  fullyDilutedShares: z.string(),
  fullyDilutedOwnershipPercent: z.string(),
  amountRaised: z.string(),
  name: z.string(),
});

const OptionPoolSchema = z.object({
  name: z.string(),
  authorizedShares: z.string(),
  outstandingEquityAwardDerivatives: z.string(),
  equityAwardOwnershipPercent: z.string(),
  availableShares: z.string(),
  fullyDilutedShares: z.string(),
  availableSharesOwnershipPercent: z.string(),
});

const ConvertibleSchema = z.object({
  name: z.string(),
  principal: z.string(),
  interest: z.string(),
  outstandingDebt: z.string(),
  amountRaised: z.string(),
});

const CartaCapTableSchema = z.object({
  outstandingShares: z.string(),
  fullyDilutedShares: z.string(),
  amountRaised: z.string(),
  shareClasses: z.array(ShareClassSchema),
  optionPools: z.array(OptionPoolSchema),
  convertibles: z.array(ConvertibleSchema),
  convertibleTotalPrincipal: z.string(),
  convertibleTotalInterest: z.string(),
  convertibleTotalOutstandingDebt: z.string(),
  convertibleTotalAmountRaised: z.string(),
});

export const CartaCapTableBlockSchema = z.object({
  id: z.string().uuid(),
  type: z.literal('carta-cap-table'),
  data: z.object({
    capTable: CartaCapTableSchema,
    synchedAt: z.string(),
  }),
});
