import {
  AnalyticsSummary,
  HydratedEngagementTotal,
} from '../../../../../api/endpoints/createAnalyticsApi.ts';
import { isSuccessResult } from '../../../../../result/Result.ts';
import { Result } from '../../../../../services/useFetch/useFetch.ts';
import { Comment } from '../../../../../types/Comment.js';
import { UserAndPermission } from '../../../../../types/User.ts';
import { AnalyticsZeckWithPublishedSections } from '../AnalyticsTypes.ts';
import {
  buildSortedSectionsWithTotals,
  SectionWithTotals,
} from './buildSortedSectionsWithTotals.ts';
import countCommentsAndReplies from './countCommentsAndReplies.ts';
import getSectionTotals from './getSectionTotals.ts';
import { getValidSectionTotals } from './getValidSectionTotals.ts';

type SectionCommentData = {
  [sectionId: string]: Comment[];
};

export const buildUserSortedSectionsWithTotals = (
  analyticsData: AnalyticsSummary | null,
  commentsResult: Result<{
    [sectionId: string]: Comment[];
  }>,
  users: UserAndPermission[],
  zeck: AnalyticsZeckWithPublishedSections,
) => {
  const userSortedSectionsWithTotals: {
    [userId: string]: SectionWithTotals[];
  } = {};
  const userTotalSections: { [userId: string]: HydratedEngagementTotal[] } = {};

  analyticsData?.engagementTotals.forEach((engagementTotal) => {
    userTotalSections[engagementTotal.userId]
      ? userTotalSections[engagementTotal.userId]?.push(engagementTotal)
      : (userTotalSections[engagementTotal.userId] = [engagementTotal]);
  });

  const allCommentsData = isSuccessResult(commentsResult)
    ? commentsResult.data || {}
    : {};

  const userCommentsData: { [userId: string]: SectionCommentData } = {};
  Object.keys(allCommentsData).forEach((sectionId) => {
    allCommentsData[sectionId]?.forEach((comment) => {
      const perUserComments = userCommentsData[comment.userId] ?? null;
      if (perUserComments) {
        perUserComments[sectionId]
          ? perUserComments[sectionId]?.push(comment)
          : (perUserComments[sectionId] = [comment]);
      } else {
        userCommentsData[comment.userId] = { [sectionId]: [comment] };
      }
    });
  });

  users.forEach((user) => {
    const totalsForCurrentZeckSections = getValidSectionTotals(
      userTotalSections[user.id] ?? [],
      zeck.publishedZeck?.sections ?? [],
    );
    const totals = getSectionTotals(totalsForCurrentZeckSections);

    const commentCounts = countCommentsAndReplies(
      userCommentsData[user.id] ?? {},
    );

    const sortedSectionsWithTotals = buildSortedSectionsWithTotals(
      zeck.publishedZeck?.sections ?? [],
      totals,
      commentCounts,
    );

    userSortedSectionsWithTotals[user.id] = sortedSectionsWithTotals;
  });

  return userSortedSectionsWithTotals;
};
