import { ZeckFolderWithCount } from '../../../types/ZeckFolder.ts';
import sortFolders from '../sortFolders.ts';
import styles from './FolderCardComponent.module.scss';
import ZeckFolderComponent from './FolderCardComponent.tsx';

const FolderList: React.FC<{
  folders: ZeckFolderWithCount[];
}> = ({ folders }) => {
  if (!folders.length) return null;

  return (
    <>
      <div className={styles.folderComponent__header}>Folders</div>
      <div data-testid="dashboard-folder-list" className={styles.folder__list}>
        {sortFolders(folders).map((folder) => (
          <ZeckFolderComponent key={folder.id} folder={folder} />
        ))}
      </div>
    </>
  );
};

export default FolderList;
