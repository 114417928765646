import createPermissionsApi from './createPermissionsApi.js';
import createUserApi from './createUserApi.js';
import createUserEmailChangeRequestApi from './createUserEmailChangeRequestApi.js';
import AiApiClient from './endpoints/AiApiClient.js';
import AssetApiClient from './endpoints/AssetApiClient.ts.ts';
import createAccessRequestApi from './endpoints/createAccessRequestApi.js';
import createAiApi from './endpoints/createAiApi.ts';
import createAnalyticsApi from './endpoints/createAnalyticsApi.js';
import createAssetApi from './endpoints/createAssetApi.ts';
import createCartaApi from './endpoints/createCartaApi.ts';
import createCommentApi from './endpoints/createCommentApi.js';
import createCompanyApi from './endpoints/createCompanyApi.js';
import createCredentialApi from './endpoints/createCredentialApi.js';
import createDeletionRequestApi from './endpoints/createDeletionRequestApi.js';
import createFileApi from './endpoints/createFileApi.js';
import createImageApi from './endpoints/createImageApi.js';
import createIntegrationApi from './endpoints/createIntegrationApi.js';
import createMagicLinkApi from './endpoints/createMagicLinkApi.js';
import createMeetingMinutesApi from './endpoints/createMeetingMinutesApi.js';
import createPasswordApi from './endpoints/createPasswordApi.js';
import createPrevoteApi from './endpoints/createPrevoteApi.ts';
import createPublishedZeckApi from './endpoints/createPublishedZeckApi.js';
import createSectionApi from './endpoints/createSectionApi.js';
import createSelectionCommentApi from './endpoints/createSelectionCommentApi.js';
import createTheConApi from './endpoints/createTheConApi.js';
import createVoteApi from './endpoints/createVoteApi.js';
import createZeckApi from './endpoints/createZeckApi.js';
import createZeckFolderApi from './endpoints/createZeckFolderApi.js';
import PublishedCommentApiClient from './endpoints/PublishedCommentApiClient.js';
import { makeMakeApiRequest } from './makeMakeApiRequest.js';
export type { Invitation } from './endpoints/createCompanyApi.js';

export type ApiService = ReturnType<typeof createApi>;

export default function createApi(
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  makeAnalyticsApiRequest: ReturnType<typeof makeMakeApiRequest>,
  makeDeletionRequestApiRequest: ReturnType<typeof makeMakeApiRequest>,
  apiClients: {
    commentApiClient: typeof PublishedCommentApiClient;
    assetApiClient: typeof AssetApiClient;
    aiApiClient: typeof AiApiClient;
  },
  accessToken: string | null,
) {
  return {
    ...createAnalyticsApi(makeAnalyticsApiRequest, accessToken),
    ...createDeletionRequestApi(makeDeletionRequestApiRequest, accessToken),
    ...createMeetingMinutesApi(makeLambdaApiRequest, accessToken),
    ...createCartaApi(makeLambdaApiRequest, accessToken),
    ...createCredentialApi(makeLambdaApiRequest, accessToken),
    ...createIntegrationApi(makeLambdaApiRequest, accessToken),
    ...createVoteApi(makeLambdaApiRequest, accessToken),
    ...createPrevoteApi(makeLambdaApiRequest, accessToken),
    ...createZeckApi(makeLambdaApiRequest, accessToken),
    ...createSectionApi(makeLambdaApiRequest, accessToken),
    ...createCompanyApi(makeLambdaApiRequest, accessToken),
    ...createImageApi(makeLambdaApiRequest, accessToken),
    ...createFileApi(makeLambdaApiRequest, accessToken),
    ...createPasswordApi(makeLambdaApiRequest, accessToken),
    ...createZeckFolderApi(makeLambdaApiRequest, accessToken),
    ...createPublishedZeckApi(makeLambdaApiRequest, accessToken),
    ...createCommentApi(apiClients.commentApiClient, accessToken),
    ...createAssetApi(apiClients.assetApiClient, accessToken),
    ...createSelectionCommentApi(makeLambdaApiRequest, accessToken),
    ...createMagicLinkApi(makeLambdaApiRequest),
    ...createAccessRequestApi(makeLambdaApiRequest, accessToken),
    ...createAiApi(makeLambdaApiRequest, apiClients.aiApiClient, accessToken),
    ...createUserEmailChangeRequestApi(makeLambdaApiRequest, accessToken),
    ...createPermissionsApi(makeLambdaApiRequest, accessToken),
    ...createTheConApi(makeLambdaApiRequest, accessToken),
    ...createUserApi(makeLambdaApiRequest, accessToken),
  };
}
