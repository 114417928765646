import cx from 'classnames';
import { Icon, IconName } from 'icons';
import React, { MouseEventHandler, useState } from 'react';
import DetectsOutsideClick from '../../junkDrawer/DetectsOutsideClick.tsx';
import BlackBox from '../atoms/BlackBox.tsx';
import styles from './Toolbar.module.scss';

export const ToolbarGroup: React.VFC<React.ComponentProps<'div'>> = (props) => {
  return <div {...props} className={styles.toolbar__group} />;
};

type ToolbarDropdownButtonProps = {
  className?: string;
  active?: boolean;
  children: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  prefixIconName?: IconName;
};

type ToolbarMultiSelectOptionProps = {
  label: string;
  onClick: () => void;
  selected: boolean;
};

type ToolbarMultiSelectMenuProps = {
  label: string;
  iconName: IconName;
  options: (ToolbarMultiSelectOptionProps | 'divider')[];
};

const ToolbarMultiSelectOption: React.FC<ToolbarMultiSelectOptionProps> = ({
  label,
  onClick,
  selected,
}) => {
  return (
    <div className={styles.toolbarMultiSelectOption} onClick={onClick}>
      {label}
      {selected && (
        <Icon name="checkmark" className={styles.toolbarSelectIcon} />
      )}
    </div>
  );
};

export const ToolbarMultiSelectMenu: React.FC<ToolbarMultiSelectMenuProps> = ({
  label,
  iconName,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <ToolbarDropdownButton
        onClick={() => setIsOpen(!isOpen)}
        prefixIconName={iconName}
      >
        {label}
      </ToolbarDropdownButton>

      {isOpen && (
        <div className={styles.toolbarMultiSelectMenu}>
          <DetectsOutsideClick<HTMLDivElement> onClick={() => setIsOpen(false)}>
            {(ref) => (
              <div ref={ref}>
                {options.map((option, index) =>
                  option === 'divider' ? (
                    <div
                      key={`divider-${index}`}
                      className={styles.toolbarMultiSelectDivider}
                    />
                  ) : (
                    <ToolbarMultiSelectOption key={option.label} {...option} />
                  ),
                )}
              </div>
            )}
          </DetectsOutsideClick>
        </div>
      )}
    </div>
  );
};

export const ToolbarDropdownButton = React.forwardRef<
  HTMLButtonElement,
  ToolbarDropdownButtonProps
>(function ToolbarDropdownButton(
  { className, children, onClick, active, prefixIconName },
  forwardedRef,
) {
  return (
    <button
      ref={forwardedRef}
      className={cx(
        styles.toolbarDropdownButton,
        active && styles.toolbarDropdownButton_active,
        className,
      )}
      onClick={onClick}
    >
      {prefixIconName && (
        <div className={styles.toolbarDropdownButton_prefixIcon}>
          <Icon name={prefixIconName} />
        </div>
      )}
      {children} <Icon name="chevronDown" />
    </button>
  );
});

type ToolbarButtonProps = {
  className?: string;
  active?: boolean;
  iconName: IconName;
  children: React.ReactNode;
} & React.ComponentProps<'button'>;

export const ToolbarButton = React.forwardRef<
  HTMLButtonElement,
  ToolbarButtonProps
>(function ToolbarDropdownButton(
  { className, children, active, iconName, ...otherProps },
  forwardedRef,
) {
  return (
    <button
      {...otherProps}
      ref={forwardedRef}
      className={cx(
        styles.toolbarButton,
        active && styles.toolbarButton_active,
        className,
      )}
    >
      <Icon name={iconName} className={styles.toolbarButton_icon} />
      {children}
    </button>
  );
});

type ToolbarProps = {
  className?: string;
  children: React.ReactNode;
};

const Toolbar = ({ className, children }: ToolbarProps) => {
  return (
    <BlackBox className={cx(styles.toolbar, className)}>{children}</BlackBox>
  );
};

export default Toolbar;
