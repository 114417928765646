import cx from 'classnames';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { AnalyticsSummary } from '../../../../api/endpoints/createAnalyticsApi.js';
import Spinner from '../../../../design-system/atoms/Spinner.tsx';
import { isSuccessResult } from '../../../../result/Result.ts';
import { Result } from '../../../../services/useFetch/useFetch.js';
import { Comment } from '../../../../types/Comment.ts';
import { AnalyticsPublishedSection } from './AnalyticsTypes.ts';
import styles from './ContentEngagementModal.module.scss';
import { Table, TableHeaderCell } from './Table.js';
import ContentEngagementSummary from './components/ContentEngagementSummary.tsx';
import { SectionAnalyticsRow } from './components/SectionAnalyticsRow.tsx';
import { buildSortedSectionsWithTotals } from './util/buildSortedSectionsWithTotals.ts';
import countCommentsAndReplies from './util/countCommentsAndReplies.ts';
import getSectionTotals from './util/getSectionTotals.js';
import { getValidSectionTotals } from './util/getValidSectionTotals.ts';

const ContentEngagementModal: React.FC<{
  analyticsSummary: AnalyticsSummary;
  companyId: string;
  publishedSections: AnalyticsPublishedSection[];
  commentsResult: Result<{ [sectionId: string]: Comment[] }>;
  modal?: boolean;
}> = ({
  companyId,
  publishedSections,
  commentsResult,
  analyticsSummary,
  modal,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 736 });

  if (!isSuccessResult(commentsResult)) {
    return (
      <div data-testid="engagement-spinner" className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  const commentCounts = countCommentsAndReplies(commentsResult.data);

  const totalCommentCount = Object.keys(commentCounts).reduce(
    (total, sectionId) => total + (commentCounts[sectionId] ?? 0),
    0,
  );

  const totalsForCurrentZeckSections = getValidSectionTotals(
    analyticsSummary.engagementTotals,
    publishedSections,
  );
  const totals = getSectionTotals(totalsForCurrentZeckSections);

  const sortedSectionsWithTotals = buildSortedSectionsWithTotals(
    publishedSections,
    totals,
    commentCounts,
  );

  const tableClass = modal
    ? cx(styles.contentEngagementTable, styles.contentEngagementTable__modal)
    : styles.contentEngagementTable;

  return (
    <div>
      <ContentEngagementSummary
        analyticsSummary={analyticsSummary}
        totalCommentCount={totalCommentCount}
      />

      {isMobile && (
        <div className={styles.contentEngagementTable__mobile_header}>
          <div>Section</div>
          <div>Where Viewers Spent Their Time</div>
        </div>
      )}
      <Table className={tableClass} data-testid="analytics-engagement-section">
        {!isMobile && (
          <>
            <TableHeaderCell>Section Title</TableHeaderCell>
            <TableHeaderCell>Where Viewers Spent Their Time</TableHeaderCell>
            <TableHeaderCell
              className={styles.sectionTotal__commentCountHeader}
            >
              Comments
            </TableHeaderCell>
          </>
        )}

        {sortedSectionsWithTotals.map((sectionWithTotal) => (
          <SectionAnalyticsRow
            companyId={companyId}
            isMobile={isMobile}
            sectionWithTotal={sectionWithTotal}
            overallTotalTime={sortedSectionsWithTotals[0]?.total.totalTime || 1}
            key={sectionWithTotal.id}
          />
        ))}
      </Table>
    </div>
  );
};

export default ContentEngagementModal;
