import cx from 'classnames';
import React from 'react';
import styles from './ButtonBase.module.scss';

const sizeToClass = (size: ButtonBaseProps['size']) => {
  switch (size) {
    case 'small':
      return styles.button_small;
    case 'medium':
      return styles.button_medium;
    case 'large':
      return styles.button_large;
    default:
      return styles.button_medium;
  }
};

type ButtonBaseProps = {
  className?: string;
  children: React.ReactNode;
  size: 'small' | 'medium' | 'large';
  isFullWidth?: boolean;
} & React.ComponentProps<'button'>;

const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
  function ButtonBase(
    { className, children, size, isFullWidth, ...otherProps },
    forwardedRef,
  ) {
    return (
      <button
        {...otherProps}
        ref={forwardedRef}
        className={cx(
          styles.button,
          sizeToClass(size),
          isFullWidth && styles.button__fullWidth,
          className,
        )}
      >
        {children}
      </button>
    );
  },
);

export default ButtonBase;
