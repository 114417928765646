import { MeetingMinutesListItem } from '../../../api/endpoints/createMeetingMinutesApi.ts';
import useApi from '../../../api/useApi.ts';
import { useDocumentTitle } from '../../../junkDrawer/useDocumentTitleFromResult.ts';
import WithResultLoading from '../../../result/WithResultLoading.tsx';
import useFetchInvalidate from '../../../services/useFetch/useFetchInvalidate.ts';
import usePageTracking from '../../../services/usePageTracking.ts';
import { UserAndCompany } from '../../../userAndCompany/FetchUserAndCompany.tsx';
import LoaderSpinnerOverlay from '../../LoaderSpinnerOverlay.tsx';
import { MeetingMinutesListPageView } from './MeetingMinutesListPageView.tsx';

type MeetingMinutesListPageProps = {
  userAndCompany: UserAndCompany;
};

const MeetingMinutesListPage: React.FC<MeetingMinutesListPageProps> = ({
  userAndCompany,
}) => {
  const {
    fetchAllMeetingMinutes,
    deleteMeetingMinutes,
    approvalMeetingMinutes,
    updateMeetingMinutes,
    fetchMeetingMinutes,
    duplicateMeetingMinutes,
  } = useApi();

  const company = userAndCompany.activeCompany;
  useDocumentTitle('Minutes Book');
  usePageTracking('minutes_list', userAndCompany);

  const { result, invalidate } = useFetchInvalidate(async () => {
    return fetchAllMeetingMinutes(company.id);
  }, [company.id]);

  const onDeleteMeetingMinutes = async (meetingMinutesId: string) => {
    await invalidate(async () => {
      await deleteMeetingMinutes(meetingMinutesId);
    });
  };

  const onRenameMeetingMinutes = async (
    meetingMinutes: MeetingMinutesListItem,
    newTitle: string,
  ) => {
    await invalidate(async () => {
      await updateMeetingMinutes({
        ...meetingMinutes,
        title: newTitle,
      });
    });
  };

  const onApproveMeetingMinutes = async (
    meetingMinutesId: string,
    approved: boolean,
  ) => {
    await invalidate(async () => {
      const newMinutes = await fetchMeetingMinutes(meetingMinutesId);

      await approvalMeetingMinutes({
        id: meetingMinutesId,
        approved,
        version: newMinutes.version,
      });
    });
  };

  const onDuplicateMeetingMinutes = async (
    meetingMinutesId: string,
    keepZeck: boolean,
  ) => {
    await invalidate(async () => {
      await duplicateMeetingMinutes(meetingMinutesId, keepZeck);
    });
  };

  return (
    <WithResultLoading
      result={result}
      duration={400}
      renderError={() => <div>Error loading Meeting Minutes.</div>}
      renderLoading={() => (
        <LoaderSpinnerOverlay data-testid="meeting-minutes-spinner" />
      )}
    >
      {(meetingMinutes) => (
        <MeetingMinutesListPageView
          {...{
            company,
            meetingMinutes,
            onDeleteMeetingMinutes,
            onApproveMeetingMinutes,
            onRenameMeetingMinutes,
            onDuplicateMeetingMinutes,
          }}
        />
      )}
    </WithResultLoading>
  );
};

export default MeetingMinutesListPage;
