import { useEffect, useState } from 'react';
import BlackBox from '../design-system/atoms/BlackBox.tsx';
import IconButton from '../design-system/atoms/IconButton.tsx';
import { logInfo } from '../logging/logger.ts';
import styles from './ErrorToaster.module.scss';
import shouldIgnoreError from './shouldIgnoreError.js';

type ErrorToasterProps = {
  children: React.ReactNode;
};

const ErrorToaster = ({ children }: ErrorToasterProps) => {
  const [showToast, setShowToast] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string>(
    'Something went wrong. Please try again.',
  );

  function handleError(e: ErrorEvent) {
    if (shouldIgnoreError(e)) return;

    logInfo('ErrorToaster: showing toast');

    setShowToast(true);
  }

  function handlePromiseRejection(event: PromiseRejectionEvent) {
    console.log(event);

    const userMessageFromCustomZeckError: string | undefined =
      event.reason?.userMessage;
    if (userMessageFromCustomZeckError) {
      setErrorMessages(userMessageFromCustomZeckError);
    } else if (shouldIgnoreError(event.reason)) {
      return;
    }

    logInfo('ErrorToaster: showing toast', { userMessageFromCustomZeckError });

    setShowToast(true);
  }

  useEffect(() => {
    window.addEventListener('unhandledrejection', handlePromiseRejection);
    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('unhandledrejection', handlePromiseRejection);
      window.removeEventListener('error', handleError);
    };
  }, []);

  return (
    <div>
      {children}
      {showToast && (
        <div className={styles.ErrorToaster__container}>
          <BlackBox className={styles.ErrorToaster__toast}>
            {errorMessages}
            <IconButton
              className={styles.ErrorToaster__toast__close}
              name="close"
              aria-label="close toast"
              onClick={() => {
                window.location.assign(window.location.href);
              }}
            />
          </BlackBox>
        </div>
      )}
    </div>
  );
};

export default ErrorToaster;
