import { useCallback, useEffect, useState, useSyncExternalStore } from 'react';
import { TagSelection } from '../../../../editor/domFacing/components/InsertTagMenu.js';
import { AvailableTag } from '../../../../types/AvailableTag.js';
import { MeetingMinutesEditorCoordinator } from '../../MeetingMinutes.js';
import InsertTag from './InsertTag.js';

export default function useInsertTag(
  editor: MeetingMinutesEditorCoordinator,
  allAvailableTags: AvailableTag[],
) {
  const editorState = useSyncExternalStore(
    (callback) => editor.subscribeToContentChanges(callback),
    () => editor.getState(),
  );
  const [tagSelection, setTagSelection] = useState<TagSelection>(null);

  const matchingTags = InsertTag.getMatchingTags(editorState, allAvailableTags);

  const onInsertTag = (tag: AvailableTag) => {
    const newState = InsertTag.insertTag(editor.getState(), tag);
    newState && editor.setState(newState);
  };

  const tagLength = matchingTags.length;

  useEffect(() => {
    setTagSelection(null);
  }, [tagLength]);

  const onPressDownTagHandler = useCallback((): boolean => {
    if (tagLength > 0) {
      setTagSelection((oldVal) => {
        if (oldVal === null) {
          return 0;
        }

        return Math.min(oldVal + 1, tagLength - 1);
      });

      return true;
    }

    return false;
  }, [tagLength]);

  const onPressUpTagHandler = useCallback((): boolean => {
    if (tagLength > 0) {
      setTagSelection((oldVal) => {
        if (oldVal === 0 || oldVal === null) {
          return null;
        }

        return oldVal - 1;
      });

      return true;
    }

    return false;
  }, [tagLength]);

  const onPressEnterTagHandler = (): boolean => {
    if (tagSelection !== null) {
      const selectedTag = matchingTags[tagSelection];
      if (selectedTag) {
        onInsertTag(selectedTag);
        return true;
      }
    }

    return false;
  };

  return {
    matchingTags,
    onInsertTag,
    onPressEnterTagHandler,
    onPressDownTagHandler,
    onPressUpTagHandler,
    tagSelection,
  };
}
