import cx from 'classnames';
import { Icon } from 'icons';
import { FormEventHandler, useState } from 'react';
import { hasStatus } from '../../api/makeMakeApiRequest.ts';
import Button from '../../design-system/atoms/Button.tsx';
import InputEmail from '../../design-system/atoms/InputEmail.tsx';
import SessionLayout from '../../design-system/layouts/SessionLayout.tsx';
import emailRegex from '../../emailRegex.ts';
import useBreakpoints from '../../services/useBreakpoints.ts';
import FormState from '../auth/FormState.ts';
import styles from './ResetPasswordRequestForm.module.scss';

type ResetPasswordRequestFormProps = {
  onResetPasswordRequest(email: string): Promise<void>;
};

const ResetPasswordRequestForm = ({
  onResetPasswordRequest,
}: ResetPasswordRequestFormProps) => {
  const { isMobile } = useBreakpoints();
  const [formState, setFormState] = useState<FormState<'email'>>({
    email: {
      value: '',
      error: null,
    },
  });

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    setFormState({
      email: {
        ...formState.email,
        error:
          formState.email.value && emailRegex.test(formState.email.value)
            ? null
            : 'Please enter a valid email address.',
      },
    });

    if (formState.email && emailRegex.test(formState.email.value)) {
      try {
        await onResetPasswordRequest(formState.email.value);
      } catch (error) {
        if (hasStatus(error) && error.status === 422) {
          setFormState({
            email: {
              ...formState.email,
              error: 'Please enter a valid email address.',
            },
          });
        } else {
          throw error;
        }
      }
    }
  };

  const setFormValue = (name: string) => (value: string) => {
    setFormState({
      ...formState,
      [name]: { value, error: null },
    });
  };

  return (
    <SessionLayout>
      <h1 className={cx(isMobile ? styles.title_mobile : styles.title)}>
        Password Reset
      </h1>
      <p className={styles.p}>
        Enter your email address, and we’ll send you instructions on how to
        reset your password.
      </p>
      <form onSubmit={handleSubmit} className={styles.form} noValidate>
        <InputEmail
          {...{
            label: 'Email',
            ...formState.email,
            onChange: setFormValue('email'),
          }}
        />
        <Button
          {...{
            className: styles.form__submit,
            size: 'large',
            color: 'primary',
          }}
        >
          <Icon name="lock" /> Send Me Secure Reset Instructions
        </Button>
      </form>
    </SessionLayout>
  );
};

export default ResetPasswordRequestForm;
