import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import ContentSelection, {
  contentSelection,
} from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import styles from './SelectableDiv.module.scss';

type SelectableDivProps = {
  isInBlockSelection: boolean;
  internalSelection: ContentSelection | null;
  onSelect(contentSelection: ContentSelection): void;
  shouldFocus?: () => boolean;
  expand?: boolean;
  square?: boolean;
  className?: string;
  children?: React.ReactNode;
} & Omit<React.ComponentProps<'div'>, 'onSelect'>;

function returnTrue() {
  return true;
}

const SelectableDiv = React.forwardRef<HTMLDivElement, SelectableDivProps>(
  function SelectableDiv(
    {
      isInBlockSelection,
      internalSelection,
      onSelect,
      shouldFocus = returnTrue,
      expand,
      square,
      className,
      children,
      ...otherProps
    },
    forwardedRef,
  ) {
    const ref = useRef<HTMLDivElement>(null);

    const isDirectlySelected = !!internalSelection;

    useEffect(() => {
      const el = ref.current;
      if (
        shouldFocus() &&
        isDirectlySelected &&
        el &&
        !el.contains(document.activeElement)
      ) {
        el.focus({
          preventScroll: true,
        });
      }
    }, [isDirectlySelected, shouldFocus]);

    return (
      <div
        {...otherProps}
        ref={mergeRefs([ref, forwardedRef])}
        tabIndex={0}
        className={cx(
          styles.selectable,
          isInBlockSelection && styles.selectable_selected,
          expand && styles.selectable_expand,
          square && styles.selectable_square,
          className,
        )}
        onFocus={() => {
          onSelect(contentSelection(0));
        }}
      >
        {children}
      </div>
    );
  },
);

export default SelectableDiv;
