import {
  BulletedListItemBlock,
  createBulletedListItemBlock,
  createLabelBlock,
  LabelBlock,
} from 'editor-content/Block.ts';
import { TableBlock } from 'editor-content/TableBlock.ts';
import { Text } from 'editor-content/TextNode.ts';
import { useState } from 'react';
import { isAiTableKeyTakeawaysResponseError } from '../../../../api/endpoints/createAiApi.ts';
import { createEventUserPayload } from '../../../../api/endpoints/createAnalyticsApi.ts';
import useApi from '../../../../api/useApi.ts';
import { useActiveCompany } from '../../../../userAndCompany/activeCompanyAtom.tsx';
import { useUser } from '../../../../userAndCompany/userAtom.tsx';
import { AiTableKeyTakeawaysFlowErrorState } from './AiTableKeyTakeawaysError.tsx';
import { AiTableKeyTakeawaysFlowLoadingState } from './AiTableKeyTakeawaysLoading.tsx';
import { AiTableKeyTakeawaysFlowReviewingState } from './AiTableKeyTakeawaysReviewing.tsx';
import { AiTableKeyTakeawaysContext } from './aiTableKeyTakeawaysContext.js';

type GenerateAiTableKeyTakeawaysFlowProps = {
  onInsertBlocks: (
    labelBlock: LabelBlock,
    blocks: BulletedListItemBlock[],
  ) => void;
  fromBlock: TableBlock;
  aiTableKeyTakeawaysContext: AiTableKeyTakeawaysContext;
};

type AiTableKeyTakeawaysFlowInactiveState = {
  type: 'inactive';
};

export const isAiTableKeyTakeawaysFlowInactiveState = (
  state: AiTableKeyTakeawaysFlowState,
): state is AiTableKeyTakeawaysFlowInactiveState => {
  return state.type === 'inactive';
};

export type AiTableKeyTakeawaysFlowActiveStates =
  | AiTableKeyTakeawaysFlowLoadingState
  | AiTableKeyTakeawaysFlowReviewingState
  | AiTableKeyTakeawaysFlowErrorState;

export type AiTableKeyTakeawaysFlowState =
  | AiTableKeyTakeawaysFlowInactiveState
  | AiTableKeyTakeawaysFlowActiveStates;

export const useGenerateAiTableKeyTakeawaysFlowProps = ({
  onInsertBlocks,
  fromBlock,
  aiTableKeyTakeawaysContext,
}: GenerateAiTableKeyTakeawaysFlowProps) => {
  const [keyTakeawaysState, setKeyTakeawaysState] =
    useState<AiTableKeyTakeawaysFlowState>({
      type: 'inactive',
    });

  const { aiTableKeyTakeaways, addZeckEvent } = useApi();

  const activeCompany = useActiveCompany();
  const user = useUser();

  return {
    generateKeyTakeaways: async () => {
      setKeyTakeawaysState({
        type: 'loading',
      });

      const result = await aiTableKeyTakeaways({
        data: aiTableKeyTakeawaysContext.sectionBody,
        companyId: activeCompany.id,
        sectionId: aiTableKeyTakeawaysContext.sectionId,
        userId: user.id,
        tableOfInterestId: fromBlock.id,
        companyName: activeCompany.name,
        zeckName: aiTableKeyTakeawaysContext.zeckName,
        sectionTitle: aiTableKeyTakeawaysContext.sectionTitle,
        metadata: createEventUserPayload({
          user,
          activeCompany,
        }),
      });

      if (isAiTableKeyTakeawaysResponseError(result)) {
        return setKeyTakeawaysState({
          type: 'error',
        });
      }

      setKeyTakeawaysState({
        type: 'reviewing',
        keyTakeaways: result.tableKeyTakeaways,
      });
    },
    onClose: () => {
      setKeyTakeawaysState({
        type: 'inactive',
      });
    },
    recordAnalytics: async (type: 'accept' | 'discard') => {
      await addZeckEvent(
        {
          contentType: 'SECTION',
          contentTypeId: aiTableKeyTakeawaysContext.sectionId,
          eventType:
            type === 'accept'
              ? 'AI_TABLE_KEY_TAKEAWAYS_ACCEPT'
              : 'AI_TABLE_KEY_TAKEAWAYS_DISCARD',
          payload: {
            tableBlockId: fromBlock.id,
          },
          firedAt: new Date().getTime(),
        },
        { user, activeCompany },
      );
    },
    onAccept: (keyTakeaways: string[]) => {
      const labelBlock = createLabelBlock([Text('Key Takeaways')]);

      const bulletedListItemBlocks = keyTakeaways.map((keyTakeaway) =>
        createBulletedListItemBlock([Text(keyTakeaway)], 0),
      );

      onInsertBlocks(labelBlock, bulletedListItemBlocks);

      setKeyTakeawaysState({
        type: 'inactive',
      });
    },
    keyTakeawaysState,
    setKeyTakeawaysState,
  };
};

export type GenerateAiTableKeyTakeawaysFlowResult = ReturnType<
  typeof useGenerateAiTableKeyTakeawaysFlowProps
>;
