import { Icon } from 'icons';
import styles from './AiWizardBadge.module.scss';

export const AiWizardBadge = () => {
  return (
    <div className={styles.aiWizardIconWrapper}>
      <Icon name="aiStar" className={styles.aiWizardIcon} />
    </div>
  );
};
