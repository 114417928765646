import { Navigate } from 'react-router-dom';
import ButtonWithIcon from '../../../design-system/atoms/ButtonWithIcon.tsx';
import PrintInstructions from '../../../design-system/organisms/PrintInstructions.tsx';
import { useDocumentTitle } from '../../../junkDrawer/useDocumentTitleFromResult.ts';
import MeetingMinutesSignature from '../../../meetingMinutes/MeetingMinutesSignature.tsx';
import MeetingMinutesView from '../../../meetingMinutes/MeetingMinutesView.tsx';
import { foldResult, pipe } from '../../../result/Result.js';
import { useRequiredParams } from '../../../routing/useRequiredParams.ts';
import usePageTracking from '../../../services/usePageTracking.ts';
import { UserAndCompany } from '../../../userAndCompany/FetchUserAndCompany.tsx';
import { isSignedMinutes } from '../MeetingMinutes.ts';
import MeetingMinutesLoading from '../MeetingMinutesLoading.tsx';
import useFetchMeetingMinutes from '../useFetchMeetingMinutes.ts';
import styles from './MeetingMinutesPrintPage.module.scss';

type MeetingMinutesPrintPageProps = {
  userAndCompany: UserAndCompany;
};
const MeetingMinutesPrintPage: React.FC<MeetingMinutesPrintPageProps> = ({
  userAndCompany,
}) => {
  const { meetingMinutesId } = useRequiredParams('meetingMinutesId');
  const { result } = useFetchMeetingMinutes(meetingMinutesId);
  usePageTracking('minutes_print', userAndCompany);
  useDocumentTitle('Meeting Minutes');

  return pipe(
    result,
    foldResult({
      loading: () => <MeetingMinutesLoading />,
      error: () => <Navigate to="/404" />,
      success: (meetingMinutes) => (
        <div className={styles.container}>
          <PrintInstructions>
            <ButtonWithIcon
              iconName="print"
              size="medium"
              color="primary"
              position="left"
              onClick={() => {
                window.print();
              }}
              className={styles.printButton}
            >
              Print
            </ButtonWithIcon>
          </PrintInstructions>
          <MeetingMinutesView
            meetingMinutes={meetingMinutes}
            additionalContent={
              isSignedMinutes(meetingMinutes) ? (
                <MeetingMinutesSignature
                  signature={meetingMinutes.signatures[0]}
                />
              ) : null
            }
          />
        </div>
      ),
    }),
  );
};

export default MeetingMinutesPrintPage;
