import { AgendaBlock } from 'editor-content/AgendaBlock.js';
import {
  BulletedListItemBlock,
  CartaCapTableBlock,
  ChartBlock,
  FileBlock,
  HeadingBlock,
  ImageBlock,
  LabelBlock,
  NumberedListItemBlock,
  ParagraphBlock,
  VideoBlock,
} from 'editor-content/Block.js';
import { DividerBlock } from 'editor-content/DividerBlock.js';
import { TableBlock } from 'editor-content/TableBlock.js';
import { TextFormat } from 'editor-content/TextNode.js';
import { BlockFormatBehavior } from '../../../../editor/blocks/BlockFormatBehavior.js';
import textBlockToggleFormatStrategy from '../../../../editor/blocks/textBlocksStrategies/textBlockToggleFormatStrategy.js';
import { isBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { HydratedBlock } from '../../../../types/HydratedBlock.js';
import { VoteBlockHydrated } from '../../../../VoteBlockHydrated.js';
import { BodyStateSelected } from './BodyEditor.js';
import updateBlockAt from './updateBlockAt.js';

const HeadingBlockFormatBehavior: BlockFormatBehavior<HeadingBlock> = {
  toggleFormat: textBlockToggleFormatStrategy,
};

const LabelBlockFormatBehavior: BlockFormatBehavior<LabelBlock> = {
  toggleFormat: textBlockToggleFormatStrategy,
};

const NumberedListItemBlockFormatBehavior: BlockFormatBehavior<NumberedListItemBlock> =
  {
    toggleFormat: textBlockToggleFormatStrategy,
  };

const ParagraphBlockFormatBehavior: BlockFormatBehavior<ParagraphBlock> = {
  toggleFormat: textBlockToggleFormatStrategy,
};

const BulletedListItemBlockFormatBehavior: BlockFormatBehavior<BulletedListItemBlock> =
  {
    toggleFormat: textBlockToggleFormatStrategy,
  };

const ImageBlockFormatBehavior: BlockFormatBehavior<ImageBlock> = {
  toggleFormat(block) {
    return block;
  },
};

const FileBlockFormatBehavior: BlockFormatBehavior<FileBlock> = {
  toggleFormat(block) {
    return block;
  },
};

const VoteBlockFormatBehavior: BlockFormatBehavior<VoteBlockHydrated> = {
  toggleFormat(block) {
    return block;
  },
};

const AgendaBlockFormatBehavior: BlockFormatBehavior<AgendaBlock> = {
  toggleFormat(block) {
    return block;
  },
};

const VideoBlockFormatBehavior: BlockFormatBehavior<VideoBlock> = {
  toggleFormat(block) {
    return block;
  },
};

const DividerBlockFormatBehavior: BlockFormatBehavior<DividerBlock> = {
  toggleFormat(block) {
    return block;
  },
};

const TableBlockFormatBehavior: BlockFormatBehavior<TableBlock> = {
  toggleFormat(block) {
    return block;
  },
};

const CartaCapTableFormatBehavior: BlockFormatBehavior<CartaCapTableBlock> = {
  toggleFormat(block) {
    return block;
  },
};

const ChartFormatBehavior: BlockFormatBehavior<ChartBlock> = {
  toggleFormat(block) {
    return block;
  },
};

const mapping = {
  paragraph: ParagraphBlockFormatBehavior,
  label: LabelBlockFormatBehavior,
  heading: HeadingBlockFormatBehavior,
  'numbered-list-item': NumberedListItemBlockFormatBehavior,
  'bulleted-list-item': BulletedListItemBlockFormatBehavior,
  image: ImageBlockFormatBehavior,
  file: FileBlockFormatBehavior,
  vote: VoteBlockFormatBehavior,
  agenda: AgendaBlockFormatBehavior,
  video: VideoBlockFormatBehavior,
  divider: DividerBlockFormatBehavior,
  table: TableBlockFormatBehavior,
  'carta-cap-table': CartaCapTableFormatBehavior,
  chart: ChartFormatBehavior,
};

export default function toggleFormat(
  initialState: BodyStateSelected,
  formatName: keyof TextFormat,
): BodyStateSelected {
  const { content, selection } = initialState;
  if (isBlockSelection(selection)) return { content, selection };
  const index = selection.index;
  const currentBlock = content[index];
  if (!currentBlock) return { content, selection };
  const BlockBehavior = mapping[
    currentBlock.type
  ] as BlockFormatBehavior<HydratedBlock>;

  return {
    content: updateBlockAt(
      content,
      index,
      BlockBehavior.toggleFormat(currentBlock, selection.offset, formatName),
    ),
    selection,
  };
}
