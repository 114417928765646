import { CheckmarkIcon, TrashIcon } from 'icons';
import React from 'react';
import Button from '../../../../design-system/atoms/Button.tsx';
import BulletedList, {
  BulletedListItem,
} from '../../../../design-system/zeck/BulletedList.tsx';
import Label from '../../../../design-system/zeck/Label.tsx';
import styles from './AiTableKeyTakeawaysReviewing.module.scss';

export type AiTableKeyTakeawaysFlowReviewingState = {
  type: 'reviewing';
  keyTakeaways: string[];
};

export type AiTableKeyTakeawaysReviewingProps = {
  onAccept: () => void;
  onDiscard: () => void;
} & AiTableKeyTakeawaysFlowReviewingState;

const AiTableKeyTakeawaysReviewing: React.FC<
  AiTableKeyTakeawaysReviewingProps
> = ({ keyTakeaways, onAccept, onDiscard }) => {
  return (
    <>
      <div className={styles.container}>
        <div>
          <Label>Key Takeaways</Label>
        </div>
        <div className={styles.content}>
          <BulletedList>
            {keyTakeaways.map((keyTakeaway, index) => (
              <BulletedListItem key={index} indent={0}>
                {keyTakeaway}
              </BulletedListItem>
            ))}
          </BulletedList>
        </div>
        <div className={styles.footer}>
          AI responses can include mistakes. Be sure to check for accuracy.
        </div>
      </div>
      <div className={styles.buttonMenu}>
        <Button
          size="medium"
          color="transparent"
          className={styles.button}
          onClick={() => {
            onAccept();
          }}
        >
          <CheckmarkIcon className={styles.buttonIcon} />
          Accept
        </Button>
        <Button
          size="medium"
          color="transparent"
          className={styles.button}
          onClick={() => {
            onDiscard();
          }}
        >
          <TrashIcon className={styles.buttonIcon} />
          Discard
        </Button>
      </div>
    </>
  );
};

export default AiTableKeyTakeawaysReviewing;
