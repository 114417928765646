import { CartaCapTable } from 'editor-content/CartaBlock.ts';
import { TableBlock } from 'editor-content/TableBlock.js';
import {
  IntegrationListItemData,
  IntegrationTypes,
} from '../../../../../api/endpoints/createIntegrationApi.ts';
import useApi from '../../../../../api/useApi.ts';
import { ModalContainer } from '../../../../../design-system/organisms/Modal.tsx';
import IntegrationsModalFlow from './IntegrationsModalFlow.tsx';

export type IntegrationsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreateTable: (table: TableBlock) => void;
  onAddCapTable: (capTable: CartaCapTable) => void;
  integrationData: IntegrationListItemData;
  integrationType: IntegrationTypes;
};

export default function IntegrationsModal({
  isOpen,
  onClose,
  onCreateTable,
  integrationData,
  integrationType,
  onAddCapTable,
}: IntegrationsModalProps) {
  const { createIntegration } = useApi();

  const { addIntegration } = integrationData;

  return (
    <ModalContainer isOpen={isOpen} onRequestClose={onClose}>
      <IntegrationsModalFlow
        onClose={onClose}
        createIntegration={createIntegration}
        addIntegration={addIntegration}
        onCreateTable={onCreateTable}
        integrationType={integrationType}
        onAddCapTable={onAddCapTable}
      />
    </ModalContainer>
  );
}
