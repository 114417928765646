import { BulletedListItemBlock, LabelBlock } from 'editor-content/Block.ts';
import { ChartBlock } from 'editor-content/ChartBlock.ts';
import { TableBlock } from 'editor-content/TableBlock.js';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import ActionChip from '../../../../design-system/molecules/ActionChip.tsx';
import isChartWorthy from '../../../../design-system/zeck/table/isChartWorthy.ts';
import TableFromBlock from '../../../../design-system/zeck/table/TableFromBlock.tsx';
import WideWidthBlockContainer from '../../../../design-system/zeck/WideWidthBlockContainer.js';
import DetectsOutsideClick from '../../../../junkDrawer/DetectsOutsideClick.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import {
  AiChartFlow,
  useChartPromptSubmission,
} from '../AiChartFlow/AiChartFlow.tsx';
import { AiContext } from '../AiChartFlow/createAiContext.ts';
import { useGenerateAiChartFlowProps } from '../AiChartFlow/useGenerateAiChartFlowProps.tsx';
import { AiTableKeyTakeawaysContext } from '../AiTableKeyTakeawaysFlow/aiTableKeyTakeawaysContext.ts';
import AiTableKeyTakeawaysFlow from '../AiTableKeyTakeawaysFlow/AiTableKeyTakeawaysFlow.tsx';
import { useGenerateAiTableKeyTakeawaysFlowProps } from '../AiTableKeyTakeawaysFlow/useGenerateAiTableKeyTakeawaysFlowProps.tsx';
import BlockActions from './BlockActions.ts';
import SelectableDiv from './SelectableDiv.tsx';
import styles from './TableEditableWithKeyboard.module.scss';

type TableEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  'data-testid'?: string;
  onInsertChartBlock: (chart: ChartBlock) => void;
  onInsertTableKeyTakeaways: (
    labelBlock: LabelBlock,
    bulletedListItemBlocks: BulletedListItemBlock[],
  ) => void;
  aiContext: AiContext;
  aiTableKeyTakeawaysContext: AiTableKeyTakeawaysContext;
} & BlockActions<TableBlock>;

const AiChartMenu = ({
  setIsOpen,
  onAddChart,
  onWriteKeyTakeaways,
}: {
  setIsOpen: (isOpen: boolean) => void;
  onAddChart: (isAddingChart: boolean) => void;
  onWriteKeyTakeaways: () => void;
}) => {
  return (
    <DetectsOutsideClick onClick={() => setIsOpen(false)}>
      {(ref) => (
        <div className={styles.aiChartMenuWrapper} ref={ref}>
          <div className={styles.aiChartMenuHeader}>Ask Zeck AI to:</div>

          <div className={styles.aiChartMenuAction}>
            <ActionChip
              iconName="aiChart"
              label="Create an interesting chart"
              onClick={() => {
                onAddChart(true);
                setIsOpen(false);
              }}
            />
          </div>
          <div className={styles.aiChartMenuAction}>
            <ActionChip
              iconName="aiPencilGradiant"
              label="Write key takeaways"
              onClick={() => {
                onWriteKeyTakeaways();
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      )}
    </DetectsOutsideClick>
  );
};

const AiActionMenuButton = ({
  onAddChart,
  onWriteKeyTakeaways,
}: {
  onAddChart: (isAddingChart: boolean) => void;
  onWriteKeyTakeaways: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <IconButton
        aria-label="Ask Zeck AI to perform an action"
        name="aiGradiantStar"
        onClick={() => setIsOpen(!isOpen)}
        className={styles.aiChartMenuButton}
      />
      {isOpen && (
        <AiChartMenu
          setIsOpen={setIsOpen}
          onAddChart={onAddChart}
          onWriteKeyTakeaways={onWriteKeyTakeaways}
        />
      )}
    </div>
  );
};

const MemoizedTable = React.memo(TableFromBlock);

export default React.forwardRef<HTMLElement, TableEditableWithKeyboardProps>(
  function TableEditableWithKeyboard(
    {
      className,
      block,
      selection,
      onSelect,
      isInBlockSelection,
      'data-testid': dataTestid,
      onInsertChartBlock,
      onInsertTableKeyTakeaways,
      aiContext,
      aiTableKeyTakeawaysContext,
    },
    forwardedRef,
  ) {
    const [addingChart, setAddingChart] = useAtom(
      aiContext.getChartMakingFamily(block),
    );

    const {
      forceTableSelection: forceTableSelectionForChart,
      setForceTableSelection,
      isSourceBlockActive,
      generateAiChart,
      setChartState,
      ...aiChartFlowProps
    } = useGenerateAiChartFlowProps({
      setAddingChart,
      onInsertChartBlock,
      fromBlock: block,
      selection,
      aiContext,
    });

    const handleSubmitChartPrompt = useChartPromptSubmission({
      generateAiChart,
      setChartState,
      setForceTableSelection,
    });

    const chartWorthy = isChartWorthy(block);

    const chartInProgress = addingChart && isSourceBlockActive;

    const tableKeyTakeawaysFlowProps = useGenerateAiTableKeyTakeawaysFlowProps({
      onInsertBlocks: onInsertTableKeyTakeaways,
      fromBlock: block,
      aiTableKeyTakeawaysContext: aiTableKeyTakeawaysContext,
    });

    const isWritingKeyTakeaways =
      tableKeyTakeawaysFlowProps.keyTakeawaysState.type !== 'inactive';

    const isActionInProgress = addingChart || isWritingKeyTakeaways;

    const onAddChart = (isAddingChart: boolean) => {
      onSelect({ anchorOffset: 0, focusOffset: 0 });
      setAddingChart(isAddingChart);
      handleSubmitChartPrompt('create an interesting chart from this table');
    };

    const forceTableSelectionForKeyTakeaways =
      tableKeyTakeawaysFlowProps.keyTakeawaysState.type !== 'inactive';

    const forceTableSelection =
      forceTableSelectionForChart || forceTableSelectionForKeyTakeaways;

    return (
      <WideWidthBlockContainer>
        <div className={styles.tableAndAiMenuContainer}>
          <SelectableDiv
            square
            className={className}
            onSelect={onSelect}
            internalSelection={selection}
            isInBlockSelection={isInBlockSelection || forceTableSelection}
            data-testid={dataTestid}
            ref={mergeRefs([forwardedRef])}
          >
            <MemoizedTable block={block} />
          </SelectableDiv>

          {chartWorthy && !isActionInProgress && (
            <AiActionMenuButton
              onAddChart={onAddChart}
              onWriteKeyTakeaways={
                tableKeyTakeawaysFlowProps.generateKeyTakeaways
              }
            />
          )}
        </div>
        {chartInProgress && (
          <AiChartFlow
            {...aiChartFlowProps}
            setForceTableSelection={setForceTableSelection}
            generateAiChart={generateAiChart}
            setChartState={setChartState}
          />
        )}
        {isWritingKeyTakeaways && (
          <AiTableKeyTakeawaysFlow {...tableKeyTakeawaysFlowProps} />
        )}
      </WideWidthBlockContainer>
    );
  },
);
