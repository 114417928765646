import { createParagraphBlock, isTextBlock } from 'editor-content/Block.js';
import pastePlaintext from '../../../../../editor/actions/copyPaste/pastePlaintext.js';
import textBlockGetEndOfBlockSelectionStrategy from '../../../../../editor/blocks/textBlocksStrategies/textBlockGetEndOfBlockSelectionStrategy.js';
import textBlockReplaceSelectedContentWithPlaintextStrategy from '../../../../../editor/blocks/textBlocksStrategies/textBlockReplaceSelectedContentWithPlaintextStrategy.js';
import { contentSelection } from '../../../../../editor/selection/contentSelection/ContentSelection.js';
import { HydratedBlock } from '../../../../../types/HydratedBlock.js';

export default pastePlaintext<HydratedBlock>({
  generateBlockEditor: (block) => ({
    getEndOfBlockSelection: () =>
      isTextBlock(block)
        ? textBlockGetEndOfBlockSelectionStrategy(block)
        : contentSelection(0),
    pastePlaintext: (plaintext, selection) => {
      if (!isTextBlock(block)) {
        return;
      } else {
        return textBlockReplaceSelectedContentWithPlaintextStrategy(
          block,
          selection,
          plaintext,
        );
      }
    },
  }),
  createDefaultBlock: createParagraphBlock,
});
