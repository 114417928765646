import { useState } from 'react';
import Point from '../../../domHelpers/Point.js';
import Rect, { isIntersecting } from '../../../domHelpers/Rect.js';
import useMouseSelection from './useMouseSelection.js';

type DeadzoneState =
  | { type: 'not-in-deadzone' }
  | { type: 'in-deadzone'; rect: Rect };

type WrappedHookType = typeof useMouseSelection;

const useMouseSelectionDeadzone =
  (wrappedHook: WrappedHookType) =>
  (...args: Parameters<WrappedHookType>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [deadzoneState, setDeadzoneState] = useState<DeadzoneState>({
      type: 'not-in-deadzone',
    });

    const { selectionStart, selectionMove, selectionEnd, isSelecting } =
      wrappedHook(...args);

    return {
      selectionStart: (p: Point, deadzone: Rect | null) => {
        if (deadzone) {
          setDeadzoneState({ type: 'in-deadzone', rect: deadzone });
        } else {
          setDeadzoneState({ type: 'not-in-deadzone' });
        }

        selectionStart(p);
      },
      selectionMove: (p: Point) => {
        switch (deadzoneState.type) {
          case 'not-in-deadzone':
            selectionMove(p);
            return;
          case 'in-deadzone':
            if (!isIntersecting([p, p], deadzoneState.rect)) {
              setDeadzoneState({ type: 'not-in-deadzone' });
              selectionMove(p);
            }
            return;
        }
      },
      selectionEnd: () => {
        selectionEnd();
      },
      isSelecting: deadzoneState.type === 'not-in-deadzone' && isSelecting,
    };
  };

export default useMouseSelectionDeadzone;
