import cx from 'classnames';
import styles from './PeopleListUsername.module.scss';

type PeopleListUsernameProps = {
  initialContent?: React.ReactNode;
  nameContent: React.ReactNode;
};

export const PeopleListUsername: React.FunctionComponent<
  PeopleListUsernameProps
> = ({ initialContent, nameContent }) => {
  return (
    <div className={styles.username}>
      {initialContent && (
        <span
          data-testid="person-initials"
          className={cx(styles.username__initialCircle)}
        >
          {initialContent}
        </span>
      )}
      <span data-testid="person-name" className={styles.username__name}>
        {nameContent}
      </span>
    </div>
  );
};
