import IconButton from '../../../../../design-system/atoms/IconButton.tsx';
import Tooltip from '../../../../../design-system/organisms/Tooltip.tsx';
import styles from './CommentIndicator.module.scss';

type CommentIndicatorProps = {
  onClick: () => void;
  commentCount: number;
  className?: string;
};

export const CommentIndicator = ({
  onClick,
  commentCount,
  className,
}: CommentIndicatorProps) => {
  const tipText = commentCount === 1 ? '1 comment' : `${commentCount} comments`;
  return (
    <Tooltip
      tipText={tipText}
      active
      className={className}
      tipClassName={styles.commentIndicator__tip}
    >
      <span
        data-testid="comment-indicator"
        className={styles.commentIndicator__wrapper}
      >
        <IconButton
          name="speechBubbleWithText"
          aria-label="Comment"
          className={styles.commentIndicator__icon}
          onClick={(e) => {
            onClick();
            e.preventDefault();
            e.stopPropagation();
          }}
        ></IconButton>
        <span className={styles.commentIndicator__count}>{commentCount}</span>
      </span>
    </Tooltip>
  );
};

export default CommentIndicator;
