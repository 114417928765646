import { TableBlock } from 'editor-content/TableBlock.js';
import { SelectionCommentsFeatureAtom } from '../../pages/zeck/previewAndPublish/publish/commentsSidebar/useComments/selectionComments/SelectionComments.js';
import TableFromBlock from './table/TableFromBlock.js';
import TableViewWithComments from './TableViewWithComments.js';
import { WideWidthBlockContainer } from './WideWidthBlockContainer.js';

type TableViewProps = {
  block: TableBlock;
  selectionCommentsFeatureAtom?: SelectionCommentsFeatureAtom;
  className?: string;
};

function TableView({
  className,
  block,
  selectionCommentsFeatureAtom,
}: TableViewProps) {
  if (selectionCommentsFeatureAtom) {
    return (
      <WideWidthBlockContainer className={className}>
        <TableViewWithComments
          block={block}
          selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
        />
      </WideWidthBlockContainer>
    );
  }

  return (
    <WideWidthBlockContainer className={className}>
      <TableFromBlock block={block} noBorder />
    </WideWidthBlockContainer>
  );
}

export default TableView;
