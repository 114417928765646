import { useState } from 'react';
import useApi from '../../../api/useApi.js';
import LinkButton from '../../../design-system/atoms/LinkButton.js';
import AsyncButton from '../../../design-system/molecules/AsyncButton.js';
import {
  AccessPageContent,
  AccessPageLayout,
} from '../../../design-system/organisms/AccessPage.js';
import styles from './UnauthorizedPage.module.scss';

type RequestAccessPageProps = {
  email: string;
  type: 'zeck' | 'section';
  requestedId: string;
  onClickLogout: () => void;
};

type UIState = 'not-requested' | 'requested' | 'error';

const RequestAccessPage: React.FC<RequestAccessPageProps> = ({
  email,
  type,
  requestedId,
  onClickLogout,
}) => {
  const { requestAccessRequest } = useApi();
  const [uiState, setUIState] = useState<UIState>('not-requested');

  switch (uiState) {
    case 'not-requested': {
      return (
        <AccessPageLayout>
          <AccessPageContent
            {...{
              title: "You Don't Have Access Yet",
              description:
                'You don’t have secure access to this Zeck yet. You can request access by clicking the button below. Or, you can reach out to your Zeck administrator directly.',
              actions: (
                <AsyncButton
                  size="large"
                  color="primary"
                  isFullWidth
                  onClick={async () => {
                    try {
                      await requestAccessRequest({
                        type,
                        requestedId,
                        path: window.location.pathname,
                      });
                      setUIState('requested');
                    } catch {
                      setUIState('error');
                    }
                  }}
                >
                  Request Access
                </AsyncButton>
              ),
              footnote: (
                <>
                  You’re trying to get access using{' '}
                  <span className={styles.unauthorizedPage__email}>
                    {email}
                  </span>
                  . Perhaps your Zeck admin added you to their secure backend
                  using a different email.{' '}
                  <a
                    href="/signin"
                    onClick={(e) => {
                      e.preventDefault();
                      onClickLogout();
                    }}
                  >
                    Sign out
                  </a>{' '}
                  to try a different account.
                </>
              ),
            }}
          />
        </AccessPageLayout>
      );
    }
    case 'requested': {
      return (
        <AccessPageLayout>
          <AccessPageContent
            {...{
              title: 'Access Requested',
              description:
                'We’ve forwarded your request for access to the company administrators.',
              actions: (
                <LinkButton size={'large'} to={'/'} isFullWidth>
                  Take Me To Home
                </LinkButton>
              ),
            }}
          />
        </AccessPageLayout>
      );
    }
    case 'error': {
      return (
        <AccessPageLayout>
          <AccessPageContent
            {...{
              title: 'Unable to Request Access',
              description:
                "Either this Zeck doesn't exist or request access is not enabled.",
              actions: (
                <LinkButton size={'large'} to={'/'} isFullWidth>
                  Take Me To Home
                </LinkButton>
              ),
            }}
          />
        </AccessPageLayout>
      );
    }
  }
};

export default RequestAccessPage;
