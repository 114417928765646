import { v4 } from 'uuid';
import { Block } from './Block.js';
import { baseBlock } from './BaseBlock.js';
import { FileBlock } from './FileBlock.js';
import { VoteType } from 'blocks/src/VoteBlock.ts';

// TODO: Put this elsewhere
export type Vote = {
  id: string;
  blockId: string;
  zeckId: string;
  sectionId: string;
  title: string;
  details: string;
  voteType: VoteType;
  approved: boolean;
  createdAt: number;
};

export type VoteBlockBase = {
  type: 'vote';
  title: string;
  details: string;
  id: string;
  supportingDocuments: FileBlock[];
  voteType: VoteType;
  prevoteEnabled: boolean;
};

export type OutstandingVoteBlock = VoteBlockBase;

export type CompletedVoteBlock = VoteBlockBase & {
  approved: boolean;
  votedAt: number;
};

export type VoteBlock = VoteBlockBase;

export const VoteBlock = (
  id: string,
  title: string,
  details: string,
  supportingDocuments: FileBlock[],
  voteType: VoteType,
  prevoteEnabled = true,
): VoteBlock =>
  baseBlock('vote', id, {
    title,
    details,
    supportingDocuments,
    voteType,
    prevoteEnabled,
  });

export const createVoteBlock = (
  title: string,
  details: string,
  voteType: VoteType = VoteType.None,
): VoteBlock => VoteBlock(v4(), title, details, [], voteType);

export const copyVoteBlock = (block: VoteBlock): VoteBlock =>
  // We've approaching this with the assumption that copying a vote block in
  // any state -- completed or not -- is intended to create a brand new vote
  // block that will be used to take a vote. We're not supporting copying the
  // result of a completed vote block.
  ({
    ...createVoteBlock(block.title, block.details),
    supportingDocuments: block.supportingDocuments,
    voteType: block.voteType,
  });

export const isVoteBlock = (block: Block): block is VoteBlock =>
  block.type === 'vote';
