import { isTextBlock } from 'editor-content/Block.js';
import {
  isBlockSelection,
  isTextSelection,
} from '../../../../editor/EditorSelection.js';
import { EditorContent } from '../../edit/useEditorState.js';
import getSelectedBlock from '../BodyEditor/getSelectedBlock.js';
import ZeckEditorSelection, {
  isBodySelection,
} from '../ZeckEditorSelection.js';
import { navRight } from './KeyboardNavigationActions.js';

/**
 * @deprecated this should only exist until we implement a general pressArrowDown that uses fancyNav
 */
export const pressArrowDownNonTextBlock = (
  content: EditorContent,
  selection: ZeckEditorSelection,
) => {
  if (!isBodySelection(selection)) return;

  if (isBlockSelection(selection)) return;
  else if (isTextSelection(selection)) {
    const bodyContent = content.body;

    const selectedBlock = getSelectedBlock(bodyContent, selection);
    if (!selectedBlock) return;

    if (isTextBlock(selectedBlock)) return;

    return navRight(content, selection);
  }
};
