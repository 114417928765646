import { Block } from 'editor-content/Block.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import { Editor } from 'editor-content/TextNode.js';
import useApi from '../../api/useApi.js';
import { foldResult, pipe } from '../../result/Result.js';
import useFetchInvalidate from '../../services/useFetch/useFetchInvalidate.js';
import SelectionCommentWithActions, {
  selectionCommentWithActions,
} from './editor/comments/SelectionCommentWithActions.js';

type HookReturn = {
  selectionComments: SelectionCommentWithActions[];
  createSelectionComment: (
    highlightId: string,
    content: CommentContentNode[],
    selection: Block | Editor.Highlight,
  ) => Promise<void>;
};

export default function useFetchSelectionComments(
  sectionId: string,
): HookReturn {
  const {
    listSelectionComments,
    createSelectionComment,
    deleteSelectionComment,
    updateSelectionComment,
  } = useApi();

  const { result, invalidate } = useFetchInvalidate(
    () => listSelectionComments(sectionId),
    [sectionId],
  );

  const selectionComments = pipe(
    result,
    foldResult({
      error: () => [],
      loading: () => [],
      success: (d) => d,
    }),
  );

  const selectionCommentsWithActions = selectionComments.map((comment) =>
    selectionCommentWithActions(
      comment,
      async (comment) => {
        await invalidate(async () => {
          await updateSelectionComment(comment);
        });
      },
      async () => {
        await invalidate(async () => {
          await deleteSelectionComment(comment.id, sectionId);
        });
      },
    ),
  );

  return {
    selectionComments: selectionCommentsWithActions,
    createSelectionComment: async (highlightId, content, selection) => {
      await invalidate(async () => {
        await createSelectionComment(
          sectionId,
          highlightId,
          content,
          selection,
        );
      });
    },
  };
}
