import { ValueType, Workbook } from 'exceljs';
import { MicrosoftExcelIcon } from 'icons';
import { useRef, useState } from 'react';
import ButtonWithLoading from '../../../../../design-system/molecules/ButtonWithLoading.tsx';
import { ErrorWithContext, logError } from '../../../../../logging/logger.ts';
import { DocumentMeta } from './integrationModalTypes.ts';
import styles from './MicrosoftExcelUploadPanel.module.scss';
import { createExcelWorkbookData } from './utils/excel/createExcelWorkbookData.ts';

type MicrosoftExcelUploadPanelProps = {
  onPickWorkbook: (data: {
    documentMeta: DocumentMeta;
    workbook: Workbook;
    valueTypes: typeof ValueType;
  }) => void;
};

const MicrosoftExcelUploadPanel = ({
  onPickWorkbook,
}: MicrosoftExcelUploadPanelProps) => {
  const [processing, setProcessing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.excelContainer}>
      <div className={styles.excelHeader}>
        <MicrosoftExcelIcon width="48" height="48" />
        <div className={styles.excelHeaderText}>Microsoft Excel</div>
      </div>

      <div className={styles.excelByline}>
        Import reports and data directly into your Zeck.
      </div>

      <ButtonWithLoading
        size="medium"
        color="secondary"
        onClick={() => {
          fileRef.current?.click();
        }}
        isLoading={processing}
        className={styles.uploadButton}
      >
        <MicrosoftExcelIcon />
        Upload Excel File
      </ButtonWithLoading>

      <input
        type="file"
        multiple={false}
        accept=".xlsx"
        onChange={(e) => {
          const file = e.currentTarget.files && e.currentTarget.files[0];

          if (file) {
            setProcessing(true);
            setHasError(false);

            const fileHandler = async (file: File) => {
              try {
                const buffer = await file.arrayBuffer();

                const workbookData = await createExcelWorkbookData(
                  { buffer, lastModified: new Date(file.lastModified) },
                  {
                    documentId: 'excelUpload',
                    documentName: file.name,
                  },
                );

                onPickWorkbook(workbookData);
              } catch (err) {
                logError(
                  new ErrorWithContext(
                    'Excel File Upload Error: ' + err,
                    {
                      filename: file.name,
                      filesize: file.size,
                      filetype: file.type,
                    },
                    'ExcelFileUploadError',
                  ),
                );
                setHasError(true);
              } finally {
                setProcessing(false);
              }
            };

            fileHandler(file);
          }
        }}
        ref={fileRef}
        className={styles.fileInput}
        data-testid="excel-file-input"
      />

      {hasError && (
        <div className={styles.errorMessage}>
          There was an issue processing this file, please try again or try a
          different file.
        </div>
      )}
    </div>
  );
};

export default MicrosoftExcelUploadPanel;
