import { createContext } from 'react';
import createApi, { ApiService } from './createApi.js';
import AiApiClient from './endpoints/AiApiClient.ts';
import AssetApiClient from './endpoints/AssetApiClient.ts.ts';
import PublishedCommentApiClient from './endpoints/PublishedCommentApiClient.js';
import makeAnalyticsApiRequest from './makeAnalyticsApiRequest.js';
import makeDeletionApiRequest from './makeDeletionRequestApiRequest.js';
import makeLambdaApiRequest from './makeLambdaApiRequest.js';

export default createContext<ApiService>(
  createApi(
    makeLambdaApiRequest,
    makeAnalyticsApiRequest,
    makeDeletionApiRequest,
    {
      commentApiClient: PublishedCommentApiClient,
      assetApiClient: AssetApiClient,
      aiApiClient: AiApiClient,
    },
    null,
  ),
);
