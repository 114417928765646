import Vote from '../../types/Vote.js';
import { makeMakeApiRequest } from '../makeMakeApiRequest.js';

const createVoteApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createVote: async ({
    sectionId,
    ...voteData
  }: Omit<Vote, 'id' | 'createdAt'>): Promise<Vote> => {
    return await makeLambdaApiRequest(`/section/${sectionId}/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(voteData),
    });
  },
  updateVote: async ({
    voteId,
    sectionId,
    title,
    details,
    approved,
  }: {
    voteId: string;
    sectionId: string;
    title: string;
    details: string;
    approved: boolean;
  }): Promise<Vote> => {
    return await makeLambdaApiRequest(`/section/${sectionId}/vote/${voteId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        title,
        details,
        approved,
      }),
    });
  },
  deleteVote: async ({
    voteId,
    companyId,
  }: {
    voteId: string;
    companyId: string;
  }): Promise<void> => {
    return await makeLambdaApiRequest(`/company/${companyId}/vote/${voteId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

export default createVoteApi;
