import cx from 'classnames';
import React, { MouseEventHandler, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rightAlignedCenteredElement } from '../../domHelpers/hoverNextTo/positionStrategy/rightAlignedCentered.ts';
import mergeRefs from '../../junkDrawer/mergeRefs.js';
import WithTooltip from '../organisms/WithTooltip.tsx';
import styles from './SidebarItem.module.scss';

type SidebarItemProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  sublabel?: React.ReactNode;
  kebab?: React.ReactNode;
  className?: string;
  tooltip?: string;
  iconOnly?: boolean;
  active?: boolean;
  hover?: boolean;
};

const SidebarItem = ({
  icon,
  label,
  sublabel,
  className,
  tooltip,
  iconOnly,
  kebab,
  ...otherProps
}: SidebarItemProps) => {
  if (iconOnly) {
    return (
      <WithTooltip<HTMLDivElement> text={tooltip}>
        {(ref, listeners) => (
          <div
            {...{
              ref,
              ...otherProps,
              ...listeners,
              className: cx(
                className,
                styles.sidebarItem,
                styles.sidebarItem_collapse,
              ),
            }}
          >
            <div className={styles.sidebarItem__iconContainer}>{icon}</div>
          </div>
        )}
      </WithTooltip>
    );
  }

  return (
    <WithTooltip<HTMLDivElement>
      text={tooltip}
      positionStrategy={rightAlignedCenteredElement(-16)}
    >
      {(ref, listeners) => (
        <div
          {...{
            ref,
            ...listeners,
            ...otherProps,
            className: cx(className, styles.sidebarItem),
          }}
        >
          <div className={styles.sidebarItem__iconContainer}>{icon}</div>
          <div className={styles.sidebarItem__labelContainer}>{label}</div>
          {!!sublabel && (
            <div className={styles.sidebarItem__sublabelContainer}>
              {sublabel}
            </div>
          )}
          {!!kebab && (
            <div className={styles.sidebarItem__kebabContainer}>{kebab}</div>
          )}
        </div>
      )}
    </WithTooltip>
  );
};

type SidebarLinkProps = {
  href: string;
  ref?: React.Ref<HTMLAnchorElement>;
  isEditing: boolean;
  tooltip?: string;
  onClick?: () => void;
} & SidebarItemProps;

export const SidebarLink = React.forwardRef<
  HTMLAnchorElement,
  SidebarLinkProps
>(function SidebarLink(
  {
    className,
    isEditing,
    href,
    active,
    hover,
    onClick = () => {},
    ...otherProps
  },
  forwardedRef,
) {
  const navigate = useNavigate();

  const ref = useRef<HTMLElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOver: MouseEventHandler = (e) => {
    const containerEl = ref.current;

    if (!containerEl) return;
    if (!(e.target instanceof Element)) return;
    const closestInteractive = e.target.closest('a,button');

    if (
      closestInteractive !== containerEl &&
      containerEl.contains(closestInteractive)
    ) {
      setIsHovered(true);

      return;
    }

    setIsHovered(false);
  };

  const disableLink = isHovered || isEditing;
  const Component = disableLink ? 'div' : 'a';

  return (
    <Component
      href={href}
      ref={mergeRefs([forwardedRef, ref])}
      className={cx(
        styles.sidebarLink,
        active && styles.sidebarLink_active,
        hover && styles.sidebarLink_hover,
        className,
      )}
      onClick={(e) => {
        if (disableLink) return;
        e.preventDefault();
        onClick();
        navigate(href);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onMouseOver={onMouseOver}
    >
      <SidebarItem {...otherProps} />
    </Component>
  );
});

type SidebarButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
} & SidebarItemProps;

export const SidebarButton = ({
  className,
  onClick,
  ...otherProps
}: SidebarButtonProps) => {
  return (
    <button className={cx(styles.sidebarButton, className)} onClick={onClick}>
      <SidebarItem {...otherProps} />
    </button>
  );
};

export default SidebarItem;
