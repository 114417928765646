import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as hubSpot from '../external/hubSpot.js';
import assertDefined from './junkDrawer/assertDefined.js';
import useBreakpoints from './services/useBreakpoints.js';
import { CompanyLite } from './types.ts';
import { getCompanyIdFromPathname } from './userAndCompany/getCompanyIdFromPathname.ts';

const noHubSpotRoutes = ['/company/:companyId/:slug'];
const noHubSpotOnMobileRoutes = [
  '/company/:companyId/s/:slug',
  '/company/:companyId/:slug',
  '/company/:id/s/:slug',
];

function showOrHideHubSpot(isMobile: boolean, currentRoute: string) {
  if (
    noHubSpotRoutes.includes(currentRoute) ||
    (isMobile && noHubSpotOnMobileRoutes.includes(currentRoute))
  ) {
    hubSpot.hide();
  } else {
    hubSpot.show();
  }
}

type RoutesProps = {
  routes: Parameters<typeof createBrowserRouter>[0];
  company?: CompanyLite;
};

export function Routes({ routes, company }: RoutesProps) {
  const router = createBrowserRouter(routes);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    const checkPathnameCompanyMismatch = (companyId: string | undefined) => {
      const companyIdInURL = getCompanyIdFromPathname(window.location.pathname);

      if (companyIdInURL && companyId && companyIdInURL !== companyId) {
        window.location.reload(); // TODO: this is a temporary fix, but ideally we should handle this case without a full page reload
      }
    };

    showOrHideHubSpot(
      isMobile,
      assertDefined(assertDefined(router.state.matches[0]).route.path),
    );

    let lastUrl: string | null = null;

    router.subscribe((state) => {
      const currentUrl = assertDefined(
        assertDefined(state.matches[0]).route.path,
      );

      showOrHideHubSpot(isMobile, currentUrl);

      if (currentUrl !== lastUrl) {
        lastUrl = currentUrl;
      }

      checkPathnameCompanyMismatch(company?.id);
    });

    checkPathnameCompanyMismatch(company?.id);

    return () => {
      router.dispose();
    };
  });

  return <RouterProvider router={router} />;
}
