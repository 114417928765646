import { TableBlock } from 'editor-content/TableBlock.ts';
import { validateTable } from '../../../../../../design-system/zeck/table/validateTable.js';
import { useErrorModal } from '../../../../../../editor/errorModal/ErrorModal.tsx';
import {
  ErrorWithContext,
  logError,
} from '../../../../../../logging/logger.js';

export const MERGES_IN_FREEZE_SYNC_ERROR = {
  title: 'Invalid Merge Cell in Frozen Area',
  message:
    'Your updated table had a merge cell in a region you had frozen. We have unfrozen the table for you.',
};

const useTableSyncValidation = () => {
  const { setError } = useErrorModal();

  const getStickyValuesAndMaybeSetError = (
    block: TableBlock,
    updatedTableBlock: TableBlock['data'],
  ): Pick<TableBlock, 'frozenColumns' | 'frozenRows'> => {
    const validateResult = validateTable({
      ...block,
      data: updatedTableBlock,
    });

    switch (validateResult) {
      case 'valid': {
        return {
          frozenRows: block.frozenRows,
          frozenColumns: block.frozenColumns,
        };
      }
      case 'invalid-dimensions': {
        // this should never happen, if it does going forward
        // we have an error in our validation logic
        logError(
          new ErrorWithContext(
            'User synced a table that is missing cells, this will likely cause problems for merges and frozen regions',
            { blockId: block.id },
            'InvalidTableDimensionsError',
          ),
        );
        return {
          frozenRows: block.frozenRows,
          frozenColumns: block.frozenColumns,
        };
      }
      case 'invalid-freeze': {
        setError(MERGES_IN_FREEZE_SYNC_ERROR);

        return {
          frozenRows: undefined,
          frozenColumns: undefined,
        };
      }
    }
  };

  return { getStickyValuesAndMaybeSetError };
};

export default useTableSyncValidation;
