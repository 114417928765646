import { Icon } from 'icons';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MeetingMinutesListItem } from '../../../api/endpoints/createMeetingMinutesApi.ts';
import useApi from '../../../api/useApi.ts';
import Button from '../../../design-system/atoms/Button.tsx';
import { meetingMinutesPath } from '../../../services/Paths.ts';
import { CompanyLite } from '../../../types.ts';
import {
  ManagementPageShell,
  ManagementPageTab,
  ManagementPageTabs,
  ManagementPageTitle,
} from '../../ManagementPage.tsx';
import { buildMeetingMinutesDefaultContent } from '../MeetingMinutes.ts';
import CreateNewMeetingMinutes from './CreateNewMeetingMinutes.tsx';
import MeetingMinutesCard from './MeetingMinutesCard.tsx';
import MeetingMinutesListEmpty from './MeetingMinutesListEmpty.tsx';
import styles from './MeetingMinutesListPageView.module.scss';

type MeetingMinutesListPageViewProps = {
  meetingMinutes: MeetingMinutesListItem[];
  company: CompanyLite;
  onDeleteMeetingMinutes: (meetingMinutesId: string) => Promise<void>;
  onRenameMeetingMinutes: (
    minutes: MeetingMinutesListItem,
    newName: string,
  ) => Promise<void>;
  onApproveMeetingMinutes: (
    meetingMinutesId: string,
    approved: boolean,
  ) => Promise<void>;
  onDuplicateMeetingMinutes: (
    meetingMinutesId: string,
    keepZeck: boolean,
  ) => Promise<void>;
};

export const MeetingMinutesListPageView: React.FC<
  MeetingMinutesListPageViewProps
> = ({
  company,
  meetingMinutes,
  onDeleteMeetingMinutes,
  onApproveMeetingMinutes,
  onRenameMeetingMinutes,
  onDuplicateMeetingMinutes,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const navigate = useNavigate();
  const {
    createMeetingMinutes: createMeetingMinutesApi,
    createMeetingMinutesFromZeck,
  } = useApi();

  const onCreateMeetingMinutes = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  const tabs: ManagementPageTab[] = [
    { name: 'Meeting Minutes', active: true },
    { name: 'Votes', link: `/company/${company.id}/voting` },
  ];

  return (
    <ManagementPageShell>
      <ManagementPageTitle title="Minutes Book" />
      <ManagementPageTabs tabs={tabs} />
      <CreateNewMeetingMinutes
        company={company}
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onStartFromBlankTemplate={async (title) => {
          const minutes = await createMeetingMinutesApi(company.id, {
            title,
            content: buildMeetingMinutesDefaultContent(company).content,
          });
          navigate(meetingMinutesPath(minutes));
        }}
        onGenerateFromPublishedZeck={async (zeck) => {
          const minutes = await createMeetingMinutesFromZeck(
            company.id,
            zeck.id,
          );
          navigate(meetingMinutesPath(minutes));
        }}
      />

      <div className={styles.meetingMinutesListPage__container}>
        {meetingMinutes.length === 0 && (
          <MeetingMinutesListEmpty
            onCreateMeetingMinutes={onCreateMeetingMinutes}
          />
        )}

        {!!meetingMinutes.length && (
          <>
            <div className={styles.meetingMinutesListPage__toolbar}>
              <span className={styles.meetingMinutesListPage__titleHeading}>
                Title
              </span>
              <span className={styles.meetingMinutesListPage__statusHeading}>
                Status
              </span>
              <Button
                onClick={onCreateMeetingMinutes}
                size={'medium'}
                color="primary"
              >
                <Icon name="plus" />
                <span data-testid="create-new-minutes">Create New Minutes</span>
              </Button>
            </div>

            <div data-testid="meeting-minutes-list">
              {meetingMinutes.map((meetingMinutes) => (
                <MeetingMinutesCard
                  key={meetingMinutes.id}
                  companyId={company.id}
                  meetingMinutes={meetingMinutes}
                  onDeleteMeetingMinutes={onDeleteMeetingMinutes}
                  onApproveMeetingMinutes={onApproveMeetingMinutes}
                  onRenameMeetingMinutes={onRenameMeetingMinutes}
                  onDuplicateMeetingMinutes={onDuplicateMeetingMinutes}
                  zeckTitle={meetingMinutes.zeckName}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </ManagementPageShell>
  );
};
