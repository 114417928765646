import { TextBlock } from 'editor-content/Block.js';
import { getTextFromNodes } from 'editor-content/TextNode.js';
import React from 'react';
import splitTextNodesFromContentSelection from '../../../../../../../editor/blocks/textNode/splitTextNodesFromContentSelection.js';
import ContentSelection from '../../../../../../../editor/selection/contentSelection/ContentSelection.js';
import { truncate } from '../../../../../../../junkDrawer/truncate.js';
import { CommentQuoteStatus } from '../../useComments/selectionComments/CommentQuoteStatus.js';
import styles from './CommentQuote.module.scss';

export const QuotedTextBlock: React.FC<{
  block: TextBlock;
  snippetSelection: ContentSelection;
  quoteStatus: CommentQuoteStatus;
}> = ({ block, snippetSelection, quoteStatus }) => {
  return (
    <div data-testid="comment-quote" className={styles.commentOrReply__quote}>
      {getQuotedTextFromBlock(block, snippetSelection)}
      <TextBlockQuoteStatus quoteStatus={quoteStatus} />
    </div>
  );
};

const getQuotedTextFromBlock = (
  block: TextBlock,
  snippetSelection: ContentSelection,
) => {
  const [, selectedTextNodes] = splitTextNodesFromContentSelection(
    block.content,
    snippetSelection,
  );
  const text = getTextFromNodes(selectedTextNodes);
  return truncate(text, 960);
};

const TextBlockQuoteStatus: React.FC<{
  quoteStatus: CommentQuoteStatus;
}> = ({ quoteStatus }) => {
  let statusText: string | null = null;

  if (quoteStatus === 'edited') {
    // is edited
    statusText = '(Text may have been edited since this was posted.)';
  }

  if (quoteStatus === 'disowned' || quoteStatus === 'orphaned') {
    statusText = '(Text was edited or deleted since this was posted.)';
  }

  if (!statusText) {
    return null;
  }

  return <div className={styles.commentOrReply__quoteStatus}>{statusText}</div>;
};
