import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './Heading.module.scss';

type HeadingProps = {
  children?: ReactNode;
  className?: string;
};

const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  (props, forwardedRef) => {
    return (
      <h2
        {...{ ...props, ref: forwardedRef }}
        className={cx(styles.heading, props.className)}
      >
        {props.children}
      </h2>
    );
  },
);

export default Heading;
