import { Icon } from 'icons';
import Button from '../design-system/atoms/Button.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../design-system/organisms/Modal.tsx';
import styles from './OldVersionModal.module.scss';

type OldVersionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function OldVersionModal({
  isOpen,
  onClose,
}: OldVersionModalProps) {
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader className={styles.header}>
        <Icon name="danger" /> We're Upgrading Zeck
      </ModalHeader>
      <ModalBody className={styles.body}>
        We just made a quick upgrade to the site. There is a small chance what
        you were doing was not saved. We recommend checking your work to see if
        it was saved or not.
      </ModalBody>
      <ModalActions>
        <Button onClick={onClose} color="primary" size="medium">
          Got It
        </Button>
      </ModalActions>
    </Modal>
  );
}
