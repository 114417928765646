import { useEffect } from 'react';
import arrayIsNotEmpty from '../../../junkDrawer/arrayIsNotEmpty.js';
import { highlightSelector } from './highlights.js';

export default function useDarkenHighlights(highlightIds: string[]): void {
  useEffect(() => {
    if (!arrayIsNotEmpty(highlightIds)) return;
    // arbitrarily pick first highlight id in array
    const [highlightId] = highlightIds;

    const style = document.createElement('style');

    style.innerHTML = `${highlightSelector(highlightId)} { background-color: var(--color-yellow-3)!important }`;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [highlightIds.join('')]); // eslint-disable-line react-hooks/exhaustive-deps
}
