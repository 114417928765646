import React, { useRef, useState } from 'react';
import Rect, { getRectFromEl } from '../../domHelpers/Rect.js';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.js';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import useAnimationLoop from '../../services/useAnimationLoop.js';
import getHoveredBlockIndex from './getHoveredBlockIndex.js';

const useHoveredBlockIndex = (
  blocksWithEl: ElementAndData<unknown>[],
  leftGutterRef: React.RefObject<HTMLElement>,
) => {
  const hoveredIndexRef = useRef(-1);

  useDocumentEventListener(
    'mousemove',
    (event) => {
      const leftGutterEl = leftGutterRef.current;
      if (!leftGutterEl) return;

      hoveredIndexRef.current = getHoveredBlockIndex(
        blocksWithEl.map(({ getEl }) => {
          const el = getEl();
          return el ? getRectFromEl(el) : Rect(0, 0, 0, 0);
        }),
        getRectFromEl(leftGutterEl),
        [event.clientX, event.clientY],
      );
    },
    [blocksWithEl],
  );

  const [renderedHoveredIndex, setRenderedHoveredIndex] = useState(-1);

  useAnimationLoop(() => {
    if (hoveredIndexRef.current === renderedHoveredIndex) return;

    setRenderedHoveredIndex(hoveredIndexRef.current);
  }, [renderedHoveredIndex]);

  return renderedHoveredIndex;
};

export default useHoveredBlockIndex;
