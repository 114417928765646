import React, { useState } from 'react';
import IconButton from '../../design-system/atoms/IconButton.js';
import InputText from '../../design-system/atoms/InputText.js';
import OutlineButton from '../../design-system/atoms/OutlineButton.js';
import ButtonWithLoading from '../../design-system/molecules/ButtonWithLoading.js';
import ThreeDotLoading from '../../design-system/molecules/ThreeDotLoading.tsx';
import DrawerMenu from '../../design-system/organisms/DrawerMenu.js';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../design-system/organisms/Modal.js';
import useBreakpoints from '../../services/useBreakpoints.js';
import styles from './UserEmailVerificationForm.module.scss';

const ResendEmailComponent: React.FC<{
  onResend: () => Promise<void>;
}> = ({ onResend }) => {
  const [isResending, setIsResending] = useState(false);
  const handleResend = async () => {
    setIsResending(true);
    await onResend();
    setIsResending(false);
  };

  return (
    <p className={styles.userEmailForm__didntReceiveCode}>
      Didn't receive code?{' '}
      {isResending ? (
        <span className={styles.userEmailForm__loaderContainer}>
          <ThreeDotLoading />
        </span>
      ) : (
        <a href="#" onClick={handleResend}>
          Resend code
        </a>
      )}
    </p>
  );
};
const UserEmailVerificationFormMobile: React.FC<{
  newEmail: string;
  formState: string;
  setFormState: (newValue: string) => void;
  isError: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onResend: () => Promise<void>;
}> = ({
  onSubmit,
  onCancel,
  onResend,
  newEmail,
  isError,
  formState,
  setFormState,
  isLoading,
}) => {
  return (
    <DrawerMenu isOpen onRequestClose={onCancel}>
      <div className={styles.userEmailFormMobile__header}>
        <span>Email Verification</span>
        <IconButton
          name="close"
          aria-label="cancel verification"
          onClick={onCancel}
        />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className={styles.userEmailFormMobile__body}>
          <p className={styles.userEmailForm__bodyText}>
            Please enter the verification code we have sent to <b>{newEmail}</b>
          </p>
          <InputText
            error={isError && 'Invalid verification code. Please try again.'}
            label={'Verification Code'}
            value={formState}
            onChange={setFormState}
            maxLength={7}
            disabled={isLoading}
            className={styles.userEmailForm__verificationCodeInput}
            autoComplete="one-time-code"
          />
          <ButtonWithLoading
            size="large"
            color="primary"
            type="submit"
            disabled={formState.length !== 7}
            isLoading={isLoading}
            className={styles.userEmailFormMobile__submit}
          >
            Verify Code & Sign Out
          </ButtonWithLoading>

          <ResendEmailComponent onResend={onResend} />
        </div>
      </form>
    </DrawerMenu>
  );
};

const UserEmailVerificationFormDesktop: React.FC<{
  newEmail: string;
  formState: string;
  setFormState: (newValue: string) => void;
  isError: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  onResend: () => Promise<void>;
}> = ({
  onSubmit,
  onCancel,
  onResend,
  newEmail,
  isError,
  formState,
  setFormState,
  isLoading,
}) => {
  return (
    <Modal isOpen onRequestClose={onCancel}>
      <ModalHeader>Email Verification</ModalHeader>
      <form
        className={styles.userEmailForm}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <ModalBody>
          <p className={styles.userEmailForm__bodyText}>
            Please enter the verification code we have sent to <b>{newEmail}</b>
          </p>
          <div className={styles.userEmailForm__row}>
            <InputText
              error={isError && 'Invalid verification code. Please try again.'}
              label={'Verification Code'}
              value={formState}
              onChange={setFormState}
              maxLength={7}
              disabled={isLoading}
              className={styles.userEmailForm__verificationCodeInput}
              autoComplete="one-time-code"
            />

            <ResendEmailComponent onResend={onResend} />
          </div>
        </ModalBody>
        <ModalActions>
          <OutlineButton
            onClick={onCancel}
            size="medium"
            color="secondary"
            type="button"
            disabled={isLoading}
          >
            Cancel
          </OutlineButton>
          <ButtonWithLoading
            size="medium"
            color="primary"
            type="submit"
            disabled={formState.length !== 7}
            isLoading={isLoading}
          >
            Verify Code & Sign Out
          </ButtonWithLoading>
        </ModalActions>
      </form>
    </Modal>
  );
};

const UserEmailVerificationForm: React.FC<{
  newEmail: string;
  onCancel: () => void;
  onSubmit: (
    confirmationCode: string,
  ) => Promise<{ type: 'success' } | { type: 'error' }>;
  onResend: () => Promise<void>;
}> = (props) => {
  const { isMobile } = useBreakpoints();

  const [formState, setFormState] = useState('');

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit() {
    setIsLoading(true);
    const result = await props.onSubmit(formState.toUpperCase());
    setIsLoading(false);

    if (result.type === 'error') setIsError(true);
  }

  if (isMobile) {
    return (
      <UserEmailVerificationFormMobile
        newEmail={props.newEmail}
        isLoading={isLoading}
        formState={formState}
        setFormState={setFormState}
        isError={isError}
        onSubmit={handleSubmit}
        onCancel={props.onCancel}
        onResend={props.onResend}
      />
    );
  }

  return (
    <UserEmailVerificationFormDesktop
      newEmail={props.newEmail}
      isLoading={isLoading}
      formState={formState}
      setFormState={setFormState}
      isError={isError}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
      onResend={props.onResend}
    />
  );
};

export default UserEmailVerificationForm;
