import Linkable from 'editor-content/html/Linkable.ts';
import { TextNode } from 'editor-content/TextNode.js';
import React from 'react';
import { HeadlineParagraph } from '../../../../design-system/zeck/Headline.tsx';
import ContentSelection from '../../../../editor/selection/contentSelection/ContentSelection.ts';
import { getSafePlaintextFromClipboardData } from '../../../../junkDrawer/getSafePlaintextFromClipboardData.ts';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import useZeckContentEditable from './useZeckContentEditable.ts';

const HeadlineEditable = React.forwardRef<
  HTMLElement,
  {
    value: TextNode[];
    onChange: (newContent: TextNode[], newSelection: ContentSelection) => void;
    onSelect: (newSelection: ContentSelection) => void;
    selection: ContentSelection | null;
    linkables: Linkable[];
    className?: string;
  }
>(function HeadlineEditable(
  { value, onChange, selection, onSelect, linkables, ...otherProps },
  forwardedRef,
) {
  const contentEditableProps = useZeckContentEditable(
    {
      content: value,
      selection,
    },
    ({ content, selection }) => {
      onChange(content, selection);
    },
    onSelect,
    { linkables },
  );
  return (
    <HeadlineParagraph
      {...{
        ...otherProps,
        ...contentEditableProps,
        onPaste(e: ClipboardEvent) {
          e.preventDefault();
          if (!e.clipboardData) return;

          const text = getSafePlaintextFromClipboardData(e.clipboardData);
          const values = text.split('\n');
          document.execCommand('insertText', false, values.join(' '));
        },
        ref: mergeRefs([contentEditableProps.ref, forwardedRef]),
      }}
    />
  );
});

export default HeadlineEditable;
