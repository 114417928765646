import { useState } from 'react';
import Point from '../../domHelpers/Point.js';
import Rect from '../../domHelpers/Rect.js';
import moveItem from './moveItem.js';

type DragState =
  | {
      type: 'not-dragging';
    }
  | {
      type: 'dragging';
      sourceIndex: number;
      dropIndex: number;
    };

export default function useSortable<O extends { getRect: () => Rect }>(
  items: O[],
  onReorder: (newItems: O[]) => void,
) {
  const [state, setState] = useState<DragState>({
    type: 'not-dragging',
  });

  switch (state.type) {
    case 'not-dragging':
      return {
        items: items.map((item, i) => ({
          ...item,
          dragStart() {
            setState({
              type: 'dragging',
              dropIndex: i,
              sourceIndex: i,
            });
          },
        })),
        dragEnd() {
          //
        },
        dragMove(_point: Point) {
          //
        },
      };

    case 'dragging': {
      const reorderedItems = moveItem(
        items,
        state.sourceIndex,
        state.dropIndex,
      );

      return {
        items: reorderedItems.map((item) => ({
          ...item,
          dragStart() {
            //
          },
        })),
        dragEnd() {
          onReorder(reorderedItems);
          setState({ type: 'not-dragging' });
        },
        dragMove(point: Point) {
          for (const [i, item] of items.entries()) {
            const rect = item.getRect();
            const dragY = point[1];
            const midpointY = (rect[0][1] + rect[1][1]) / 2;

            if (dragY <= midpointY && i !== state.sourceIndex) {
              setState({
                type: 'dragging',
                sourceIndex: state.sourceIndex,
                dropIndex: i,
              });

              return;
            }
          }

          setState({
            type: 'dragging',
            sourceIndex: state.sourceIndex,
            dropIndex: items.length,
          });
        },
      };
    }
  }
}
