import cx from 'classnames';
import { Icon, IconName } from 'icons';
import { MouseEventHandler } from 'react';
import styles from './TurnIntoMenu.module.scss';

type ToolbarDropdownMenuItemProps = {
  className?: string;
  iconName: IconName;
  children: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ToolbarDropdownMenuItem = ({
  className,
  iconName,
  onClick,
  children,
}: ToolbarDropdownMenuItemProps) => {
  return (
    <button
      className={cx(styles.turnIntoMenuItem, className)}
      onClick={onClick}
    >
      <div className={styles.turnIntoMenuItem__iconContainer}>
        <Icon name={iconName} className={styles.turnIntoMenuItem__icon} />
      </div>
      <div className={styles.turnIntoMenuItem__content}>{children}</div>
    </button>
  );
};

type ToolbarDropdownMenuProps = {
  className?: string;
  children: React.ReactNode;
} & React.ComponentProps<'div'>;

const ToolbarDropdownMenu = ({
  className,
  children,
  ...props
}: ToolbarDropdownMenuProps) => {
  return (
    <div {...props} className={cx(styles.turnIntoMenu, className)}>
      <div className={styles.turnIntoMenu__heading}>Turn Into</div>
      <div className={styles.turnIntoMenu__body}>{children}</div>
    </div>
  );
};

export default ToolbarDropdownMenu;
