import { ChartBlock } from 'editor-content/ChartBlock.ts';
import ChartFormattingMenu from '../../../../design-system/organisms/ChartFormattingMenu.tsx';
import HoverNextToPoint from '../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import { AiContext } from '../AiChartFlow/createAiContext.ts';

type ChartFormattingExperienceProps = {
  block: ChartBlock;
  getEl: () => HTMLElement | undefined;
  aiContext: AiContext;
};

const ChartFormattingExperience: React.FC<ChartFormattingExperienceProps> = ({
  block,
  getEl,
  aiContext,
}) => {
  return (
    <>
      <HoverNextToPoint
        getPoint={(popoverEl) => {
          const targetEl = getEl();
          if (!targetEl) return [0, 0];

          const targetRect = targetEl.getBoundingClientRect();
          const popoverRect = popoverEl.getBoundingClientRect();
          return [
            targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
            targetRect.y - popoverRect.height - 16,
          ];
        }}
        usePortal
      >
        <ChartFormattingMenu block={block} aiContext={aiContext} />
      </HoverNextToPoint>
    </>
  );
};

export default ChartFormattingExperience;
