import { User } from 'api-client/types.js';
import { Navigate, RouteObject } from 'react-router-dom';
import getIdFromSlug from '../getIdFromSlug.js';
import NoCompanyHomePage from '../NoCompanyHomePage.js';
import { NotFoundPage } from '../pages/error/notFound/NotFoundPage.js';
import RequestAccessPage from '../pages/error/unauthorized/RequestAccessPage.js';
import { useRequiredParams } from '../routing/useRequiredParams.js';

type Parameters = {
  user: User;
  signOut: () => void;
};
export default function makeNoCompanyAccessRoutes({
  user,
  signOut,
}: Parameters): RouteObject[] {
  return [
    {
      path: '/company/:companyId/s/:slug',
      Component: () => {
        const { slug } = useRequiredParams('slug');
        const sectionId = getIdFromSlug(slug);

        return (
          <RequestAccessPage
            type="section"
            requestedId={sectionId}
            email={user.email}
            onClickLogout={signOut}
          />
        );
      },
    },
    {
      path: '/company/:companyId/:slug',
      Component: () => {
        const { slug } = useRequiredParams('slug');
        const zeckId = getIdFromSlug(slug);

        return (
          <RequestAccessPage
            type="zeck"
            requestedId={zeckId}
            email={user.email}
            onClickLogout={signOut}
          />
        );
      },
    },
    { path: '/signin', element: <Navigate to="/" /> },
    { path: '/signup', element: <Navigate to="/" /> },
    { path: '/login', element: <Navigate to="/" /> },
    // This can only be accessed if you have no active company, which can only happen if you have no companies.
    // We become confused because this file appears to be where routing logic is handled, but actually
    // FetchUserAndCompany determines the active company from the url and local storage at a higher level than this
    //
    // Technically, we could end up on this page if a route in this list tried to "soft" navigate (vs "hard" reloading)
    // to this page. This is existing weirdness based on how FetchUserAndCompany works, and the places that need to do this
    // are doing a hard reload.
    {
      path: '/',
      element: <NoCompanyHomePage user={user} onLogout={signOut} />,
    },
    { path: '*', element: <NotFoundPage to="/" /> },
  ];
}
