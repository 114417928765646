import cx from 'classnames';
import { BlockContent } from 'editor-content/Block.js';
import Linkable from 'editor-content/html/Linkable.ts';
import BlockEditorAdapter from '../editor/BlockEditorAdapter.ts';
import UploadedImage from '../UploadedImage.js';
import styles from './SectionView.module.scss';

type CoverHeadlineCardProps = {
  content: BlockContent;
  coverImageId: string | null;
  linkables: Linkable[];
  isMobile: boolean;
};

function CoverHeadlineCard({
  content,
  coverImageId,
  isMobile,
  linkables,
}: CoverHeadlineCardProps) {
  return (
    <>
      {coverImageId && (
        <UploadedImage
          imageId={coverImageId}
          spinnerSize="large"
          className={cx(
            styles.sectionCoverImage,
            isMobile && styles.sectionCoverImage_mobile,
          )}
          data-testid="section cover image"
        />
      )}
      {content.length > 0 && (
        <div className={styles.headlineCard}>
          <p
            className={styles.headlineCard__headline}
            dangerouslySetInnerHTML={{
              __html: BlockEditorAdapter.toHTMLString(content, {
                linkables,
              }),
            }}
          />
        </div>
      )}
    </>
  );
}

export default CoverHeadlineCard;
