import { Icon, OneDriveIcon } from 'icons';
import {
  ErrorCredentialResponse,
  ValidCredentialResponse,
} from '../../../../../api/endpoints/createCredentialApi.ts';
import OutlineButton from '../../../../../design-system/atoms/OutlineButton.tsx';
import ButtonWithLoading from '../../../../../design-system/molecules/ButtonWithLoading.tsx';
import {
  ModalActions,
  ModalBody,
  ModalHeader,
  ModalPanel,
} from '../../../../../design-system/organisms/Modal.tsx';
import googleAuthScopes from '../../../../../images/googleAuthScopes.png';
import styles from './ReconnectModalContent.module.scss';
import useAuthForCredential from './utils/useAuthForCredential.ts';

type ReconnectModalProps = {
  credential: ErrorCredentialResponse;
  onClose: () => void;
  onReconnected: (
    credential: ValidCredentialResponse | ErrorCredentialResponse,
  ) => void;
};

type IntegrationType = 'microsoft' | 'google';

const integrationProviderPhrases: Record<IntegrationType, string> = {
  microsoft: 'Microsoft OneDrive',
  google: 'Google Sheets',
};

const ProviderIcon = ({ type }: { type: IntegrationType }) => {
  if (type === 'google') {
    return <Icon name="googleSpreadsheet" className={styles.sheetsIcon} />;
  }

  return <OneDriveIcon />;
};

const UnknownErrorContent = ({
  credential,
  onClose,
}: {
  credential: ErrorCredentialResponse;
  onClose: () => void;
}) => {
  const type = credential.type;

  return (
    <ModalPanel className={styles.reconnectModal}>
      <ModalHeader className={styles.reconnectModal__header}>
        <ProviderIcon type={type} /> Error with{' '}
        {integrationProviderPhrases[type]}
      </ModalHeader>
      <ModalBody className={styles.reconnectModal__body}>
        <div data-testid="error_unknown_error">
          You are unable to connect <i>{credential.displayName}</i> with the{' '}
          {integrationProviderPhrases[type]} integration. Please try again or
          reach out to <a href="mailto:ready@zeck.app">ready@zeck.app</a>.
        </div>
      </ModalBody>
      <ModalActions>
        <OutlineButton size="medium" color="secondary" onClick={onClose}>
          Close
        </OutlineButton>
      </ModalActions>
    </ModalPanel>
  );
};

type ReconnectModalContentProps = {
  actionSlot: React.ReactElement;
  displayName: string;
  type: IntegrationType;
};

const NoAccessTokenContent: React.FC<ReconnectModalContentProps> = ({
  actionSlot,
  displayName,
  type,
}) => {
  return (
    <ModalPanel className={styles.reconnectModal}>
      <ModalHeader className={styles.reconnectModal__header}>
        <ProviderIcon type={type} />
        Reconnect to {integrationProviderPhrases[type]}
      </ModalHeader>
      <ModalBody className={styles.reconnectModal__body}>
        <div data-testid="error_no_access_token">
          You need to reconnect <i>{displayName}</i> to{' '}
          {integrationProviderPhrases[type]} to use this table content
          integration.
        </div>
      </ModalBody>
      {actionSlot}
    </ModalPanel>
  );
};

const MissingScopesContent: React.FC<ReconnectModalContentProps> = ({
  actionSlot,
  displayName,
}) => {
  return (
    <ModalPanel className={styles.reconnectModal}>
      <ModalHeader className={styles.reconnectModal__header}>
        <Icon name="danger" className={styles.warningIcon} />
        Grant Access to Files
      </ModalHeader>
      <ModalBody className={styles.reconnectModal__body}>
        <div data-testid="error_missing_scopes">
          <div>
            Please grant file access for <i>{displayName}</i>. Access to
            download and edit are both required for the Google Sheets
            integration to work.
          </div>

          <img
            src={googleAuthScopes}
            className={styles.scopesImage}
            alt="add Google access"
          />

          <div>
            You’ll need to reconnect your account and check both boxes shown
            above to proceed.
          </div>
        </div>
      </ModalBody>
      {actionSlot}
    </ModalPanel>
  );
};

const ReconnectModalContent: React.FC<ReconnectModalProps> = ({
  credential,
  onClose,
  onReconnected,
}) => {
  const { doAuth, isLoading } = useAuthForCredential(onReconnected);

  const authUrl = credential.authUrl;
  if (credential.errorCode === 'unknown_error' || !authUrl) {
    return <UnknownErrorContent credential={credential} onClose={onClose} />;
  }

  const actionSlotContents = (
    <ModalActions>
      <OutlineButton size="medium" color="secondary" onClick={onClose}>
        Cancel
      </OutlineButton>
      <ButtonWithLoading
        isLoading={isLoading}
        color="primary"
        size="medium"
        onClick={() => {
          doAuth({ id: credential.id, authUrl });
        }}
      >
        Reconnect
      </ButtonWithLoading>
    </ModalActions>
  );

  if (credential.errorCode === 'missing_scopes') {
    return (
      <MissingScopesContent
        displayName={credential.displayName}
        actionSlot={actionSlotContents}
        type={credential.type}
      />
    );
  }

  if (credential.errorCode === 'no_access_token') {
    return (
      <NoAccessTokenContent
        displayName={credential.displayName}
        actionSlot={actionSlotContents}
        type={credential.type}
      />
    );
  }

  return null;
};

export default ReconnectModalContent;
