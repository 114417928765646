import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './Headline.module.scss';

type HeadlineProps = {
  children?: ReactNode;
  className?: string;
};

export const HeadlineParagraph = React.forwardRef<
  HTMLParagraphElement,
  HeadlineProps
>((props, forwardedRef) => {
  return (
    <p
      {...{
        ...props,
        ref: forwardedRef,
        className: cx(props.className, styles.headline),
      }}
    >
      {props.children}
    </p>
  );
});

export const HeadlineLine = (props: { className?: string }) => {
  return <hr className={cx(styles.hr, props.className)} />;
};

const Headline = React.forwardRef<HTMLParagraphElement, HeadlineProps>(
  function Headline(props, forwardedRef) {
    return (
      <>
        <HeadlineParagraph
          {...{
            ...props,
            ref: forwardedRef,
          }}
        >
          {props.children}
        </HeadlineParagraph>
        <HeadlineLine />
      </>
    );
  },
);

export default Headline;
