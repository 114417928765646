import assertUnreachable from '../../../../junkDrawer/assertUnreachable.ts';
import { AiTableKeyTakeawaysError } from './AiTableKeyTakeawaysError.tsx';
import styles from './AiTableKeyTakeawaysFlow.module.scss';
import { AiTableKeyTakeawaysLoading } from './AiTableKeyTakeawaysLoading.tsx';
import AiTableKeyTakeawaysReviewing from './AiTableKeyTakeawaysReviewing.tsx';
import {
  AiTableKeyTakeawaysFlowActiveStates,
  GenerateAiTableKeyTakeawaysFlowResult,
  isAiTableKeyTakeawaysFlowInactiveState,
} from './useGenerateAiTableKeyTakeawaysFlowProps.tsx';

const AiTableKeyTakeawaysFlow = (
  props: GenerateAiTableKeyTakeawaysFlowResult,
) => {
  const { keyTakeawaysState } = props;

  if (isAiTableKeyTakeawaysFlowInactiveState(keyTakeawaysState)) {
    return null;
  }

  return (
    <div data-testid="ai-table-key-takeaways-flow" className={styles.container}>
      <AiTableKeyTakeawaysInner
        {...props}
        keyTakeawaysState={keyTakeawaysState}
      />
    </div>
  );
};

const AiTableKeyTakeawaysInner = ({
  keyTakeawaysState,
  onClose,
  recordAnalytics,
  onAccept,
}: Omit<GenerateAiTableKeyTakeawaysFlowResult, 'keyTakeawaysState'> & {
  keyTakeawaysState: AiTableKeyTakeawaysFlowActiveStates;
}) => {
  switch (keyTakeawaysState.type) {
    case 'loading':
      return <AiTableKeyTakeawaysLoading />;
    case 'reviewing':
      return (
        <AiTableKeyTakeawaysReviewing
          type={keyTakeawaysState.type}
          keyTakeaways={keyTakeawaysState.keyTakeaways}
          onDiscard={async () => {
            onClose();
            await recordAnalytics('discard');
          }}
          onAccept={async () => {
            await onAccept(keyTakeawaysState.keyTakeaways);
            onClose();
            await recordAnalytics('accept');
          }}
        />
      );
    case 'error':
      return <AiTableKeyTakeawaysError onClose={onClose} />;
    default:
      assertUnreachable(keyTakeawaysState);
  }
};

export default AiTableKeyTakeawaysFlow;
