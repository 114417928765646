import Button from '../../../../../design-system/atoms/Button.tsx';
import OutlineButton from '../../../../../design-system/atoms/OutlineButton.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../../../../design-system/organisms/Modal.tsx';
import styles from './ConfirmDeleteSectionModal.module.scss';

type ConfirmDeleteSectionModalProps = {
  isOpen: boolean;
  sectionTitle: string;
  onDelete(): void;
  onCancel(): void;
};

export default function ConfirmDeleteSectionModal({
  isOpen,
  sectionTitle,
  onDelete,
  onCancel,
}: ConfirmDeleteSectionModalProps) {
  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <ModalHeader onClickClose={onCancel}>Delete Section?</ModalHeader>
      <ModalBody>
        Permanently delete <em>{sectionTitle}</em> ? You won’t be able to get it
        back.
      </ModalBody>
      <ModalActions>
        <OutlineButton onClick={onCancel} color="secondary" size="small">
          Cancel
        </OutlineButton>
        <Button onClick={onDelete} color="danger" size="small">
          Yes, Delete
        </Button>
      </ModalActions>
    </Modal>
  );
}
