import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import {
  EditorConfiguration,
  EditorState,
  MaybeHandled,
} from '../EditorAction.js';
import { isTextSelection } from '../EditorSelection.js';
import {
  BlockSelection,
  getStartOfSelection,
} from '../selection/BlockSelection.js';
import ContentSelection, {
  contentSelection,
} from '../selection/contentSelection/ContentSelection.js';
import { textSelection, TextSelection } from '../selection/TextSelection.js';

function pressArrowUpBlockSelection<BlockType>(
  selection: BlockSelection,
  content: BlockType[],
  generateBlockEditor: (b: BlockType) => {
    getEndOfBlockSelection: () => ContentSelection;
  },
) {
  const startOfSelection = getStartOfSelection(selection);
  const prevBlockIndex = startOfSelection - 1;
  const prevBlock = content[prevBlockIndex];

  if (prevBlock) {
    const prevBlockEditor = generateBlockEditor(prevBlock);
    return {
      content,
      selection: textSelection(
        prevBlockIndex,
        prevBlockEditor.getEndOfBlockSelection(),
      ),
    };
  }

  const firstBlock = content[startOfSelection];

  if (!firstBlock) {
    return {
      content,
      selection: textSelection(startOfSelection, contentSelection(0)),
    };
  }

  return {
    content,
    selection: textSelection(startOfSelection, contentSelection(0)),
  };
}

type PressArrowUpBlockEditorInterface = {
  getEndOfBlockSelection: () => ContentSelection;
  pressArrowUp: (isOnFirstLineOfBlock: () => boolean) => boolean;
};

function pressArrowUpTextSelection<BlockType>(
  content: BlockType[],
  selection: TextSelection,
  generateBlockEditor: (b: BlockType) => PressArrowUpBlockEditorInterface,
  isOnFirstLineOfBlock: (blockIndex: number) => boolean,
  fancyNavUp: (
    currentBlockIndex: number,
    newBlockIndex: number,
  ) => ContentSelection | void,
) {
  const selectedBlock = getSelectedBlock(content, selection);
  if (!selectedBlock) return;
  const targetBlockEditor = generateBlockEditor(selectedBlock);

  const isExitingBlock = targetBlockEditor.pressArrowUp(() =>
    isOnFirstLineOfBlock(selection.index),
  );

  if (!isExitingBlock) return;
  const prevIndex = selection.index - 1;
  const prevBlock = content[prevIndex];

  if (!prevBlock) return;
  const prevBlockEditor = generateBlockEditor(prevBlock);

  let newSelection: ContentSelection | void;

  try {
    newSelection = fancyNavUp(selection.index, prevIndex);
    // fancyNavUp will throw if point is offscreen.
    // For example: you are trying to arrow up to a block that has been scrolled offscreen
  } catch {
    newSelection = prevBlockEditor.getEndOfBlockSelection();
  }

  if (!newSelection) return;

  return {
    content,
    selection: textSelection(prevIndex, newSelection),
  };
}

export default function pressArrowUp<BlockType>({
  generateBlockEditor,
}: EditorConfiguration<BlockType, PressArrowUpBlockEditorInterface>) {
  return (
    { content, selection }: EditorState<BlockType>,
    isOnFirstLineOfBlock: (blockIndex: number) => boolean,
    fancyNavUp: (
      currentBlockIndex: number,
      newBlockIndex: number,
    ) => ContentSelection | void,
  ): MaybeHandled<EditorState<BlockType, TextSelection>> => {
    if (!selection) return;

    if (isTextSelection(selection)) {
      return pressArrowUpTextSelection(
        content,
        selection,
        generateBlockEditor,
        isOnFirstLineOfBlock,
        fancyNavUp,
      );
    }

    return pressArrowUpBlockSelection(selection, content, generateBlockEditor);
  };
}
