import { TableBlock } from 'editor-content/TableBlock.js';
import { validateTable } from '../../../../../../design-system/zeck/table/validateTable.ts';
import {
  ErrorWithContext,
  logError,
} from '../../../../../../logging/logger.ts';

export const validateAndLogTableErrors = (
  table: Pick<TableBlock, 'data' | 'frozenRows' | 'frozenColumns'>,
): Pick<TableBlock, 'data' | 'frozenRows' | 'frozenColumns'> => {
  const validateResult = validateTable(table);

  if (validateResult !== 'valid') {
    logError(
      new ErrorWithContext(
        'User tried to render a table that is invalid, this will likely cause problems for merges and frozen regions',
        { table },
        'InvalidTableError',
      ),
    );
  }

  return table;
};

export const getFrozenCount = (
  count: number | undefined,
): 1 | 2 | undefined => {
  if (count !== 1 && count !== 2) return undefined;
  return count as 1 | 2;
};

export const createFrozenDimensions = (
  frozenRows: number | undefined,
  frozenColumns: number | undefined,
): Pick<TableBlock, 'frozenRows' | 'frozenColumns'> => {
  const maybeFrozenRows = frozenRows
    ? { frozenRows: getFrozenCount(frozenRows) }
    : {};
  const maybeFrozenColumns = frozenColumns
    ? { frozenColumns: getFrozenCount(frozenColumns) }
    : {};

  return {
    ...maybeFrozenRows,
    ...maybeFrozenColumns,
  };
};
