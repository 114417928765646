import cx from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { Icon } from 'icons';
import { useRef, useState } from 'react';
import HoverNextToElement from '../domHelpers/hoverNextTo/HoverNextToElement.tsx';
import { leftAlignedBelowElement } from '../domHelpers/hoverNextTo/positionStrategy/leftAlignedBelow.js';
import DateTimeForm from './DateTimeForm.tsx';
import styles from './DateTimeInput.module.scss';
import { getSystemTimezone } from './timestamp.ts';

export type DateTimeInputProps = {
  label: string;
  timestamp: number | null;
  timeZone: string | null;
  onChange: (values: { timestamp: number | null; timeZone: string }) => void;
};

export default function DateTimeInput({
  label,
  timestamp,
  timeZone,
  onChange,
}: DateTimeInputProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dateTimeButtonRef = useRef<HTMLButtonElement>(null);
  const defaultedTimeZone = timeZone || getSystemTimezone();

  return (
    <div className={styles.container}>
      <button
        ref={dateTimeButtonRef}
        aria-label={label}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
        className={cx(styles.button, timestamp === null && styles.empty)}
      >
        <Icon name="date" />
        {timestamp === null ? (
          <span> {label}</span>
        ) : (
          <>
            <span>
              {formatInTimeZone(timestamp, defaultedTimeZone, 'MMMM do, y')}
            </span>
            <span>⋅</span>
            <span>
              {formatInTimeZone(timestamp, defaultedTimeZone, 'h:mmaaa zzz')}
            </span>
          </>
        )}
      </button>

      <button
        className={styles.deleteButton}
        aria-label="unset time"
        onClick={() =>
          onChange({
            timestamp: null,
            timeZone: defaultedTimeZone,
          })
        }
      >
        <Icon name="delete" />
      </button>

      {isOpen && (
        <HoverNextToElement
          elementRef={dateTimeButtonRef}
          positionStrategy={leftAlignedBelowElement}
          viewportPolicy="none"
        >
          <DateTimeForm
            timestamp={timestamp}
            timeZone={timeZone}
            onSubmit={(values) => {
              onChange(values);
              setIsOpen(false);
            }}
            onCancel={() => setIsOpen(false)}
          />
        </HoverNextToElement>
      )}
    </div>
  );
}
