import React, { useEffect, useRef, useState } from 'react';
import useApi from '../../api/useApi.ts';
import Loader, { LoaderProps } from '../../design-system/atoms/Loader.tsx';
import mergeRefs from '../../junkDrawer/mergeRefs.ts';
import styles from './UploadedImage.module.scss';

type UploadedImageProps = {
  imageId: string;
  spinnerSize: LoaderProps['size'];
  imagePreset?: 'large' | 'thumbnail';
  alt?: string;
  className?: string;
  onClick?: () => void;
};

const UploadedImage = React.forwardRef<HTMLImageElement, UploadedImageProps>(
  function UploadedImage(
    {
      alt = 'uploaded image',
      imageId,
      imagePreset = 'large',
      spinnerSize,
      className,
      ...otherProps
    },
    ref,
  ) {
    const [imageUrl, setImageUrl] = useState<string>();
    const [loaded, setLoaded] = useState(false);
    const imageRef = useRef<HTMLImageElement>();
    const { getImage } = useApi();

    useEffect(() => {
      setLoaded(false);
      (async () => {
        setImageUrl(await getImage(imageId, imagePreset));

        if (imageRef.current) {
          imageRef.current.onload = () => {
            setLoaded(true);
          };
        }
      })();
    }, [imageId, getImage, imagePreset]);

    return (
      <>
        <img
          src={imageUrl}
          className={className}
          data-loaded={loaded}
          style={{
            transition: 'opacity 200ms',
            opacity: loaded ? '1' : '0',
          }}
          alt={alt}
          {...otherProps}
          ref={mergeRefs([ref, imageRef])}
        />
        {!loaded && <Loader size={spinnerSize} className={styles.spinner} />}
      </>
    );
  },
);

export default UploadedImage;
