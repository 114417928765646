import { TextNode } from 'editor-content/TextNode.js';
import { AppContext } from 'editor-content/textNodesToHtmlString.ts';
import BaseAndExtent from '../../../editor/selection/contentSelection/BaseAndExtent.js';
import ContentSelection, {
  baseAndExtentToContentSelection,
  contentSelectionToBaseAndExtent,
} from '../../../editor/selection/contentSelection/ContentSelection.js';
import {
  IPromiseThisHTMLStringIsSafeToRender,
  SafeHTMLString,
} from '../../../editor/useContentEditable/SafeHTMLString.js';
import htmlStringToTextNodes from './textNode/htmlStringToTextNodes.js';
import { textNodesToHtmlString } from './textNode/TextNode.js';

function toBaseAndExtent(
  selection: ContentSelection,
  el: HTMLElement,
): BaseAndExtent {
  return contentSelectionToBaseAndExtent(el, selection);
}

function fromBaseAndExtent(
  baseAndExtent: BaseAndExtent,
  containerElement: HTMLElement,
): ContentSelection {
  return baseAndExtentToContentSelection(containerElement, baseAndExtent);
}

function toHTMLString(
  content: TextNode[],
  context: AppContext,
): SafeHTMLString {
  const s = textNodesToHtmlString(content, context);
  return IPromiseThisHTMLStringIsSafeToRender(s);
}

function fromHTMLString(html: string): TextNode[] {
  return htmlStringToTextNodes(html);
}

const BlockEditorAdapter = {
  toBaseAndExtent,
  fromBaseAndExtent,
  fromHTMLString,
  toHTMLString,
};

export default BlockEditorAdapter;
