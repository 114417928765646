import { isTextBlock } from 'editor-content/Block.js';
import copy from '../../../../../editor/actions/copyPaste/copy.js';
import textBlockCopyStrategy from '../../../../../editor/blocks/textBlocksStrategies/textBlockCopyStrategy.js';
import { HydratedBlock } from '../../../../../types/HydratedBlock.js';

export const zeckEditorCopy = copy<HydratedBlock>((block) => {
  if (isTextBlock(block)) {
    return {
      copy: (selection) => textBlockCopyStrategy(block, selection),
    };
  }
  return {
    copy: () => ({ type: 'block', content: [block] }),
  };
});
