import cx from 'classnames';
import React from 'react';
import styles from './ClickToCopyIcon.module.scss';
import IconButton, { IconButtonProps } from './IconButton.tsx';

type ClickToCopyIconProps = {
  copyContent: string;
} & IconButtonProps;

const ClickToCopyIcon = React.forwardRef<
  HTMLButtonElement,
  ClickToCopyIconProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(function ClickToCopyIcon(
  { copyContent, children, className, name, ...otherProps },
  ref,
) {
  return (
    <IconButton
      name={name}
      className={cx(styles.clickToCopy__icon, className)}
      onClick={() => {
        navigator.clipboard.writeText(copyContent);
      }}
      ref={ref}
      {...otherProps}
    >
      {children}
    </IconButton>
  );
});

export default ClickToCopyIcon;
