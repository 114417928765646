import { StateMachine } from '@xstate/fsm';
import { EventObject, Typestate } from '@xstate/fsm/lib/types.js';
import { useCallback, useState } from 'react';

const useStateMachine = <
  TContext extends Record<string, unknown>,
  TEvent extends EventObject,
  TState extends Typestate<TContext>,
>(
  stateMachine: StateMachine.Machine<TContext, TEvent, TState>,
) => {
  const [state, setCurrent] = useState(stateMachine.initialState.value);

  return {
    state,
    send: useCallback(
      (event: TEvent | TEvent['type']) => {
        setCurrent((state) => stateMachine.transition(state, event).value);
      },
      [stateMachine],
    ),
  };
};

export default useStateMachine;
