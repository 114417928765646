import cx from 'classnames';
import { Icon } from 'icons';
import { useState } from 'react';
import Button from '../../../design-system/atoms/Button.tsx';
import InputEmail from '../../../design-system/atoms/InputEmail.tsx';
import InputPassword from '../../../design-system/atoms/InputPassword.tsx';
import Link from '../../../design-system/atoms/Link.tsx';
import Spinner from '../../../design-system/atoms/Spinner.tsx';
import SessionLayout from '../../../design-system/layouts/SessionLayout.tsx';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import FormState from '../FormState.ts';
import styles from './SigninPage.module.scss';

export type SigninPageProps = {
  onSignIn: (credentials: {
    email: string;
    password: string;
  }) => Promise<string | null>;
};

const SigninPage = ({ onSignIn }: SigninPageProps) => {
  const { isMobile } = useBreakpoints();

  const [submitting, setSubmitting] = useState(false);
  const [formState, setFormState] = useState<FormState<'email' | 'password'>>({
    email: {
      value: '',
      error: null,
    },
    password: {
      value: '',
      error: null,
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormState({
      email: {
        ...formState.email,
        error: formState.email.value ? null : 'Please enter your email.',
      },
      password: {
        ...formState.password,
        error: formState.password.value ? null : 'Please enter your password.',
      },
    });

    if (formState.email.value && formState.password.value) {
      setSubmitting(true);

      const success = await onSignIn({
        email: formState.email.value,
        password: formState.password.value,
      });

      setSubmitting(false);

      if (!success) {
        setFormState({
          ...formState,
          email: {
            ...formState.email,
            error: (
              <span>
                Your email or password is incorrect. Try again, or{' '}
                <Link href="/reset-password-request">Reset Your Password</Link>.
              </span>
            ),
          },
        });
      }
    }
  };

  const setFormValue = (name: string) => (value: string) => {
    setFormState({
      ...formState,
      [name]: { error: null, value },
    });
  };

  return (
    <SessionLayout>
      <h1 className={cx(isMobile ? styles.title_mobile : styles.title)}>
        Sign In
      </h1>
      <form onSubmit={handleSubmit} noValidate className={styles.form}>
        <InputEmail
          {...{
            ...formState.email,
            label: 'Email',
            autoComplete: 'username',
            onChange: setFormValue('email'),
          }}
        />
        <InputPassword
          {...{
            ...formState.password,
            label: 'Password',
            autoComplete: 'current-password',
            onChange: setFormValue('password'),
          }}
        />
        <Button
          {...{
            className: styles.form__submit,
            size: 'large',
            color: 'primary',
            disabled: submitting,
          }}
        >
          {submitting ? (
            <Spinner className={styles.spinner} />
          ) : (
            <>
              <Icon name="lock" /> Sign In
            </>
          )}
        </Button>
        <Link
          className={styles.resetPasswordLink}
          href="/reset-password-request"
        >
          Having Trouble Signing In?
        </Link>
      </form>
    </SessionLayout>
  );
};

export default SigninPage;
