import {
  baseAndExtentToCharacterOffset,
  characterOffsetToBaseAndExtent,
} from 'selection-character-offset';
import allTextNodes from './allTextNodes.js';

type ContentSelection = {
  anchorOffset: number;
  focusOffset: number;
};

export default ContentSelection;

export const contentSelection = (
  anchorOffset: number,
  focusOffset: number = anchorOffset,
): ContentSelection => ({
  anchorOffset,
  focusOffset,
});

export const getStartOfSelection = (selection: ContentSelection) =>
  Math.min(selection.anchorOffset, selection.focusOffset);

export const getEndOfSelection = (selection: ContentSelection) =>
  Math.max(selection.anchorOffset, selection.focusOffset);

export const rangeToContentSelection = (
  containerElement: HTMLElement,
  range: Range,
): ContentSelection => {
  const anchorRange = range.cloneRange();
  anchorRange.collapse(true);
  anchorRange.setStartBefore(containerElement);
  const anchorOffset = anchorRange.toString().length;

  const focusRange = range.cloneRange();
  focusRange.collapse(false);
  focusRange.setStartBefore(containerElement);
  const focusOffset = focusRange.toString().length;
  return {
    anchorOffset,
    focusOffset,
  };
};

const findNodeAndOffset = (
  containerElement: HTMLElement,
  charOffset: number,
): [Node, number] => {
  const textNodes = Array.from(allTextNodes(containerElement));

  let traveledLength = 0;

  for (const textNode of textNodes) {
    const newLength = traveledLength + textNode.length;
    if (newLength >= charOffset) {
      return [textNode, charOffset - traveledLength];
    }

    traveledLength = newLength;
  }

  const lastTextNode = textNodes[textNodes.length - 1];

  if (!lastTextNode) {
    return [containerElement, 0];
  }

  return [lastTextNode, lastTextNode.length];
};

export const contentSelectionToRange = (
  containerElement: HTMLElement,
  contentSelection: ContentSelection,
): Range => {
  const { anchorOffset, focusOffset } = contentSelection;
  const r = new Range();

  r.setStart(...findNodeAndOffset(containerElement, anchorOffset));
  r.setEnd(...findNodeAndOffset(containerElement, focusOffset));

  return r;
};

export const contentSelectionToBaseAndExtent = characterOffsetToBaseAndExtent;
export const baseAndExtentToContentSelection = baseAndExtentToCharacterOffset;

export const isCollapsed = (s: ContentSelection): boolean =>
  s.anchorOffset === s.focusOffset;

export const contentSelectionIsEqual = (
  s1: ContentSelection | null,
  s2: ContentSelection | null,
): boolean => {
  return (
    s1?.anchorOffset === s2?.anchorOffset && s1?.focusOffset === s2?.focusOffset
  );
};

export const isEqualContentSelection = (
  s1: ContentSelection | null,
  s2: ContentSelection | null,
) => {
  if (s1 === null || s2 === null) {
    return s1 === s2;
  }

  return (
    s1.anchorOffset === s2.anchorOffset && s1.focusOffset === s2.focusOffset
  );
};

export const isRightToLeftContentSelection = (
  contentSelection: ContentSelection,
): boolean => contentSelection.focusOffset < contentSelection.anchorOffset;
