import cx from 'classnames';
import styles from './SidebarHeading.module.scss';

type SidebarHeadingProps = {
  children: React.ReactNode;
} & React.ComponentProps<'h2'>;
const SidebarHeading: React.FC<SidebarHeadingProps> = ({
  children,
  className,
}) => {
  return <h2 className={cx(styles.sidebarHeading, className)}>{children}</h2>;
};

export default SidebarHeading;
