import { createFileBlock } from 'editor-content/Block.js';
import { logInfo } from '../../../logging/logger.js';
import { showFilePicker } from './showFilePicker.js';

const FILE_SIZE_LIMIT = 2 ** 20 * 500; // 500MB

export const uploadAndCreateFileBlock =
  (uploadFile: (file: File) => Promise<string>) => async () => {
    let files: File[];
    try {
      files = await showFilePicker();
    } catch (e) {
      return { type: 'cancel' as const };
    }

    const file = files[0];

    if (!file) {
      return {
        type: 'error' as const,
        message: 'Please pick a file',
      };
    }

    if (file.size > FILE_SIZE_LIMIT) {
      return {
        type: 'error' as const,
        message: `Your file is too large. Files must be 500 MB or less. Can you choose another file or reach out to us and we'll be ready to help?`,
      };
    }

    let fileId: string;
    try {
      fileId = await uploadFile(file);
    } catch (e) {
      logInfo('file upload error');
      return {
        type: 'error' as const,
        message: 'There was an issue uploading this file, please try again.',
      };
    }

    return {
      type: 'success' as const,
      data: createFileBlock(fileId, file.name),
    };
  };
