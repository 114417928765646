import VoteControlHeading from './VoteControlHeading.tsx';
import styles from './VoteSectionLayout.module.scss';

const VoteSectionLayout: React.FC<{
  children: React.ReactNode;
  heading: React.ReactNode;
  tooltip: string;
  className?: string;
}> = ({ children, tooltip, heading, className }) => {
  return (
    <div className={className}>
      <VoteControlHeading tooltip={tooltip}>{heading}</VoteControlHeading>

      <div className={styles.voteSection__body}>{children}</div>
    </div>
  );
};

export default VoteSectionLayout;
