import React, { SVGProps } from 'react';

export function AiPencilGradiantIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      <path
        d="M4.3875 9.09724L4.4275 9.01724C4.59417 8.69391 4.8575 8.43057 5.18083 8.26391L5.26083 8.22391C5.5775 8.06391 5.5775 7.61057 5.26083 7.44724L5.18083 7.40724C4.8575 7.24057 4.59417 6.97724 4.4275 6.65391L4.3875 6.57391C4.2275 6.25724 3.77417 6.25724 3.61083 6.57391L3.57083 6.65391C3.40417 6.97724 3.14083 7.24057 2.8175 7.40724L2.7375 7.44724C2.42083 7.60724 2.42083 8.06057 2.7375 8.22391L2.8175 8.26391C3.14083 8.43057 3.40417 8.69391 3.57083 9.01724L3.61083 9.09724C3.77083 9.41391 4.22417 9.41391 4.3875 9.09724Z"
        fill="url(#paint0_linear_15868_38920)"
      />
      <path
        d="M13.9308 11.7806L13.8508 11.7406C13.5275 11.5739 13.2642 11.3106 13.0975 10.9872L13.0575 10.9072C12.8975 10.5906 12.4442 10.5906 12.2808 10.9072L12.2408 10.9872C12.0742 11.3106 11.8108 11.5739 11.4875 11.7406L11.4075 11.7806C11.0908 11.9406 11.0908 12.3939 11.4075 12.5572L11.4875 12.5972C11.8108 12.7639 12.0742 13.0272 12.2408 13.3506L12.2808 13.4306C12.4408 13.7472 12.8942 13.7472 13.0575 13.4306L13.0975 13.3506C13.2642 13.0272 13.5275 12.7639 13.8508 12.5972L13.9308 12.5572C14.2475 12.3972 14.2475 11.9439 13.9308 11.7806Z"
        fill="url(#paint1_linear_15868_38920)"
      />
      <path
        d="M4.64083 4.92724L4.7475 4.98057C5.08083 5.15057 5.3475 5.41724 5.5175 5.75057L5.57083 5.85724C5.75417 6.21724 6.12083 6.44057 6.52417 6.44057C6.9275 6.44057 7.29417 6.21724 7.48083 5.85724L7.53417 5.75057C7.70417 5.41724 7.97083 5.15057 8.30417 4.98057L8.41083 4.92724C8.77083 4.74391 8.99417 4.37724 8.99417 3.97391C8.99417 3.57057 8.77083 3.20391 8.41083 3.01724L8.30417 2.96391C7.97083 2.79391 7.70417 2.52724 7.53417 2.19391L7.48083 2.08724C7.2975 1.72724 6.93083 1.50391 6.52417 1.50391C6.1175 1.50391 5.75417 1.72724 5.5675 2.08724L5.51417 2.19391C5.34417 2.52724 5.0775 2.79391 4.74417 2.96391L4.6375 3.01724C4.2775 3.20057 4.05417 3.56724 4.05417 3.97391C4.05417 4.38057 4.2775 4.74391 4.6375 4.93057L4.64083 4.92724ZM5.09417 3.90724L5.20083 3.85391C5.72417 3.58724 6.14083 3.17057 6.4075 2.64724L6.46083 2.54057C6.46083 2.54057 6.48083 2.50057 6.52417 2.50057C6.5675 2.50057 6.58417 2.52724 6.5875 2.54057L6.64083 2.64724C6.9075 3.17057 7.32417 3.58724 7.8475 3.85391L7.95417 3.90724C7.95417 3.90724 7.99417 3.92724 7.99417 3.97057C7.99417 4.01391 7.96417 4.03057 7.95417 4.03391L7.8475 4.08724C7.32417 4.35391 6.9075 4.77057 6.64083 5.29391L6.5875 5.40057C6.5875 5.40057 6.5675 5.44057 6.52417 5.44057C6.48083 5.44057 6.46417 5.41057 6.46083 5.40057L6.4075 5.29391C6.14083 4.77057 5.72417 4.35391 5.20083 4.08724L5.09417 4.03391C5.09417 4.03391 5.05417 4.01391 5.05417 3.97057C5.05417 3.92724 5.08417 3.91057 5.09417 3.90724Z"
        fill="url(#paint2_linear_15868_38920)"
      />
      <path
        d="M14.5975 4.21724L13.9508 3.57057C13.6908 3.31057 13.3442 3.16724 12.9775 3.16724C12.6108 3.16724 12.2642 3.31057 12.0042 3.57057L5.08083 10.4939C5.08083 10.4939 5.07417 10.5039 5.07083 10.5106C5.03083 10.5539 4.9975 10.6006 4.97417 10.6539V10.6606L4.01083 12.9572C3.86083 13.3139 3.94417 13.7206 4.22083 13.9939C4.40083 14.1706 4.6375 14.2639 4.88083 14.2639C5.0075 14.2639 5.1375 14.2372 5.26083 14.1839L7.51083 13.1972L7.52417 13.1906C7.5775 13.1672 7.62083 13.1339 7.66083 13.0972C7.66417 13.0939 7.67083 13.0906 7.6775 13.0872L14.6008 6.16391C14.8608 5.90391 15.0042 5.55724 15.0042 5.19057C15.0042 4.82391 14.8608 4.47724 14.6008 4.21724H14.5975ZM7.32083 12.0272L6.14083 10.8472L11.3275 5.66057L12.5075 6.84057L7.32083 12.0272ZM13.8908 5.45724L13.2142 6.13391L12.0342 4.95391L12.7108 4.27724C12.8508 4.13391 13.1008 4.13391 13.2442 4.27724L13.8908 4.92391C13.9608 4.99391 14.0008 5.09057 14.0008 5.19057C14.0008 5.29057 13.9608 5.38391 13.8908 5.45724Z"
        fill="url(#paint3_linear_15868_38920)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15868_38920"
          x1="-1.53896"
          y1="5.6255"
          x2="6.77172"
          y2="18.2321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3E7BFA" />
          <stop offset="1" stopColor="#6600CC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15868_38920"
          x1="-1.53896"
          y1="5.6255"
          x2="6.77172"
          y2="18.2321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3E7BFA" />
          <stop offset="1" stopColor="#6600CC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15868_38920"
          x1="-1.53896"
          y1="5.6255"
          x2="6.77172"
          y2="18.2321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3E7BFA" />
          <stop offset="1" stopColor="#6600CC" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_15868_38920"
          x1="-1.53896"
          y1="5.6255"
          x2="6.77172"
          y2="18.2321"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3E7BFA" />
          <stop offset="1" stopColor="#6600CC" />
        </linearGradient>
      </defs>
    </svg>
  );
}
