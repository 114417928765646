import { useState } from 'react';
import useApi from '../../../app/api/useApi.ts';
import InputText from '../../design-system/atoms/InputText.tsx';
import WithResultLoading from '../../result/WithResultLoading.tsx';
import useFetchInvalidate from '../../services/useFetch/useFetchInvalidate.ts';
import LoaderSpinnerOverlay from '../LoaderSpinnerOverlay.tsx';
import AdminPanel, {
  AdminPanelField,
  AdminPanelFormContainer,
  AdminPanelSubmit,
} from './adminPanel/AdminPanel.tsx';
import CompanySearch from './CompanySearch.tsx';
import styles from './DeleteCompanyData.module.scss';
import DeletionRequestList from './deletionRequests/DeletionRequests.tsx';
import getPermittedCompanyDeleteEmails from './deletionRequests/getPermittedCompanyDeleteEmails.ts';

type CompanyInfo = {
  id: string;
  name: string;
};

const canUserDeleteCompanyData = (email: string) => {
  const whitelist = getPermittedCompanyDeleteEmails();

  return whitelist.includes(email);
};

type DeleteCompanyDataProps = {
  user: { id: string; email: string };
};

const DeleteCompanyData: React.FC<DeleteCompanyDataProps> = ({ user }) => {
  const [guid, setGuid] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyInfo | null>(
    null,
  );

  const canUserDeleteCompanies = canUserDeleteCompanyData(user.email);

  const { createDeletionRequest, listDeletionRequests, cancelDeletionRequest } =
    useApi();

  const { result, invalidate } = useFetchInvalidate(async () => {
    return canUserDeleteCompanies
      ? listDeletionRequests()
      : new Promise(() => {});
  }, [canUserDeleteCompanies]);

  const onCancelDeletionRequest = async (companyId: string, jobId: string) =>
    await invalidate(() => cancelDeletionRequest(companyId, jobId));

  if (!canUserDeleteCompanies) return null;

  const allFieldsSet = selectedCompany && guid;

  const companyMismatch = allFieldsSet && selectedCompany?.id !== guid;

  return (
    <AdminPanel
      title="All Stop. Quick Quiet."
      subtitle="(Delete a Company's Data)"
      innerWrapClassname={styles.fullWidthInnerWrap}
    >
      <AdminPanelFormContainer>
        <AdminPanelField description="Search for the Company you want to FOREVER delete data for.">
          <CompanySearch
            selectedCompanyId={selectedCompany?.id ?? null}
            onSelectCompany={(company: CompanyInfo) => {
              setSelectedCompany(company);
              setCompanyName(company.name);
            }}
          />

          <InputText label="Company ID" value={guid ?? ''} onChange={setGuid} />

          {companyMismatch && (
            <div style={{ color: 'red' }}>
              Company ID does not match selected company
            </div>
          )}
        </AdminPanelField>

        <AdminPanelSubmit
          disabled={!allFieldsSet || !!companyMismatch}
          onClick={async (e) => {
            e.preventDefault();

            if (!guid || !companyName) return;

            await invalidate(() => createDeletionRequest(guid, companyName));
          }}
        >
          Submit Delete Request
        </AdminPanelSubmit>

        <WithResultLoading
          result={result}
          duration={400}
          renderError={() => <div>Error loading Deletion Requests.</div>}
          renderLoading={() => <LoaderSpinnerOverlay />}
        >
          {(deleteRequests) => (
            <DeletionRequestList
              deleteRequests={deleteRequests}
              cancelDeletionRequest={onCancelDeletionRequest}
            />
          )}
        </WithResultLoading>
      </AdminPanelFormContainer>
    </AdminPanel>
  );
};

export default DeleteCompanyData;
