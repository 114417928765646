import { isCompletedVoteBlock } from '../../../../VoteBlockHydrated.js';
import { isTextSelection } from '../../../../editor/EditorSelection.js';
import { getStartOfBlockSelection } from '../../../../editor/selection/BlockSelection.js';
import { EditorContent } from '../../edit/useEditorState.tsx';
import { BodyState } from '../BodyEditor/BodyEditor.ts';
import { ZeckBodySelection } from '../ZeckEditorSelection.ts';
import { ZeckEditorState } from './ZeckEditorState.js';

// This is normally a pass-thru, but will return modified content and selection
// when a new bodyState attempts to remove undeletable blocks
const getPreservedBodyState = (
  currentState: { content: EditorContent; selection: ZeckBodySelection },
  bodyState: BodyState,
): BodyState => {
  const undeletableBlocks = currentState.content.body
    .filter(isCompletedVoteBlock)
    .filter((block) => !bodyState.content.map((b) => b.id).includes(block.id));

  if (!undeletableBlocks.length) {
    return { content: bodyState.content, selection: bodyState.selection };
  }

  const selectionStart = isTextSelection(currentState.selection)
    ? currentState.selection.index
    : getStartOfBlockSelection(currentState.selection);

  const maybeSelectionIndex = isTextSelection(bodyState.selection)
    ? { index: bodyState.selection.index + +undeletableBlocks.length }
    : {};

  return {
    content: [
      ...bodyState.content.slice(0, selectionStart),
      ...undeletableBlocks,
      ...bodyState.content.slice(selectionStart),
    ],
    selection: bodyState.selection && {
      ...bodyState.selection,
      ...maybeSelectionIndex,
    },
  };
};

export const applyBodyState = (
  currentState: { content: EditorContent; selection: ZeckBodySelection },
  bodyState: BodyState | void,
): ZeckEditorState => {
  if (!bodyState) return currentState;

  const { content, selection } = getPreservedBodyState(currentState, bodyState);

  return {
    content: {
      ...currentState.content,
      body: content,
    },
    selection: selection && {
      target: 'body',
      ...selection,
    },
  };
};
