import cx from 'classnames';
import { AgendaBlock } from 'editor-content/AgendaBlock.js';
import Linkable from 'editor-content/html/Linkable.ts';
import React from 'react';
import DateTimeView from '../../dateTime/DateTimeView.tsx';
import { TimeView } from '../../dateTime/TimeView.tsx';
import {
  getSystemTimezone,
  setMinutesFromMidnightInTimeZone,
} from '../../dateTime/timestamp.ts';
import getAgendaItemCustomDisplayName from '../../pages/zeck/editor/editableBlocks/getAgendaItemCustomDisplayName.ts';
import useBreakpoints from '../../services/useBreakpoints.ts';
import Link from '../atoms/Link.tsx';
import styles from './Agenda.module.scss';

export const AgendaContainer = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>(function AgendaContainer(props, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className={cx(props.className, styles.agendaContainer)}
    >
      {props.children}
    </div>
  );
});

type AgendaPanelProps = React.ComponentProps<'div'> & {
  isMobile: boolean;
};

export const AgendaPanel = React.forwardRef<HTMLDivElement, AgendaPanelProps>(
  function AgendaPanel(
    { className, isMobile, children, ...props },
    forwardedRef,
  ) {
    return (
      <div
        {...props}
        className={cx(
          className,
          styles.agendaPanel,
          isMobile && styles.agendaPanel_mobile,
        )}
        ref={forwardedRef}
      >
        {children}
      </div>
    );
  },
);

type AgendaItemProps = {
  name: React.ReactNode;
  description: React.ReactNode;
  startTime: React.ReactNode;
  isMobile?: boolean;
  url?: string;
} & Omit<React.ComponentProps<'div'>, 'children'>;

export const AgendaItem = React.forwardRef<HTMLDivElement, AgendaItemProps>(
  function AgendaItem(
    { isMobile, name, description, startTime, url, ...props },
    ref,
  ) {
    return (
      <div
        ref={ref}
        {...props}
        className={cx(
          props.className,
          styles.agenda__item,
          isMobile && styles.agenda__item_mobile,
        )}
      >
        <div className={styles.agenda__itemNameAndDescription}>
          <div className={styles.agenda__titleWrap}>
            <div className={styles.agenda__itemStartTime}>{startTime}</div>
            <h3 className={styles.agenda__itemName}>
              {url ? (
                <Link href={url} className={styles.agenda__sectionLink}>
                  {name}
                </Link>
              ) : (
                name
              )}
            </h3>
          </div>
          {description && (
            <div className={styles.agenda__itemDescription}>{description}</div>
          )}
        </div>
      </div>
    );
  },
);

type AgendaTitleProps = React.ComponentProps<'h2'>;

type AgendaDescriptionProps = React.ComponentProps<'p'>;

export const AgendaTitle = React.forwardRef<
  HTMLHeadingElement,
  AgendaTitleProps
>(function AgendaTitle(props, ref) {
  return (
    <h2
      {...props}
      ref={ref}
      data-testid="agenda-title"
      className={cx(styles.agenda__title, props.className)}
    >
      {props.children}
    </h2>
  );
});

export const AgendaDescription = React.forwardRef<
  HTMLParagraphElement,
  AgendaDescriptionProps
>(function AgendaDescription(props, ref) {
  return (
    <p
      {...props}
      ref={ref}
      className={cx(styles.agenda__description, props.className)}
    >
      {props.children}
    </p>
  );
});

type AgendaProps = {
  block: AgendaBlock;
  className?: string;
  linkables: Linkable[];
};

const Agenda = React.forwardRef<HTMLDivElement, AgendaProps>(function Agenda(
  { block, className, linkables },
  forwardedRef,
) {
  const { isMobile } = useBreakpoints();

  const itemsHaveStartTime = block.items.some(
    (item) => typeof item.startTime === 'number',
  );

  return (
    // id is used for linking from pdf
    <AgendaContainer id={block.id} className={className} ref={forwardedRef}>
      <AgendaTitle>{block.title || 'Untitled'}</AgendaTitle>
      {block.startTime && <DateTimeView timestamp={block.startTime} />}
      {block.description && (
        <AgendaDescription>{block.description}</AgendaDescription>
      )}
      <AgendaPanel data-testid="agenda" isMobile={isMobile}>
        {block.items.length > 0 ? (
          block.items.map((item, i) => {
            const startTimeOnDay =
              typeof item.startTime === 'number'
                ? setMinutesFromMidnightInTimeZone(
                    block.startTime ? new Date(block.startTime) : new Date(),
                    item.startTime,
                    block.timeZone || getSystemTimezone(),
                  )
                : null;

            const startTimeColumn = startTimeOnDay ? (
              <TimeView
                timestamp={startTimeOnDay.getTime()}
                className={styles.agenda__itemStartTime}
              />
            ) : itemsHaveStartTime ? (
              <div className={styles.agenda__itemStartTime} />
            ) : null;

            switch (item.type) {
              case 'section': {
                const linkable = linkables.find(
                  (linkable) =>
                    linkable.itemId === item.sectionId &&
                    linkable.type === 'section',
                );

                if (!linkable) {
                  return null;
                }

                return (
                  <AgendaItem
                    key={i}
                    isMobile={isMobile}
                    name={linkable.title}
                    description={item.description}
                    startTime={startTimeColumn}
                    url={linkable.url}
                  />
                );
              }
              case 'custom':
                return (
                  <AgendaItem
                    key={i}
                    isMobile={isMobile}
                    name={getAgendaItemCustomDisplayName(item.name)}
                    description={item.description}
                    startTime={startTimeColumn}
                  />
                );
            }

            return null;
          })
        ) : (
          <div className={styles.agenda__emptyText}>
            There are no items in this agenda.
          </div>
        )}
      </AgendaPanel>
    </AgendaContainer>
  );
});

export default Agenda;
