import { useRef, useState } from 'react';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import DrawerMenu from '../../../../design-system/organisms/DrawerMenu.tsx';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import CompanyName from '../../CompanyName.tsx';
import styles from './CompanyDrawerMenu.module.scss';
import { CompanyMenuButton } from './CompanyMenuButton.js';

type CompanyDrawerMenuProps = {
  userAndCompany: UserAndCompany;
};

export default function CompanyDrawerMenu({
  userAndCompany,
}: CompanyDrawerMenuProps) {
  const [showOtherCompanies, setShowOtherCompanies] = useState(false);
  const dropdownButtonRef = useRef<HTMLLabelElement>(null);

  return (
    <>
      <CompanyMenuButton
        ref={dropdownButtonRef}
        companyName={userAndCompany.activeCompany.name}
        onClickDropdown={() => {
          setShowOtherCompanies(true);
        }}
      />

      <DrawerMenu
        isOpen={showOtherCompanies}
        onRequestClose={() => {
          setShowOtherCompanies(false);
        }}
      >
        <div className={styles.companyMenu__title}>
          Switch Company
          <IconButton
            name="close"
            aria-label="close"
            onClick={() => {
              setShowOtherCompanies(false);
            }}
          />
        </div>
        <div
          className={styles.companyMenu__companies}
          data-testid="other-companies"
        >
          {userAndCompany.userCompanies.map(
            (company) =>
              company.id !== userAndCompany.activeCompany.id && (
                <div
                  key={company.id}
                  data-testid="other-company"
                  className={styles.companyMenu__company}
                  onClick={() => {
                    userAndCompany.setActiveCompany(company);
                  }}
                >
                  <CompanyName
                    companyName={company.name}
                    className={styles.companyMenu__companyName}
                  />
                </div>
              ),
          )}
        </div>
      </DrawerMenu>
    </>
  );
}
