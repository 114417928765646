import { isTextBlock } from 'editor-content/Block.js';
import { HydratedBlock } from '../../../types/HydratedBlock.js';
import isTextBlockEmpty from '../../blocks/isTextBlockEmpty.js';
import textBlockGetEndOfBlockSelectionStrategy from '../../blocks/textBlocksStrategies/textBlockGetEndOfBlockSelectionStrategy.js';
import { contentSelection } from '../../selection/contentSelection/ContentSelection.js';
import { AddBlockBlockEditorInterface } from '../AddBlockEditorActions.js';

const generateZeckBlockEditorAddBlock = (
  block: HydratedBlock,
): AddBlockBlockEditorInterface => {
  return {
    isEmptyDefaultBlock: () =>
      block.type === 'paragraph' && isTextBlockEmpty(block),
    getEndOfBlockSelection: () =>
      isTextBlock(block)
        ? textBlockGetEndOfBlockSelectionStrategy(block)
        : contentSelection(0),
  };
};

export default generateZeckBlockEditorAddBlock;
