import cx from 'classnames';
import React from 'react';
import styles from './TableOfContentsList.module.scss';

type TableOfContentsListProps = {
  title?: React.ReactNode;
  children: React.ReactNode;
};

const TableOfContentsList = React.forwardRef<
  HTMLOListElement,
  TableOfContentsListProps
>(({ children, title, ...props }, ref) => (
  <div {...props} data-testid="table-of-contents-list">
    {title}
    <ol ref={ref} className={cx(styles.tableOfContents_list)}>
      {children}
    </ol>
  </div>
));

export default TableOfContentsList;
