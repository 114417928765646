import { useState } from 'react';
import useApi from '../../../api/useApi.ts';
import Button from '../../../design-system/atoms/Button.tsx';
import InputText from '../../../design-system/atoms/InputText.tsx';
import Toast from '../../../design-system/molecules/toast/Toast.tsx';
import getUIEnv from '../../../junkDrawer/getUIEnv.ts';
import { Company, CompanyUser } from '../../../types.ts';
import AdminPanel from '../adminPanel/AdminPanel.tsx';
import styles from '../createCompany/CreateCompany.module.scss';

const AddToSampleZeck: React.FunctionComponent = () => {
  const { inviteToCompany, updateZeckViewer } = useApi();
  const [emailAddress, setEmailAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  let companyId: string;
  let zeckId: string;

  /**
   * These ids are not secrets, so it's not a security concern really
   * You can set variables in the .env.local to have our local machine work correctly
   */
  switch (getUIEnv()) {
    case 'local': {
      if (
        !import.meta.env.UI_LOCAL_SAMPLE_ZECK_COMPANY_ID ||
        !import.meta.env.UI_LOCAL_SAMPLE_ZECK_ID
      ) {
        return null;
      }

      companyId = import.meta.env.UI_LOCAL_SAMPLE_ZECK_COMPANY_ID;
      zeckId = import.meta.env.UI_LOCAL_SAMPLE_ZECK_ID;
      break;
    }
    case 'development': {
      companyId = 'a301c67c-b687-4df5-a672-b6dfaff909e3';
      zeckId = '5cb4bbfe-a9f2-4f2b-b192-9482d61d7b51';
      break;
    }
    case 'uat': {
      companyId = '1badd076-07e6-469f-8819-d2382ff99d0c';
      zeckId = '01f17088-f3c3-4e64-a33a-094a024dd1c5';
      break;
    }
    case 'production': {
      companyId = '6de8707d-708b-4308-a266-0f36f501cbd7';
      zeckId = 'a12a1051-13cc-4813-a627-33855c485c5b';
      break;
    }
    default: {
      return null;
    }
  }

  return (
    <AdminPanel
      title={'Full Ahead Flank'}
      subtitle={'(Add Someone to the Sample Zeck)'}
    >
      <form
        className={styles.form}
        onSubmit={async (e) => {
          e.preventDefault();

          if (emailAddress.trim().length === 0) {
            return;
          }
          setIsLoading(true);

          const updatedCompany: Company = await inviteToCompany(
            companyId,
            [emailAddress],
            false,
          );

          const newlyCreatedUser: CompanyUser[] = updatedCompany.users.filter(
            (user) => user.email === emailAddress.toLowerCase(),
          );

          if (newlyCreatedUser[0]) {
            await updateZeckViewer(zeckId, newlyCreatedUser[0].id, true);
          } else {
            throw new Error(
              'could not find expected user in the company response! Ejecting!',
            );
          }

          setShowSuccessToast(true);
        }}
      >
        <InputText
          label="Email Address"
          disabled={isLoading}
          onChange={(newName) => {
            setEmailAddress(newName);
          }}
          value={emailAddress}
        />
        <Button size="large" type="submit" color="primary" disabled={isLoading}>
          Add Member / Viewer
        </Button>
      </form>
      <Toast
        message={`wow customer enablement complete ${String.fromCodePoint(
          0x1f354,
        )}`}
        isOpen={showSuccessToast}
        onClose={() => {
          setShowSuccessToast(false);
          setIsLoading(false);
        }}
      />
    </AdminPanel>
  );
};

export default AddToSampleZeck;
