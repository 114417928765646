import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { HoverPortal } from '../../domHelpers/hoverNextTo/HoverLayer.js';
import HoverNextToPoint from '../../domHelpers/hoverNextTo/HoverNextToPoint.js';
import styles from './DragDropExperience.module.scss';
import { ReactComponent as HandleIcon } from './HandleIcon.svg';
import { BlockDnd } from './useDragBlock.js';

export const BlockDndHandle: React.FC<{
  hoveredIndex: number;
  blockdnd: Pick<BlockDnd, 'isDragging' | 'onDragStart'>;
}> = ({ hoveredIndex, blockdnd }) => (
  <HandleIcon
    className={styles.dragDropHandle}
    data-testid="block-dnd-handle"
    onMouseDown={(e) => {
      if (e.button !== 0) return;
      e.stopPropagation();
      e.preventDefault();
      blockdnd.onDragStart(hoveredIndex);
    }}
    style={{ cursor: blockdnd.isDragging ? 'grabbing' : 'grab' }}
  />
);

export const BlockDndOverlay: React.FC<{ blockdnd: BlockDnd }> = ({
  blockdnd,
}) => {
  const dragOverlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dragOverlayEl = dragOverlayRef.current;
    if (!dragOverlayEl) return;
    if (!blockdnd.isDragging) return;

    const newChild = blockdnd.getDraggedEl();
    if (!newChild) return;

    dragOverlayEl.replaceChildren(newChild);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockdnd.isDragging]);

  if (!blockdnd.isDragging) return null;

  return (
    <>
      <HoverNextToPoint
        getPoint={blockdnd.getDragOverlayPos}
        viewportPolicy={'none'}
      >
        <div ref={dragOverlayRef} className={styles.dragDropOverlay} />
      </HoverNextToPoint>
      <HoverPortal>
        <div className={styles.dragDropScrim} />
      </HoverPortal>
    </>
  );
};

export const BlockDndCaret: React.FC<{
  blockdnd: BlockDnd;
  currentIndex: number;
}> = ({ blockdnd, currentIndex }) => {
  return (
    <AnimatePresence>
      {blockdnd.dropIndex === currentIndex && (
        <motion.div
          initial={{ opacity: 0, marginTop: 0 }}
          animate={{ opacity: 1, marginTop: 32, transition: { duration: 0.1 } }}
          exit={{ opacity: 0, marginTop: 0, transition: { duration: 0.1 } }}
        >
          <div className={styles.dragDropCaret} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
