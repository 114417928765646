import { z } from 'zod';

export const ImageBlockSchema = z.object({
  type: z.literal('image'),
  id: z.string().uuid(),
  guid: z.string().uuid(),
  caption: z.string(),
  width: z.enum(['column', 'wide', 'full-width']),
  align: z.enum(['left', 'center']),
  dimensions: z.optional(
    z.object({
      width: z.number(),
      height: z.number(),
    }),
  ),
});
