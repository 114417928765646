import cx from 'classnames';
import { Icon, IconName } from 'icons';
import React from 'react';
import Button from './Button.tsx';
import styles from './ButtonWithIcon.module.scss';

type ButtonWithIconProps = React.ComponentProps<typeof Button> & {
  iconName: IconName;
  position: 'left' | 'right';
};

const ButtonWithIcon = React.forwardRef<HTMLButtonElement, ButtonWithIconProps>(
  ({ iconName, position, children, className, ...props }, forwardedRef) => {
    return (
      <Button
        {...props}
        {...{
          ref: forwardedRef,
          className: cx(className, styles.buttonWithIcon),
        }}
      >
        {position === 'right' ? (
          <>
            {children}{' '}
            <Icon name={iconName} className={styles.buttonWithIcon__icon} />
          </>
        ) : (
          <>
            <Icon
              name={iconName}
              className={cx(
                styles.buttonWithIcon__icon,
                styles.buttonWithIcon__icon_left,
              )}
            />{' '}
            {children}
          </>
        )}
      </Button>
    );
  },
);

export default ButtonWithIcon;
