import { useEffect, useState } from 'react';
import {
  ErrorResult,
  LoadingResult,
  SuccessResult,
} from '../../result/Result.js';
import { Result } from './useFetch.js';

const useFetchInvalidate = <T>(fn: () => Promise<T>, deps: Array<unknown>) => {
  const [result, setResult] = useState<Result<T>>(LoadingResult());

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setResult(LoadingResult());
      try {
        const results = await fn();
        if (!isCancelled) {
          setResult(SuccessResult(results));
        }
      } catch (e) {
        if (!isCancelled) {
          setResult(ErrorResult(undefined));
        }
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  const invalidate = async (
    action: () => Promise<void>,
    optimisticData?: T,
  ) => {
    if (optimisticData) {
      setResult(SuccessResult(optimisticData));
    }

    await action();
    try {
      setResult(SuccessResult(await fn()));
    } catch (e) {
      setResult(ErrorResult(undefined));
    }
  };

  return {
    result,
    invalidate,
  };
};

export default useFetchInvalidate;
