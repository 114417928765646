import cx from 'classnames';
import React, { ReactNode } from 'react';
import styles from './Title.module.scss';

type TitleProps = {
  children?: ReactNode;
  className?: string;
} & React.ComponentProps<'h1'>;

const Title = React.forwardRef<HTMLHeadingElement, TitleProps>(function Title(
  { className, ...props },
  forwardedRef,
) {
  return (
    <h1
      {...{
        ...props,
        ref: forwardedRef,
        className: cx(styles.title, className),
      }}
    >
      {props.children}
    </h1>
  );
});

export default Title;
