import { useCallback } from 'react';
import InputAutoSaveCheckbox from '../../../design-system/atoms/InputAutoSaveCheckbox.tsx';
import ZeckWithActions from '../edit/zeckCover/ZeckWithActions.ts';
import styles from './ZeckSettings.module.scss';

type ZeckSettingsProps = {
  zeck: Pick<ZeckWithActions, 'settings' | 'actions'>;
};

const ZeckSettings: React.FC<ZeckSettingsProps> = ({ zeck }) => {
  const updateDisablePdf = useCallback(
    async (checked: boolean) => {
      await zeck.actions.update({
        settings: {
          ...zeck.settings,
          disablePrintToPdf: checked,
        },
      });
    },
    [zeck],
  );

  const updateDisableComments = useCallback(
    async (checked: boolean) => {
      await zeck.actions.update({
        settings: {
          ...zeck.settings,
          disableComments: checked,
        },
      });
    },
    [zeck],
  );

  return (
    <div data-testid="zeck-settings-modal">
      <div className={styles.zeckSettings__header}>Settings</div>
      <div className={styles.zeckSettings__body}>
        <div className={styles.zeckSettings__section_header}>Export to PDF</div>
        <InputAutoSaveCheckbox
          label={
            <div>
              <div className={styles.zeckSettings__option_header}>
                Disable Export to PDF
              </div>
              <div>
                Viewers will not be able to export the published Zeck to a PDF.
              </div>
            </div>
          }
          value={zeck.settings.disablePrintToPdf}
          onChange={updateDisablePdf}
        />

        <div className={styles.zeckSettings__section_header}>Comments</div>
        <InputAutoSaveCheckbox
          label={
            <div>
              <div className={styles.zeckSettings__option_header}>
                Disable comments in the published version
              </div>
              <div>
                Viewers will no longer see or be able to comment on the
                Published version of this Zeck
              </div>
            </div>
          }
          value={zeck.settings.disableComments}
          onChange={updateDisableComments}
        />
      </div>
    </div>
  );
};

export default ZeckSettings;
