import { TableBlock } from 'editor-content/TableBlock.js';
import { useAtomValue, useSetAtom } from 'jotai';
import { equals } from 'lodash/fp.js';
import { useRef } from 'react';
import { match, P } from 'ts-pattern';
import Point from '../../domHelpers/Point.ts';
import { SelectionCommentsFeatureAtom } from '../../pages/zeck/previewAndPublish/publish/commentsSidebar/useComments/selectionComments/SelectionComments.js';
import { DisownedCommentIndicatorFromAtom } from '../../pages/zeck/previewAndPublish/publish/selectionComments/DisownedCommentIndicator.js';
import PublishedSelectionCommentMenu from '../../pages/zeck/previewAndPublish/publish/selectionComments/PublishedSelectionCommentMenu.js';
import { getTableGridDimensions } from './table/getTableGridDimensions.js';
import TableFromBlock from './table/TableFromBlock.js';
import { TableSelection } from './table/TableSelection.js';

type TableViewProps = {
  block: TableBlock;
  selectionCommentsFeatureAtom: SelectionCommentsFeatureAtom;
  className?: string;
};

function TableViewWithComments({
  className,
  block,
  selectionCommentsFeatureAtom,
}: TableViewProps) {
  const selectionCommentsFeature = useAtomValue(selectionCommentsFeatureAtom);
  const selectedHighlightId = useAtomValue(
    selectionCommentsFeature.selectedCommentIdAtom,
  );

  const selectionCommentHighlights = useAtomValue(
    selectionCommentsFeature.getBlockHighlights(block.id),
  );

  const highlights = selectionCommentHighlights
    .map((highlight) =>
      match(highlight)
        .with(
          {
            type: P.union('valid', 'edited'),
            selection: { columnIndex: P.number, rowIndex: P.number },
          },
          (highlight) => highlight,
        )
        .otherwise(() => null),
    )
    .filter((a) => !!a);

  const uiStateAtoms = useAtomValue(
    selectionCommentsFeature.blockSpecificUIAtomFamily(block.id),
  );

  const selection = match(useAtomValue(uiStateAtoms.selection))
    .with(
      { rowIndex: P.number, columnIndex: P.number },
      (selection) => selection,
    )
    .otherwise(() => null);

  const changeSelection = useSetAtom(uiStateAtoms.changeSelection);
  const selectComment = useSetAtom(selectionCommentsFeature.selectCommentAtom);

  const handleSelectCell = (selection: TableSelection) => {
    changeSelection(selection);
    const maybeSelectedHighlight = highlights.find((highlight) =>
      equals(highlight.selection, selection),
    );

    if (maybeSelectedHighlight) {
      selectComment(maybeSelectedHighlight.commentId);
    }
  };

  const selectFirstCommentForBlock = useSetAtom(
    selectionCommentsFeature.selectFirstCommentForBlockAtom,
  );

  const { cellRefAt, getMenuPosition } = useTableCellSelectionHover(
    block,
    selection,
  );

  return (
    <DisownedCommentIndicatorFromAtom
      className={className}
      onClickCommentIndicator={() => selectFirstCommentForBlock(block.id)}
      selectedCommentIdAtom={selectionCommentsFeature.selectedCommentIdAtom}
      highlightsAtom={selectionCommentsFeature.getBlockHighlights(block.id)}
    >
      <TableFromBlock
        className={className}
        block={block}
        noBorder
        selection={selection}
        onSelectCell={handleSelectCell}
        setCellRefAt={cellRefAt}
        highlights={highlights}
        highlightSelectionId={selectedHighlightId}
      />
      <PublishedSelectionCommentMenu
        block={block}
        selectionCommentsFeatureAtom={selectionCommentsFeatureAtom}
        publishedCommentSelectionUIStateAtom={selectionCommentsFeature.blockSpecificUIAtomFamily(
          block.id,
        )}
        getMenuPosition={getMenuPosition}
      />
    </DisownedCommentIndicatorFromAtom>
  );
}

const useTableCellSelectionHover = (
  block: TableBlock,
  selection: TableSelection | null,
) => {
  const tableGridDimensions = getTableGridDimensions(block);
  const tableCellsRef = useRef<(HTMLElement | null)[][]>(
    Array.from({ length: tableGridDimensions.numRows }, () =>
      Array.from({ length: tableGridDimensions.numCols }, () => null),
    ),
  );

  const cellRefAt =
    (rowIndex: number, columnIndex: number) => (el: HTMLElement | null) => {
      const row = tableCellsRef.current[rowIndex];
      if (!row) {
        const newRow = Array.from(
          { length: tableGridDimensions.numCols },
          (): HTMLElement | null => null,
        );
        newRow[columnIndex] = el;
        tableCellsRef.current[rowIndex] = newRow;
        return;
      }
      row[columnIndex] = el;
    };

  const getMenuPosition = (childElement: HTMLElement): Point | null => {
    if (!selection) return null;
    const selectedCellEl =
      tableCellsRef.current[selection.rowIndex]?.[selection.columnIndex];
    if (!selectedCellEl) return null;
    const { right, bottom } = selectedCellEl.getBoundingClientRect();
    const { width: childElementWidth } = childElement.getBoundingClientRect();
    return [right - childElementWidth, bottom + 8];
  };

  return { cellRefAt, getMenuPosition };
};

export default TableViewWithComments;
