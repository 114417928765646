import getSelectedBlock from '../../pages/zeck/editor/BodyEditor/getSelectedBlock.js';
import { EditorConfiguration, EditorState } from '../EditorAction.js';
import { isTextSelection } from '../EditorSelection.js';
import ContentSelection from '../selection/contentSelection/ContentSelection.js';
import { TextSelection, textSelection } from '../selection/TextSelection.js';

export type PressArrowLeftBlockInterface = {
  isAtBeginningOfBlock: (selection: ContentSelection) => boolean;
  getEndOfBlockSelection: () => ContentSelection;
};

type PressArrowLeftResult =
  | {
      type: 'selection';
      selection: TextSelection;
    }
  | { type: 'boundary' }
  | void;

export const pressArrowLeft =
  <BlockType>({
    generateBlockEditor,
  }: EditorConfiguration<BlockType, PressArrowLeftBlockInterface>) =>
  (initialState: EditorState<BlockType>): PressArrowLeftResult => {
    const { content, selection } = initialState;
    if (isTextSelection(selection)) {
      const currentBlock = getSelectedBlock(content, selection);
      if (!currentBlock) return;
      const isAtBeginningOfBlock = generateBlockEditor(
        currentBlock,
      ).isAtBeginningOfBlock(selection.offset);

      if (!isAtBeginningOfBlock) return;

      const newBlockIndex = selection.index - 1;
      const previousBlock = content[newBlockIndex];
      if (!previousBlock) return { type: 'boundary' };

      const newContentSelection =
        generateBlockEditor(previousBlock).getEndOfBlockSelection();
      return {
        type: 'selection',
        selection: textSelection(newBlockIndex, newContentSelection),
      };
    }
  };
