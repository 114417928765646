import cx from 'classnames';
import { ComponentProps } from 'react';
import ReactModal from 'react-modal';
import IconButton from '../atoms/IconButton.tsx';
import styles from './Modal.module.scss';

const noop = () => null;

type ModalContainerProps = {
  isOpen: boolean;
  onRequestClose?: () => void;
  children: ReactModal.Props['children'];
  className?: string;
  overlayClassName?: string;
};

export const ModalContainer = ({
  isOpen,
  onRequestClose = noop,
  className,
  overlayClassName,
  children,
}: ModalContainerProps) => {
  const isTest = import.meta.env.TEST;

  return (
    <ReactModal
      {...{
        isOpen,
        onRequestClose,
        testId: 'modal',
        ariaHideApp: false,
        closeTimeoutMS: !isTest ? 200 : undefined,
        className: {
          base: cx(styles.modal__container, className),
          afterOpen: styles.modal__container_afterOpen,
          beforeClose: styles.modal__container_beforeClose,
        } as ReactModal.Classes,
        overlayClassName: {
          base: cx(styles.modal__overlay, overlayClassName),
          afterOpen: styles.modal__overlay_afterOpen,
          beforeClose: styles.modal__overlay_beforeClose,
        } as ReactModal.Classes,
      }}
    >
      {children}
    </ReactModal>
  );
};

type ModalProps = {
  isOpen: boolean;
  onRequestClose?: () => void;
  children: React.ReactNode;
  dark?: boolean;
  className?: string;
  overlayClassName?: string;
};

const Modal = ({
  isOpen,
  onRequestClose,
  className,
  overlayClassName,
  dark,
  children,
}: ModalProps) => {
  return (
    <ModalContainer
      {...{
        isOpen,
        onRequestClose,
        overlayClassName,
      }}
    >
      <ModalPanel dark={dark} className={className}>
        {children}
      </ModalPanel>
    </ModalContainer>
  );
};

type ModalHeaderProps = {
  onClickClose?: ComponentProps<typeof IconButton>['onClick'];
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
};

export const ModalHeader = ({
  children,
  onClickClose,
  className,
  containerClassName,
}: ModalHeaderProps) => {
  return (
    <div className={cx(styles.modal__header, containerClassName)}>
      <div className={cx(styles.modal__heading, className)}>{children}</div>
      {onClickClose && (
        <IconButton
          {...{
            name: 'close',
            'aria-label': 'close modal',
            onClick: onClickClose,
            className: styles.modal__closeButton,
          }}
        />
      )}
    </div>
  );
};

type ModalBodyProps = {
  children: React.ReactNode;
  className?: string;
};

export const ModalBody = ({ children, className }: ModalBodyProps) => {
  return <div className={cx(styles.modal__body, className)}>{children}</div>;
};

type ModalActionsProps = {
  children: React.ReactNode;
  className?: string;
};

export const ModalActions = ({ children, className }: ModalActionsProps) => {
  return <div className={cx(styles.modal__actions, className)}>{children}</div>;
};

type ModalPanelProps = {
  children: React.ReactNode;
  dark?: boolean;
  className?: string;
  sidebar?: React.ReactNode;
};

type ModalSidebarItemProps = {
  active: boolean;
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
};

export const ModalSidebarItem: React.FC<ModalSidebarItemProps> = ({
  icon,
  label,
  onClick,
  active,
}) => {
  return (
    <div
      onClick={onClick}
      className={cx([
        styles.modal_sidebar_item,
        active && styles.modal_sidebar_item_active,
      ])}
    >
      {icon}

      <div>{label}</div>
    </div>
  );
};

type ModalSidebarWrapperProps = {
  sidebar: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

export const ModalWithSidebar: React.FC<ModalSidebarWrapperProps> = ({
  sidebar,
  children,
  className,
}) => {
  return (
    <div className={cx(styles.modal__panel_with_sidebar, className)}>
      {!!sidebar && <div className={styles.modal_sidebar}>{sidebar}</div>}
      <div className={styles.modal__panel_with_sidebar_body}>{children}</div>
    </div>
  );
};

export const ModalPanel: React.FunctionComponent<ModalPanelProps> = ({
  children,
  dark,
  className,
}) => {
  return (
    <div
      className={cx(
        styles.modal__panel,
        dark && styles.modal__panel_dark,
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Modal;
